import AccountPage from '../../Component/Elements/Page/AccountPage/AccountPage';
import LoginInputContainer from './components/LoginInputContainer/LoginInputContainer';
import CSS from "./static/css/login.module.css";

function Login() {
    return (
        <AccountPage>
            <div className={CSS.l_Login_main}>
                <LoginInputContainer />
            </div>
        </AccountPage>
    );
}
export default Login;
