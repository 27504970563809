import CSS from "./static/css/FolderList.module.css";
import { useState } from "react";
import CardContainer from "../../../../Component/Elements/CardContainer/CardContainer";
import { ProductDetail_Folder_FolderListEle_Interface } from "../../static/interface/ProductDetail.interface";
import FolderEle from "../FolderEle/FolderEle";
import Hr from "../../../../Component/Elements/Hr/Hr";
import FolderImageOverlay from "../FolderImageOverlay/FolderImageOverlay";

const FolderList = (
    {
        folder_list,
        is_have_options,
        eleClickHandler,
        ele_refs,
        readOnly
    }:{
        folder_list: ProductDetail_Folder_FolderListEle_Interface[];
        is_have_options?: boolean;
        eleClickHandler?: (folder_id: number) => void;
        ele_refs: React.MutableRefObject<Array<(HTMLDivElement | null)>>;
        readOnly?: boolean;
    }
) => {
    const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);
    const [folderDetailImgSrc, setFolderDetailImgSrc] = useState<string>("");

    const overlayOpenHandler = (detailImgSrc: string) => {
        setFolderDetailImgSrc(detailImgSrc);
        setIsOverlayOpen(true);
    }

    const overlayCloseHandler = () => {
        setIsOverlayOpen(false);
        setFolderDetailImgSrc("");
    }

    return(
        <CardContainer class_name_list={[CSS.l_FolderList_main]}>
            {
                folder_list.map((item) => {
                    return(
                        <FolderEle 
                            key={item.folder_id}
                            foler_name={item.folder_name}
                            onClick={eleClickHandler?.bind(null, item.folder_id)}
                            detail_click_handler={item.middle_img_url ? overlayOpenHandler.bind(null, item.middle_img_url) : undefined}
                            // is_active={false}
                            ref={(ele) => ele_refs.current[item.folder_id] = ele}
                        />
                    )
                })
            }

            {
                is_have_options && !readOnly
                && <Hr />
            }

            {
                is_have_options && !readOnly
                &&  <FolderEle 
                        foler_name={"옵션 선택"}
                        onClick={eleClickHandler?.bind(null, -1)}
                        // is_active={false}
                        ref={(ele) => ele_refs.current[-1] = ele}
                    />
            }

            <FolderImageOverlay 
                isOverlayOpen={isOverlayOpen}
                overlayCloseHandler={overlayCloseHandler}
                folderDetailImgSrc={folderDetailImgSrc}
            />
        </CardContainer>
    )
}

export default FolderList;