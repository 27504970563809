import { DatePicker, TimePicker } from "../../../../Component/Elements/Datepicker/Datepicker";
import Input from "../../../../Component/Elements/Input/Input";
import Text from "../../../../Component/Elements/Text/Text";
import CSS from "./static/css/BabyInfoInput.module.css";

export const BabyInfoInputName = (
    {
        value,
        onChange
    }:{
        value?: string | number;
        onChange?: React.ChangeEventHandler<HTMLInputElement>;
    }
) => {
    return(
        <div className={`${CSS.l_BabyInfoInput_main} ${CSS.Name}`}>
            <Input
                value={value}
                onChange={onChange}
                placeholder="아이 이름(영문)"
            />
        </div>
    )
}

export const BabyInfoInputBirthDay = (
    {
        value,
        onChange
    }:{
        value: Date | null;
        onChange?: ((date: Date | null) => void);
    }
) => {
    return(
        <div className={`${CSS.l_BabyInfoInput_main} ${CSS.BirthDay}`}>
            <DatePicker 
                dateValue={value}
                onChange={onChange}
                placeholderText={"아이 생일"}
            />
        </div>
    )
}

export const BabyInfoInputBirthTime = (
    {
        value,
        onChange
    }:{
        value: Date | null;
        onChange?: ((date: Date | null) => void);
    }
) => {
    return(
        <div className={`${CSS.l_BabyInfoInput_main} ${CSS.BirthTime}`}>
            <TimePicker 
                dateValue={value}
                onChange={onChange}
                placeholderText={"출생 시간"}
            />
        </div>
    )
}

export const BabyInfoInputTall = (
    {
        value,
        onChange
    }:{
        value?: string | number;
        onChange?: React.ChangeEventHandler<HTMLInputElement>;
    }
) => {
    return(
        <div className={`${CSS.l_BabyInfoInput_main} ${CSS.Tall}`}>
            <Input
                value={value}
                onChange={onChange}
                placeholder={"아이 키"}
                type={"number"}
                min={0}
                max={9999}
            />
            <Text>
                cm
            </Text>
        </div>
    )
}

export const BabyInfoInputWeigth = (
    {
        value,
        onChange
    }:{
        value?: string | number;
        onChange?: React.ChangeEventHandler<HTMLInputElement>;
    }
) => {
    return(
        <div className={`${CSS.l_BabyInfoInput_main} ${CSS.Weigth}`}>
            <Input 
                value={value}
                onChange={onChange}
                placeholder={"아이 몸무게"}
                type={"number"}
                min={0}
                max={99999}
            />
            <Text>
                kg
            </Text>
        </div>
    )
}