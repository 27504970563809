import CSS from "./static/css/WrokRequiredItem.module.css";
import WrokRequiredItemContainer from "../WrokRequiredItemContainer/WrokRequiredItemContainer";
import Text from "../../../../../Component/Elements/Text/Text";
import { MouseEventHandler } from "react";

const WrokRequiredItem = (
    {
        text,
        item_num,
        class_name_list,
        onClick,
    }:{
        text: string;
        item_num: number;
        class_name_list?: string[];
        onClick?: MouseEventHandler<HTMLDivElement>;
    }
) => {
    return(
        <WrokRequiredItemContainer 
            preset={"item"}
            class_name_list={class_name_list}
            onClick={onClick}
        >
            <div className={CSS.l_WrokRequiredItem_main}>
                <Text class_name_list={[CSS.l_WrokRequiredItem_titleText]}>
                    {text}
                </Text>
                <Text 
                    class_name_list={[CSS.l_WrokRequiredItem_numText]}
                    color={item_num <= 0 ? "gray4" : undefined}
                    text_decoration={item_num > 0 ? "underline" : undefined}
                    bold={true}
                >
                    {item_num}건
                </Text>
            </div>
        </WrokRequiredItemContainer>
    )
}

export default WrokRequiredItem;