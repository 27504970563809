import { photoTypeSelecterItemInterface } from "../interface/PhotoList.interface";

// PHOTO_LIST_FILTER_CODE 값은 PHOTO_TYPE_SELECTER_ITEM_LIST의 index조회에 직접적으로 사용되므로 절대 값을 수정하면 안됩니다.
export const PHOTO_LIST_FILTER_CODE = {
    shoot: 0,
    review_event: 1,
    product_request: 2,
} as const;

export const PHOTO_TYPE_CODE = {
    contract_shoot: 1,
    event_shoot: 2,
} as const;

export const PHOTO_DETAILS_TYPE_CODE = {
    origin: 1,
    editing: 2,
    edit_end: 3,
} as const;

export const PHOTO_TYPE_SELECTER_ITEM_LIST: photoTypeSelecterItemInterface[] = [
    {
        photo_type: PHOTO_TYPE_CODE.contract_shoot,
        photo_details_type: PHOTO_DETAILS_TYPE_CODE.origin,
    },{
        photo_type: PHOTO_TYPE_CODE.event_shoot,
        photo_details_type: PHOTO_DETAILS_TYPE_CODE.origin,
    },
    {
        photo_type: PHOTO_TYPE_CODE.contract_shoot,
        photo_details_type: PHOTO_DETAILS_TYPE_CODE.edit_end,
    }
]

export const REVIEW_EVENT_READY_CODE = {
    ready: 1,   // 이벤트 참여 가능
    not_ready: 2, // 이벤트 참여 불가능
} as const;