import { DayCellContentArg, EventApi } from "@fullcalendar/core";
import { dateObjectManagerClass, dev_console } from "../../../../../../Common/ts/commonTools";
import { useEffect, useMemo } from "react";

const dateObjectManager = new dateObjectManagerClass();

const useDayCellSetter = (
    {
        selectedDate,
        dayCellInfo,
    }:{
        selectedDate: Date | null;
        dayCellInfo: DayCellContentArg;
    }
) => {

    // api를 통해 이벤트 정보를 가져온다.
    const events = dayCellInfo.view.calendar.getEvents();

    // 이벤트 일자인지 필터링하는 함수
    const isEventDate = (event: EventApi) => {

        // 시간값을 제거한 순수한 날짜로만 이루어진 Date객체로 변환단다.
        let init_today_date = dateObjectManager.removeTime(dayCellInfo.date);

        // 이벤트 시작날짜: 시간값을 제거한 순수한 날짜로만 이루어진 Date객체로 변환단다.
        let init_event_start: Date | null = null;
        if(event.start){
            init_event_start = dateObjectManager.removeTime(event.start);
        }
        
        // 이벤트 종료 날짜: 시간값을 제거한 순수한 날짜로만 이루어진 Date객체로 변환단다.(당일에만 진행되는 이벤트일경우 null일 수 있다.)
        let init_event_end: Date | null = null;
        if(event.end){
            init_event_end = dateObjectManager.removeTime(event.end);
        }
        
        let RTN: boolean = false;

        IsEvent:{
            // 오늘 Date 객체를 확보하지 못함.
            if(!init_today_date){
                RTN = false;
                break IsEvent;
            }

            // 시작일자 Date객체가 없거나 시작일이 오늘보다 이후임(이벤트 일자 정보 자체를 처리하지 못했거나 이벤트 일이 아님)
            if(!init_event_start || init_event_start.getTime() > init_today_date.getTime()){
                RTN = false;
                break IsEvent;
            }

            // 끝 일자가 Date객체가 존재하는데, 오늘이 오기 전임(일정이 이미 끝남)
            if(init_event_end && init_event_end.getTime() < init_today_date.getTime()){
                RTN = false;
                break IsEvent;
            }
            
            // 끝 일자가 Date객체가 존재하지 않는데, 시작 일자 Date와 오늘 Date의 시간이 일치하지 않음.(당일 이벤트인데 이미 지나감)
            if(!init_event_end && init_event_start.getTime() !== init_today_date.getTime()){
                RTN = false;
                break IsEvent;
            }

            RTN = true;
        }

        return RTN;
    }

    // 해당 날짜에 존재하는 이벤트만 필터링
    const l_today_events = events.filter((event) => 
        isEventDate(event)
    );

    // (클릭해서) 선택한 일자인지 확인하는 함수
    const isSelectedDate = useMemo(() => {
        let RTN: boolean = false;
    
        let init_selected_date: Date | null = null;
        
        if(selectedDate){
            init_selected_date = dateObjectManager.removeTime(selectedDate);
        }
    
        if(init_selected_date && init_selected_date.getTime() === dayCellInfo.date.getTime()){
            // total_class_name_list.push(CSS.l_selected);
            RTN = true
        }

        return RTN;
    }, [selectedDate])

    // useEffect(() => {
    //     if(l_today_events.length > 0){
    //         for(const item of l_today_events){
    //             dev_console.log(item.extendedProps.type);
    //         }
    //     }
    // }, [l_today_events])

    return({
        isToday: dayCellInfo.isToday,
        today_events: l_today_events,
        isSelected: isSelectedDate,
        isSunday: dayCellInfo.date.getDay() === 0,
    })
}

export default useDayCellSetter;