import CSS from "./static/css/ShootList.module.css";
import CardContainer from "../../../../Component/Elements/CardContainer/CardContainer";
import useShootList from "./hooks/useShootList.hook";
import { schedulePhotoProductThemeInterface } from "../../static/interface/selectTheme.interface";
import ShootListItem from "../ShootListItem/ShootListItem";

const ShootList = (
    {
        selectedThemeList,
        ref_list,
        itemClickHandler,
        topEleIndex,
    }:{
        selectedThemeList: schedulePhotoProductThemeInterface[];
        ref_list: React.MutableRefObject<Array<(HTMLDivElement | null)>>;
        itemClickHandler: (index: number) => void;
        topEleIndex: number;
    }
) => {
    const {
        shootListItemList
    } = useShootList({
        selectedThemeList
    })

    if(shootListItemList.length > 0){
        return(
            <CardContainer>
                <div className={CSS.l_ShootList_main}>
                    {
                        shootListItemList.map((item, index) => {
                            return(
                                <ShootListItem 
                                    key={item.schedule_photo_product_id}
                                    shoot_name={`${item.shoot_name} 촬영`}
                                    ref={(el) => (ref_list.current[index] = el)}
                                    onClick={() => {
                                        itemClickHandler(index);
                                    }}
                                    is_highlight={topEleIndex === index}
                                />
                            )
                        })
                    }
                </div>
            </CardContainer>
        )
    }else{
        return (null);
    }
}

export default ShootList;