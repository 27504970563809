import CardContainer from "../../../../Component/Elements/CardContainer/CardContainer";
import Hr from "../../../../Component/Elements/Hr/Hr";
import { ProductDetail_Folder_FolderListEle_Interface } from "../../static/interface/ProductDetail.interface";
import UploadImageContainer from "../UploadImageContainer/UploadImageContainer";

const UploadImageList = (
    {
        folderList,
        imgFiles,
        setImgFiles,
        isSatisfiedPhotoNums,
        setIsSatisfiedPhotoNums,
        cardRefs,
        min_resolution,
        min_long_size,
        min_short_size,
    }:{
        folderList: ProductDetail_Folder_FolderListEle_Interface[];
        imgFiles?: {[key: number]: File[];};
        setImgFiles?: React.Dispatch<React.SetStateAction<{[key: number]: File[];}>>;
        isSatisfiedPhotoNums?: {[key:number]: boolean;};
        setIsSatisfiedPhotoNums?: React.Dispatch<React.SetStateAction<{[key:number]: boolean;}>>;
        cardRefs?: React.MutableRefObject<(HTMLDivElement | null)[]>;
        min_resolution: number;
        min_long_size: number;
        min_short_size: number;
    }
) => {
    return(
        <CardContainer preset={"SelectProduct_SelectArea"}>
            {
                folderList.length > 0
                &&  folderList.map((item, index) => {
                        return(
                            <div 
                                key={index}
                                ref={
                                    cardRefs
                                    ?   (el) => cardRefs.current[item.folder_id] = el 
                                    :   undefined
                                }
                                // FolderImageViewer컴포넌트에서 읽어서 사용한다.
                                data-folder-id={item.folder_id}
                            >
                                <UploadImageContainer
                                    key={index}
                                    imgFiles={imgFiles}
                                    setImgFiles={setImgFiles}
                                    folder={item}
                                    needPhotoNum={{
                                        min: item.min_photo_count,
                                        max: item.max_photo_count,
                                    }}
                                    isSatisfiedPhotoNums={isSatisfiedPhotoNums}
                                    setIsSatisfiedPhotoNums={setIsSatisfiedPhotoNums}
                                    min_resolution={min_resolution}
                                    min_long_size={min_long_size}
                                    min_short_size={min_short_size}
                                />

                                {
                                    // 조금 고전적인 방식이지만, 사이에 줄 삽입
                                    folderList.length > index + 1
                                    &&  <Hr />
                                }
                            </div>
                        )
                    })
            }
        </CardContainer>
    )
}

export default UploadImageList;