import { MouseEventHandler, useEffect, useState } from "react";
import Text from "../../../../Component/Elements/Text/Text";
import CSS from "./static/css/SelectedOptionEle.module.css";
import IconX from "../../../../Component/Elements/Icon/IconX/IconX";

const SelectedOptionEle = (
    {
        option_name,
        onClick
    }:{
        option_name: string;
        onClick?: MouseEventHandler<HTMLDivElement>;
    }
) => {
    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    function classNameSetter(){
        const RTN: string[] = [];

        if(onClick){
            RTN.push("g_click_event_item");
        }

        RTN.push(CSS.l_SelectedOptionEle_main);
        
        return RTN;
    }

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "));
    }, [onClick])

    return(
        <div 
            className={mainClassName}
            onClick={onClick}
        >
            <Text is_text_truncation_on={true}>
                {option_name}
            </Text>

            {
                onClick
                &&  <IconX />
            }
        </div>
    )
}

export default SelectedOptionEle;