import { whichBrowserDoYouUse } from "../../../../Common/ts/commonTools";
import Button from "../../../../Component/Elements/Button/Normal/Button";
import useImageZipDownloadREST from "./hooks/useImageZipDownloadREST.hook";
import DownloadLoadingModalForKakao from "../DownloadLoadingModal/DownloadLoadingModalForKakao";
import DownloadLoadingModal from "../DownloadLoadingModal/DownloadLoadingModal";
import { BROWSER_CODE } from "../../../../Common/ts/const";

const ImageZipDownload = (
    {
        download_type,
        file_name,
        photo_upload_id,
    }:{
        download_type: number;
        file_name: string;
        photo_upload_id: number;
    }
) => {

    const {
        isDownloading,
        downloadHandler,
        downloadingEndHandler,
        file_size,
        download_time,
    } = useImageZipDownloadREST({
        download_type,
        photo_upload_id,
        file_name,
    })

    // useEffect(() => {
    //     const handleBeforeUnload = (event: Event) => {
    //         setIsDownloading(false); // 다운로드가 시작되면 모달 닫기
    //     };

    //     window.addEventListener('beforeunload', handleBeforeUnload);

    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    // }, []);

    const l_browserCode = whichBrowserDoYouUse();

    if(isDownloading){
        return(
            <Button
                size={"size3"}
                isHidden={"mobile"}
                // purpose={"disabled"}
            >
                다운로드가 진행중입니다.
            </Button>
        )
    }else{
        return(
            <>
                <Button
                    onClick={downloadHandler}
                    isHidden={"mobile"}
                    size={"size3"}
                >
                    사진 다운로드
                </Button>

                {/* 다운로드 로딩 처리 */}
                {
                    (l_browserCode === BROWSER_CODE.KakaoTalk || l_browserCode === BROWSER_CODE.LINE)
                    ?   <DownloadLoadingModalForKakao
                            isModalOpen={isDownloading}
                            modalCloseHandler={downloadingEndHandler}
                            fileSize={file_size}
                            download_time={download_time}
                            auto_close={2}
                        />
                    :   <DownloadLoadingModal
                            isModalOpen={isDownloading}
                            fileSize={file_size}
                            download_time={download_time}
                        />
                }
            </>
        )
    }
}

export default ImageZipDownload;