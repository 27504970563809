import { fileCodeType } from "../../../../../Common/interface/Common.interface";
import { FILE_CODE } from "../../../../../Common/ts/const";
import { ProductDetail_Folder_PhotoListEle_Interface } from "../../../../../CustomerPage/ProductManufacturing/static/interface/ProductDetail.interface";
import { viewerImageInterface } from "../../../ImageGallery/interface/ImageGallery.interface";

export function uploadImageListViewMode_imgListSetter(
    {
        folder_id,
        all_photo_list
    }:{
        folder_id: number;
        all_photo_list: ProductDetail_Folder_PhotoListEle_Interface[];
    }
){
    let RTN: viewerImageInterface[] = [];

    const m_target_img_list = all_photo_list.find((item) => item.folder_id === folder_id);

    let m_file_type_code: fileCodeType = FILE_CODE.none;
    
    if(m_target_img_list){
        RTN = m_target_img_list.photo_list.map((item) => {
            // 서버에서 소문자로 줄수도 대문자로 줄 수도 있어서 소문자로 통일시키는 작업을 거친다
            const m_file_type = typeof item.file_type === "string" ? item.file_type.toLowerCase() : ""

            switch(m_file_type){
                case "jpg":
                    m_file_type_code = FILE_CODE.img;
                    break;
                case "mp4":
                    m_file_type_code = FILE_CODE.video;
                    break;
                case "zip":
                    m_file_type_code = FILE_CODE.zip;
                    break;
            }

            return({
                thumbnail_url: item.middle_img_url,
                origin_url: item.middle_img_url,
                file_type: m_file_type_code,
                file_name: item.key,
                folder_id: item.folder_id,
            })
        })
    }

    return RTN;
}