import CSS from "./static/css/PaymentHistoryListHeader.module.css";
import ContractAmountDetailPart from "../elements/ContractAmountDetailPart/ContractAmountDetailPart";
import PayDate from "../elements/ContractDate/ContractDate";
import ContractId from "../elements/ContractId/ContractId";
import MorePayInfo from "../elements/MorePayInfo/MorePayInfo";
import ProductName from "../elements/ProductName/ProductName";
import ContractDocsImgViewIconBtn from "../ContractDocsImgViewIconBtn/ContractDocsImgViewIconBtn";

const PaymentHistoryListHeader = () => {
    return (
        <div className={CSS.l_PaymentHistoryListHeader_main}>
            <PayDate 
                className={CSS.l_PayDate}
                is_header={true}
            >
                {"계약 날짜"}
            </PayDate>

            <ContractId 
                className={CSS.l_ContractId}
                is_header={true}
            >
                {"계약 번호"}
            </ContractId>

            <ProductName 
                className={CSS.l_ProductName}
                is_header={true}
            >
                {"상품명"}
            </ProductName>

            <ContractDocsImgViewIconBtn 
                className={CSS.l_DescriptionIcon}
                label={"계약서 확인"}
                contractImageList={[]}
            />

            <ContractAmountDetailPart
                className={CSS.l_ContractTotamount}
                is_header={true}
                label=""
                content={"총액"}
            />

            <ContractAmountDetailPart 
                className={CSS.l_ContractPayamount}
                is_header={true}
                label=""
                content={"결제액"}
            />

            <ContractAmountDetailPart 
                className={CSS.l_ContractBalance}
                is_header={true}
                label=""
                content={"잔액"}
            />

            <MorePayInfo
                className={CSS.l_MorePayInfo}
                label={""}
                is_extended={false}
                is_has_payment_detail_list={false}
            />
        </div>
    )
}

export default PaymentHistoryListHeader;