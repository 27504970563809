import CSS from "./static/css/ListCardHeaderText.module.css"
import Text from "../Text/Text";

const ListCardHeaderText = (
    {
        header_text,
        copy_function_target_text,
        is_view_essential_icon,
        isSatisfiedPhotoNums,
    }:{
        header_text?: string
        copy_function_target_text?: string;
        is_view_essential_icon?: boolean;
        isSatisfiedPhotoNums?: boolean;
    }
) => {
    return(
        <Text
            class_name_list={[CSS.l_ListCardHeaderText_main]}
            bold={true}
            preset={"select_theme_shoot_title"}
            is_view_essential_icon={is_view_essential_icon}
            copy_function_target_text={copy_function_target_text}
            copy_message={"폴더명이 클립보드에 추가되었습니다."}
            color={isSatisfiedPhotoNums ? undefined : "red_gray"}
        >
            {header_text}
        </Text>
    )
}

export default ListCardHeaderText;