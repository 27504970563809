import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { CalendarOptions, EventSourceInput } from "@fullcalendar/core";
import CalendarHeader from "./components/CalendarHeader/CalendarHeader";
import CSS from "./static/css/customFullCalendar.module.css";
import WeekDays from "./components/WeekDays/WeekDays";
import DayCell from "./components/DayCell/DayCell";

const CustomFullCalendar = (
    {
        selectedDate, 
        setSelectedDate,
        setStartDate,
        setEndDate,
        viewingDate,
        setViewingDate,
        events,
    }:{
        selectedDate: Date | null
        setSelectedDate: Dispatch<React.SetStateAction<Date | null>>;
        setStartDate?: Dispatch<SetStateAction<Date | null>>;
        setEndDate?: Dispatch<SetStateAction<Date | null>>;
        viewingDate?: Date | null;
        setViewingDate?: Dispatch<SetStateAction<Date | null>>;
        events?: EventSourceInput;
    }
) => {
    const [title, setTitle] = useState<string>('');

    const calendarRef = useRef<FullCalendar>(null);

    const handlePrevClick = () => {
        const calendar_api = calendarRef.current?.getApi();
        if(calendar_api){
            calendar_api.prev();
        }
    };

    const handleNextClick = () => {
        const calendar_api = calendarRef.current?.getApi();
        if(calendar_api){
            calendar_api.next();
        }
    };

    useEffect(() => {
        const calendar_ref_current = calendarRef.current;
        if(calendar_ref_current){
            const calendarApi = calendar_ref_current.getApi();
            if (calendarApi) {
                setTitle(calendarApi.view.title);
            }
        }
    }, []);

    const calendarOptions: CalendarOptions = {
        plugins: [dayGridPlugin],
        initialView: "dayGridMonth",
        initialDate: viewingDate ? viewingDate : undefined,
        events: events,
        locale: 'ko', // 한국어 로케일 설정 -> 달력이 한글로 표시됨
        titleFormat: { month: 'long' }, // 날짜 형식 설정
        headerToolbar: false,
        height: "100%",
        dayCellContent: (dayCellInfo) => <DayCell dayCellInfo={dayCellInfo} selectedDate={selectedDate} />,
        dayCellDidMount: (dayCellInfo) => {
            dayCellInfo.el.addEventListener('click', () => {
                const m_clickedDate = dayCellInfo.date;
                const m_calendarApi = calendarRef.current?.getApi();

                SetMonth:{
                    if(!m_calendarApi){
                        break SetMonth;
                    }
    
                    const m_currentDate = m_calendarApi.getDate();
    
                    if(m_clickedDate.getMonth() === m_currentDate.getMonth()){
                        break SetMonth;
                    }
    
                    m_calendarApi.gotoDate(m_clickedDate);
                }

                setSelectedDate(dayCellInfo.date);
            });
        },
        dayHeaderContent: WeekDays,
        fixedWeekCount: false,
        datesSet: (arg) => {
            // dev_console.log("CustomFullCalendar arg ===>", arg)
            
            setTitle(arg.view.title);

            // 달력에 출력되는 시작일자(해당월의 이전달 마지막 주)와 마지막 일자(해당월의 다음달 첫째 주)를 설정한다.
            if(setStartDate){
                setStartDate(arg.start);
            }
            
            if(setEndDate){
                setEndDate(arg.end);
            }

            // 현재 달력에 출력되고있는 년, 월을 가져온다.
            if(setViewingDate){
                const calendarApi = calendarRef.current?.getApi();
                if (calendarApi) {
                    const currentDate = calendarApi.getDate();
                    setViewingDate(currentDate);
                }
            }
        }
    };

    return(
        <div className={CSS.l_custom_full_calendar_main}>
            <CalendarHeader 
                title={title}
                handlePrevClick={handlePrevClick}
                handleNextClick={handleNextClick}
            />
            <div className={CSS.l_custom_full_calendar__calendar_container}>
                <FullCalendar 
                    ref={calendarRef}
                    {...calendarOptions}
                />
            </div>
        </div>
    )
}

export default CustomFullCalendar;