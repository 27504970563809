import { progressScheduleInterface } from "../../CustomerPage/Reservation/static/interface/reservationHome.interface";

export const progressScheduleSort = (a: progressScheduleInterface, b: progressScheduleInterface) => {
    const now = Date.now();
    
    // 1차 정렬: status_id (0이 상위로)
    if (a.status_contents !== b.status_contents) {
        return a.status_contents - b.status_contents;
    }

    // 2차 정렬: 현재시간에 가까울수록 상단으로
    // 현재 시간

    // a의 시간 계산
    const a_datetime_time = new Date(`${a.schedule_date} ${a.detail?.photo_start}`).getTime();
    // b의 시간 계산
    const b_datetime_time = new Date(`${b.schedule_date} ${b.detail?.photo_start}`).getTime();

    // 유효하지 않은 날짜 처리
    if (isNaN(a_datetime_time) && isNaN(b_datetime_time)) {
        return 0;
    } else if (isNaN(a_datetime_time)) {
        return 1;
    } else if (isNaN(b_datetime_time)) {
        return -1;
    } else {
        // 2차 정렬: 현재 시간과의 차이가 적은 순서대로 정렬
        return Math.abs(a_datetime_time - now) - Math.abs(b_datetime_time - now);
    }
}