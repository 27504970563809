import { dateObjectManagerClass } from "../../../../../../Common/ts/commonTools";

const dateObjectManager = new dateObjectManagerClass();

const useShootPhotoInfoCardSetter = (
    {
        shoot_date
    }:{
        shoot_date: string | Date;
    }
) => {
    let l_d_day_num = dateObjectManager.getDaysDifferenceFromToday(shoot_date);

    return({
        d_day: l_d_day_num,
    })
}

export default useShootPhotoInfoCardSetter;