import Navi from "./Navi";

import home_icon_activate from "./static/img/icon1_on@2x.png";
import home_icon_deactivate from "./static/img/icon1_off@2x.png";

import schedule_icon_activate from "./static/img/icon2_on@2x.png";
import schedule_icon_deactivate from "./static/img/icon2_off@2x.png";

import picture_icon_activate from "./static/img/icon3_on@2x.png";
import picture_icon_deactivate from "./static/img/icon3_off@2x.png";

import product_icon_activate from "./static/img/icon4_on@2x.png";
import product_icon_deactivate from "./static/img/icon4_off@2x.png";

import camera_icon_activate from "./static/img/icon5_on@2x.png";
import camera_icon_deactivate from "./static/img/icon5_off@2x.png";

import document_icon_activate from "./static/img/icon6_on@2x.png";
import document_icon_deactivate from "./static/img/icon6_off@2x.png";

const CustomerNavi = (
    {
        isOpenMenu,
        setIsOpenMenu,
    }:{
        isOpenMenu: boolean
        setIsOpenMenu: React.Dispatch<React.SetStateAction<boolean>>
    }
) => {

    return(
        <Navi 
            isOpenMenu={isOpenMenu}
            setIsOpenMenu={setIsOpenMenu}
            navi_item_list={[
                {
                    name: "홈",
                    img_src: home_icon_deactivate,
                    activated_img_src: home_icon_activate,
                    navigate: "/",
                    is_badge_on: false,
                },
                {
                    name: "촬영 일정",
                    img_src: schedule_icon_deactivate,
                    activated_img_src: schedule_icon_activate,
                    navigate: "/reservationHome",
                    is_badge_on: false,
                },                                
                {
                    name: "내 사진 모아보기",
                    img_src: picture_icon_deactivate,
                    activated_img_src: picture_icon_activate,
                    navigate: "/photoList",
                    is_badge_on: false,
                },                                
                {
                    name: "제품 신청 관리",
                    img_src: product_icon_deactivate,
                    activated_img_src: product_icon_activate,
                    navigate: "/productList",
                    is_badge_on: false,
                },                                
                {
                    name: "촬영 테마 둘러보기",
                    img_src: camera_icon_deactivate,
                    activated_img_src: camera_icon_activate,
                    navigate: "/themeBook",
                    is_badge_on: false,
                },
                {
                    name: "계약 내용 확인",
                    img_src: document_icon_deactivate,
                    activated_img_src: document_icon_activate,
                    navigate: "/paymentHistory",
                    is_badge_on: false,
                }, 
            ]}
        />
    )
}

export default CustomerNavi;