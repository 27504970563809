import CSS from "./static/css/SearchIcon.module.css"
import search_icon from "./static/img/search_icon_20@2x.png";

const SearchIcon = () => {
    return(
        <div className={CSS.l_SearchIcon_main}>
            <img
                className={CSS.l_SearchIcon_iconImg} 
                src={search_icon} 
            />
        </div>
    )
}

export default SearchIcon;