import { useQuery } from "@tanstack/react-query";
import { callAxios, dev_console, getQueryParamsUrl, isSuccess, printStateMsg, whoAmI } from "../../../Common/ts/commonTools";
import { defaultApiReaponseInterface } from "../../../Common/interface/Common.interface";
import { photoProductTypeCodeType, themaBookDetailListInterface } from "../static/interface/themeBook.interface";
import { PHOTO_PRODUCT_TYPE_CODE } from "../static/ts/const";
import { RTN_interface } from "../../../Common/interface/App.interface";

const EMPTY_LIST: themaBookDetailListInterface[] = [];

const useThemaBookDetailListREST = (
    {
        // is_has_photo_product_list를 통해 제품목록이 정상적으로 있는 경우에만 테마 목록을 요청시킨다 -> 그래야만 목록에서 촬영상품 목록이 없는데 이전 테마목록이 나오는 비 정상적인 경우를 막을 수 있다.
        // photo_product_id나 photo_product_type등은 photo_product_list가 없더라도 초기화 시키지 않으므로 is_has_photo_product_list 라는 대체제를 사용한다.

        is_has_photo_product_list,
        is_available_office_id,
        photo_product_id,
        photo_product_type,
        office_id,
    }:{
        is_has_photo_product_list: boolean,
        is_available_office_id: boolean;
        photo_product_id: number;
        photo_product_type: photoProductTypeCodeType;
        office_id: number;
    }
) => {
    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer() || whoAmI.isAdmin()){
            result = true;
        }

        return result;
    }

    const {
        data: themeList = EMPTY_LIST,
        error: themeListError,
        isLoading: isThemeListLoading,
        refetch: themeListRefetch,
    } = useQuery({
        queryKey: ["theme_book_office", photo_product_id, photo_product_type, office_id, is_has_photo_product_list, is_available_office_id],
        queryFn: callThemeList,
        enabled: isAbleToRequest,       
        select: (response) => {
            const RTN: RTN_interface<themaBookDetailListInterface[]> = {
                sign: false,
                data: EMPTY_LIST,
                msg: "",
            };

            SetData:{
                if(!response){
                    RTN.sign = true;
                    break SetData;
                }

                if(!isSuccess(response)){
                    RTN.msg = "테마 목록을 불러오는데 실패했습니다.";
                    break SetData;
                }
                
                const data: defaultApiReaponseInterface<themaBookDetailListInterface[]> = response.data;
                RTN.sign = true;
                RTN.data = data.result;
                RTN.msg = "success";
            }

            if(!RTN.sign){
                if(response){
                    printStateMsg(response);
                }
            }

            return RTN.data;
        },
    });

    // 테마 목록을 서버에 요청하는 함수
    async function callThemeList(){   
        if(office_id > 0 && photo_product_type !== PHOTO_PRODUCT_TYPE_CODE.none && is_has_photo_product_list && is_available_office_id){            
            const m_thema_book_detail_list_url = getQueryParamsUrl({
                main_url: "api/v3/customers/thema_book_detail_list",
                queryParams: {
                    office_id: `${office_id || ""}`,
                    photo_id: `${photo_product_id || ""}`,
                    type: `${photo_product_type}`,
                    thema_id: "",
                }
            })

            const response = await callAxios.api({
                url: m_thema_book_detail_list_url,
                method: "get",
            })
    
            dev_console.log(m_thema_book_detail_list_url);
            dev_console.log(response);
    
            return response;
        }else{
            return null;
        }
    }

    return({
        themeList,
        themeListError,
        isThemeListLoading,
        themeListRefetch,
    })
}

export default useThemaBookDetailListREST;