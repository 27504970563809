import CSS from "./static/css/PhotoSwipeGalleryItem.module.css";
import { Item } from "react-photoswipe-gallery"
import VideoPlayer from "../../../VideoPlayer/VideoPlayer"
import { FILE_CODE } from "../../../../../Common/ts/const"
import { fileCodeType } from "../../../../../Common/interface/Common.interface";

const PhotoSwipeGalleryItem = (
    {
        file_type,
        origin_url,
        thumbnail_url,
        viewport_width,
        viewport_height,
        preset,
        children,
    }:{
        file_type: fileCodeType;
        origin_url: string;
        thumbnail_url: string;
        viewport_width: number;
        viewport_height: number;
        preset?: "ReactPhotoSwipeSingleViewer";
        children?: React.ReactNode;
    }
) => {

    function classNameSetter(){
        const RTN: string[] = [CSS.l_PhotoSwipeGalleryItem_MediaItemCardContainer];
    
        if(preset){
            RTN.push(CSS.preset);
            RTN.push(CSS[preset]);
        }

        return RTN;
    }

    return(
        <Item
            original={origin_url}
            thumbnail={thumbnail_url}
            width={viewport_width}
            height={viewport_height}
            content={
                file_type === FILE_CODE.video
                ?   <VideoPlayer src={origin_url}/>
                :   undefined
            }
        >     
            {({ ref, open }) => (
                <div 
                    className={classNameSetter().join(" ")}
                    ref={ref}
                    onClick={(event)=>{
                        open(event)
                    }}
                >
                    {children}
                </div>
            )}
        </Item>
    )
}

export default PhotoSwipeGalleryItem;