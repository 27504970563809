import { useEffect, useRef, useState } from "react";
import { getAccountDataClass } from "../../../../Common/ts/commonTools";
import Text from "../../../../Component/Elements/Text/Text";
import ExtensionContainer from "../ExtensionContainer/ExtensionContainer";
import CSS from "./static/css/UserFunctionBtn.module.css";
import user_info from "./static/img/user_icon_26@2x.png";

const getAccountData = new getAccountDataClass();

const UserFunctionBtn = () => {
    const [isUserFunctionExtension, setIsUserFunctionExtension] = useState<boolean>(false);

    const containerRef = useRef<HTMLDivElement>(null);

    const clickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setIsUserFunctionExtension((prev) => !prev);
    }

    useEffect(() => {
        // 컴포넌트 외부 클릭 감지를 위한 핸들러
        const handleClickOutside = (event: MouseEvent) => {
            // ref가 존재하고, 클릭 대상이 컴포넌트 내부가 아니라면
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setIsUserFunctionExtension(false);
            }
        };
  
        document.addEventListener("mousedown", handleClickOutside);
        
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [])

    return(
        <div 
            className={`g_click_event_item ${CSS.l_UserFunctionBtn_main}`}
            onClick={clickHandler}
            ref={containerRef}
        >
            <div className={CSS.l_UserFunctionBtn_mainContainer}>
                <div className={CSS.l_UserFunctionBtn_iconContainer}>
                    <img 
                        className={CSS.l_UserFunctionBtn_iconImg}
                        src={user_info}
                    />
                </div>
                <Text 
                    class_name_list={[CSS.l_UserFunctionBtn_userName]}
                    color={"secondary"}
                    isHidden={"mobile"}
                >
                    {getAccountData.name()}
                </Text>
            </div>

            <ExtensionContainer 
                is_extension={isUserFunctionExtension}
            />
        </div>
    )
}

export default UserFunctionBtn;