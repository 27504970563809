import { useEffect, useState } from "react";
import { productStateCodeType, productStateTextType } from "../../../static/interface/ProductList.interface";
import { textColorType } from "../../../../../Component/Elements/Text/interface/Text.interface";
import { PRODUCT_STATE_TEXT_OBJ } from "../../../static/ts/const";

interface textStyleInterface{
    color: textColorType,
    text: string,
}

const useProductBlock = (
    {
        product_state
    }:{
        product_state: productStateCodeType | null;
    }
) => {
    const [text, setText] = useState<textStyleInterface>(textSetter())
    
    function textSetter(){
        const RTN: textStyleInterface = {
            color: "gray0",
            text: product_state ? PRODUCT_STATE_TEXT_OBJ[product_state] : ""
        }

        switch(product_state){
            case "1":
                RTN.color = "gray4";
                break;
            case "2":
                RTN.color = "primary";
                break;
            case "3":
                RTN.color = "gray2";
                break;
            case "4":
                RTN.color = "gray2";
                break;
            case "5":
                RTN.color = "primary";
                break;
            case "6":
                RTN.color = "gray4";
                break;
            case "7":
                RTN.color = "gray4";
                break;
            default:
        }

        return RTN;
    }

    useEffect(() => {
        setText(textSetter())
    }, [product_state])

    return(text)
}

export default useProductBlock;