import { MouseEventHandler, ReactNode, useEffect, useRef, useState } from "react";
import CSS from "./static/css/ModalChildrenContainer.module.css";
import { isHiddenType } from "../../../Common/interface/Common.interface";
import { isHiddenClassNameSetter } from "../../../Common/ts/commonTools";
import IconX from "../Icon/IconX/IconX";
import MoveArrowBtn from "../Overlay/components/MoveArrowBtn/MoveArrowBtn";

const ModalChildrenContainer = (
    {
        isViewing,
        closeHandler,
        children,
        isHidden,
        padding,
        headerNode,
        prevThemeHandler,
        nextThemeHandler,
        min_heigth_full,
    }:{
        isViewing: boolean;
        closeHandler?: () => void;
        children?: ReactNode;
        isHidden?: isHiddenType;
        padding?: "none";
        headerNode?: ReactNode;
        prevThemeHandler?: MouseEventHandler<HTMLDivElement>;
        nextThemeHandler?: MouseEventHandler<HTMLDivElement>;
        min_heigth_full?: boolean;
    }
) => {    
    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));
    const l_children_container_ref = useRef<HTMLDivElement>(null);

    function classNameSetter(){
        let RTN: string[] = [];

        if(isHidden){
            RTN = isHiddenClassNameSetter({
                classNameList: RTN,
                isHidden,
                display: "flex"
            })
        }

        RTN.push(CSS.l_ModalChildrenContainer_main);

        if(padding){
            RTN.push(CSS.padding);
            RTN.push(CSS[padding]);
        }

        if(min_heigth_full){
            RTN.push(CSS.min_heigth_full);
        }

        return RTN;
    }

    // 모달이 열리거나 출력되고있는 항목이 바뀌는경우 포커스를 이동시킨다.
    // 동시에 이동되어있던 스크롤바의 위치를 최 상단으로 복귀시켜준다.
    useEffect(() => {
        if(isViewing && l_children_container_ref.current){
            l_children_container_ref.current.focus();
            l_children_container_ref.current.scrollTop = 0;
        }
    }, [isViewing, children])

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "));
    }, [isHidden, padding, min_heigth_full])

    if(isViewing){
        return(
            <div className={mainClassName}>
    
                <div className={CSS.l_ModalChildrenContainer_closeButton}>
                    <IconX
                        preset={"big_black"}
                        onClick={closeHandler}
                    />
                </div>
    
                {
                    headerNode
                    &&  <div 
                            className={CSS.l_ModalChildrenContainer_headerContainer}
                            onClick={(event) => {
                                // 모달의 본문을 클릭했을땐, 클릭 이벤트가 전파되서 창이 닫히는것을 막기위해...
                                event.stopPropagation();
                            }}
                        >
                            {headerNode}
                        </div>
                }
    
                {
                    prevThemeHandler
                    &&  <MoveArrowBtn
                            diraction={"left"}
                            onClick={prevThemeHandler}
                        />
                }

                {
                    nextThemeHandler
                    &&  <MoveArrowBtn 
                            diraction={"right"}
                            onClick={nextThemeHandler}
                        />
                }
    
                <div 
                    className={CSS.l_ModalChildrenContainer_childrenContainer}
                    onClick={(event) => {
                        // 모달의 본문을 클릭했을땐, 클릭 이벤트가 전파되서 창이 닫히는것을 막기위해...
                        event.stopPropagation();
                    }}
                    ref={l_children_container_ref}
                    tabIndex={0}
                >
                    {children}
                </div>
    
            </div>
        )
    }else{
        return null
    }
}

export default ModalChildrenContainer;