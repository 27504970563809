import CSS from "./static/css/ProductDetailPageWebViewing.module.css";
import { useContext, useEffect, useState } from "react";
import ThreeSplitPages from "../../../../../Component/Elements/Page/ThreeSplitPages/ThreeSplitPages";
import { ProductDetail_Folder_FolderListEle_Interface, ProductDetail_Folder_PhotoListEle_Interface, ProductDetail_SelectedOptionListEle_Interface, ProductDetail_SelectInformationDate_AddedInfo_Interface, ProductDetail_SelectInformationDate_Address_Interface } from "../../../static/interface/ProductDetail.interface";
import { productStateCodeType } from "../../../static/interface/ProductList.interface";
import DetailPageBtnTotalContainer from "../../DetailPageBtnTotalContainer/DetailPageBtnTotalContainer";
import ProductInfoCardViewMode from "../../ProductInfoCardViewMode/ProductInfoCardViewMode";
import UploadImageListViewMode from "../../../../../Component/Elements/UploadImageListViewMode/UploadImageListViewMode";
import { uploadImageListViewMode_imgListSetter } from "../../../../../Component/Elements/UploadImageListViewMode/static/ts/common.tools";
import { commonContext } from "../../../../../App";

const ProductDetailPageWebViewing = (
    {
        textTitle,
        folderList,
        photoList,
        product_name,
        is_has_babyinfo,
        selectOptionList,
        addedInfo,
        address,
        contract_product_id,
        album_save_id,
        photo_upload_id,
        status_cd,
        isSingle,
        refetchHandler,
        isCanReupload,
    }:{
        textTitle: string;
        folderList: ProductDetail_Folder_FolderListEle_Interface[];
        photoList: ProductDetail_Folder_PhotoListEle_Interface[];
        product_name: string;
        is_has_babyinfo: boolean;
        selectOptionList: ProductDetail_SelectedOptionListEle_Interface[];
        addedInfo: ProductDetail_SelectInformationDate_AddedInfo_Interface | null;
        address: ProductDetail_SelectInformationDate_Address_Interface | null;
        contract_product_id: number;
        album_save_id: number | null;
        photo_upload_id: number | null;
        status_cd: productStateCodeType;
        isSingle: boolean;
        refetchHandler: () => void;
        isCanReupload: boolean;
    }
) => {
    const [viewBtn, setViewBtn] = useState<boolean>(true);

    const l_commonContext = useContext(commonContext);

    const buttonToggleHandler = (flag: boolean) => {
        setViewBtn(flag);
    }

    useEffect(() => {
        l_commonContext.setHeaderRightNode(<></>);
    }, [])

    return(
        <ThreeSplitPages
            title={textTitle}
            isMainHidden={"mobile"}

            centerContainerNode={
                <UploadImageListViewMode 
                    folderInfoList={
                        folderList.map((folder_item) => {
                            return({
                                folder_name: folder_item.folder_name,
                                folder_id: folder_item.folder_id,
                                middle_img_url: folder_item.middle_img_url,
                                img_list: uploadImageListViewMode_imgListSetter({
                                    folder_id: folder_item.folder_id,
                                    all_photo_list: photoList
                                })
                            })
                        })
                    }
                    isComfrimMode={false}
                    isSingle={isSingle}
                />
            }
            rightContainerNode={
                <div className={CSS.l_ProductDetailPageWebViewing_rightContainer}>
                    <ProductInfoCardViewMode 
                        product_name={product_name}
                        is_view_babyinfo={is_has_babyinfo}
                        selectedOptionData={selectOptionList}

                        addedInfo={addedInfo}
                        address={address}
                        status_cd={status_cd}
                        album_save_id={album_save_id}
                        refetchHandler={refetchHandler}
                        buttonToggleHandler={buttonToggleHandler}
                    />

                    {
                        viewBtn
                        &&  <DetailPageBtnTotalContainer 
                                contract_product_id={contract_product_id}
                                album_save_id={album_save_id}
                                photo_upload_id={photo_upload_id}
                                status_cd={status_cd}
                                isCanReupload={isCanReupload}
                            />
                    }
                </div>
            }
        />
    )
}

export default ProductDetailPageWebViewing;