import CSS from "./static/css/ProductConfirm.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { customParseInt, customToast, isAbleAccesser } from "../../Common/ts/commonTools";
import ThreeSplitPages from "../../Component/Elements/Page/ThreeSplitPages/ThreeSplitPages";
import useProductDetailREST from "./hooks/REST/useProductDetailREST";
import useProductDetailSetting from "./hooks/useProductDetailSetting";
import FolderList from "./components/FolderList/FolderList";
import { useEffect, useRef, useState } from "react";
import UploadImageListViewMode from "../../Component/Elements/UploadImageListViewMode/UploadImageListViewMode";
import AdditionalInfoContainer from "./components/AdditionalInfoContainer/AdditionalInfoContainer";
import ProductInfoCardViewMode from "./components/ProductInfoCardViewMode/ProductInfoCardViewMode";
import { PRODUCT_TYPE_CODE_OBJ } from "./static/ts/const";
import ConfrimBtnContainer from "./components/ConfrimBtnContainer/ConfrimBtnContainer";
import { uploadImageListViewMode_imgListSetter } from "../../Component/Elements/UploadImageListViewMode/static/ts/common.tools";

const ProductConfirm = () => {
    const[request, setRequest] = useState<string>("");

    const navigate = useNavigate();

    const leftContainerEleRefList = useRef<Array<HTMLDivElement | null>>([]);
    const centerContainerEleRefList = useRef<Array<HTMLDivElement | null>>([]);

    const [URLSearchParams, SetURLSearchParams] = useSearchParams();
    
    let l_contract_product_id: number | null = null;
    const l_query_contract_product_id = URLSearchParams.get("contract_product_id");
    if(l_query_contract_product_id){
        l_contract_product_id = customParseInt(l_query_contract_product_id);
    }   
    
    let l_album_save_id: number | null = null;
    const l_query_album_save_id = URLSearchParams.get("album_save_id");
    if(l_query_album_save_id){
        l_album_save_id = customParseInt(l_query_album_save_id);
    }
    
    let l_photo_upload_id: number | null = null;
    const l_query_photo_upload_id = URLSearchParams.get("photo_upload_id");
    if(l_query_photo_upload_id){
        l_photo_upload_id = customParseInt(l_query_photo_upload_id);
    }
    
    // album_save_id는 고객이 사진을 업로드하면 생성된다.
    // photo_upload_id는 편집 사진이 업로드되면 생성된다.
    // 따라서 contract_product_id값과 status_cd값이 넘어오는지만 확인한다.
    if(!l_contract_product_id){
        customToast.error({
            msg: "제품의 상세정보를 불러올 수 없습니다.",
            toastId: "ProductDetail_state_error",
        })
        navigate("/productList");
    }

    l_contract_product_id = l_contract_product_id as number;

    const {        
        productDetailData,
        productDetailError,
        productDetailIsLoading,
        productDetailRefetch
    } = useProductDetailREST({
        contract_product_id: l_contract_product_id,
        album_save_id: l_album_save_id,
        photo_upload_id: l_photo_upload_id
    })

    const {
        productDetailInfoData,
        productOptionDataList,
        folderList,
        initialSelectOptionDataList,
        initialSelectPhotoList,
        initialEditPhotoList,
        addedInfo,
        address,
        isSingle,
        selectInformationDate,
    } = useProductDetailSetting({
        productDetailData,
    })

    // 스크롤 액션 함수
    const scrollToFirstFalseItem = (folder_id: number) => { 
        const m_ele_current = centerContainerEleRefList.current[folder_id];
        if (m_ele_current) {
            // 스크롤 이동
            m_ele_current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };

    useEffect(() => {
        isAbleAccesser({
            allowedUserTypeList: ["C"]
        })
    }, [])

    return(
        <ThreeSplitPages 
            title=""
            is_navi_hidden={true}
            // is_header_hidden={true}
            background_color={"select_page"}
            isMainHidden={"mobile"}

            leftContainerNode={
                <FolderList
                    folder_list={folderList} 
                    ele_refs={leftContainerEleRefList}
                    is_have_options={productOptionDataList.length > 0}
                    eleClickHandler={scrollToFirstFalseItem}
                    readOnly={true}
                />
            }
            centerContainerNode={                
                <UploadImageListViewMode 
                    folderInfoList={
                        folderList.map((folder_item) => {
                            return({
                                folder_name: folder_item.folder_name,
                                folder_id: folder_item.folder_id,
                                middle_img_url: folder_item.middle_img_url,
                                img_list: uploadImageListViewMode_imgListSetter({
                                    folder_id: folder_item.folder_id,
                                    all_photo_list: initialEditPhotoList
                                })
                            })
                        })
                    }
                    isComfrimMode={true}
                    isSingle={isSingle}
                />
            }
            rightContainerNode={
                <div className={CSS.l_ProductConfirm_rightContainerNodeContainer}>
                    <div className={CSS.l_ProductConfirm_productInfoContainer}>
                        <ProductInfoCardViewMode
                            product_name={productDetailInfoData?.product_name || ""}
                            // is_view_options={productOptionDataList.length > 0}
                            is_view_babyinfo={productDetailInfoData?.product_type === PRODUCT_TYPE_CODE_OBJ.album}
                            selectedOptionData={initialSelectOptionDataList}

                            addedInfo={addedInfo}

                            address={address}
                            status_cd={selectInformationDate?.status_cd}
                            album_save_id={l_album_save_id}
                        />
        
                        <AdditionalInfoContainer 
                            request={request}
                            setRequest={setRequest}
                            is_request_view_essential_icon={true}
                        />
                    </div>

                    <ConfrimBtnContainer
                        status_cd={selectInformationDate?.status_cd}
                        contract_product_id={l_contract_product_id}
                        album_save_id={l_album_save_id}
                        photo_upload_id={l_photo_upload_id}
                        request_num={selectInformationDate?.request_num || 0}
                        request={request}
                    />
                </div>
            }
        />
    )
}

export default ProductConfirm;