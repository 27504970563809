import CSS from "./static/css/ProductInfoCard.module.css";
import LabelAndContent from "../../../../Component/Elements/LabelAndContent/LabelAndContent";
import MainInfoCard from "../../../../Component/Elements/MainInfoCard/MainInfoCard";
import { ProductDetail_SelectedOptionListEle_Interface } from "../../static/interface/ProductDetail.interface";
import SelectedOptionEle from "../SelectedOptionEle/SelectedOptionEle";

const ProductInfoCard = (
    {
        product_name,
        is_has_options,
        selectedOptionData,
        subOptionItemHandler,
    }:{
        product_name: string;
        is_has_options?: boolean;
        selectedOptionData?: ProductDetail_SelectedOptionListEle_Interface[];
        subOptionItemHandler?: (option: ProductDetail_SelectedOptionListEle_Interface) => void;
    }
) => {
    return(
        <div className={CSS.l_ProductInfoCard_main}>
            <MainInfoCard title={product_name}>
                {
                    is_has_options
                    &&  <LabelAndContent 
                            label={"추가 옵션"}
                            flex_direction={"column"}
                            is_view_essential_icon={true}
                        >
                            <div className={CSS.l_ProductInfoCard_selectedOptionContainer}>
                                {
                                    selectedOptionData && selectedOptionData.map((item) => {
                                        return(
                                            <SelectedOptionEle
                                                option_name={item.option_name}
                                                onClick={subOptionItemHandler?.bind(null, item)}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </LabelAndContent>
                }
            </MainInfoCard>
        </div>
    )
}

export default ProductInfoCard;