import { useEffect, useId, useState } from "react";
import CSS from "./static/css/radioBtnWrap.module.css";

interface radioBtnItemInterface{
    label?: string
    value: string | number;
}

const RadioBtnWrap = (
    {
        main_label,
        item_list,
        name,
        onChange,
        is_disabled,
        is_readOnly,
        value,
        classNameList,
        justify_content,
        inputClassNameList,
    }:{
        main_label?: string;
        item_list: radioBtnItemInterface[];
        name: string;
        onChange?: React.ChangeEventHandler<HTMLInputElement>;
        is_disabled?: boolean;
        is_readOnly?: boolean;
        value: string | number;
        classNameList?: string[];
        justify_content?: "flex_start" | "flex_end" | "center" | "space_around" | "space_between" | "space_evenly";
        inputClassNameList?: string[];
    }
) => {
    
    const [totalClassName, setTotalClassName] = useState<string>(CSS.l_radio_btn_wrap_main);
    const [inputTotalClassName, setInputTotalClassName] = useState<string>("g_input type radio");
    const [inputLableTotalClassName, setInputLableTotalClassName] = useState<string>("");

    const baseId = useId();

    useEffect(() => {
        const class_name_list: string[] = [];

        if(classNameList){
            for(const class_name of classNameList){
                class_name_list.push(class_name);
            }
        }

        class_name_list.push(CSS.l_radio_btn_wrap_main);

        if(justify_content){
            class_name_list.push(CSS.justify_content);
            class_name_list.push(CSS[justify_content]);
        }

        setTotalClassName(class_name_list.join(" "));
    }, [classNameList, justify_content])

    useEffect(() => {
        const input_class_name_list: string[] = ["g_input", "type", "radio"];
        const input_label_class_name_list: string[] = [];

        if(!is_disabled && !is_readOnly && onChange){
            input_class_name_list.push("g_click_event_item");
            input_label_class_name_list.push("g_click_event_item");
        }

        if(inputClassNameList){
            for(const input_class_name of inputClassNameList){
                input_class_name_list.push(input_class_name);
            }
        }

        setInputTotalClassName(input_class_name_list.join(" "));
        setInputLableTotalClassName(input_label_class_name_list.join(" "));
    }, [inputClassNameList, is_disabled, is_readOnly, onChange])

    return (
        <div 
            className={totalClassName}
        >
            {
                main_label
                &&  <label>
                        {main_label}
                    </label>
            }
            <div className={CSS.l_radio_btn_wrap__input_context}>
                {
                    item_list.map((item, index) => {
                        const id = `${baseId}-${index}`;

                        return (
                            <div 
                                className={CSS.l_radio_btn_wrap__item}
                                key={index}
                            >            
                                <input
                                    className={inputTotalClassName}
                                    type={"radio"}
                                    name={name}
                                    id={id}
                                    value={item.value}
                                    onChange={onChange}
                                    disabled={is_disabled}
                                    readOnly={is_readOnly}
                                    checked={value === item.value}
                                />
                                {
                                    item.label
                                    &&  <label
                                            className={inputLableTotalClassName}
                                            htmlFor={id}
                                        >
                                            {item.label}
                                        </label>
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default RadioBtnWrap;