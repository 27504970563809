// 컴포넌트 시뮬레이션을을 위한 테스트페이지
import CSS from "./static/css/testPage.module.css";
import MainPage from "../Component/Elements/Page/MainPage/MainPage";
import { useState } from "react";
import { imageDataManagerClass } from "../Common/ts/commonTools";
import { dimensions_Interface } from "../Common/interface/Common.interface";
import AlertList from "../PublicPage/Header/components/AlertList/AlertList";

const imageDataManager = new imageDataManagerClass();

const TestPage = () => {
    const [dimensions, setDimensions] = useState<dimensions_Interface | null>(null);
    const [dpi, setDpi] = useState<dimensions_Interface | null>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.files && e.target.files[0]){
            const file = e.target.files[0];
            const img = new Image();
            // URL.createObjectURL을 사용하여 이미지 파일의 임시 URL 생성
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                // 이미지가 로드된 후, 자연스러운 가로, 세로 크기를 가져옴
                setDimensions({ width: img.naturalWidth, height: img.naturalHeight });
                // 사용한 객체 URL은 메모리 해제를 위해 revoke 처리할 수 있음
                URL.revokeObjectURL(img.src);
            };
            imageDataManager.getImageDpi(file)
            .then((result) =>{
                setDpi(result);
            })
        }
    };

    return (
        <MainPage title="">
            <div className={CSS.l_test_page_main}>

                <input type="file" accept="image/*" onChange={handleFileChange} />
                
                {
                    dimensions 
                    ?   <div>
                            <p>가로: {dimensions.width}px</p>
                            <p>세로: {dimensions.height}px</p>
                        </div>
                    :   <p>이미지가 업로드되지 않았거나 아직 로드되지 않았습니다.</p>
                }

                {
                    dpi 
                    ?   <div>
                            <p>가로: {dpi.width}dpi</p>
                            <p>세로: {dpi.height}dpi</p>
                        </div>
                    :   <p>이미지가 업로드되지 않았거나 아직 로드되지 않았습니다.</p>
                }

                <AlertList />
            </div>
        </MainPage>
    )
}

export default TestPage