import CSS from "./static/css/ThemeSelectButtons.module.css";
import useThemeSelectButtons from "./hooks/useThemeSelectButtons.hook";
import Button from "../../../../Component/Elements/Button/Normal/Button";
import { useNavigate } from "react-router-dom";
import useSelectedThemeSubmitREST from "../../hooks/useSelectedThemeSubmitREST.hook";
import { RESERVATION_LIST_FILTER_CODE } from "../../static/ts/const";
import useProgressScheduleDataREST from "../../hooks/useProgressScheduleDataREST.hook";
import { schedulePhotoProductThemeInterface } from "../../static/interface/selectTheme.interface";

const ThemeSelectButtons = (
    {
        schedule_id,
        contract_id,
        photoProductList,
        isReadOnly
    }:{
        schedule_id: number,
        contract_id: string,
        photoProductList: schedulePhotoProductThemeInterface[],
        isReadOnly?: boolean
    }
) => {
    const navigate = useNavigate();

    const {
        progressScheduleDataListRefetch: progressScheduleAllDataRefetch,
    } = useProgressScheduleDataREST({
        main_type: 0,
        status_type: RESERVATION_LIST_FILTER_CODE.all,
    })

    const {
        progressScheduleDataListRefetch: progressScheduleBeforeDataRefetch,
    } = useProgressScheduleDataREST({
        main_type: 0,
        status_type: RESERVATION_LIST_FILTER_CODE.reservat,
    })

    //  실질적으로 서버에 선택 요청을 보내는 함수
    const { selectedThemeSubmitMutate } = useSelectedThemeSubmitREST({
        progressScheduleAllDataRefetch,
        progressScheduleBeforeDataRefetch,
    });

    const {
        onSubmit,
        stateCode,
    } = useThemeSelectButtons({
        contract_id,
        schedule_id,
        selectedThemeSubmitMutate,
        photoProductList,
        isReadOnly
    })

    const cancelClickHandler = () => {
        navigate("/reservationHome");
    }

    return(
        <div className={`g_backdrop_filter ${CSS.l_ThemeSelectButtons_main}`}>
            {/* <Button
                size={"size1"}
                preset={"normal_gray"}
                onClick={cancelClickHandler}
            >
                취소
            </Button> */}

            {
                stateCode === 1
                &&  <Button
                        size={"size3"}
                        onClick={onSubmit}
                    >
                        테마 선택 완료
                    </Button>
            }

            {/* {
                stateCode === 2
                &&  <Button
                        size={"size3"}
                        disabled={true}
                    >
                        테마 선택 완료
                    </Button>
            } */}
        </div>
    )
}

export default ThemeSelectButtons;