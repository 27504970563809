import { MouseEventHandler } from "react";
import CalendarTitle from "../CalendarTitle";
import CSS from "./static/css/calendarHeader.module.css";
import TriangleIcon from "../../../Icon/TriangleIcon/TriangleIcon";

const CalendarHeader = (
    {
        title,
        handlePrevClick,
        handleNextClick,
    }:{
        title: string;
        handlePrevClick: MouseEventHandler<HTMLDivElement>;
        handleNextClick: MouseEventHandler<HTMLDivElement>;
    }
) => {
    return(
        <div className={CSS.l_CalendarHeader_main}>
            <div 
                className={`g_click_event_item ${CSS.l_CalendarHeader_moveBtnContainer}`}
                onClick={handlePrevClick}
            >
                <TriangleIcon 
                    direction={"left"}
                    color={"gray"}
                    size={"small"}
                />
            </div>
        
            <CalendarTitle 
                title={title}
            />

            <div 
                className={`g_click_event_item ${CSS.l_CalendarHeader_moveBtnContainer}`}
                onClick={handleNextClick}
            >
                <TriangleIcon 
                    direction={'right'}
                    color={"gray"}
                    size={"small"}
                />
            </div>
        </div>
    )
}

export default CalendarHeader;