import { useCallback } from "react";
import TabMenuItem from "./TabMenuItem/TabMenuItem";
import CSS from "./static/css/TabMenu.module.css";
import { tebMenuItemInterface } from "./static/interface/TabMenu.interface";

const TabMenu = <T,>(
    {
        tab_list,
        selectedTab,
        setSelectedTab,
    }:{
        tab_list: tebMenuItemInterface<T>[];
        selectedTab: T,
        setSelectedTab: React.Dispatch<React.SetStateAction<T>>
    }
) => {
    const tabClickHandler = useCallback((value: T) => {
        setSelectedTab(value);
    }, [])

    return(
        <div className={CSS.l_TabMenu_main}>
            {
                tab_list.map((tab_item, tab_index) => {
                    return(
                        <TabMenuItem 
                            key={tab_index}
                            tab_data={tab_item}
                            is_active={selectedTab === tab_item.value}
                            onClick={tabClickHandler.bind(null, tab_item.value)}
                        />
                    )
                })
            }
        </div>
    )
}

export default TabMenu;