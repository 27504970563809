import { progressScheduleInterface, reservationListFilterCodeType } from "../../static/interface/reservationHome.interface"
import CSS from "./static/css/ScheduleList.module.css";
import ReservationInfoCard from "../ReservationInfoCard/ReservationInfoCard";
import Flex from "../../../../Component/Elements/Flex/Flex";
import LoopAnimationModal from "../../../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import EmptyData from "../../../../Component/Elements/EmptyData/EmptyData";

const ScheduleList = (
    {
        progressSchedule,
        tab1,
        isLoading,
    }:{
        progressSchedule: progressScheduleInterface[];
        tab1: reservationListFilterCodeType;
        isLoading: boolean;
    }
) => {
    return (                        
        <div className={CSS.l_ScheduleList_main}>
            {
                progressSchedule.length > 0
                ?   <Flex>
                        {
                            progressSchedule.map((item, index) => {
                                return(
                                    <ReservationInfoCard
                                        key={index}
                                        tab1={tab1}
                                        selectedSchedule={item}
                                    />
                                )
                            })
                        }
                    </Flex>
                :   <EmptyData />
            }

            {/* 로딩 애니메이션 모달 */}
            <LoopAnimationModal
                isModalOpen={isLoading}
            />
        </div>
    )
}

export default ScheduleList;