import { useQuery } from "@tanstack/react-query";
import { callAxios, customToast, dev_console, isSuccess, whoAmI } from "../../../Common/ts/commonTools";
import { photoTypeCodeType } from "../static/interface/const.interface";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";
import { myPhotoREST_Interface } from "../static/interface/MyPhotoREST.interface";
import { useEffect } from "react";

const EMPTY_LIST: myPhotoREST_Interface[] = [];

const useMyPhotoREST = (
    {
        main_type
    }:{
        main_type: photoTypeCodeType
    }
) => {
    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer()){
            result = true;
        }

        return result;
    }

    const {
        data: myPhotoData = EMPTY_LIST,
        isLoading: myPhotoIsLoading,
        error: myPhotoError,
        refetch: myPhotoRefetch
    } = useQuery({
        queryKey: ["MyPhoto", main_type],
        queryFn: callMyPhoto,
        enabled: isAbleToRequest,
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
        select: (response) => {
            let RTN: myPhotoREST_Interface[] = EMPTY_LIST;

            if(response.status === 200 && response.data.msg === "success"){
                RTN = response.data.datas as myPhotoREST_Interface[];
            }

            return RTN;
        },
    })

    async function callMyPhoto(){
        const my_photo_url = `api/v3/oops/my-photo/list?main_type=${main_type}`;

        const response = await callAxios.api({
            url: my_photo_url,
            method: "get",
        })

        dev_console.log(my_photo_url);
        dev_console.log(response);

        return response;
    }

    useEffect(() => {
        if(myPhotoError){
            customToast.error({
                msg: "사진 목록을 불러오는데 실패했습니다.",
                toastId: "myPhotoError",
            })
        }
    }, [myPhotoError])

    return({
        myPhotoDataList: myPhotoData,
        myPhotoDataListIsLoading: myPhotoIsLoading,
        myPhotoDataListError: myPhotoError,
        myPhotoDataListRefetch: myPhotoRefetch
    })
}

export default useMyPhotoREST;