import CSS from "./static/css/UploadImageItemEle.module.css";
import CardContainer from "../../../../Component/Elements/CardContainer/CardContainer";
import Text from "../../../../Component/Elements/Text/Text";
import WasteBasket from "../../../../Component/Elements/Icon/WasteBasket/WasteBasket";
import Media from "../../../../Component/Elements/Media/Media";

const UploadImageItemEle = (
    {
        img_src,
        img_name,
        deleteIconClickHandler,
        copy_function_target_text,
        copy_message,
    }:{
        img_src: string;
        img_name: string;
        deleteIconClickHandler?: React.MouseEventHandler<HTMLDivElement>;
        copy_function_target_text?: string;
        copy_message?: string;
    }
) => {
    return(
        <div className={CSS.l_UploadImageItemEle_main}>
            <CardContainer
                preset={"img"}
            >
                <Media 
                    src={img_src}
                    object_fit={"contain"}
                />
            </CardContainer>
            <Text
                preset={"main_content"}
                is_text_truncation_on={true}
                copy_function_target_text={copy_function_target_text}
                copy_message={copy_message}
            >
                {img_name}
            </Text>
            {
                deleteIconClickHandler
                &&  <div className={CSS.l_UploadImageItemEle_WasteBasketIcon}>
                        <WasteBasket 
                            onClick={deleteIconClickHandler}
                        />
                    </div>
            }
        </div>
    )
}

export default UploadImageItemEle;