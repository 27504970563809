import { useEffect, useState } from "react";
import { isAbleAccesser, whoAmI } from "../../Common/ts/commonTools";
import ThemeBook from "./ThemeBook";
import TitleBtnWrap from "../../Component/Elements/TitleBtnWrap/TitleBtnWrap";
import CSS from "./static/css/themeBookHome.module.css";
import useOfficeDataREST from "./hooks/useOfficeDataREST.hook";
import usePhotoProductDataREST from "./hooks/usePhotoProductDataREST.hook";
import useThemaBookDetailListREST from "./hooks/useThemaBookDetailListREST.hook";
import { photoProductTypeCodeType } from "./static/interface/themeBook.interface";
import { REGION_LIST } from "../../Common/ts/const";
import { PHOTO_PRODUCT_TYPE_CODE } from "./static/ts/const";
import { useSearchParams } from "react-router-dom";
import { themeBookParamsData } from "./static/ts/ThemeBook.tools";
import useStaffOfficeListREST from "./hooks/useStaffOfficeListREST.hook";

const ThemeBookHome = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    const [URLSearchParams, SetURLSearchParams] = useSearchParams();

    const {
        l_region_id,
        l_region_value,
        l_office_id,
        l_photo_product_id,
    } = themeBookParamsData({URLSearchParams})

    // 선택된 필터정보
    const [selectedRegionId, setSelectedRegionId] = useState<number>(l_region_id);
    const [selectedOfficeId, setSelectedOfficeId] = useState<number>(l_office_id);
    const [selectedPhotoProductId, setSelectedPhotoProductId] = useState<number>(l_photo_product_id);
    const [selectedPhotoProductType, setSelectedPhotoProductType] = useState<photoProductTypeCodeType>(PHOTO_PRODUCT_TYPE_CODE.none);

    const l_is_admin = whoAmI.isAdmin();

    // 선택한 지역목록에 따른 지점 목록 요청
    const {
        officeList,
        officeListError,
        isOfficeListLoading,
    } = useOfficeDataREST({
        region: l_region_value
    })

    // 관리자 페이지 에서 출력되는 지점 목록
    const {
        staffoOfficeList,
        staffoOfficeListError,
        isStaffoOfficeListLoading,
        staffoOfficeListRefetch
    } = useStaffOfficeListREST();

    const l_is_has_office_list = (officeList.length > 0 && officeList.some((item) => item.office_id === selectedOfficeId)) || l_is_admin; //관리자 계정인 경우는 통과시킨다.

    // 선택된 지점에 따른 촬영 상품 목록 요청
    const {
        photoProductList, 
        photoProductError, 
        isPhotoProductLoading,
    } = usePhotoProductDataREST({
        is_has_office_list: l_is_has_office_list,
        office_id: selectedOfficeId
    })

    const l_is_has_photo_product_list = photoProductList.length > 0;
    const l_is_available_office_id = officeList.some((item) => item.office_id === l_office_id) || l_is_admin; //관리자 계정인 경우는 통과시킨다.

    // 최종적으로 테마 목록 값 요청
    const {
        themeList,
        themeListError,
        isThemeListLoading,
    } = useThemaBookDetailListREST({
        is_available_office_id: l_is_available_office_id,
        is_has_photo_product_list: l_is_has_photo_product_list,
        office_id: selectedOfficeId,
        photo_product_id: selectedPhotoProductId,
        photo_product_type: selectedPhotoProductType,
    })

    useEffect(() => {
        // 접속이 가능한 계정인지 검증
        isAbleAccesser({
            allowedUserTypeList: ["C", "E"],
        });
    }, [])

    // URLSearchParams가 바뀌면 한번에 업데이트 시킨다.
    useEffect(() => {
        const {
            l_region_id,
            l_office_id,
            l_photo_product_id,
        } = themeBookParamsData({URLSearchParams})

        // 지역 id설정
        setSelectedRegionId(l_region_id);
        // 지점 id설정
        setSelectedOfficeId(l_office_id)
        // 촬영상품 id설정
        setSelectedPhotoProductId(l_photo_product_id)
    }, [URLSearchParams])

    useEffect(() => {
        let m_photo_product_type: photoProductTypeCodeType = PHOTO_PRODUCT_TYPE_CODE.none;

        PhotoProductSetting:{
            if(photoProductList.length === 0){
                break PhotoProductSetting;
            }

            // selectedPhotoProductId가 0이라는 뜻은, 전체 데이터를 모두 호출하겠다는 뜻이다.
            if(selectedPhotoProductId === 0){
                m_photo_product_type = PHOTO_PRODUCT_TYPE_CODE.all
                break PhotoProductSetting;
            }

            const m_selected_photo_product = photoProductList.find((item) => item.photo_id === selectedPhotoProductId);
            if(m_selected_photo_product){
                m_photo_product_type = m_selected_photo_product.type;
            }
        }

        setSelectedPhotoProductType(m_photo_product_type);
    }, [selectedPhotoProductId, photoProductList]);

    // 선택한 텝 메뉴 내용을 쿼리파라미터에 반영해준다.
    useEffect(() => {
        SetURLSearchParams((prev) => {
            prev.set("tab1", selectedRegionId.toString());
            prev.set("tab2", selectedOfficeId.toString());
            prev.set("tab3", selectedPhotoProductId.toString());
            
            return prev;
        }, {replace: true});
    }, [selectedRegionId, selectedOfficeId, selectedPhotoProductId])
    
    // 통합 로딩 처리
    useEffect(() => {
        setIsLoading(isOfficeListLoading || isPhotoProductLoading || isThemeListLoading || isStaffoOfficeListLoading)
    }, [isOfficeListLoading, isPhotoProductLoading, isThemeListLoading, isStaffoOfficeListLoading])

    if(l_is_admin){
        return (
            <ThemeBook
                themeList={themeList || []}
                isLoading={isLoading}
            >
                <div className={CSS.l_ThemeBookHome_filterContainer}>
                    <TitleBtnWrap<number>
                        title={"지점 선택"}
                        valueState={selectedOfficeId}
                        setValueState={setSelectedOfficeId}
                        value_list={staffoOfficeList.map((item) => item.office_id)}
                        innerText_list={staffoOfficeList.map((item) => `${item.office_name}`)}
                        is_activated={true}
                        is_print_badge_list={[]}
                        is_loading={isLoading}
                        category_tag="ThemeBook_selectedOfficeId"
                    />

                    <TitleBtnWrap<number>
                        title={"테마 선택"}
                        valueState={selectedPhotoProductId}
                        setValueState={setSelectedPhotoProductId}
                        value_list={photoProductList.map((item) => item.photo_id)}
                        innerText_list={photoProductList.map((item) => item.photo_name)}
                        is_activated={true}
                        is_print_badge_list={[]}
                        is_loading={isLoading}
                        category_tag="ThemeBook_selectedPhotoProduct"
                    />
                </div>
            </ThemeBook>
        )
    }else{
        return (
            <ThemeBook
                themeList={themeList || []}
                isLoading={isLoading}
            >
                <div className={CSS.l_ThemeBookHome_filterContainer}>
                    <TitleBtnWrap<number>
                        title={"지역 선택"}
                        valueState={selectedRegionId}
                        setValueState={setSelectedRegionId}
                        value_list={REGION_LIST.map((item) => item.id)}
                        innerText_list={REGION_LIST.map((item) => item.name)}
                        is_activated={true}
                        is_print_badge_list={[]}
                        is_loading={isLoading}
                        category_tag="ThemeBook_selectedRegion"
                    />

                    <TitleBtnWrap<number>
                        title={"지점 선택"}
                        valueState={selectedOfficeId}
                        setValueState={setSelectedOfficeId}
                        value_list={!!officeList ? officeList.map((item) => item.office_id) : []}
                        innerText_list={!!officeList ? officeList.map((item) => `${item.office_name}`) : []}
                        is_activated={true}
                        is_print_badge_list={[]}
                        is_loading={isLoading}
                        category_tag="ThemeBook_selectedOfficeId"
                    />

                    <TitleBtnWrap<number>
                        title={"테마 선택"}
                        valueState={selectedPhotoProductId}
                        setValueState={setSelectedPhotoProductId}
                        value_list={photoProductList.map((item) => item.photo_id)}
                        innerText_list={photoProductList.map((item) => item.photo_name)}
                        is_activated={true}
                        is_print_badge_list={[]}
                        is_loading={isLoading}
                        category_tag="ThemeBook_selectedPhotoProduct"
                    />
                </div>
            </ThemeBook>
        )
    }
}

export default ThemeBookHome;