import { useQuery } from "@tanstack/react-query";
import { callAxios, dev_console, isSuccess, whoAmI } from "../../../Common/ts/commonTools";
import { RTN_interface } from "../../../Common/interface/App.interface";
import { alarmListData_Interface } from "../static/interface/AlarmList.interface";
import { defaultApiReaponseInterface } from "../../../Common/interface/Common.interface";
import { useEffect } from "react";

const EMPTY_LIST: alarmListData_Interface[] = [];

const useAlarmListREST = () => {
    const {
        data: alarmListData = EMPTY_LIST,
        isLoading: alarmListIsLoading,
        error: alarmListError,
        refetch: alarmListRefetch
    } = useQuery({
        queryKey: ["useAlarmListREST"],
        queryFn: requestAlarmListData,
        enabled: isAbleToRequest,
        select: (response) => {
            const RTN: RTN_interface<alarmListData_Interface[]> = {
                sign: false,
                data: EMPTY_LIST,
                msg: ""
            }
            
            SetData:{
                if(!isSuccess(response)){
                    RTN.msg = "알람 목록 데이터 호출에 실패했습니다."
                    break SetData;
                }

                const data: defaultApiReaponseInterface<alarmListData_Interface[]> = response.data;

                RTN.sign = true;
                RTN.data = data.result;
                RTN.msg = "success";
            }

            if(!RTN.sign){
                dev_console.error(RTN.msg);
            }

            return RTN.data;
        }
    })
    
    function isAbleToRequest(){
        let RTN: boolean = false;

        if(whoAmI.isCustomer() || whoAmI.isAdmin()){
            RTN = true;
        }

        return RTN;
    }
    
    async function requestAlarmListData(){
        const alarm_list_url: string = "api/v3/oops/alarm/list";

        const response = await callAxios.api({
            url: alarm_list_url,
            method: "get",
        })

        dev_console.log(alarm_list_url);
        dev_console.log(response);

        return response;
    }

    useEffect(() => {
        if(alarmListError){
            dev_console.error(alarmListError);
        }
    }, [alarmListError]);

    return({
        alarmListData,
        alarmListIsLoading,
        alarmListError,
        alarmListRefetch
    })
}

export default useAlarmListREST;