import { useEffect, useRef, useState } from "react";
import CSS from "./static/css/TextareaOverlaySet.module.css";
import Text from "../Text/Text";
import Overlay from "../Overlay/Overlay";
import Textarea from "../Textarea/Textarea";
import TextareaViewDiv from "../TextareaViewDiv/TextareaViewDiv";
import { dev_console } from "../../../Common/ts/commonTools";

const TextareaOverlaySet = (
    {
        onChange,
        placeholder,
        readOnly,
        disabled,
        value,
    }:{
        onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
        placeholder?: string;
        readOnly?: boolean;
        disabled?: boolean;
        value?: string;
    }
) => {
    const [isOverlayView, setIsOverlayView] = useState<boolean>(false);
    const [isOverflow, setIsOverflow] = useState<boolean>(false);
    const l_textAreaRef = useRef<HTMLTextAreaElement | null>(null);

    const checkOverflow = () => {
        const m_textAreaRef_current = l_textAreaRef.current;
        
        if(m_textAreaRef_current){
            const m_isVerticalOverflow = m_textAreaRef_current.scrollHeight > m_textAreaRef_current.clientHeight;
            const m_isHorizontalOverflow = m_textAreaRef_current.scrollWidth > m_textAreaRef_current.clientWidth;

            setIsOverflow(m_isVerticalOverflow || m_isHorizontalOverflow);
        }
    }

    const overlayOpenHandler = () => {
        setIsOverlayView(true);
    }

    const overlayCloseHandler = () => {
        setIsOverlayView(false);
    }

    useEffect(() => {
        checkOverflow();
    }, [value])

    useEffect(() => {
        dev_console.log("isOverlayView ===>", isOverlayView);
    }, [isOverlayView])

    if((disabled || readOnly) && isOverflow){
        return(
            <div 
                className={`g_click_event_item ${CSS.l_click_encourage_container}`}
                onClick={overlayOpenHandler}
            >
                <Text
                    isHidden={"mobile"}
                    preset={"text_area_click_guide"}
                >
                    클릭해서 전체 내용을 확인해 보세요.
                </Text>

                <Text 
                    isHidden={"web"}
                    preset={"text_area_click_guide"}
                >
                    터치해서 전체 내용을 확인해 보세요.
                </Text>

                <Overlay 
                    isOverlayOpen={isOverlayView}
                    overlayCloseHandler={overlayCloseHandler}
                >
                    <TextareaViewDiv
                        value={value}
                    />
                </Overlay>
            </div>
        )
    }else{
        return(
            <Textarea
                onChange={onChange}
                placeholder={placeholder}
                readOnly={readOnly}
                disabled={disabled}
                value={value}
                ref={l_textAreaRef}
            />
        )
    }
}

export default TextareaOverlaySet;