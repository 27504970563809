import { useEffect, useState } from "react";

const useHistoryBackContralOverlayState = (initialState: boolean) => {
    const [isOpen, setIsOpen] = useState<boolean>(initialState);
    
    const overlayHandler = (open: boolean) => {
        if(open){
            window.history.pushState(null, "");
        }else{
            window.history.replaceState(null, "");
        }

        setIsOpen(open);
    }

    
    useEffect(() => {
        const modalCloseHandler = () => {
            setIsOpen(false)
        }

        window.addEventListener("popstate", modalCloseHandler);

        return(() => {
            window.removeEventListener("popstate", modalCloseHandler);
        })
    }, [])

    return({
        isOpen,
        overlayHandler,
    })
}

export default useHistoryBackContralOverlayState;