import { useGallery } from "react-photoswipe-gallery";
import { FILE_CODE } from "../../../../../Common/ts/const";
import ReactPhotoSwipeSetter from "../../hooks/ReactPhotoSwipeSetter.hook";
import { ReactNode } from "react";
import PhotoSwipeGalleryItem from "../PhotoSwipeGalleryItem/PhotoSwipeGalleryItem";

const PhotoSwipeGalleryIconVersionItem = (
    {
        img_list,
        children,
    }:{
        img_list: string[];
        children?: ReactNode;
    }
) => {
    const {viewportSize} = ReactPhotoSwipeSetter();

    const { open } = useGallery();

    const openHandler = () => {
        if(img_list.length > 0){
            open(0);
        }
    }

    return(
        <>
            <div onClick={openHandler}>
                {children}
            </div>
            {
                img_list.map((item, index) => {
                    return(
                        <PhotoSwipeGalleryItem
                            key={index}
                            file_type={FILE_CODE.img}
                            origin_url={item}
                            thumbnail_url={item}
                            viewport_width={viewportSize.width}
                            viewport_height={viewportSize.height}
                        />
                    )
                })
            }
        </>
    )
}

export default PhotoSwipeGalleryIconVersionItem;