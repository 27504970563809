import { useQuery } from "@tanstack/react-query";
import { callAxios, customToast, dev_console, isSuccess, printStateMsg, setQueryParameter, whoAmI } from "../../../Common/ts/commonTools";
import { defaultApiReaponseInterface } from "../../../Common/interface/Common.interface";
import { themaBookDetailListInterface } from "../static/interface/themeBook.interface";
import { useEffect } from "react";
import { RTN_interface } from "../../../Common/interface/App.interface";

const TOAST_ID = "useThemeDetailDataREST";

const useThemeDetailDataREST = (
    {
        type,
        office_id,
        photo_id,
        thema_id,
    }:{
        type: string;
        office_id: string;
        photo_id: string;
        thema_id: string;
    }
) => {
    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer() || whoAmI.isAdmin()){
            if(type && office_id && thema_id){
                result = true;
            }
        }

        return result;
    }

    const {
        data: themeDetailData = null,
        error: themeDetailDataError,
        isLoading: themeDetailDataIsLoading,
        refetch: themeDetailDataRefetch,
    } = useQuery({
        queryKey: ["themeDetailData", type, office_id, photo_id, thema_id],
        queryFn: callThemeDetailData,
        enabled: isAbleToRequest,
        select: (response) => {
            const RTN: RTN_interface<themaBookDetailListInterface | null> = {
                sign: false,
                data: null,
                msg: "",
            };

            SetData:{                
                if(!isSuccess(response)){
                    RTN.msg = "테마 상세정보를 요청하는 과정에서 문제가 발생했습니다.";
                    break SetData;
                }

                const row_response: defaultApiReaponseInterface<themaBookDetailListInterface[]> = response.data;

                if(!row_response.result || row_response.result.length <= 0){
                    RTN.msg = "EMPTY";
                    break SetData;
                }
                
                RTN.sign = true;
                RTN.data = row_response.result[0];
                RTN.msg = "success";
            }

            if(!RTN.sign){
                if(response){
                    printStateMsg(response);
                }
            }

            return RTN.data;
        },
    })

    async function callThemeDetailData(){
        const thema_book_detail_list_endpoint:string = "api/v3/customers/thema_book_detail_list";
        const query_parameter_string = setQueryParameter([
            {
                key: "type",
                value: type,
            },{
                key: "office_id",
                value: office_id,
            },{
                key: "photo_id",
                value: photo_id,
            },{
                key: "thema_id",
                value: thema_id,
            }
        ])

        const thema_book_detail_list_url = thema_book_detail_list_endpoint.concat("?", query_parameter_string);
        
        const response = await callAxios.api({
            url: thema_book_detail_list_url,
            method: "get",
        })

        dev_console.log(thema_book_detail_list_url);
        dev_console.log(response);

        return response;
    }

    useEffect(() => {
        if(!!themeDetailDataError){
            dev_console.error(themeDetailDataError);
            customToast.error({
                msg: "테마 상세정보를 요청하는 과정에서 문제가 발생했습니다.",
                toastId: TOAST_ID
            });
        }
    }, [themeDetailDataError])

    return({
        themeDetailData,
        themeDetailDataIsLoading,
        themeDetailDataError,
        themeDetailDataRefetch,
    })
}

export default useThemeDetailDataREST;