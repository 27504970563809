import CSS from "./static/css/DetailModalIcon.module.css";
import icon_img from "./static/img/more_icon.svg";

const DetailModalIcon = () => {
    return(
        <span className={CSS.l_DetailModalIcon_main}>
            <img
                className={CSS.l_DetailModalIcon__icon_img} 
                src={icon_img} 
            />
        </span>
    )
}

export default DetailModalIcon;