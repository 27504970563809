import { useEffect, useState } from "react";
import CSS from "./static/css/badge.module.css";
import Text from "../Text/Text";

const Badge = (
    {

        badge,
        children,
        is_print_badge,
        class_name_list,
    }:{
        badge?: string;
        children?: React.ReactNode;
        is_print_badge: boolean;
        class_name_list?: string[];
    }
) => {
    const [totalClassName, setTotalClassName] = useState<string>(CSS.l_badge_main);

    useEffect(() => {
        const total_class_name_list: string[] = [];

        if(class_name_list){
            for(const class_name of class_name_list){
                total_class_name_list.push(class_name);
            }
        }

        total_class_name_list.push(CSS.l_badge_main)

        setTotalClassName(total_class_name_list.join(" "));
    }, [class_name_list])

    return (
        <div
            className={totalClassName}
        >
            {
                is_print_badge
                &&  <Text
                        color={"red_gray"}
                        size={"size1"}
                        class_name_list={[CSS.l_bage_mark_container]}
                    >
                        {badge || "●"}
                    </Text>
            }
            {
                children
                &&  <div className={`${CSS.l_bage_children_container}`}>
                        {children}
                    </div>   
            }
        </div>
    )
}

export default Badge;