import { useEffect, useState } from "react";
import { mainREST_Interface } from "../static/interface/wrokRequiredNotificationDataREST.interface";

const useWrokRequiredNotificationDataSetter = (
    {
        mainData
    }:{
        mainData: mainREST_Interface | null;
    }
) => {
    const [selectPhotoCount, setSelectPhotoCount] = useState<number>(0);
    const [editPhotoCount, setEditPhotoCount] = useState<number>(0);
    const [confPhotoCount, setConfPhotoCount] = useState<number>(0);
    const [gooutPhotoCount, setGooutPhotoCount] = useState<number>(0);
    const [selectThemeCount, setSelectThemeCount] = useState<number>(0);
    const [eventReviewCount, setEventReviewCount] = useState<number>(0);

    useEffect(() => {
        if(mainData){
            setSelectPhotoCount(mainData.select_photo_count);
            setEditPhotoCount(mainData.edit_photo_count);
            setConfPhotoCount(mainData.conf_photo_count);
            setGooutPhotoCount(mainData.goout_photo_count);
            setSelectThemeCount(mainData.thema_select_count);
            setEventReviewCount(mainData.event_review_count);
        }
    }, [mainData])

    return({
        totalCount: selectPhotoCount + confPhotoCount + selectThemeCount + eventReviewCount,
        selectPhotoCount,
        editPhotoCount,
        confPhotoCount,
        gooutPhotoCount,
        selectThemeCount,
        eventReviewCount,
    })
}

export default useWrokRequiredNotificationDataSetter;