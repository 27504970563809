import { useMediaQuery } from "react-responsive";
import CSS from "./static/css/PaymentDetailItmeText.module.css";
import Text from "../../../../../Component/Elements/Text/Text";
import { ReactNode, useMemo } from "react";

const PaymentDetailItmeText = (
    {
        className,
        children,
        is_title,
        justify_content,
    }:{
        className?: string;
        children?: ReactNode;
        is_title?: boolean;
        justify_content?: "flex_start" | "flex_end" | "center"
    }
) => {
    const isMobile = useMediaQuery({ maxWidth: 700 });

    const mainClassName = useMemo(() => {
        const RTN: string[] = [];

        if(className){
            RTN.push(className);
        }

        RTN.push(CSS.l_PaymentDetailItmeText_main);

        if(justify_content){
            RTN.push(CSS.justify_content)
            RTN.push(CSS[justify_content])
        }

        return RTN.join(" ")
    }, [className, justify_content])

    return(
        <div className={mainClassName}>
            <Text
                size={
                    isMobile
                    ?   "size1"
                    :   "size3"
                }
                color={
                    is_title
                    ?   "secondary"
                    :   "gray3"
                }
            >
                {children}
            </Text>
        </div>
    )
}

export default PaymentDetailItmeText;