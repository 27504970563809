// 모바일 환경에서 사용하는 제품 제작 신청 페이지

import CSS from "./static/css/ProductRequestEditMobileViewing.module.css";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { Address } from "react-daum-postcode";
import { ProductDetail_Folder_FolderListEle_Interface, ProductDetail_OptionListEle_Interface, ProductDetail_SelectedOptionListEle_Interface } from "../../../static/interface/ProductDetail.interface";
import { addedInfoDataInterface, satisfiedPhotoNumsInterface, uploadImgFilesInterface } from "../../../static/interface/ProductRequestEdit.interface";
import { addressDataInterface } from "../../../../../Component/Elements/Postcode/interface/useDaumPostcode.interface";
import PageChangeTabMenu from "../../PageChangeTabMenu/PageChangeTabMenu";
import UploadImageList from "../../UploadImageList/UploadImageList";
import OptionList from "../../OptionList/OptionList";
import AdditionalInfoContainer from "../../AdditionalInfoContainer/AdditionalInfoContainer";
import AddressInfoCard from "../../AddressInfoCard/AddressInfoCard";
import SubmitBtnContainer from "../../SubmitBtnContainer/SubmitBtnContainer";
import MainPage from "../../../../../Component/Elements/Page/MainPage/MainPage";
import { recipientInfoInterface } from "../../../static/interface/common.interface";

const ProductRequestEditMobileViewing = (
    {
        folderList,
        productOptionDataList,
        imgFiles,
        setImgFiles,
        satisfiedPhotoNums,
        setSatisfiedPhotoNums,
        selectedOptionList,
        newAddedInfo,
        setNewAddedInfo,
        request,
        setRequest,
        customAddressData,
        setCustomAddressData,
        setRowAddressData,
        cancelHandler,
        requesetSelectHandler,
        readonly,
        selectOptionItemClickHandler,
        isAlbum,
        recipientInfo,
        recipientInfoChangeHandler,
        min_resolution,
        min_long_size,
        min_short_size,
        deliveryAddressListOverlayOpenHandler,
    }:{
        folderList: ProductDetail_Folder_FolderListEle_Interface[];
        productOptionDataList: ProductDetail_OptionListEle_Interface[];
        imgFiles: uploadImgFilesInterface;
        setImgFiles: Dispatch<SetStateAction<uploadImgFilesInterface>>;
        satisfiedPhotoNums: satisfiedPhotoNumsInterface;
        setSatisfiedPhotoNums: Dispatch<SetStateAction<satisfiedPhotoNumsInterface>>;
        selectedOptionList: ProductDetail_SelectedOptionListEle_Interface[];
        newAddedInfo: addedInfoDataInterface;
        setNewAddedInfo: Dispatch<SetStateAction<addedInfoDataInterface>>;
        request: string;
        setRequest: Dispatch<SetStateAction<string>>;
        customAddressData: addressDataInterface;
        setCustomAddressData: Dispatch<SetStateAction<addressDataInterface>>;
        setRowAddressData: Dispatch<SetStateAction<Address | null>>
        cancelHandler: React.MouseEventHandler<HTMLButtonElement>;
        requesetSelectHandler: React.MouseEventHandler<HTMLButtonElement>;
        readonly?: boolean;
        selectOptionItemClickHandler: (option: ProductDetail_SelectedOptionListEle_Interface) => void;
        isAlbum: boolean;
        recipientInfo: recipientInfoInterface;
        recipientInfoChangeHandler: (value: string, target: "name" | "phone") => void;
        min_resolution: number;
        min_long_size: number;
        min_short_size: number;
        deliveryAddressListOverlayOpenHandler: () => void;
    }
) => {
    const [pageNum, setPageNum] = useState<number>(1);

    const pageNumBtnClickHandler = useCallback((pageNum: number) => {
        setPageNum(pageNum);
    }, [])

    return(
        <MainPage
            title=""
            is_navi_hidden={!readonly}
            background_color={!readonly ? "select_page" : undefined}
            isHidden={"web"}
        >
            <div className={CSS.l_ProductRequestEditMobileViewing_main}>
                <PageChangeTabMenu 
                    pageNum={pageNum}
                    isHaveOption={productOptionDataList.length > 0}
                    clickHandler={pageNumBtnClickHandler}
                />
                
                <div className={CSS.l_ProductRequestEditMobileViewing_contentContainer}>
                    {
                        pageNum === 1
                        ?   <div className={CSS.l_ProductRequestEditMobileViewing_contentPage}>
                                <UploadImageList
                                    folderList={folderList}
                                    imgFiles={imgFiles}
                                    setImgFiles={setImgFiles}
                                    isSatisfiedPhotoNums={satisfiedPhotoNums}
                                    setIsSatisfiedPhotoNums={setSatisfiedPhotoNums}
                                    min_resolution={min_resolution}
                                    min_long_size={min_long_size}
                                    min_short_size={min_short_size}
                                />
                            </div>
                        :   pageNum === 2
                        ?   <div className={CSS.l_ProductRequestEditMobileViewing_contentPage}>
                                <OptionList
                                    productOptionDataList={productOptionDataList}
                                    selectedOptionList={selectedOptionList}
                                    itemOnClickHandler={selectOptionItemClickHandler}
                                />
                            </div>
                        :   pageNum === 3
                        ?   <div className={CSS.l_ProductRequestEditMobileViewing_contentPage}>
                                <AdditionalInfoContainer
                                    addedInfo={newAddedInfo}
                                    setAddedInfo={setNewAddedInfo}
                                    request={request}
                                    setRequest={setRequest}
                                    isAlbum={isAlbum}
                                />
                                
                                <AddressInfoCard
                                    recipientInfo={recipientInfo}
                                    recipientInfoChangeHandler={recipientInfoChangeHandler}
                                    customAddressData={customAddressData}
                                    setCustomAddressData={setCustomAddressData}
                                    setRowAddressData={setRowAddressData}
                                    overlayHandler={deliveryAddressListOverlayOpenHandler}
                                />
                            </div>
                        :   null
                    }

                    <SubmitBtnContainer 
                        cancelBtnHandler={cancelHandler}
                        selectBtnHandler={requesetSelectHandler}
                    />
                </div>
            </div>
        </MainPage>
    )
}

export default ProductRequestEditMobileViewing;