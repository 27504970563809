import CSS from "./static/css/productBlock.module.css";
import Text from "../../../../Component/Elements/Text/Text";
import MainListItemCard from "../../../../Component/Elements/MainListItemCard/MainListItemCard";
import { productStateCodeType, productTypeCodeType } from "../../static/interface/ProductList.interface";
import { PRODUCT_TYPE_TEXT_OBJ } from "../../static/ts/const";
import useProductBlock from "./hooks/useProductBlock.hook";

const ProductBlock = (
    {
        product_name,
        product_type,
        product_state,
        product_qty,
        options,
        thumbnail_src,
        onClick,
    }:{
        product_name: string;
        product_type: productTypeCodeType | null;
        product_state: productStateCodeType | null;
        product_qty: string;
        options: string;
        thumbnail_src: string;
        onClick?: React.MouseEventHandler<HTMLDivElement>;
    }
) => {

    const {
        color,
        text,
    } = useProductBlock({
        product_state
    })

    return (
        <MainListItemCard
            onClick={onClick}
            img_src={thumbnail_src}
        >
            <div className={CSS.l_ProductBlock_infoContiner}>
                <div className={CSS.l_ProductBlock_headerContainer}>
                    <Text
                        color={"gray3"}
                        text_wrap={"nowrap"}
                    >
                        {product_type ? PRODUCT_TYPE_TEXT_OBJ[product_type] : ""}
                    </Text>
                    {
                        product_qty
                        &&  <>
                                <Text
                                    size={"size1"}
                                    color={"gray3"}
                                >
                                    |
                                </Text>
                                <Text
                                    color={"gray3"}
                                    text_wrap={"nowrap"}
                                >
                                    수량 : {product_qty}
                                </Text>
                            </>
                    }
                </div>

                <div className={CSS.l_ProductBlock_centerContainer}>
                    <Text 
                        preset={"content_title"}
                        is_text_truncation_on={true}
                    >
                        {product_name}
                    </Text>
                    <Text 
                        is_text_truncation_on={true}
                        color={"gray2"}
                    >
                        {options}
                    </Text>
                </div>

                <Text
                    color={color}
                >
                    {text}
                </Text>
            </div>
        </MainListItemCard>
    )
}

export default ProductBlock