import CSS from "./static/css/Overlay.module.css";
import BottomSheet from "../BottomSheet/BottomSheet";
import ModalChildrenContainer from "../ModalChildrenContainer/ModalChildrenContainer";
import { MouseEventHandler, ReactNode, useMemo } from "react";

const Overlay = (
    {
        isOverlayOpen,
        overlayCloseHandler,
        modal_padding,
        headerNode,
        children,
        prevThemeHandler,
        nextThemeHandler,
        min_heigth_full,
    }:{
        isOverlayOpen: boolean;
        overlayCloseHandler?: () => void;
        modal_padding?: "none";
        headerNode?: ReactNode;
        children?: ReactNode;
        prevThemeHandler?: MouseEventHandler<HTMLDivElement>;
        nextThemeHandler?: MouseEventHandler<HTMLDivElement>;
        min_heigth_full?: boolean;
    }
) => {

    const mainClassName = useMemo(() => {
        const RTN: string[] = [CSS.l_Overlay_main];

        if(isOverlayOpen){
            RTN.push(CSS.open);
        }

        return RTN.join(" ");
    }, [isOverlayOpen])

    return(
        <div 
            className={mainClassName}
            onClick={(event) => {
                event.stopPropagation();

                if(overlayCloseHandler){
                    overlayCloseHandler();
                }
            }}
            onTouchStart={(event) => {
                event.stopPropagation();
            }}
            onTouchEnd={(event) => {
                event.stopPropagation();
            }}
        >
            {/* 웹환경일때는 모달 형식으로 출력한다. */}
            <ModalChildrenContainer
                isViewing={isOverlayOpen}
                closeHandler={overlayCloseHandler}
                isHidden={"mobile"} // 모바일 환경에서 숨긴다 === 웹 환경에서만 출력한다.
                padding={modal_padding}
                headerNode={headerNode}
                prevThemeHandler={prevThemeHandler}
                nextThemeHandler={nextThemeHandler}
                min_heigth_full={min_heigth_full}
            >
                {children}
            </ModalChildrenContainer>

            {/* 모바일환경에서는 모달대신 바텀시트형식으로 출력한다. */}
            <BottomSheet
                isViewing={isOverlayOpen}
                closeHandler={overlayCloseHandler}
                isHidden={"web"} // 웹환경에서 숨긴다 === 모바일 환경에서만 출력한다.
                prevThemeHandler={prevThemeHandler}
                nextThemeHandler={nextThemeHandler}
                min_heigth_full={min_heigth_full}
            >
                {children}
            </BottomSheet>
        </div>
    )
}

export default Overlay;