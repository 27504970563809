import { joinedEventType } from "../../static/interface/PhotoList.interface";
import { callAxios, customToast, dev_console } from "../../../../Common/ts/commonTools";
import { useState } from "react";
import Button from "../../../../Component/Elements/Button/Normal/Button";
import usePhotoDetailDataREST from "../../hooks/usePhotoDetailDataREST.hook";
import usePhotoListDataREST from "../../hooks/usePhotoListDataREST.hook";
import { PHOTO_DETAILS_TYPE_CODE, PHOTO_TYPE_CODE } from "../../static/ts/const";
import useEventApplication from "../../hooks/useEventApplication.hook";

const EventApplicationBtn = (
    {
        isEventApplication,
        schedule_id,
        upload_date,
        joined_event,
        photo_upload_id
    }:{
        isEventApplication: boolean;
        schedule_id: number;
        upload_date: string;
        joined_event: joinedEventType;
        photo_upload_id: number;
    }
) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {
        photoDetailDataRefetch,
    } = usePhotoDetailDataREST({
        photo_upload_id,
        main_type: 1,
    });

    const {
        photoListDataRefetch,
    } = usePhotoListDataREST({
        main_type: PHOTO_TYPE_CODE.contract_shoot,
        status: PHOTO_DETAILS_TYPE_CODE.origin,
    })

    const {
        state,
    } = useEventApplication({      
        upload_date: upload_date,
        joined_event: joined_event,
        isEventApplication,
    });

    const eventApplicationBtnHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        
        if(!window.confirm("후기 이벤트를 신청하시겠습니까?")){
            return null;
        }

        setIsLoading(true);
        requestEventApplication(schedule_id)
        .then(async (response) => {
            dev_console.log(response);
            await photoDetailDataRefetch();
            await photoListDataRefetch();
            customToast.success({msg: "후기 이벤트 신청이 완료되었습니다."});
        })
        .catch((error) => {
            dev_console.error(error);
            customToast.success({msg: "후기 이벤트 신청 중에 문제가 발생했습니다."});
        })
        .finally(() => {
            setIsLoading(false);
        })
    }

    async function requestEventApplication( schedule_id: number ){
        const config = {
            schedule_id,
        }
    
        const event_check = "api/v3/customers/event-check";
        const response = await callAxios.api({
            url: event_check,
            method: "post",
            dataType: "json",
            data: config,
        })
    
        return response;
    }

    if(state.state === 1){        
        return(
            <Button
                onClick={eventApplicationBtnHandler}
                preset={"move_page"}
                disabled={isLoading}
                size={"size3"}
            >
                후기 이벤트 신청
            </Button>
        )
    }else if(state.state === -1){
        return(
            <Button
                preset={"disabled"}
                size={"size3"}
            >
                후기 이벤트 신청 완료
            </Button>
        )
    }else{
        return(null)
    }
}

export default EventApplicationBtn;