import CSS from "./static/css/CheckbaxContainer.module.css";
import Checkbox from "../Checkbox/Checkbox";
import Text from "../Text/Text";
import { useEffect, useState } from "react";

const CheckbaxContainer = (
    {
        label,
        isChecked,
        onClickHander,
        disabled,
    }:{
        label?: string;
        isChecked: boolean;
        onClickHander?: React.MouseEventHandler<HTMLDivElement>;
        disabled?: boolean;
    }
) => {
    const [totalClassName, setTotalClassName] = useState<string>(CSS.l_checkbax_container_main);

    useEffect(() => {
        const total_class_name_list: string[] = ["g_click_event_item"];

        if(disabled){
            total_class_name_list.push("not_allowed");
        }

        total_class_name_list.push(CSS.l_checkbax_container_main);
        setTotalClassName(total_class_name_list.join(" "));
    }, [disabled, onClickHander])

    const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if(disabled){
            return ;
        }

        if(onClickHander){
            onClickHander(event);
        }
    }

    return(
        <div 
            className={totalClassName}
            onClick={onClick}
        >
            <Checkbox 
                isChecked={isChecked}
                disabled={disabled}
            />
            {
                !!label
                &&  <Text
                        size={"size3"}
                    >
                        {label}
                    </Text>
            }
        </div>
    )
}

export default CheckbaxContainer;