import { backPointUrlManagerClass, logout } from "../../Common/ts/appTools";
import { callAxios, customToast, dev_console, printStateMsg, validatePassword } from "../../Common/ts/commonTools";
import AccountPageContentsCardContainer from "../../Component/Elements/AccountPageContentsCardContainer/AccountPageContentsCardContainer";
import Input from "../../Component/Elements/Input/Input";
import AccountPage from "../../Component/Elements/Page/AccountPage/AccountPage"
import { useRef, useState } from "react";
import LabelAndContent from "../../Component/Elements/LabelAndContent/LabelAndContent";
import Button from "../../Component/Elements/Button/Normal/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import CSS from "./static/ChangePassword.module.css";

const backPointUrlManager = new backPointUrlManagerClass();

const TOAST_ID = 'change_password';

const ChangePassword = () => {
    const [password, setPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");

    const l_passwordRef = useRef<HTMLInputElement>(null);
    const l_newPasswordRef = useRef<HTMLInputElement>(null);
    const l_newPasswordConfirmRef = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();

    const [URLSearchParams, SetURLSearchParams] = useSearchParams();

    const onSubmit = () => {
        if(password === ""){
            customToast.error({
                msg: "기존 비밀번호를 입력해 주세요.",
                toastId: TOAST_ID
            });

            // 기존 비밀번호 입력 input요소로 포커스 이동
            if(l_passwordRef.current){
                l_passwordRef.current.focus();
            }
            return ;
        }

        if(newPassword === ""){
            customToast.error({
                msg: "신규 비밀번호를 입력해 주세요.",
                toastId: TOAST_ID
            });

            // 신규 비밀번호 입력 input요소로 포커스 이동
            if(l_newPasswordRef.current){
                l_newPasswordRef.current.focus();
            }
            return ;
        }

        if(newPasswordConfirm === ""){
            customToast.error({
                msg: "재확인용 비밀번호를 입력해 주세요.",
                toastId: TOAST_ID
            });

            // 재확인용 비밀번호 입력 input요소로 포커스 이동
            if(l_newPasswordConfirmRef.current){
                l_newPasswordConfirmRef.current.focus();
            }
            return ;
        }

        if(password === newPassword){
            customToast.error({
                msg: "입력하신 기존 비밀번호와 신규 비밀번호가 같습니다.",
                toastId: TOAST_ID
            });

            // 신규 비밀번호 입력 input요소로 포커스 이동
            if(l_passwordRef.current){
                l_passwordRef.current.focus();
            }

            return ;
        }

        if(newPassword !== newPasswordConfirm){
            customToast.error({
                msg: "입력하신 신규 비밀번호와 재확인 비밀번호가 다릅니다.",
                toastId: TOAST_ID
            });

            // 재확인용 비밀번호 입력 input요소로 포커스 이동
            if(l_newPasswordConfirmRef.current){
                l_newPasswordConfirmRef.current.focus();
            }

            return ;
        }
        
        const m_password_error_list = validatePassword(newPassword);
        if(m_password_error_list.length > 0){
            customToast.error({
                msg: `새로운 비밀번호는 다음의 조건을 충족해야 합니다.`,
                toastId: TOAST_ID
            });

            for(const error of m_password_error_list){
                customToast.error({
                    msg: error,
                });
            }

            return ;
        }

        if(!window.confirm("비밀번호를 변경하시겠습니까?")){
            return ;
        }

        const config = {
            old_password: password,
            new_password: newPassword,
            confirm_password: newPasswordConfirm
        }

        const password_change_url = 'api/v1/accounts/password_change/';

        callAxios.api({
            method: 'post',
            url: password_change_url,
            data: config,
            dataType: 'json'
        })
        .then((response) => {
            dev_console.log(password_change_url);
            dev_console.log(response);

            if(response.status === 200){
                logout();
            }else{
                printStateMsg(response);

                if(response.status === 400){
                    customToast.error({
                        msg: "비밀번호 변경에 실패했습니다.\n기존 비밀번호를 확인해 주세요.",
                        toastId: TOAST_ID
                    });
                }else{
                    customToast.error({
                        msg: "비밀번호 변경에 실패했습니다.",
                        toastId: TOAST_ID
                    });
                }
            }
        })
        .catch((error) => {
            dev_console.error(error);

            if(error.response.status === 400){
                customToast.error({
                    msg: "비밀번호 변경에 실패했습니다.\n기존 비밀번호를 확인해 주세요.",
                    toastId: TOAST_ID
                });
            }else if(error.response.status === 401){
                logout();
            }else{
                customToast.error({
                    msg: "문제가 발생했습니다. 관리자에게 문의해 주세요.",
                    toastId: TOAST_ID
                });
            }
        })
    }

    const onKeyPressAction = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    };

    const goToOutHandler = () => {
        let m_back_point_url = backPointUrlManager.getBackPointUrlAndDecode(URLSearchParams);

        if(!m_back_point_url){
            m_back_point_url = "/";
        }

        navigate(m_back_point_url, {replace: true});
    }
    
    return (
        <AccountPage>
            <AccountPageContentsCardContainer 
                title="비밀번호 변경"
                contents={
                    <div className={CSS.l_ChangePassword_main}>
                        <div className={CSS.l_ChangePassword_passwordContainer}>
                            <LabelAndContent
                                label={"기존 비밀번호"}
                                flex_direction={"column"}
                            >
                                <Input 
                                    type={"password"}
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                    onKeyDown={onKeyPressAction}
                                    ref={l_passwordRef}
                                    placeholder={"기존 비밀번호 입력"}
                                />
                            </LabelAndContent>
                        </div>
                        <div className={CSS.l_ChangePassword_newPasswordContainer}>
                            <LabelAndContent
                                label={"새 비밀번호"}
                                flex_direction={"column"}
                            >
                                <Input 
                                    type={"password"}
                                    value={newPassword}
                                    onChange={(e) => {
                                        setNewPassword(e.target.value);
                                    }}
                                    onKeyDown={onKeyPressAction}
                                    ref={l_newPasswordRef}
                                    placeholder={"새 비밀번호 입력"}
                                />
                            </LabelAndContent>

                            <LabelAndContent
                                label={"새 비밀번호 재확인"}
                                flex_direction={"column"}
                            >  
                                <Input 
                                    type={"password"}
                                    value={newPasswordConfirm}
                                    onChange={(e) => {
                                        setNewPasswordConfirm(e.target.value);
                                    }}
                                    onKeyDown={onKeyPressAction}
                                    placeholder={"새 비밀번호 재입력"}
                                    ref={l_newPasswordConfirmRef}
                                />
                            </LabelAndContent>
                        </div>
                    </div>
                }
                bottom_content={
                    <div className={CSS.l_ChangePassword_buttonContainer}>
                        <Button
                            preset={"normal_gray"} 
                            onClick={goToOutHandler}
                            size={"size3"}
                        >
                            취소
                        </Button>            
                        
                        <Button
                            size={"size3"}
                            onClick={onSubmit}
                        >
                            비밀번호 변경
                        </Button>
                    </div>
                }
            />
        </AccountPage>
    );
}

export default ChangePassword;