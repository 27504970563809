
import IconButtonCheckCheckMark from "../../../../../Component/Elements/Icon/IconButtonCheck/IconButtonCheckCheckMark";
import CSS from "./static/css/MoveBtn.module.css";

import left_arrow_img from "./static/img/Group 17569_left.svg";
import right_arrow_img from "./static/img/Group 17570_rigth.svg";

const MoveBtn = (
    {
        leftClickHandler,
        rightClickHandler
    }:{
        leftClickHandler?: React.MouseEventHandler<HTMLDivElement>;
        rightClickHandler?: React.MouseEventHandler<HTMLDivElement>;
    }
) => {
    return(
        <div className={CSS.l_move_btn_main}>
            {
                leftClickHandler
                &&  <IconButtonCheckCheckMark
                        src={left_arrow_img}
                        size={"small"}
                        onClick={leftClickHandler}
                    />
            }

            {
                rightClickHandler
                &&  <IconButtonCheckCheckMark 
                        src={right_arrow_img}
                        size={"small"}
                        onClick={rightClickHandler}
                    />            
            }
        </div>
    )
}

export default MoveBtn;