export const MONTHLY_SCHEDULE_BTN_CODE_OBJECT = {
    calendar: 1,
    list: 2
} as const;

// 이벤트의 종류
export const EVENT_CODE = {
    schedule: 1,
    Event: 2,
    holiday: 3,
} as const