import { forwardRef, MouseEventHandler, useEffect, useState } from "react";
import CSS from "./static/css/button.module.css";
import Text from "../../Text/Text";
import { buttonColorType, buttonPresetType, buttonSizeType } from "./interface/Button.interface";
import useButtonText from "./hooks/useButtonText.hook";
import MovePageButtonIcon from "../../MovePageButtonIcon/MovePageButtonIcon";
import DetailModalIcon from "./components/DetailModalIcon/DetailModalIcon";
import { isHiddenType } from "../../../../Common/interface/Common.interface";
import { isHiddenClassNameSetter } from "../../../../Common/ts/commonTools";

interface ButtonInterface{
    children: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    class_name_list?: string[];
    disabled?: boolean;
    type?: "button" | "submit" | "reset";
    preset?: buttonPresetType;
    color?: buttonColorType;
    size?: buttonSizeType;
    isHidden?: isHiddenType;
}

const Button = forwardRef<HTMLButtonElement, ButtonInterface>(({
    children,
    onClick,
    class_name_list,
    disabled,
    type,
    preset,
    color,
    size,
    isHidden,
}, ref) => {

    const classNameSetter = () => {
        let RTN: string[] = [];

        if(class_name_list){
            for(const class_name of class_name_list){
                RTN.push(class_name)
            }
        }

        if(isHidden){
            RTN = isHiddenClassNameSetter({
                classNameList: RTN,
                isHidden,
                display: "flex"
            })
        }

        if(onClick){
            RTN.push("g_click_event_item");
        }

        RTN.push(CSS.l_Button_main);

        if(preset){
            RTN.push(CSS.preset);
            RTN.push(CSS[preset]);
        }

        if(color){
            RTN.push(CSS.color);
            RTN.push(CSS[color]);
        }

        if(size){
            RTN.push(CSS.size);
            RTN.push(CSS[size]);
        }

        if(disabled){
            RTN.push(CSS.disabled);
        }

        return RTN;
    }

    const [totalClassName, setTotalClassName] = useState<string>(classNameSetter().join(" "));

    const {
        textColor, 
        textPreset
    } = useButtonText({
        color,
        preset,
        disabled,
    });

    useEffect(() => {
        setTotalClassName(classNameSetter().join(" "));
    }, [class_name_list, isHidden, onClick, color, size, preset])

    return (
        <button
            className={totalClassName}
            onClick={onClick}
            type={type || "button"}
            disabled={disabled}
            ref={ref}
        >
            <Text
                preset={textPreset}
                color={textColor}
            >
                {children}
            </Text>

            {
                preset === "move_page"
                &&  <MovePageButtonIcon />
            }

            {
                preset === "detail_modal"
                &&  <DetailModalIcon />
            }
        </button>
    )
})

export default Button;