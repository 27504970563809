import Hr from "../../../../Component/Elements/Hr/Hr";
import PaymentDetailHeader from "../PaymentDetailHeader/PaymentDetailHeader";
import PaymentDetailItem from "../PaymentDetailItem/PaymentDetailItem";
import { PaymentDetailItem_Interface } from "../PaymentDetailItem/static/interface/PaymentDetailItem.interface";
import CSS from "./static/css/PaymentDetail.module.css"
import { useMediaQuery } from "react-responsive";

const PaymentDetail = (
    {
        paymentDetailList,
    }:{
        paymentDetailList: PaymentDetailItem_Interface[];
    }
) => {
    const isMobile = useMediaQuery({ maxWidth: 700 });
    
    return(
        <div className={CSS.l_PaymentDetail_main}>
            {
                !isMobile
                &&  <PaymentDetailHeader />
            }

            {
                !isMobile
                &&  <Hr class_name_list={[CSS.l_Hr]} />
            }

            {
                paymentDetailList.map((item, index) => {

                    return(
                        <PaymentDetailItem
                            key={index}
                            payDate={item.payDate}
                            payType={item.payType}
                            payMethod={item.payMethod} 
                            payAmount={item.payAmount}
                        />
                    )
                })
            }
        </div>
    )
}

export default PaymentDetail;