import { useMediaQuery } from "react-responsive";
import DescriptionIcon from "../../../../Component/Elements/Icon/DescriptionIcon/DescriptionIcon";
import CSS from "./static/css/ContractDocsImgViewIconBtn.module.css";
import { useMemo } from "react";
import Text from "../../../../Component/Elements/Text/Text";
import ReactPhotoSwipeIconVersion from "../../../../Component/Elements/ReactPhotoSwipeGallery/ReactPhotoSwipeIconVersion";

const ContractDocsImgViewIconBtn = (
    {
        label,
        className,
        contractImageList,
    }:{
        label?: string;
        className?: string
        contractImageList: string[];
    }
) => {
    const isMobile = useMediaQuery({ maxWidth: 700 });

    const mainClassName = useMemo(() => {
        const RTN: string[] = [];

        if(className){
            RTN.push(className);
        }

        RTN.push(CSS.l_ContractDocsImgViewIconBtn_main)

        return RTN.join(" ");
    }, [className])

    return(
        <div className={mainClassName}>
            {
                label
                &&  <Text 
                        size={"size3"}
                        color={"gray2"}
                    >
                        {label}
                    </Text>
            }

            {
                contractImageList.length > 0
                &&  <ReactPhotoSwipeIconVersion img_list={contractImageList}>
                        <DescriptionIcon 
                            size={isMobile ? "medium" : "small"}
                        />
                    </ReactPhotoSwipeIconVersion>
            }
        </div>
    )
}

export default ContractDocsImgViewIconBtn;