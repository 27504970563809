import { callAxios, customToast, dev_console } from "../../../../Common/ts/commonTools";

const TOAST_ID = "confirmHandler";

// 제품 컨펌 승락 버튼 핸들러
export function confirmBtnHandler(
    {        
        photo_upload_id,
        contract_product_id,
    }:{
        photo_upload_id: number | null;
        contract_product_id: number;
    }
){
    if(!photo_upload_id){
        customToast.error({
            msg: "재요청 데이터에서 문제가 발견되었습니다.\n스튜디오로 문의해 주세요.",
            toastId: TOAST_ID,
        });
        return ;
    }

    if(!window.confirm("상품 제작을 시작할까요?")){
        return ;
    }

    if(!window.confirm("제작이 시작되면 변경이나 취소가 불가능합니다.\n 상품 제작을 시작할까요?")){
        return ;
    }

    requestEditPhotoConf({
        confirm: "accept",
        photo_upload_id,
        contract_product_id,
    })
    .then((response) => {
        dev_console.log(response);

        if(response.status === 200){
            customToast.success({
                msg: "제품 제작이 승인되었습니다.",
                toastId: TOAST_ID,
            });
        }else{
            customToast.error({
                msg: "제품 제작 승인 요청에 실패했습니다.",
                toastId: TOAST_ID,
            });
        }
    })
    .catch((error) => {
        dev_console.error(error);
        customToast.error({
            msg: "제품 제작 승인 요청 과정에서 문제가 발생했습니다.",
            toastId: TOAST_ID,
        });
    })
}

// 제품 컨펌 거부 버튼 핸들러
export function refusalBtnHandler(
    {
        photo_upload_id,
        contract_product_id,
        request_num,
        request,
        completActionHandler,
    }:{
        photo_upload_id: number | null;
        contract_product_id: number;
        request_num: number
        request: string;
        completActionHandler: () => void;
    }    
){
    if(!photo_upload_id || isNaN(request_num)){
        customToast.error({
            msg: "재요청 데이터에서 문제가 발견되었습니다.\n스튜디오로 문의해 주세요.",
            toastId: TOAST_ID,
        });
        return ;
    }

    if(request_num > 2){
        customToast.error({
            msg: "재요청 가능 횟수를 초과하셨습니다.\n스튜디오로 문의해 주세요.",
            toastId: TOAST_ID,
        });
        return ;
    }

    if(request.length === 0){
        customToast.info({
            msg: "사진이 마음에 들지 않는 이유를 작성해 주세요.",
            toastId: TOAST_ID,
        });
        return ;
    }

    if(window.confirm("재편집이 요청됩니다.\n진행하시겠습니까?")){
        
        requestEditPhotoConf({
            confirm: "reject",
            photo_upload_id,
            contract_product_id,
            request
        })
        .then((response) => {
            dev_console.log(response);

            if(response.status === 200){
                customToast.success({
                    msg: "재편집 요청이 신청되었습니다.",
                    toastId: TOAST_ID,
                });

                completActionHandler();
            }else{
                customToast.error({
                    msg: "제품 제작 승인 요청에 실패했습니다.",
                    toastId: TOAST_ID,
                });
            }
        })
        .catch((error) => {
            dev_console.error(error);
            customToast.error({
                msg: "제품 제작 승인 요청 과정에서 문제가 발생했습니다.",
                toastId: TOAST_ID,
            });
        })
    }
}

// 실제로 컨펌 요청을 서버로 보내는 함수
async function requestEditPhotoConf(
    {
        confirm,
        request = "",
        photo_upload_id,
        contract_product_id,
    }:{
        confirm: "accept" | "reject";
        request?: string;
        photo_upload_id: number;
        contract_product_id: number;
    }
){
    const m_config = {
        photo_upload_id: `${photo_upload_id}`,
        confirm: confirm === "accept" ? 2 : 4,
        confirm_reason: request,
        contract_product_id: contract_product_id,
    }

    const m_edit_photo_conf_url = "api/v3/customers/edit-photo-conf";
    const RTN = await callAxios.api({
        url: m_edit_photo_conf_url,
        method: "post",
        dataType: "json",
        data: m_config,
    })

    return RTN;
}