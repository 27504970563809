import CSS from "./static/css/SubmitBtnContainer.module.css";
import Button from "../../../../Component/Elements/Button/Normal/Button";
import { MouseEventHandler } from "react";

const SubmitBtnContainer = (
    {
        cancelBtnHandler,
        selectBtnHandler,
    }:{
        cancelBtnHandler: MouseEventHandler<HTMLButtonElement>;
        selectBtnHandler: MouseEventHandler<HTMLButtonElement>;
    }
) => {
    return(
        <div className={`g_backdrop_filter ${CSS.l_SubmitBtnContainer_main}`}>
            <Button
                preset={"normal_gray"}
                size={"size1"}
                onClick={cancelBtnHandler}
            >
                취소
            </Button>
            <Button
                size={"size2"}
                onClick={selectBtnHandler}
            >
                사진 선택 완료
            </Button>
        </div>
    )
}

export default SubmitBtnContainer;