import { useEffect, useState } from "react";
import Text from "../../../../Component/Elements/Text/Text";
import CSS from "./static/css/DdayIcon.module.css";
import { dateObjectManagerClass } from "../../../../Common/ts/commonTools";

const dateObjectManager = new dateObjectManagerClass();

const DdayIcon = (
    {
        d_day,
    }:{
        d_day: number | null;
    }
) => {
    const [mainClassNameList, setMainClassNameList] = useState<string[]>(classNameSetter());
    const [dayText, setDayText] = useState<string>(ddayTextSetter());

    function classNameSetter(){
        const RTN: string[] = [CSS.l_DdayIcon_main];

        if(typeof d_day === "number" && d_day < 0){
            RTN.push(CSS.before);
        }else if(d_day === 0){
            RTN.push(CSS.dday);
        }else{
            RTN.push(CSS.after);
        }
        
        return RTN;
    }

    function ddayTextSetter(){
        let RTN: string = "";

        if(typeof d_day === "number"){
            RTN = dateObjectManager.calculatePeriod(d_day);
        }

        return RTN
    }

    useEffect(() => {
        setMainClassNameList(classNameSetter());
        setDayText(ddayTextSetter());
    }, [d_day])

    return(
        <Text class_name_list={mainClassNameList}>
            {dayText}
        </Text>
    )
}

export default DdayIcon;