import CSS from "./static/css/UploadImageContainerViewMode.module.css";
import { useState } from "react";
import ImageGalleryContainer from "../../../../Component/Elements/ImageGallery/ImageGalleryContainer";
import { viewerImageInterface } from "../../../../Component/Elements/ImageGallery/interface/ImageGallery.interface";
import ReactPhotoSwipeGallery from "../../../../Component/Elements/ReactPhotoSwipeGallery/ReactPhotoSwipeGallery";
import OvalIconButton from "../../../../Component/Elements/Button/OvalIconButton/OvalIconButton";
import FolderImageOverlay from "../FolderImageOverlay/FolderImageOverlay";
import ListCardHeaderText from "../../../../Component/Elements/ListCardHeaderText/ListCardHeaderText";
import DetailIcon from "../../../../Component/Elements/Icon/DetailIcon/DetailIcon";
import EmptyData from "../../../../Component/Elements/EmptyData/EmptyData";

const UploadImageContainerViewMode = (
    {
        folder_name,
        folder_middle_img_url,
        
        initialImgList,
        isComfrimMode,
        isSingle,
    }:{
        folder_name: string;
        folder_middle_img_url: string;

        initialImgList: viewerImageInterface[];
        isComfrimMode?: boolean;
        isSingle: boolean;
    }
) => {
    // 폴더 이미지 보기기능을 위한 state
    const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);
    const [folderDetailImgSrc, setFolderDetailImgSrc] = useState<string>("");

    // 폴더 이미지 보기 오버레이 출력 핸들러
    const overlayOpenHandler = (detailImgSrc: string) => {
        setFolderDetailImgSrc(detailImgSrc);
        setIsOverlayOpen(true);
    }

    // 폴더 이미지 보기 오버레이 닫기 핸들러
    const overlayCloseHandler = () => {
        setIsOverlayOpen(false);
        setFolderDetailImgSrc("");
    }

    return(
        <ImageGalleryContainer class_name_list={[CSS.l_UploadImageContainerViewMode_main]}>
            {
                !isSingle
                &&  <div className={CSS.l_UploadImageContainerViewMode_header}>
                        <div className={CSS.l_UploadImageContainerViewMode_header_left}>
                            <ListCardHeaderText
                                header_text={folder_name}
                                copy_function_target_text={isComfrimMode ? `[${folder_name}]` : undefined}
                                isSatisfiedPhotoNums={true}
                            />

                            {/* 모바일환경에서의 상세보기 버튼 */}
                            {
                                folder_middle_img_url
                                &&  <DetailIcon
                                        size={"large"}
                                        onClick={overlayOpenHandler.bind(null, folder_middle_img_url)}
                                        isHidden={"web"}
                                    />
                            }
                        </div>

                        {/* PC환경에서의 상세보기 버튼 */}
                        <div className={`g_display_none_object mobile ${CSS.l_UploadImageContainerViewMode_header_right}`}>
                            {
                                folder_middle_img_url
                                &&  <OvalIconButton
                                        preset={"detail_view"}
                                        onClick={overlayOpenHandler.bind(null, folder_middle_img_url)}
                                    />
                            }
                        </div>
                    </div>
            }

            {
                initialImgList.length > 0
                ?   <ReactPhotoSwipeGallery
                        img_list={initialImgList}
                        isComfrimMode={isComfrimMode}
                    />
                :   <EmptyData />
            }

            <FolderImageOverlay 
                isOverlayOpen={isOverlayOpen}
                overlayCloseHandler={overlayCloseHandler}
                folderDetailImgSrc={folderDetailImgSrc}
            />
        </ImageGalleryContainer>
    )
}

export default UploadImageContainerViewMode;