import Navi from "./Navi";

import home_icon_activate from "./static/img/icon1_off@2x.png";
import home_icon_deactivate from "./static/img/icon1_on@2x.png";

import camera_icon_activate from "./static/img/icon5_off@2x.png";
import camera_icon_deactivate from "./static/img/icon5_on@2x.png";

const AdminNavi = (
    {
        isOpenMenu,
        setIsOpenMenu,
    }:{
        isOpenMenu: boolean
        setIsOpenMenu: React.Dispatch<React.SetStateAction<boolean>>
    }
) => {
    return(
        <Navi 
            isOpenMenu={isOpenMenu}
            setIsOpenMenu={setIsOpenMenu}
            navi_item_list={[  
                {
                    name: "홈",
                    img_src: home_icon_deactivate,
                    activated_img_src: home_icon_activate,
                    navigate: "/",
                    is_badge_on: false,
                },                              
                {
                    name: "촬영 테마 둘러보기",
                    img_src: camera_icon_deactivate,
                    activated_img_src: camera_icon_activate,
                    navigate: "/themeBook",
                    is_badge_on: false,
                },
            ]}
        />
    )
}

export default AdminNavi;