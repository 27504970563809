import CSS from "./static/css/BottomSheetOpenButton.module.css";
import Button from "../Button/Normal/Button";
import { useEffect, useState } from "react";
import { isHiddenType } from "../../../Common/interface/Common.interface";
import { isHiddenClassNameSetter } from "../../../Common/ts/commonTools";

const BottomSheetOpenButton = (
    {
        title,
        onClick,
        isHidden,
    }:{
        title: string;
        onClick?: React.MouseEventHandler<HTMLButtonElement>;
        isHidden?: isHiddenType;
    }
) => {
    const classNameSetter = () => {
        let RTN: string[] = [];

        if(isHidden){
            RTN = isHiddenClassNameSetter({
                classNameList: RTN,
                isHidden,
                display: "flex"
            })
        }

        RTN.push(CSS.l_BottomSheetOpenButton_main)

        return RTN;
    }

    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "));
    }, [isHidden])

    return(
        <div className={mainClassName}>
            <Button
                preset={"detail_modal"}
                onClick={onClick}
            >
                {title}
            </Button>
        </div>

    )
}

export default BottomSheetOpenButton;