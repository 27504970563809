import { useEffect, useState } from "react";
import CSS from "./static/css/mainButton.module.css"
import Text from "../../../../Component/Elements/Text/Text";
import CommonIcon from "../../../../Component/Elements/Icon/CommonIcon/CommonIcon";

const MainButton = (
    {
        name,
        img_src,
        activated_img_src,
        onClickFc,
        is_activated,
        is_print_badge,
    }:{
        name: string;
        img_src: string;
        activated_img_src: string;
        onClickFc?: () => void;
        is_activated?: boolean;
        is_print_badge: boolean;
    }
) => {

    const [mainClassName, setMainClassName] = useState<string>(`g_click_event_item ${CSS.l_MainButton_main}`);
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const [imgNode, setImgNode] = useState<React.ReactNode | null>(null);

    useEffect(() => {
        const m_class_name_list: string[] = ["g_click_event_item", CSS.l_MainButton_main];

        let m_state:number = 0;

        if(img_src){
            if(activated_img_src){
                if(isHovered || is_activated){
                    m_state = 1;
                }else{
                    m_state = 2;
                }
            }else{
                m_state = 2;
            }
        }

        switch(m_state){
            case 1:
                setImgNode(
                    <CommonIcon 
                        src={activated_img_src} 
                        onClick={onClickFc}
                    />
                );
                m_class_name_list.push(CSS.activate);
                break;
            case 2:
                setImgNode(
                    <CommonIcon 
                        src={img_src}
                        onClick={onClickFc} 
                    />
                );
                break;
            default:
                setImgNode(null);
        }
        
        setMainClassName(m_class_name_list.join(" "));
    }, [img_src, activated_img_src, isHovered, is_activated])

    return (
        <div
            className={mainClassName}
            onClick={onClickFc}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {imgNode}

            <div className={CSS.l_lable_container}>
                <Text 
                    class_name_list={[CSS.l_label]}
                    bold={isHovered || is_activated}
                    preset={"navi"}
                >
                    {name}
                </Text>

                {
                    is_print_badge
                    &&  <Text 
                            class_name_list={[CSS.l_lable_badge]}
                            preset={"navi_badge"}
                        >
                            NEW
                        </Text>
                }
            </div>

        </div>
    )
}

export default MainButton;