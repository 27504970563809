import Overlay from "../../../../Component/Elements/Overlay/Overlay"
import useDeliveryAddressListREST from "../../hooks/REST/useDeliveryAddressListREST";
import DeliveryAddressList from "./components/DeliveryAddressList/DeliveryAddressList";

const DeliveryAddressListOverlay = (
    {
        isOpen,
        overlayCloseHandler,
        onClickHandler,
    }:{
        isOpen: boolean;
        overlayCloseHandler: () => void;
        onClickHandler?: (
            {
                recipient_name,
                recipient_phone,
                zonecode,
                mainAddress,
                buildingCode,
                detailAddress
            }:{
                recipient_name: string;
                recipient_phone: string;
                zonecode: string;
                mainAddress: string;
                buildingCode: string;
                detailAddress: string;
            }
        ) => void;
    }
) => {
    const {
        deliveryAddressListData,
    } = useDeliveryAddressListREST();

    return(
        <Overlay
            isOverlayOpen={isOpen}
            overlayCloseHandler={overlayCloseHandler}
        >
            <DeliveryAddressList
                deliveryAddressList={deliveryAddressListData}
                onClickHandler={onClickHandler}
            />
        </Overlay>
    )
}

export default DeliveryAddressListOverlay;