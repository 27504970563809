import { useNavigate } from "react-router-dom";
import { dateObjectManagerClass } from "../../../../Common/ts/commonTools";
import EmptyData from "../../../../Component/Elements/EmptyData/EmptyData";
import useAlarmListREST from "../../hooks/useAlarmListREST.hook";
import { ALARM_READ_YN_OBJ } from "../../static/ts/const";
import AlertItem from "./components/AlertItem/AlertItem";
import CSS from "./static/css/AlertList.module.css";

const dateObjectManager = new dateObjectManagerClass();

const AlertList = () => {
    const {
        alarmListData,
        alarmListIsLoading,
        alarmListError,
        alarmListRefetch
    } = useAlarmListREST();

    const navigate = useNavigate();

    async function clickHander(
        {
            url,
        }:{
            url: string,
        }
    ){
        navigate(url);
    }

    return (
        <div className={CSS.l_AlertList_main}>
            {
                alarmListData.length > 0
                ?   alarmListData.map((item, index) => {
                        const m_push_data = dateObjectManager.changeServerDateToFormatDateString(item.alarm_insert_dt, "yyyy년 MM월 dd일 HH시 mm분");

                        let m_push_date_format_string = "";
                        if(m_push_data.date){
                            m_push_date_format_string = dateObjectManager.dateCalculatePeriod(m_push_data.date);
                        }

                        return (
                            <AlertItem
                                key={index}
                                title={item.alarm_title}
                                content={item.alarm_content}
                                push_date={m_push_date_format_string} 
                                is_new={item.alarm_read_yn === ALARM_READ_YN_OBJ.unreaded}
                                onClick={() => {
                                    clickHander({
                                        url: item.alarm_redirect_url,
                                    })
                                }}
                            />
                        )
                    })
                :   <EmptyData />
            }
        </div>
    )
}

export default AlertList;