import { useEffect, useState } from "react";
import { contractScheduleReservationConfig_selectedTheme_Interface, contractScheduleReservationConfigInterface, schedulePhotoProductThemeInterface } from "../../../static/interface/selectTheme.interface";
import { buttonStateCodeType } from "../interface/ThemeSelectButtons.interface";
import { BUTTON_STATE_TYPE_CODE } from "../static/ts/const";
import { customToast } from "../../../../../Common/ts/commonTools";
import { useNavigate } from "react-router-dom";
import { UseMutateFunction } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

const TOAST_ID = "theme_select_toast_id";

const useThemeSelectButtons = (
    {
        contract_id,
        schedule_id,
        photoProductList,
        selectedThemeSubmitMutate,
        isReadOnly,
    }:{
        contract_id: string,
        schedule_id: number,
        photoProductList: schedulePhotoProductThemeInterface[],
        selectedThemeSubmitMutate: UseMutateFunction<AxiosResponse<any, any>, Error, {
            config: contractScheduleReservationConfigInterface;
        }, unknown>;
        isReadOnly?: boolean;
    }
) => {
    const buttonStateSetter = () => {
        let RTN: buttonStateCodeType = BUTTON_STATE_TYPE_CODE.cant_select;

        if(isReadOnly){
            RTN = BUTTON_STATE_TYPE_CODE.select_done;
        }else if(photoProductList.length === 0){
            RTN = BUTTON_STATE_TYPE_CODE.cant_select;
        }else{
            RTN = BUTTON_STATE_TYPE_CODE.need_select;
        }

        return RTN;
    }

    const [stateCode, setStateCode] = useState<buttonStateCodeType>(buttonStateSetter());
    
    const navigate = useNavigate();

    const onSubmit = () => {
        // 선택이 전혀 안된 촬영 목록
        const non_selected_photo_index_list: number[] = [];

        const alert_msg: string[] = ["선택하신 테마를 확인해 주세요.", `\n`];

        for(const theme of photoProductList){
            let theme_alert_string: string = `${theme.photo_name}: `;
            const theme_alert_msg: string[] = [];

            let theme_max:number = parseInt(theme.theme_num);
            if(isNaN(theme_max)){
                customToast.error({
                    msg: `${theme.photo_name} 촬영에 대해 선택해야 할 테마 수가 설정되지 않았습니다. 계약하신 지점으로 문의해 주세요.`,
                    toastId: TOAST_ID,
                })
                return ;
            }

            if(theme.selected_theme_list.length < theme_max){
                non_selected_photo_index_list.push(theme.schedule_photo_product_id);
            }

            for(const selected_theme of theme.selected_theme_list){
                theme_alert_msg.push(selected_theme.thema_name);
            }
            
            theme_alert_string += theme_alert_msg.join(", ");
            alert_msg.push(theme_alert_string);
        }

        if(non_selected_photo_index_list.length > 0){
            customToast.error({
                msg: "테마를 선택해야 하는 수만큼 모두 선택해 주세요.",
                toastId: TOAST_ID,
            })

            return ;
        }

        // 완성된 안내 문장을 출력한다.
        alert(alert_msg.join(`\n`));

        const confirm_msg: string[] = ["촬영 테마 선택을 완료하시겠습니까?", `\n`, "선택이 완료된 후에 테마 변경을 원하실 경우", "촬영 당일 현장에서 직접 요청하셔야 합니다."];
        if(!window.confirm(confirm_msg.join(`\n`))){
            return ;
        }

        const m_selected_theme_list: contractScheduleReservationConfig_selectedTheme_Interface[] = [];

        for(const theme of photoProductList){
            for(const selected_theme of theme.selected_theme_list){
                m_selected_theme_list.push({
                    theme_id: selected_theme.thema_id,
                    photo_id: parseInt(theme.photo_id),
                    schedule_photo_product_id: theme.schedule_photo_product_id,
                    schedule_id: schedule_id,
                    contract_id: contract_id,
                })
            }
        }

        selectedThemeSubmitMutate({config: { thema_list: m_selected_theme_list }}, {
            onSuccess: (data, variables, context) => {
                if(data.status === 200){
                    customToast.success({
                        msg: "선택하신 테마가 저장되었습니다.",
                        toastId: TOAST_ID,
                    });
                    navigate("/reservationHome");
                }else{
                    customToast.error({
                        msg: "선택하신 테마를 저장하는 과정에서 문제가 발생했습니다.",
                        toastId: TOAST_ID,
                    }); 
                }
            },
            onError: (error, variables, context) => {
                customToast.error({
                    msg: "선택하신 테마를 저장하는 과정에서 문제가 발생했습니다.",
                    toastId: TOAST_ID,
                });
            },
        });
    }

    useEffect(() => {
        setStateCode(buttonStateSetter());
    }, [photoProductList, isReadOnly])

    return({
        stateCode,
        onSubmit
    })
}

export default useThemeSelectButtons;