import axios, { AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import JSZip from 'jszip';
import React from 'react';
import { getCookie, logout } from './appTools';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { browserCodeType, chunkImageUploadDataInterface, chunkImageUploadResultInterface, defaultApiReaponseInterface, dimensions_Interface, fileCodeType, imageFilesUploadResultInterface, isHiddenType, queryParameterInterface, regionInterface } from '../interface/Common.interface';
import { loginUserTypeType, RTN_interface } from '../interface/App.interface';
import { BROWSER_CODE, BROWSER_CODE_TO_NAME, CHUNK_SIZE, FILE_CODE, REGION_LIST, TOAST_AUTO_CLOSE } from './const';
import * as exifr from 'exifr';
import { sign } from 'crypto';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCYzIwKqLjfujkfmdW8GlhrJ6t8QAiugAnk',
  authDomain: 'oops-e8c72.firebaseapp.com',
  projectId: 'oops-e8c72',
  storageBucket: 'oops-e8c72.appspot.com',
  messagingSenderId: '345734616574',
  appId: '1:345734616574:web:abc36aaba1de42fa44dcaf',
  measurementId: 'G-4LWWQ32735',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

class callAxiosClass {
    async api({
        method,
        url,
        data,
        dataType,
        timeOut = 1000 * 60 * 10,
        responseType,
        self_token,
        cache_control,
        signal,
    }: {
        method: "get" | "post" | "delete" | "put";
        url: string;
        data?: any;
        dataType?: "formdata" | "json";
        timeOut?: number;
        responseType?: ResponseType;
        self_token?: string;
        cache_control?: 'no-cache';
        signal?: AbortSignal;
    }) 
    {    
        let token = await getAccountData.token();
        if(self_token){
            token = self_token;
        }

        const api_url = process.env.REACT_APP_API_URL;

        const config: AxiosRequestConfig<any> = {
            method,
            url: `${api_url}/${url}`,
            maxBodyLength: Infinity,
            maxContentLength: Infinity
        };

        if (timeOut) {
            config.timeout = timeOut;
        }

        if (responseType) {
            config.responseType = responseType;
        }

        if (token) {
            config.headers = {
                Authorization: `Token ${token}`,
                ...(cache_control && { 'Cache-Control': cache_control }),
            };
        }

        if (method === 'get') {
        // get일때는 별다른 추가 동작이필요 없다.
        } else if (method === 'post' || method === 'delete' || method === 'put') {
            if (data) {
                if (dataType === 'formdata') {
                    config.data = data;
                } else if (dataType === 'json') {
                    config.data = data;
                }
            }
        }

        if(signal){
            config.signal = signal;
        }

        return axios(config);
    }

    async image(url: string) {
        console.log('CALL AXIOS IMAGE');
        console.log(url);

        return await axios({
            method: 'get',
            url,
            maxBodyLength: Infinity,
            timeout: 5000,
            responseType: 'blob',
        });
    }
}

const callAxios = new callAxiosClass();

const downloadFile = async (presignedUrl: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = presignedUrl;
    if (fileName) {
        link.download = fileName;
    }
    document.body.appendChild(link);
    link.click();
};

const isSuccess = (
  response: AxiosResponse<any, any> | { data: null; status: number; statusText: string; msg: string },
) => {
    if(response.status === 200){

        const response_data: defaultApiReaponseInterface<any> = response.data;

        if(response_data.status.code === 200 && response_data.status.msg === "OK"){
            return true;
        }else{
            return false;
        }
        
    }else{
        printStateMsg(response);
        return false;
    }
};

const printStateMsg = (response: AxiosResponse<any, any> | { data: null; status: number; statusText: string; msg: string }) => {
    dev_console.error(`status code : ${response.status}`);
    
    const response_data: defaultApiReaponseInterface<any> = response.data;
    dev_console.error(`msg: ${response_data.status.msg}`)
}

class divConsoleClass {
    log: (...args: any[]) => void;
    error: (...args: any[]) => void;
    trace: (...args: any[]) => void;

    constructor() {
        const result = whatIsMode();
        if (result.is_not_product_mode_safe) {
            this.log = console.log.bind(console);
            this.error = console.error.bind(console);
            this.trace = console.trace.bind(console);
        } else {
            this.log = () => {};
            this.error = () => {};
            this.trace = () => {};
        }
    }
}
export const dev_console = new divConsoleClass();

// 문자열이 숫자로만 이루어져 있는지 확인하는 함수
const isNumericString = (str: string) => {
    if (str) {
        const regex = /^[0-9]+$/;
        return regex.test(str);
    }
    return true;
}

// 전제 데이터 수와 한 페이지 당 표시할 데이터수를 입력받아 최소 필요 페이지 수를 구함
const getMaxPageNum = ({ count, item_per_page = 15 }: { count: number; item_per_page?: number }) => {
    if (item_per_page <= 0 || count <= 0) {
        return 1;
    }

    return Math.ceil(count / item_per_page);
};

// "2023-10-19T16:49:37.672341"형식의 문자열을 "2023-10-19"와 "16:49:37"로 분리시켜주는 함수
const transformISOString = (dateTimeString: string, format?: string): [string, string] | null => {
    // 주어진 문자열을 Date 객체로 파싱합니다.
    const dateTime = new Date(dateTimeString);

    // 파싱이 실패하면 null을 반환합니다.
    if (isNaN(dateTime.getTime())) {
        return null;
    }

    if (format === 'ko') {
        // 날짜를 "년 월 일" 형식으로 포맷합니다.
        const datePart = `${dateTime.getFullYear()}년 ${dateTime.getMonth() < 9 ? `0${dateTime.getMonth() + 1}` : dateTime.getMonth() + 1}월 ${dateTime.getDate() < 10 ? `0${dateTime.getDate()}` : dateTime.getDate()}일`;

        // 시간을 "시:분" 형식으로 포맷합니다.
        const hours = dateTime.getHours().toString().padStart(2, '0');
        const minutes = dateTime.getMinutes().toString().padStart(2, '0');
        const timePart = `${hours}:${minutes}`;

        return [datePart, timePart];
    } else {
        // 날짜와 시간을 추출합니다.
        const datePart = dateTime.toISOString().split('T')[0];
        const timePart = dateTime.toTimeString().split(' ')[0];

        return [datePart, timePart];
    }
}

// 11글자의 문자열이 전화번호형식인인지 검증
const isValidPhoneNumber = (phoneNumber: string): boolean => {
    // 1. 문자열의 길이가 11자리인지 확인
    if (phoneNumber.length !== 11) {
        return false;
    }

    // 2. 모든 문자열은 숫자로만 이루어져야 함
    if (!/^\d{11}$/.test(phoneNumber)) {
        return false;
    }

    const prefix = phoneNumber.slice(0, 3);
    const validPrefixes = ['010', '011', '016', '017', '019'];

    if (!validPrefixes.includes(prefix)) {
        return false;
    }

    return true;
}

// 전화번호에 하이픈을 추가하는 함수
const addHyphens = (input: string) => {
    if(input){
        const cleanedInput = input.replace(/\D/g, ''); // 숫자 이외의 문자 제거

        const m_length = cleanedInput.length;

        if (m_length <= 3) {
            return cleanedInput;
        }else if(m_length <= 7) {
            // 이 단계가 제거되면 사용자가 작성된 번호를 지우는 과정에서 문제가 생긴다.
            return `${cleanedInput.slice(0, 3)}-${cleanedInput.slice(3)}`;
        }else if(m_length <= 8){
            // 이 단계가 제거되면 사용자가 작성된 번호를 지우는 과정에서 문제가 생긴다.
            return `${cleanedInput.slice(0, 4)}-${cleanedInput.slice(4)}`;
        }else if(m_length <= 9){
            // 지역번호 자리수가 2개고 가운데 번호가 3자리
            return `${cleanedInput.slice(0, 2)}-${cleanedInput.slice(2, 5)}-${cleanedInput.slice(5, 9)}`;
        }else if(m_length <= 10){
            if(cleanedInput.slice(0, 2) === "02"){
                // 지역번호 자리수가 2개고 가운데 번호가 4자리
                return `${cleanedInput.slice(0, 2)}-${cleanedInput.slice(2, 6)}-${cleanedInput.slice(6, 10)}`;
            }else{
                // 지역번호 자리수가 3개고 가운데 번호가 3자리
                return `${cleanedInput.slice(0, 3)}-${cleanedInput.slice(3, 6)}-${cleanedInput.slice(6, 10)}`;
            }
        }else{
            // 지역번호 자리수가 3개고 가운데 번호가 4자리
            // 11자리의 스마트폰 번호
            return `${cleanedInput.slice(0, 3)}-${cleanedInput.slice(3, 7)}-${cleanedInput.slice(7, 11)}`;
        }
    }else{
        return "";
    }
};

// 비밀번호 조건건 충족 확인
const validatePassword = (password: string): string[] => {
    let errors: string[] = [];

    // 비밀번호 길이 확인
    if (password.length < 8 || password.length > 12) {
        errors.push('글자 수 8 ~ 12');
    }

    if(!/[A-Za-z]/.test(password)){
        errors.push('하나 이상의 영어 문자');
    }

    // 숫자 확인
    if (!/[0-9]/.test(password)) {
        errors.push('하나 이상의 숫자');
    }

    // 특수문자 확인
    if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) {
        errors.push('하나 이상의 특수 문자');
    }

    return errors;
}

// 이미지를 미리 순차적으로 캐시요청(정확히는 순차적으로 get요청을 보냄)
const preloadImages = async (urls: string[], limit = 3) => {
    const requests = urls.map((url) => () => axios.get(url));

    // 동시 요청 수를 제한하면서 요청을 순차적으로 실행
    for (let i = 0; i < requests.length; i += limit) {
        const batch = requests.slice(i, i + limit);
        await Promise.all(batch.map((req) => req()));
    }
};

// 클라이언트에서 이미지를 압축하여 zip으로 생성
const downloadOriginalImages = async ({
    image_list,
    isSetEncoded = true,
}: {
    image_list: string[];
    isSetEncoded?: boolean;
}) => {
    const zip = new JSZip();
    let downloadSuccessCount = 0; // 성공 카운트를 저장할 변수 초기화

    // 이미지 리스트 순회
    for (const [index, imgUrl] of image_list.entries()) {
        let encodedUrl = isSetEncoded ? encodeURI(imgUrl) : imgUrl;

        try {
            const response = await fetch(encodedUrl);
            if(response.ok){
                console.log("이미지 다운로드 성공");
                const imageBlob = await response.blob();

                let imageName = isSetEncoded ? 
                    decodeURIComponent(encodedUrl.split('/').pop() || `image-${index}.JPG`) :
                    encodedUrl.split('/').pop() || `image-${index}.JPG`;

                zip.file(imageName, imageBlob, { binary: true });
                downloadSuccessCount++; // 성공 카운트 증가
            } else {
                console.log("이미지 다운로드 실패: 응답 상태가 ok가 아님");
                // 여기서는 실패한 경우 특별한 처리를 하지 않음
            }
        } catch (error) {
            console.error("이미지 다운로드 중 오류 발생:", error); 
            // 오류 발생시 처리도 여기서
        }
    }

    // ZIP 파일 생성
    const zipBlob = await zip.generateAsync({ type: 'blob' }); 


    // ZIP 파일과 성공 카운트 반환
    return { zipFile: zipBlob, successCount: downloadSuccessCount };
};

// 보안성 향상을 위해 문자열에서 쿼리문과 직접적인 관련이 있는 특수문자를 제거해주는 함수
const sanitizeInput = (input: string): string => {
    const regex = /[';\\-]/g;
    return input.replace(regex, '');
}

// 가격에 콤마(,)를 추가하는 함수
const addCommasToPrice = (price: string | number): string => {
    try{
        const priceStr = price.toString();
        const formattedPrice = priceStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return formattedPrice;
    }catch(error){
        dev_console.error(error);
        return "";
    }

}

// 세션에 저장된 토큰값과 user_type을 확인하여 결과에 따라 logout함수를 실행시킴(로드아웃 시킴);
const isAbleAccesser = (
    {
        allowedUserTypeList,
    }:{
        allowedUserTypeList?: loginUserTypeType[];
    }
) => {

    const all_user_type_list:loginUserTypeType[] = ["C", "E"];

    const token = getAccountData.token();
    const user_type = getAccountData.user_type();

    if(allowedUserTypeList){
        const result = allowedUserTypeList.find((item) => item === user_type);

        if(!token || !result){
            logout();
        }
    }else{
        const result = all_user_type_list.find((item) => item === user_type);

        if(!token || !result){
            logout();
        }
    }
}

// SQL 인젝션과 XSS 공격을 방지하기 위해 입력된 문자열을 검증하는 함수
const validateInput = (input: string, isKo: boolean = false): boolean => {
    // SQL 인젝션과 XSS 공격을 시도할 수 있는 위험한 문자열 패턴 정의
    const dangerousPatterns = [
        /['";]/, // SQL 인젝션에 사용될 수 있는 문자
        /<script.*?>/, /<\/script>/, // XSS 공격에 사용될 수 있는 스크립트 태그
        /<img.*?>/, // 이미지 태그를 이용한 XSS 공격
        /javascript:/, // "javascript:" 프로토콜을 이용한 공격
        /<iframe.*?>/, /<\/iframe>/, // iframe을 이용한 공격
        /onerror=/, /onload=/, // 이벤트 핸들러를 이용한 XSS 공격
        /\s/ // 공백 문자 (스페이스, 탭, 줄바꿈, 캐리지 리턴 포함)
    ];

    if(!isKo){
        // 한글 문자 범위를 정의하는 정규 표현식 추가
        const koreanPattern = /[가-힣]/;
        if (koreanPattern.test(input)) {
            dev_console.log(`catch ${koreanPattern}`);
            return false; // 위험한 패턴 중 하나라도 발견되면 false 반환
        }
    }

    // 위험한 패턴들을 순회하며 입력값 검사
    for (const pattern of dangerousPatterns) {
        if (pattern.test(input)) {
            dev_console.log(`catch ${pattern}`);
            return false; // 위험한 패턴 중 하나라도 발견되면 false 반환
        }
    }

    return true; // 모든 검사를 통과하면 true 반환
}

// 선택된 div 에서 오버플로우가 발생했는지 확인하는 함수
const checkOverflow = (div_ref: React.RefObject<HTMLDivElement>) => {
    const element = div_ref.current;

    let hasVerticalOverflow = false;
    let hasHorizontalOverflow = false;

    if(element){
        hasVerticalOverflow = element.scrollHeight > element.clientHeight;
        hasHorizontalOverflow = element.scrollWidth > element.clientWidth;
    }

    return ({
        y: hasVerticalOverflow,
        x: hasHorizontalOverflow,
    })
};

// 입력받은 문자열의 불필요하거나 과도한 공백문자를 제거하는 함수
const refineString = (str: string): string => {
    // 문자열의 모든 줄바꿈과 탭을 공백으로 변환
    let refinedStr: string = str.replace(/[\n\r\t]+/g, ' ');

    // 문자열 내의 2개 이상의 공백을 단일 공백으로 줄임
    refinedStr = refinedStr.replace(/\s\s+/g, ' ');

    // 문자열의 앞뒤 공백 제거
    return refinedStr.trim();
}

// 어떤 div안에 들어갈 마지막 항목이 가질수 있는 최대 높이를 계산하는 함수
const calculateLastDivHeight = (
    {
        wrapperDiv,
        innerDivs,
        spacing,
    }:{
        wrapperDiv: HTMLElement;    //  전체를 감싸는 몸체
        innerDivs: HTMLElement[];   //  마지막 자식항목을 제외한 항목들
        spacing: string;    //  간격
    }
): string => {
    // 간격 단위 추출 (px 또는 rem)
    const spacingUnit = spacing.includes('px') ? 'px' : 'rem';
    
    // 숫자로 된 간격 값 추출
    const spacingValue = parseFloat(spacing);

    // 간격 총합 계산
    const totalSpacing = (innerDivs.length - 1) * spacingValue;

    // 내부 div들의 높이 총합 계산
    const totalInnerDivsHeight = innerDivs.reduce((acc, div) => acc + div.offsetHeight, 0);

    // 전체 wrapperDiv의 높이 추출
    const wrapperDivHeight = wrapperDiv.offsetHeight;

    // rem 단위인 경우, document의 font-size를 기준으로 px 단위로 변환
    let convertedSpacing = totalSpacing;
    if (spacingUnit === 'rem') {
    const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
    convertedSpacing = totalSpacing * rootFontSize;
    }

    // 마지막 div의 최대 높이 계산
    const lastDivMaxHeight = wrapperDivHeight - totalInnerDivsHeight - convertedSpacing;

    // 계산된 높이를 원래 단위로 변환하여 리턴
    return spacingUnit === 'px' ? `${lastDivMaxHeight}px` : `${lastDivMaxHeight / parseFloat(getComputedStyle(document.documentElement).fontSize)}rem`;
};

// 시간형식의 문자열 두개를 입력받아서 몇 분의 차이가 나는지 리턴
const getTimeDifferenceInMinutes = (time1: string, time2: string, flag?: boolean): number => {
    // 시간을 분으로 변환하는 헬퍼 함수
    const convertToMinutes = (time: string): number | null => {
        const parts = time.split(':');
        if (parts.length !== 2) return null;
    
        const [hoursStr, minutesStr] = parts;
        const hours = parseInt(hoursStr, 10);
        const minutes = parseInt(minutesStr, 10);
    
        // 시간과 분이 숫자로 변환 가능한지 체크
        if (isNaN(hours) || isNaN(minutes)) return null;
        // 시간과 분이 유효한 범위인지 체크
        if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) return null;
    
        return hours * 60 + minutes;
    };
  
    const minutes1 = convertToMinutes(time1);
    const minutes2 = convertToMinutes(time2);
  
    // 둘 중 하나라도 유효하지 않으면 -1 반환
    if (minutes1 === null || minutes2 === null) return -1;
  
    let result = Math.abs(minutes2 - minutes1);
    if(flag){
        result += 1;
    }

    return result;
}

// 문자열이 이메일 형식의 조건을 충족하는지 검사하는 함수
const validateEmail = (email: string): boolean => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
};

// 성별을 코드화 하는 함수
const getGenderCode = (gender: string) => {
    if(gender.includes("남")){
        return 1;
    }else if(gender.includes("여")){
        return 2;
    }else{
        return 0;
    }
}

// 함수 인자 사이사이에 구분자? 원소를 삽입해주는 함수
// ex) [1,2,3,4,5], 0 => [1,0,2,0,3,0,4,0,5]
const insertBetweenList = <T>(arr: T[], value: T): T[] => {
    return arr.reduce((acc, curr, index) => {
        acc.push(curr);
        if(index < arr.length - 1){
            acc.push(value);
        }
        return acc;
    }, [] as T[]);
}

// 어떤 수가 두 수 사이에 포함되는지 검증하는 함수
const isBetweenNum = ({min, max, value, inclusive}:{min: number; max: number; value: number; inclusive: boolean}):boolean => {
    //  inclusive === true : min과 max값도 범위에 포함된다.(이하, 이상)
    //  inclusive === true : min과 max값도 범위에 포함되지 않는다.(미만, 초과)
    
    // 작은수보다 큰 수가 작은경우는 무조건 false
    if(min > max){
        return false;
    }
    
    if(inclusive){
        return value >= min && value <= max;
    }else{
        return value > min && value < max;
    }
}

// 파일의 S3주소에서 파일의 타입을 추출하는 함수.
const getFileType = (filePath: string): fileCodeType => {

    const cleanFilePath = filePath.split('?')[0];

    // 파일 확장자 목록
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'webp', 'svg'];
    const videoExtensions = ['mp4', 'avi', 'mkv', 'mov', 'flv', 'wmv', 'webm', 'm4v'];
    const archiveExtensions = ['zip', 'rar', '7z', 'tar', 'gz'];

    // 파일 확장자 추출
    const extension = cleanFilePath.split('.').pop()?.toLowerCase();

    // 파일 유형 판별
    if (extension) {
        if (imageExtensions.includes(extension)) {
            return FILE_CODE.img; // 이미지 파일
        } else if (videoExtensions.includes(extension)) {
            return FILE_CODE.video; // 동영상 파일
        } else if (archiveExtensions.includes(extension)) {
            return FILE_CODE.zip; // 압축 파일
        } else {
            return FILE_CODE.none; // 기타 파일
        }
    } else {
        return FILE_CODE.none; // 확장자가 없는 경우
    }
}

// 파일의 S3주소에서 파일의 타입을 추출하는 함수.
const getFileName = (filePath: string): string => {
    // 파일 확장자 추출
    return filePath.split('/').pop() || "";
}

// 파일의 해시값을 계산
const getFileHash = async (file: File): Promise<string> => {
    const arrayBuffer = await file.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

// 파일 배열값들 중 중복된 파일을 감지 및 중복이 제거된 파일 과 중복이 감지된 파일 리턴
const detectDuplicates = async (files: File[]): Promise<{ duplicateFiles: File[]; duplicateFileNames:string[]; uniqueFiles: File[] }> => {
    const duplicateFiles: File[] = [];
    const duplicateFileNames: string[] = [];
    const uniqueFiles: File[] = [];
    const seenHashes = new Set<string>();
    const seenNames = new Set<string>();

    for (const file of files) {
        const hash = await getFileHash(file);
        const name = file.name;

        if (seenNames.has(name)){
            duplicateFileNames.push(name)
            customToast.error({msg:`${name} 파일 명이 중복됩니다.`});
            dev_console.error(name);
        }else if (seenHashes.has(hash)) {
            // 중복된 파일 발견
            duplicateFiles.push(file);
            customToast.error({msg: `${file.name} 파일이 중복됩니다.`});
            dev_console.error(file.name);
        } else {
            uniqueFiles.push(file);
            seenHashes.add(hash);
            seenNames.add(name);
        }
    }

    dev_console.error(seenHashes)    

    return {
        duplicateFiles,
        duplicateFileNames,
        uniqueFiles
    };
}

// 쿼리파라미터 사용을 위한 문자열을 생성해주는 함수
const setQueryParameter = (parameter: queryParameterInterface[]) => {
    return parameter.map((item) => `${item.key}=${item.value}`).join("&");
}

// 배열 내용 비교 함수
const arraysAreEqual = <T>(arr1: T[], arr2: T[]): boolean => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
};

// 테스트모드상태(테스트 데이터 사용) 확인
export const isUseTestData = () => {
    return process.env.REACT_APP_IS_TEST_MODE === "TRUE";
}

// 개발모드상태 확인
export function whatIsMode(){
    const mode = process.env.REACT_APP_MODE;

    const is_normal_mode: boolean = (mode === "LOCAL" || mode === "DEV" || mode === "STAGE" || mode === "PRODUCT")

    return {
        // 현재 상태가 해당 상태인가
        is_product_mode: mode === "PRODUCT",
        is_stage_mode: mode === "STAGE",
        is_dev_mode: mode === "DEV",
        is_loacl_mode: mode === "LOCAL",

        // 현재 상태가 해당 상태가 아닌가
        is_not_product_mode: mode !== "PRODUCT",
        is_not_stage_mode: mode !== "STAGE",
        is_not_dev_mode: mode !== "DEV",
        is_not_loacl_mode: mode !== "LOCAL",
        
        // 정상적인 상태인가
        is_normal_mode: is_normal_mode,
        
        // 현재 상태가 해당 상태는 아니지만, 정상정인 상태값을 가지는가
        is_not_loacl_mode_safe: mode !== "LOCAL" && is_normal_mode,
        is_not_product_mode_safe: mode !== "PRODUCT" && is_normal_mode,
        is_not_stage_mode_safe: mode !== "STAGE" && is_normal_mode,
        is_not_dev_mode_safe: mode !== "DEV" && is_normal_mode,
        
        // 현재 모드 이름
        mode_name: process.env.REACT_APP_MODE,
        
        // 현재 연결된 엔드포인트 주소
        get_api_url: process.env.REACT_APP_API_URL,
    };
}

const chunkUpload = async (
    {
        index,
        CHUNK_SIZE,
        file_size,
        file,
        file_name,
        total_chunks,
        file_idx,
        file_extension,
        file_dir,
    }:{
        index: number;
        CHUNK_SIZE: number;
        file_size: number;
        file: File;
        file_name: string;
        total_chunks: number;
        file_idx: number;
        file_extension: string;
        file_dir: string;
    }
) => {
    const start = index * CHUNK_SIZE;
    const end  = Math.min(start + CHUNK_SIZE, file_size);
    const chunk = file.slice(start, end);

    const form_data = new FormData();
    form_data.append("file_name", file_name);
    form_data.append("total_chunks", `${total_chunks}`);
    form_data.append("files", chunk);
    form_data.append("file_dir", file_dir);
    form_data.append("current_chunk", `${index}`);
    form_data.append("file_idx", `${file_idx}`);
    form_data.append("file_ext", file_extension);

    const common_img_upload_url = "api/v1/pgp/common-img-upload";
    return await callAxios.api({
        url: common_img_upload_url,
        method: "post",
        dataType: "formdata",
        data: form_data,
        // cache_control: "no-cache", // 해당 헤더를 설정하기 위해선 장고서버의 허용이 필요하다.
    })
}

export class getAccountDataClass{
    token(){
        return getCookie("token") || "";
    }
    
    oops_id(): string{
        return getCookie("oops_id") || "";
    }

    name(): string{
        return getCookie("name") || "";
    }

    user_type(){
        let RTN: string = "";

        const m_userType = getCookie("user_type") || "";

        RTN = m_userType;

        return RTN;
    }

    user_id(): number{
        return parseInt(getCookie("user_id") || "0");
    }

    office_id(): number{
        return parseInt(getCookie("office_id") || "0");
    }

    office_name(): string{
        return getCookie("office_name") || "";
    }

    office_addr(): string{
        return getCookie("office_addr") || "";
    }

    office_addr_detail(): string{
        return getCookie("office_addr_detail") || "";
    }

    brand_id(): number{
        return parseInt(getCookie("brand_id") || "0");
    }

    brand_name(): string{
        return getCookie("brand_name") || "";
    }
}

export const getAccountData = new getAccountDataClass();

const imageChunkUpload = async (
    {
        file,
        file_idx,
        file_dir,
    }:{
        file: File;
        file_idx: number;
        file_dir: string;
    }
) => {    
    const file_name = file.name;
    const file_size = file.size;
    const file_extension = file_name.substring(file_name.lastIndexOf(".") + 1);
    const total_chunks = Math.ceil(file.size / CHUNK_SIZE);

    const result: chunkImageUploadResultInterface = {
        state: 0,
        message: "",
        next_fleg: false,
        is_error: false,
        data: {
            file_name,
            file_size,
            file_extension,
        }
    }

    // dev_console.log(`${total_chunks} = ${file.size} / ${CHUNK_SIZE}`)

    let index: number = 0;
    while(index < total_chunks){
        try{
            const response = await chunkUpload({
                index,
                CHUNK_SIZE,
                file_size,
                file,
                file_name,
                total_chunks,
                file_idx,
                file_extension,
                file_dir
            });

            if(response.status === 200){
                const code = response.data.status.code;

                if (code === 200) {
                    result.state = code;
                    result.message = "success";
                    result.next_fleg = true;
                    result.is_error = false;
                    break;
                } else if (code === 201) {
                    // 다음 청크 업로드 준비됨
                    index += 1;
                } else {
                    // 에러 처리
                    result.state = -1;
                    result.message = "state_error";
                    dev_console.error(`응답된 상태값이 ${code}입니다.`);
                    result.next_fleg = false;
                    result.is_error = true;
                    break;
                }
            }else{
                result.state = -1;
                result.message = "state_error";
                dev_console.error(`서버 state에러: ${response.status}입니다.`);
                result.next_fleg = false;
                result.is_error = true;
            }
        }catch(error){
            result.state = -1;
            result.message = "requset_error";
            dev_console.error("요청중 문제가 발생했습니다.");
            dev_console.error(error);
            result.next_fleg = false;
            result.is_error = true;
            break;
        }
    }

    return result;
}

const imageFilesUpload = async (
    {
        files,
        file_dir
    }:{
        files: File[],
        file_dir: string;
    }
) => {
    
    const total_result: imageFilesUploadResultInterface = {
        state: 0,
        message: "",
        data: [],
    }
    
    let index: number = 0;
    const data_list:chunkImageUploadDataInterface[] = [];

    const toast_id = file_dir;

    while(index < files.length){
        dev_console.log(`${index + 1} - ${files.length}`);

        try{
            const result = await imageChunkUpload({
                file: files[index],
                file_idx: index,
                file_dir
            })

            total_result.state = result.state;
            total_result.message = result.message;
            if(result.next_fleg){
                if(result.data){
                    data_list.push(result.data);
                }

                customToast.info({
                    msg: `${index + 1} / ${files.length}`,
                    autoClose: false,
                    toastId: toast_id, 
                })

                index++;
            }else{
                if(result.is_error){
                    break ;
                }
            }
        }catch(error){
            total_result.state = -1;
            total_result.message = "error";
            dev_console.error("파일 업로드 중 오류가 발생했습니다.");
            dev_console.error(error);
            break ;
        }
    }

    toast.dismiss(toast_id);

    total_result.data = data_list;

    dev_console.log(total_result);

    return total_result;
}

export const printFormData = (fromdata: FormData) => {
    for (let [key, value] of fromdata.entries()) {
        dev_console.log(`${key}: ${value}`);
    }
}

export const isNotPrintModeView = () => {
    return process.env.REACT_APP_NO_PRINT_MODE_VIEW === "TRUE";
}

class whoAmIClass{
    getAccountData: getAccountDataClass

    constructor(){
        this.getAccountData = new getAccountDataClass();
    }

    isCustomer(): boolean{
        return (this.getAccountData.user_type() === "C" && !!this.getAccountData.token())
    }

    isAdmin(): boolean{
        return (this.getAccountData.user_type() === "E" && !!this.getAccountData.token())
    }
}
export const whoAmI = new whoAmIClass();

interface customToastMethodInterface{
    msg: string, 
    toastId?: string, 
    autoClose?: number | false
}

class customToastClass{

    info({msg, toastId, autoClose = TOAST_AUTO_CLOSE}: customToastMethodInterface){
        if(!!toastId && toast.isActive(toastId)){
            toast.update(toastId, {
                render: msg,
            });
        }else{
            toast.info(msg, {
                toastId,
                autoClose,
            });
        }
    }

    success({msg, toastId, autoClose = TOAST_AUTO_CLOSE}: customToastMethodInterface){
        if(!!toastId && toast.isActive(toastId)){
            toast.update(toastId, {
                render: msg,
            });
        }else{
            toast.success(msg, {
                toastId,
                autoClose,
            });
        }
    }

    error({msg, toastId, autoClose = TOAST_AUTO_CLOSE}: customToastMethodInterface){
        if(!!toastId && toast.isActive(toastId)){
            toast.update(toastId, {
                render: msg,
            });
        }else{
            toast.error(msg, {
                toastId,
                autoClose,
            });
        }
    }
}

export const customToast = new customToastClass();

// 주소가 어느 지역에 속하는지 확인하는 함수
export const findRegionByAddress = (address: string): regionInterface | null => {
    // 주소에서 시, 구, 군 명칭을 우선으로 처리합니다.
    for (const region of REGION_LIST) {
        const cities = region.cities;
        for (const city of cities) {
            // 시/구/군 단위가 포함된 경우 해당 지역으로 반환
            if (address.includes(city)) {
                return region;
            }
        }
    }
    return null; // 어느 지역에도 속하지 않는 경우
}

// 다운로드에 걸리는 예상시간을 리턴해줌
export function estimateDownloadTime(fileSizeInMB: number): number {
    const mb_per_sec = 2048 / 180; // 2GB를 180초(3분) 안에 다운로드하는 속도
    
    if(fileSizeInMB < 0){
        dev_console.error("파일의 용량은 음수일 수 없습니다.");
        return 0;
    }

    const timePerMB = 1 / mb_per_sec; // 10MB당 1초 = 1MB당 0.1초
    const totalSeconds = fileSizeInMB * timePerMB; // 총 다운로드 시간(초 단위)

    // 1분 이하일 때는 1분으로 처리
    if (totalSeconds < 60) {
        return 1;
    }

    // 1분 이상일 경우, 분 단위로 반올림
    const totalMinutes = Math.ceil(totalSeconds / 60);
    return totalMinutes;
}

// 키로바이트를 메가바이트로 변환해주는 함수
export function kilobytesToMB(kilobytes: number): number {
    const KB_IN_MB = 1024; // 1MB는 1024KB
    if (kilobytes < 0) {
        throw new Error("킬로바이트 값은 음수일 수 없습니다.");
    }
    const mbValue = kilobytes / KB_IN_MB;
    return Math.round(mbValue * 100) / 100; // 소숫점 둘째 자리에서 반올림
}

// 키로바이트를 적절한 단위 (KB, MB, GB)로 변환해주는 함수
export function bytesToFormattedSize(kb: number): string {
    const KB_IN_MB = 1024; // 1MB는 1024KB
    const MB_IN_GB = 1024; // 1GB는 1024MB

    if (kb < 0) {
        throw new Error("바이트 값은 음수일 수 없습니다.");
    }

    // 1MB보다 작으면 KB로 반환
    if (kb < KB_IN_MB) {
        return `${kb}KB`;
    }

    // 1MB 이상 1GB 미만이면 MB로 반환
    const mbValue = kb / KB_IN_MB;
    if (mbValue < MB_IN_GB) {
        return `${Math.round(mbValue * 100) / 100}MB`;
    }

    // 1GB 이상이면 GB로 반환
    const gbValue = mbValue / MB_IN_GB;
    return `${Math.round(gbValue * 100) / 100}GB`;
}

// 브라우저 확인 함수
export const whichBrowserDoYouUse = (): browserCodeType => {
    let RTN: browserCodeType = BROWSER_CODE.Other;

    const userAgent = navigator.userAgent.toLowerCase();

    if(userAgent.includes("kakaotalk")){
        RTN = BROWSER_CODE.KakaoTalk; // 카카오톡 인앱 브라우저
    }else if(userAgent.includes("fbav") || userAgent.includes("fban")){
        RTN = BROWSER_CODE.Facebook; // 페이스북 인앱 브라우저
    }else if(userAgent.includes("instagram")){
        RTN = BROWSER_CODE.Instagram; // 인스타그램 인앱 브라우저
    }else if(userAgent.includes("line")){
        RTN = BROWSER_CODE.LINE; // 라인 인앱 브라우저
    }else if(userAgent.includes("crios")){
        RTN = BROWSER_CODE.Chrome; // iOS 크롬 브라우저
    }else if(userAgent.includes("chrome")){
        RTN = BROWSER_CODE.Chrome; // 일반 크롬 브라우저
    }else if(userAgent.includes("safari")){
        RTN = BROWSER_CODE.Safari; // 사파리 브라우저
    }

    return RTN;
};

// 운영체제를 확인해주는 함수
export const getMobileOperatingSystem = (): "iOS" | "Android" | "Other" => {
    const userAgent = navigator.userAgent.toLowerCase();

    // iOS 감지
    if (/ipad|iphone|ipod/.test(userAgent) && !(window as any).MSStream) {
        return "iOS";
    }
  
    // 안드로이드 감지
    if (/android/.test(userAgent)) {
        return "Android";
    }
  
    // 그 외 환경 (데스크탑 등)
    return "Other";
};

// 외부 브라우저로 리다이렉트를 시켜주는 주소 생성
class getRedirectUrlClass{
    KakaoTalk(){
        const currentURL = window.location.href
        return "kakaotalk://web/openExternal?url="+encodeURIComponent(currentURL);
    }

    LINE(){
        const currentURL = window.location.href
        if(currentURL.indexOf("?") !== -1){
            return currentURL+"&openExternalBrowser=1";
        }else{
            return currentURL+"?openExternalBrowser=1";
        }
    }
}
export const getRedirectUrl = new getRedirectUrlClass();

// 인앱 브라우저를 닫는데 사용되는 url
class getCloseInAppBrowserUrlClass{
    KakaoTalk(){
        const os = getMobileOperatingSystem();
        if(os === "Android"){
            return "kakaotalk://inappbrowser/close";
        }else if(os === "iOS"){
            return "kakaoweb://closeBrowser";
        }else{
            return "";
        }
    }
}
export const getCloseInAppBrowserUrl = new getCloseInAppBrowserUrlClass();

// 외부 브라우저를 열어주는 함수
class openInExternalBrowserClass extends getRedirectUrlClass{
    KakaoTalk_open(){
        setTimeout(() => {
            window.location.href = getCloseInAppBrowserUrl.KakaoTalk();
        }, 500)

        window.location.href = this.KakaoTalk();
    }

    LINE_open(){
        setTimeout(() => {
            window.close();
        }, 500)

        window.location.href = this.LINE();
    }

    auto_open(){
        const l_browserCode = whichBrowserDoYouUse();

        if(l_browserCode === BROWSER_CODE.KakaoTalk){
            this.KakaoTalk_open();
        }else if(l_browserCode === BROWSER_CODE.LINE){
            this.LINE_open();
        }
    }
}
export const openInExternalBrowser = new openInExternalBrowserClass();

// PhotoSwipeGallery에서 사용되는 뷰포트 사이즈를 리턴해주는 함수
export const getViewportSize = () => {
    const viewport_width = window.innerWidth - 50;
    const viewport_height = window.visualViewport ? window.visualViewport.height - 50 : window.innerHeight - 50;

    return ({
        viewport_width,
        viewport_height
    })
}

// 개발용 - 토큰을 통해 직접 로그인을 진행할 경우 활용
class adminTokenLoginClass{
    isTokenLoginMode(){
        return (process.env.REACT_APP_USE_TOKEN_LOGIN === "TRUE");
    }

    token(){
        return process.env.REACT_APP_TOKEN || "";
    }
}

export const adminTokenLogin = new adminTokenLoginClass();

// Date객체를 다루는 클래스
export class dateObjectManagerClass{

    getWeekday(date: string | Date){
        let RTN: string = "";
    
        const date_time = new Date(date);
    
        if(!isNaN(date_time.getTime())){
            const weekday_ko_list: string[] = ["일", "월", "화", "수", "목", "금", "토"];
            const weekday = weekday_ko_list[date_time.getDay()];
            RTN = weekday;
        }
    
        return RTN;
    }

    // 날짜를 설정한 형식의 문자열로 출력해주는 메소드
    customFormatDate(date: string | Date, formatString: string = "yyyy년 MM월 dd일"){
        try {
            const date_time = new Date(date);
            
            if(isNaN(date_time.getTime())){
                dev_console.error("Invalid date");
                return "";
            }else{
                return format(date_time, formatString);
            }
        } catch (error) {
            dev_console.error("Invalid date format");
            dev_console.error(error);
            return "";
        }
    };

    // Date객체의 날짜 비교를 위해 년, 월, 일의 정보만 제외하고 초기화
    removeTime(date: Date){
        const reset_date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        if(isNaN(reset_date.getTime())){
            return null
        }else{
            return reset_date;
        }
    }

    // 두 날짜 사이의 간격을 구하는 메소드
    getDaysDifference(date1: string | Date, date2: string | Date){
        let d1 = new Date(date1);
        let d2 = new Date(date2);

        if(isNaN(d1.getTime()) || isNaN(d2.getTime())){
            return null;
        }

        // 두 날짜의 차이를 밀리초로 구하고, 1000*60*60*24로 나누어 일수를 얻습니다.
        const deff = (d1.getTime() - d2.getTime()) / (1000 * 60 * 60 * 24);

        // 절댓값으로 변환합니다.
        const diffAbs = Math.abs(deff);

        // 소숫점 이하를 제거합니다.
        let diffAbsFloor = Math.floor(diffAbs);

        // 수숫점 이하 제거를 위해 절댓값으로 변환한 값을 다시 기존의 음수값으로 맞춰줍니다.
        if(deff < 0){
            diffAbsFloor *= -1;
        }

        return Math.floor(diffAbsFloor);
    }

    // 인자로 들어온 날짜가 오늘로부터 며칠 떨어진 날짜인지 계산한다.
    // 양수 : 오늘보다 미래
    // 음수 : 오늘보다 과거
    getDaysDifferenceFromToday(targetDate: string | Date){
        if(!targetDate){
            return null;
        }

        const target_datetime = new Date(targetDate);
        if(isNaN(target_datetime.getTime())){
            return null;
        }
        target_datetime.setHours(0, 0, 0, 0);

        const today = new Date(); // 현재 날짜와 시간을 포함하는 Date 객체를 생성합니다.
        // 오늘 날짜만 필요하므로, 시간, 분, 초, 밀리초를 0으로 설정합니다.
        today.setHours(0, 0, 0, 0);
        
        return this.getDaysDifference( today, target_datetime);
    }

    // 두 날짜의 분 차이를 리턴
    getMinuteDifference(date1: string, date2: string){
        let date1_datetime = new Date(date1);
        let date2_datetime = new Date(date2);

        if(isNaN(date1_datetime.getTime()) || isNaN(date2_datetime.getTime())){
            dev_console.error("date객체로 변환이 불가능한 인자값");
            return 0;
        }

        // 두 날짜의 시간 차이를 밀리초로 계산하고 절대값 처리
        let diffInMilliseconds = Math.abs(date1_datetime.getTime() - date2_datetime.getTime());

        // 값이 음수면 양수로 변환한다.
        if(diffInMilliseconds < 0){
            diffInMilliseconds = diffInMilliseconds * -1;
        }

        // 밀리초를 분으로 변환
        const RTN = Math.floor(diffInMilliseconds / (1000 * 60));

        return RTN;
    }

    // 일을 입력받아서 해당일을 기간 단위로 변환해주는 메소드
    // 과거, 미래표현 기준.
    // days가 음수다 -> 오늘 - 지정된날짜가 음수값이다 -> 지정된 날짜가 오늘보다 이후에 있다 -> 미래다.
    // days가 양수다 -> 오늘 - 지정된날짜가 양수다 -> 지정된 날짜가 오늘보다 이전에 있다 -> 과거다.
    calculatePeriod(days: number){
        const POINT_OF_VIEW_CODE = {
            past: -1,
            present: 0,
            future: 1,
        } as const;

        type pointOfViewCodeType = typeof POINT_OF_VIEW_CODE [keyof typeof POINT_OF_VIEW_CODE];

        let m_point_of_view: pointOfViewCodeType = POINT_OF_VIEW_CODE.present;

        if(days < 0){
            m_point_of_view = POINT_OF_VIEW_CODE.future;
        }else if(days > 0){
            m_point_of_view = POINT_OF_VIEW_CODE.past;
        }else{
            m_point_of_view = POINT_OF_VIEW_CODE.present
        }

        // 일수 차이값을 절대값처리해준다.
        let m_days = days;
        if(m_days < 0){
            m_days = m_days * -1;
        }
            
        let RTN: string = "";

        SetDayText: {
            // 오늘
            if(m_point_of_view === POINT_OF_VIEW_CODE.present){
                RTN = "오늘";
                break SetDayText;
            }

            // 1일 전 후
            if(m_days === 1){
                if(m_point_of_view === POINT_OF_VIEW_CODE.past){
                    RTN = "어제";
                }else{
                    RTN = "내일";
                }
                break SetDayText;
            }

            // // 2일 전 후
            // if(m_days === 2){
            //     if(m_point_of_view === POINT_OF_VIEW_CODE.past){
            //         RTN = "그저께";
            //     }else{
            //         RTN = "모레";
            //     }
            //     break SetDayText;
            // }

            // 7일 전 후
            if(m_days < 7){
                if(m_point_of_view === POINT_OF_VIEW_CODE.past){
                    RTN = `${m_days}일 전`;
                }else{
                    RTN = `${m_days}일 후`;
                }
                break SetDayText;
            }

            // 한달 전 후
            if(m_days < 30){
                if(m_point_of_view === POINT_OF_VIEW_CODE.past){
                    RTN = `${Math.floor(m_days / 7)}주 전`;
                }else{
                    RTN = `${Math.floor(m_days / 7)}주 후`;
                }
                break SetDayText;
            }

            // 1년 전 후
            if(m_days < 365){
                if(m_point_of_view === POINT_OF_VIEW_CODE.past){
                    RTN = `${Math.floor(m_days / 30)}개월 전`; // 단순화를 위해 모든 달을 30일로 계산
                }else{
                    RTN = `${Math.floor(m_days / 30)}개월 후`; // 단순화를 위해 모든 달을 30일로 계산
                }
                break SetDayText;
            }

            // 1년을 초과함
            if(m_point_of_view === POINT_OF_VIEW_CODE.past){
                RTN = `${Math.floor(m_days / 365)}년 전`; // 단순화를 위해 모든 달을 30일로 계산
            }else{
                RTN = `${Math.floor(m_days / 365)}년 후`;// 단순화를 위해 모든 달을 30일로 계산
            }
        }

        return RTN;
    }

    // 인자로 들어온 날짜가 오늘로부터 떨어진 시간을 계산하여 문자로 표현으로 리턴해준다.(1초전, 하루전, 한달전...)
    dateCalculatePeriod(date: Date) {
        const now = new Date();
        let m_diff_seconds = Math.floor((date.getTime() - now.getTime()) / 1000);

        type timeDirection_Type = "past" | "future";

        const timeDirection: timeDirection_Type = m_diff_seconds < 0 ? "past" : "future";

        if(m_diff_seconds < 0){
            m_diff_seconds = m_diff_seconds * -1;
        }

        let RTN: string = "";

        SetDayText: {
            if(m_diff_seconds < 60){
                RTN = `${m_diff_seconds}초`;
                break SetDayText;
            }

            if(m_diff_seconds < 60 * 60){
                RTN = `${Math.floor(m_diff_seconds / 60)}분`;
                break SetDayText;
            }

            if(m_diff_seconds < 60 * 60 * 24){
                RTN = `${Math.floor(m_diff_seconds / (60 * 60))}시간`;
                break SetDayText;
            }
        }

        ExceptionHandling:{
            // SetDayText에서 세팅이 이루어지지 못했다면, 하루를 넘어가는 경우이므로 계산단위를 일로 변경한다
            if(!RTN){
                const m_dday = Math.floor(m_diff_seconds / (60 * 60 * 24));
                RTN = this.calculatePeriod(m_dday);
                break ExceptionHandling;
            }

            if(timeDirection === "past"){
                RTN = `${RTN} 전`;
            }else{
                RTN = `${RTN} 후`;
            }
        }

        return RTN;
    }

    // 24시간 포기법을 가진 문자열(HH:mm)을 12시간 표기법으로 변환
    getClockTimeAMPMString(
        {
            time,
        }:{
            time: string
        }
    ){
        let RTN: {
            AmPmTime: string,
            ampm: {
                state: boolean, // 오전이면 false, 오후면 true
                ko: string,
                en: string,
            }
        } = {
            AmPmTime: "",
            ampm: {
                state: false,
                ko: "",
                en: "",
            }
        }

        if(time.length !== 5){
            dev_console.error("시간을 표시하는 문자열의 길이가 맞지 않습니다.", time.length);
            return null;
        }

        const m_split_time = time.split(":");
        if(m_split_time.length !== 2){
            dev_console.error("시간 표시 문자열이 올바른 형식이 아닙니다.", m_split_time.length);
            return null;
        }

        const m_hour = customParseInt(m_split_time[0]);
        const m_minute = customParseInt(m_split_time[1]);

        if(m_hour === null || m_minute === null){
            dev_console.error("시간을 숫자로 변환하는데 실패했습니다.", m_hour, m_minute);
            return null;
        }

        if(m_hour > 23 || m_minute > 59){
            dev_console.error("정상적인 시간 범의내의 숫자가 아닙니다.", m_hour, m_minute);
            return null;
        }

        if(m_hour >= 12){
            RTN.AmPmTime = `${m_hour - 12}:${m_minute}`
            RTN.ampm.state = true; 
            RTN.ampm.ko = "오후";
            RTN.ampm.en = "PM";
        }else{
            RTN.AmPmTime = `${m_hour}:${m_minute}`
            RTN.ampm.state = false;
            RTN.ampm.ko = "오전";
            RTN.ampm.en = "AM";
        }

        return RTN;
    }

    // 해당 달의 첫쨋날 1주 전과 마지막날의 1주 후를 구한다.
    getMonthBeforeAfterWeekDate(selectedDate: Date){
        const year = selectedDate.getFullYear();
        const month = selectedDate.getMonth(); // 월은 0부터 시작합니다.
        
        // 해당 월의 첫 날과 마지막 날 구하기
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0); // 다음 달의 0일은 현재 달의 마지막 날입니다.
        
        // 첫 날에서 7일 전과 마지막 날에서 7일 후 계산
        const weekBefore = new Date(firstDay);
        weekBefore.setDate(firstDay.getDate() - 7);
        
        const weekAfter = new Date(lastDay);
        weekAfter.setDate(lastDay.getDate() + 7);
        
        return { weekBefore, weekAfter };
    }

    // 서버에서 전달해주는 yyyyMMdd, yyyyMMddHHmm, yyyyMMddHHmmss 형식의 문자열을 사용자가 원하는 형식의 문자열로 변환해주는 메소드
    changeServerDateToFormatDateString(serverDate: string, formatString: string = "yyyy년 MM월 dd일 HH시 mm분"){
        interface data_Interface{
            format: string;
            date: Date | null;
        }
        
        const RTN: RTN_interface<data_Interface> = {
            sign: false,
            data: {
                format: "",
                date: null,
            },
            msg: "",
        }
        
        const m_serverDate_length = serverDate.length;

        CheckDate:{
            if(m_serverDate_length !== 8 && m_serverDate_length !== 12 && m_serverDate_length !== 14){
                RTN.sign = false;
                RTN.msg = "사용가능한 문자열 형식은 yyyyMMdd, yyyyMMddHHmm, yyyyMMddHHmmss 입니다.";
                break CheckDate;
            }
                
            RTN.sign = true;
        }

        SetDateString:{
            if(!RTN.sign){
                break SetDateString;
            }

            let m_date_string: string = "";

            switch(m_serverDate_length){
                case 8:
                    m_date_string = serverDate.substring(0, 4) + "-" + serverDate.substring(4, 6) + "-" + serverDate.substring(6, 8);
                    break;
                case 12:
                    m_date_string = serverDate.substring(0, 4) + "-" + serverDate.substring(4, 6) + "-" + serverDate.substring(6, 8) + " " + serverDate.substring(8, 10) + ":" + serverDate.substring(10, 12);
                    break;
                case 14:
                    m_date_string = serverDate.substring(0, 4) + "-" + serverDate.substring(4, 6) + "-" + serverDate.substring(6, 8) + "  " + serverDate.substring(8, 10) + ":" + serverDate.substring(10, 12) + ":" + serverDate.substring(12, 14);
                    break;
            }

            const m_date_object = new Date(m_date_string);

            const m_format_date_string = this.customFormatDate(m_date_string, formatString);

            if(m_format_date_string.length === 0){
                RTN.sign = false;
                RTN.msg = "날짜 형식 변환에 실패했습니다.";
                break SetDateString;
            }

            RTN.sign = true;
            RTN.data.format = m_format_date_string;
            RTN.data.date = m_date_object;
        }

        if(!RTN.sign){
            dev_console.error(RTN.msg);
        }

        return RTN.data;
    }
}

// 랜덤한 값을 생성해주는 클래스
export class getRandomValueClass{
    // 특정 정수범위 내에서 랜덤으로 정수를 뽑아주는 메소드
    getRandomInt(min: number, max: number){
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    // 랜덤으로 색을 뽑아주는 함수
    getRandomColor(){
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
}

// g_display_none_object클래스를 적용해주는 함수
export const isHiddenClassNameSetter = (
    {
        classNameList, 
        isHidden,
        display,
    }:{
        classNameList: string[], 
        isHidden?: isHiddenType
        display?: "flex"
    }
) => {
    let RTN: string[] = [];
    
    RTN.push("g_display_none_object");

    if(isHidden){
        RTN.push(isHidden);
    }

    if(display){
        RTN.push(display);
    }

    RTN = [...RTN, ...classNameList]

    return RTN;
}

// 쿼리파라미터를 포함한 url를 생성해주는 함수
export const getQueryParamsUrl = (
    {
        main_url,
        queryParams,
    }:{
        main_url: string;
        queryParams: string[][] | Record<string, string> | string | URLSearchParams;
    }
) => {
    const m_queryParams_url = new URLSearchParams(queryParams).toString();
    const RTN = `${main_url}?${m_queryParams_url}`;
    return RTN;
}

// 문자열을 정수로 변환해주는 함수
// 변환 후 정상적인 숫자인지 검증하는 로직이 추가됬다.
export const customParseInt = (str: string | null | undefined) => {
    let RTN: number | null = null;
    
    if(!str){
        RTN = null;
    }else{
        const m_result = parseInt(str);
    
        if(isNaN(m_result)){
            RTN = null;
        }else{
            RTN = m_result;
        }
    }

    return RTN;
}

// 텍스트 클립보드에 복사기능
export const textCopy = (
    {
        copy_target_text,
        toast_id, 
        copy_message,
    }:{
        copy_target_text: string; 
        toast_id?: string;
        copy_message?: string;
    }
) => {
    if(copy_target_text){
        navigator.clipboard.writeText(copy_target_text)
        .then(() => {
            customToast.success({
                msg: copy_message ? copy_message : "클립보드에 복사되었습니다.",
                toastId: toast_id,
                autoClose: 1000 * 1,
            });
        })
        .catch(() => {
            customToast.error({
                msg: "클립보드 복사에 실패했습니다.",
                toastId: toast_id,
                autoClose: 1000 * 1,
            });
        })
    }
}

export const isMaintenancePageOn = () => {
    const m_is_alive = process.env.REACT_APP_SERVER_IS_ALIVE;

    if(m_is_alive === "FALSE"){
        return true;
    }else{
        return false;
    }
}

export class imageDataManagerClass{

    async getImageDpi(file: File){
        const RTN: RTN_interface<dimensions_Interface | null> = {
            sign: false,
            data: null,
            msg: "",
        }

        try {
            // 'XResolution'과 'YResolution' 태그를 지정해서 EXIF 메타데이터를 파싱합니다.
            const exifData = await exifr.parse(file, ['XResolution', 'YResolution']);
            if (exifData && exifData.XResolution && exifData.YResolution) {
                RTN.sign = true;
                RTN.data = {
                    width: exifData.XResolution,
                    height: exifData.YResolution,
                }
                RTN.msg = "success";
            } else {
              RTN.sign = false;
              RTN.data = null
              RTN.msg = "이미지 메타데이터에 DPI 정보가 포함되어 있지 않습니다.";
            }
        }catch(error){
            RTN.sign = false;
            RTN.data = null;
            RTN.msg = `DPI 정보를 읽어오는 중 오류가 발생했습니다`;
            dev_console.error(error);
        }

        if(!RTN.sign){
            dev_console.error(RTN.msg)
        }

        return RTN.data;
    }
    
    // 이미지파일데이터에서 px값을 추출하는 함수
    getImageDimensions(file: File): Promise<dimensions_Interface> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            
            img.onload = () => {
                const dimensions = {
                    width: img.naturalWidth,
                    height: img.naturalHeight
                };
                URL.revokeObjectURL(img.src);
                resolve(dimensions);
            };
            
            img.onerror = () => {
                URL.revokeObjectURL(img.src);
                reject(new Error('Failed to load image'));
            };
        });
    }
}

export {
    callAxios,
    downloadFile,
    isSuccess,
    isNumericString,
    getMaxPageNum,
    transformISOString,
    isValidPhoneNumber,
    addHyphens,
    preloadImages,
    downloadOriginalImages,
    addCommasToPrice,
    sanitizeInput,
    printStateMsg,
    isAbleAccesser,
    validateInput,
    checkOverflow,
    refineString,
    calculateLastDivHeight,
    validatePassword,
    getTimeDifferenceInMinutes,
    validateEmail,
    getGenderCode,
    insertBetweenList,
    isBetweenNum,
    getFileType,
    detectDuplicates,
    setQueryParameter,
    getFileName,
    arraysAreEqual,
    imageFilesUpload,
};
