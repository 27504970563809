import { schedulePhotoProductThemeInterface, themaInterface } from "../../../static/interface/selectTheme.interface";

const useSelectedThemeList = (
    {
        photoProduct,
    }:{
        photoProduct: schedulePhotoProductThemeInterface;
    }
) => {

    let theme_num: number = parseInt(photoProduct.theme_num);
    if(isNaN(theme_num)){
        theme_num = 0;
    }

    let photo_id: number = parseInt(photoProduct.photo_id);
    if(isNaN(photo_id)){
        photo_id = 0;
    }

    let selected_theme_list: themaInterface[] = photoProduct.selected_theme_list;

    return({
        photo_id,
        photo_name: photoProduct.photo_name,
        theme_num,
        allThemeList: photoProduct.theme_list,
        selected_theme_list,
        schedule_photo_product_id: photoProduct.schedule_photo_product_id,
    })
}

export default useSelectedThemeList;