import CSS from "./static/css/AdditionalInfoContainer.module.css";
import MainInfoCard from "../../../../Component/Elements/MainInfoCard/MainInfoCard";
import BabyInfoInputContainer from "../BabyInfoInputContainer/BabyInfoInputContainer";
import EditRequestTextArea from "../EditRequestTextArea/EditRequestTextArea";
import { addedInfoDataInterface } from "../../static/interface/ProductRequestEdit.interface";
import { Dispatch, SetStateAction } from "react";

const AdditionalInfoContainer = (
    {
        request,
        setRequest,
        addedInfo,
        setAddedInfo,
        is_request_view_essential_icon,
        isAlbum,
    }:{
        request: string;
        setRequest: Dispatch<SetStateAction<string>>;
        addedInfo?: addedInfoDataInterface;
        setAddedInfo?: Dispatch<SetStateAction<addedInfoDataInterface>>;
        is_request_view_essential_icon?: boolean;
        isAlbum?: boolean;
    }
) => {
    return(
        <div className={CSS.l_AdditionalInfoContainer_main}>
            <MainInfoCard preset={"additionalInfoCard"}>
                <div className={CSS.l_AdditionalInfoContainer_inputComponents}>
                    {
                        addedInfo && setAddedInfo && isAlbum
                        &&  <BabyInfoInputContainer 
                                addedInfo={addedInfo}
                                setAddedInfo={setAddedInfo}
                            />
                    }
                    <EditRequestTextArea 
                        request={request}
                        setRequest={setRequest}
                        is_request_view_essential_icon={is_request_view_essential_icon}
                    />
                </div>
            </MainInfoCard>
        </div>
    )
}

export default AdditionalInfoContainer;