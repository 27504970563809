import CSS from "./static/css/ProductInfoCardViewMode.module.css";
import MainInfoCard from "../../../../Component/Elements/MainInfoCard/MainInfoCard";
import { ProductDetail_SelectedOptionListEle_Interface, ProductDetail_SelectInformationDate_AddedInfo_Interface, ProductDetail_SelectInformationDate_Address_Interface } from "../../static/interface/ProductDetail.interface";
import LabelAndContent from "../../../../Component/Elements/LabelAndContent/LabelAndContent";
import SelectedOptionEle from "../SelectedOptionEle/SelectedOptionEle";
import BabyInfoView from "../BabyInfoView/BabyInfoView";
import EditRequestView from "../EditRequestView/EditRequestView";
import AddressInfoView from "../AddressInfoView/AddressInfoView";
import Hr from "../../../../Component/Elements/Hr/Hr";
import AddressInfoMain from "../AddressInfoMain/AddressInfoMain";
import { productStateCodeType } from "../../static/interface/ProductList.interface";
import { PRODUCT_STATE_CODE_OBJ } from "../../static/ts/const";

const ProductInfoCardViewMode = (
    {
        product_name,
        is_view_babyinfo,
        selectedOptionData,
        
        addedInfo,
        address,
        
        status_cd,
        album_save_id,
        
        isRemoveOutline,
        refetchHandler,

        buttonToggleHandler,
    }:{
        product_name: string;
        is_view_babyinfo?: boolean;
        selectedOptionData?: ProductDetail_SelectedOptionListEle_Interface[];
        
        addedInfo: ProductDetail_SelectInformationDate_AddedInfo_Interface | null;
        address: ProductDetail_SelectInformationDate_Address_Interface | null;
        
        status_cd?: productStateCodeType;
        album_save_id?: number | null;
        
        isRemoveOutline?: boolean;
        refetchHandler?: () => void;

        buttonToggleHandler?: (sign: boolean) => void;
    }
) => {
    return(
        <div 
            className={CSS.l_ProductInfoCardViewMode_main}
            onClick={(event) => {
                event.stopPropagation();
            }}
        >
            <MainInfoCard 
                title={product_name}
                isInnerComponent={isRemoveOutline}
            >
                {
                    is_view_babyinfo
                    ?   <>
                            <LabelAndContent 
                                label={"추가 옵션"}
                                flex_direction={"column"}
                                is_view_essential_icon={true}
                            >
                                <div className={CSS.l_ProductInfoCard_selectedOptionContainer}>
                                    {
                                        selectedOptionData && selectedOptionData.map((item) => {
                                            return(
                                                <SelectedOptionEle 
                                                    key={item.option_id} 
                                                    option_name={item.option_name} 
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </LabelAndContent>

                            <Hr />
                        </>
                    :   null
                }

                {
                    is_view_babyinfo
                    ?   <>
                            <BabyInfoView 
                                baby_name={addedInfo?.baby_name || ""}
                                baby_birth_day={addedInfo?.baby_birth_day || ""}
                                baby_birth_time={addedInfo?.baby_birth_time || ""}
                                baby_height={addedInfo?.baby_height || ""}
                                baby_weight={addedInfo?.baby_weight || ""}
                            />

                            <Hr />
                        </>
                    :   null
                }

                {
                    addedInfo?.common_memo
                    &&  <>
                            <EditRequestView 
                                request={addedInfo?.common_memo || ""}
                            />
            
                            <Hr />
                        </>
                }

                {
                    !status_cd || status_cd === PRODUCT_STATE_CODE_OBJ.edit_end
                    ?   <AddressInfoView 
                            recipient_name={address?.recipient_name || ""}
                            recipient_phone={address?.recipient_phone || ""}
                            zip_code={address?.zip_code || ""}
                            addr={address?.addr || ""}
                            addr_detail={address?.addr_detail || ""}
                        /> 
                    :   <AddressInfoMain
                            album_save_id={album_save_id}
                            address={address}
                            refetchHandler={refetchHandler}
                            buttonToggleHandler={buttonToggleHandler}
                        />
                }
            </MainInfoCard>
        </div>
    )
}

export default ProductInfoCardViewMode;