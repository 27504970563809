import DragAndDrop from "../../../../Component/Elements/DragAndDrop/DragAndDrop";
import CSS from "./static/css/UploadImageContainer.module.css";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { arraysAreEqual, customToast, detectDuplicates, imageDataManagerClass, isBetweenNum, whatIsMode } from "../../../../Common/ts/commonTools";
import ImageGalleryContainer from "../../../../Component/Elements/ImageGallery/ImageGalleryContainer";
import { viewerImageInterface } from "../../../../Component/Elements/ImageGallery/interface/ImageGallery.interface";
import { FILE_CODE } from "../../../../Common/ts/const";
import Flex from "../../../../Component/Elements/Flex/Flex";
import UploadImagePhotoEle from "../UploadImageItemEle/UploadImageItemEle";
import { ProductDetail_Folder_FolderListEle_Interface } from "../../static/interface/ProductDetail.interface";
import OvalIconButton from "../../../../Component/Elements/Button/OvalIconButton/OvalIconButton";
import FolderImageOverlay from "../FolderImageOverlay/FolderImageOverlay";
import ListCardHeaderText from "../../../../Component/Elements/ListCardHeaderText/ListCardHeaderText";
import Text from "../../../../Component/Elements/Text/Text";
import { webViewBridgeClass } from "../../../../Common/ts/appTools";
import { RTN_interface } from "../../../../Common/interface/App.interface";

const webViewBridge = new webViewBridgeClass();

const imageDataManager = new imageDataManagerClass();

// 플러터 개발 환경인가?
const is_flutter_div_mode =  whatIsMode().is_dev_mode && webViewBridge.flutterInAppWebView;

const UploadImageContainer = (
    {
        imgFiles,
        setImgFiles,
        folder,
        needPhotoNum,
        isSatisfiedPhotoNums,
        setIsSatisfiedPhotoNums,
        min_resolution,
        min_long_size,
        min_short_size,
    }:{
        imgFiles?: {[key: number]: File[];};
        setImgFiles?: React.Dispatch<React.SetStateAction<{[key: number]: File[];}>>;
        folder: ProductDetail_Folder_FolderListEle_Interface;
        needPhotoNum: {
            min: number;
            max: number;
        };
        isSatisfiedPhotoNums?: {[key:number]: boolean;}
        setIsSatisfiedPhotoNums?: Dispatch<SetStateAction<{[key:number]: boolean;}>>;
        min_resolution: number;
        min_long_size: number;
        min_short_size: number;
    }
) => {
    // 업로드되는 이미지 구분을 위해 사용하는 state
    // 초기값을 0으로 하는경우 미처 폴더리스트에 대한 처리가 되기 전에 0번의 폴더가 실제로 있는것처럼 업데이트되는 버그를 막기 위해 -1로 초기설정 그리고 하단에선 folderId가 -1보다 클 때만 처리하도록 해야함
    const [folderId, setFolderId] = useState<number>(-1);

    // 업로드된 이미지 주소
    const [previewUrls, setPreviewUrls] = useState<viewerImageInterface[]>([]);

    const [isUploading, setIsUploading] = useState<boolean>(false);

    // 폴더 이미지 보기기능을 위한 state
    const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);
    const [folderDetailImgSrc, setFolderDetailImgSrc] = useState<string>("");

    // input ref
    const uploadInputRef = useRef<HTMLInputElement>(null);

    // 객체에 해당 key값의 이미지 데이터 배열을 리턴하는 함수
    const getImgFileList = (folder_id: number) => {
        return (!!imgFiles && !!imgFiles[folder_id]) ? imgFiles[folder_id] : [];
    }

    // 새로운 이미지 데이터를 추가하는 함수
    const addFile = async (
        {
            files, 
            folder_id,
            min_resolution,
            min_long_size,
            min_short_size,
        }:{
            files: File[], 
            folder_id: number,
            min_resolution: number,
            min_long_size: number,
            min_short_size: number,
        }
    ) => {
        setIsUploading(true);

        const old_files = getImgFileList(folder_id);
        let total_files = [...old_files, ...files];

        const not_allowed_file_list = total_files.filter(file => {
            const fileExtension = file.name.split('.').pop()?.toLowerCase();

            let result = null

            // 개발을 위해 임시로 플러터환경에선 모든 파일에 대한 업로드를 허용한다.
            if(!is_flutter_div_mode){
                result = fileExtension !== "jpg" || !file.type.startsWith("image/");
            }
            
            if(result){
                customToast.error({msg: `.jpg 확장자의 이미지만 업로드 가능합니다. (${file.name})`});
            }

            return result;
        })

        if(not_allowed_file_list.length > 0){
            customToast.info({msg: "형식에 맞지 않는 파일은 제외됩니다."});

            total_files = total_files.filter(file => {
                const fileExtension = file.name.split('.').pop()?.toLowerCase();
                return fileExtension === "jpg" && file.type.startsWith("image/");
            })
        }

        // 화질 검사 - 시작 ------------------- 
        const m_checked_file_list: File[] = [];

        for(const file of total_files){
            const m_dimensions = await imageDataManager.getImageDimensions(file);
            const m_dpi = await imageDataManager.getImageDpi(file);
            const name = file.name;

            const RTN: RTN_interface<File | null> = {
                sign: false,
                data: null,
                msg: ""
            }

            CheckSize:{
                if(!m_dpi){
                    RTN.sign = false;
                    RTN.data = null;
                    RTN.msg = `${name}의 DPI 정보가 없습니다.`;
                    break CheckSize;
                }

                if(!m_dimensions){
                    RTN.sign = false;
                    RTN.data = null;
                    RTN.msg = `${name}의 해상도 정보가 없습니다.`;
                    break CheckSize;
                }

                let m_long_size: number = 0;
                let m_short_size: number = 0;

                if(m_dimensions.width > m_dimensions.height){
                    m_long_size = m_dimensions.width;
                    m_short_size = m_dimensions.height;
                }else{
                    m_long_size = m_dimensions.height;
                    m_short_size = m_dimensions.width;
                }

                if(m_dpi.width < min_resolution ||  m_dpi.height < min_resolution){
                    RTN.sign = false;
                    RTN.data = null;
                    RTN.msg = `${name}의 DPI값이 ${min_resolution}보다 낮습니다.`;
                    break CheckSize;
                }

                if(m_long_size < min_long_size ||  m_short_size < min_short_size){
                    RTN.sign = false;
                    RTN.data = null;
                    RTN.msg = `${name}의 해상도가 ${min_long_size}x${min_short_size} 보다 낮습니다.`;
                    break CheckSize;
                }

                RTN.sign = true;
                RTN.data = file;
                RTN.msg = "success";
            }

            if(RTN.sign && RTN.data){
                m_checked_file_list.push(RTN.data);
            }else{
                customToast.error({msg: RTN.msg});
            }
        }

        total_files = m_checked_file_list;
        // 화질 검사 - 끝 -------------------

        // 중복되는 파일이 있는지 검사
        detectDuplicates(total_files)
        .then((result) => {
            let final_file_list: File[] = [];
            if(result.duplicateFileNames.length > 0 || result.duplicateFiles.length > 0){
                customToast.info({msg: "중복되는 사진들은 제외됩니다."});
                final_file_list = result.uniqueFiles;
            }else {
                final_file_list = total_files;
            }

            const selected_img_file_list = getImgFileList(folder_id);
            if (!arraysAreEqual<File>(final_file_list, selected_img_file_list)) {
                if(setImgFiles){
                    setImgFiles((prev) => {
                        return {
                            ...prev,
                            [folder_id]: final_file_list
                        }
                    })
                }
            }
        })
        .catch((error) => {
            console.error(error);
            customToast.error({msg: "파일 검증 과정에서 문제가 발생했습니다."});
        })
        .finally(() => {
            setIsUploading(false);

            // input value를 비워줘서 동일한 파일을 다시 선택할 때도 onChange 이벤트가 발생하도록 함
            if (uploadInputRef.current) {
                uploadInputRef.current.value = "";
            }
        })

    }

    // input에 파일이 업로드됬을 때, state에 해당 내용 업로드
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || []);

        addFile({
            files,
            folder_id: folderId,
            min_resolution: whatIsMode().is_not_product_mode_safe ? 60 : min_resolution,
            min_long_size,
            min_short_size,
        });
    };

    // drag and drop동작
    const onDropHandler = (event: React.DragEvent<HTMLDivElement>) => {
        if(isUploading){
            customToast.info({msg: "잠시 기다려주세요"});
            return ;
        }

        const files = Array.from(event.dataTransfer.files); // 드롭된 파일들
        
        addFile({
            files,
            folder_id: folderId,
            min_resolution: whatIsMode().is_not_product_mode_safe ? 60 : min_resolution,
            min_long_size,
            min_short_size,
        });
    }

    // 특정 사진을 제거하는 함수
    const handleRemove = (index: number, folder_id: number) => {          
        if(isUploading){
            customToast.info({msg: "잠시 기다려주세요"});
            return ;
        }

        if(!window.confirm("해당 사진을 제거하시겠습니까?")){
            return ;
        }

        if(!!imgFiles && !!imgFiles[folder_id]){
            const new_list = imgFiles[folder_id].filter((_, i) => i !== index);
            if(setImgFiles){
                setImgFiles((prev) => {
                    return {
                        ...prev,
                        [folder_id]: new_list
                    }
                })
            }
        }
    };

    // 사진 추가 버튼을 클릭했을때 input에 해당 이벤트를 전달하는 함수
    const handleClick = () => {
        if(isUploading){
            customToast.info({msg: "잠시 기다려주세요"});
            return ;
        }

        uploadInputRef.current?.click();
    };
    
    // 선택해야하는 사진의 수 안내문구를 생성해주는 함수
    const howManySelectPictuerText = ({min, max}:{min:number, max:number}) => {
        let num_text:string = "";
        
        if(min === max){
            num_text = `${min}`
        }else{
            num_text = `${min}-${max}`
        }

        return `${num_text}개 사진 필요`
    }

    // 현재 업로드한 모든 사진 제거
    const removeImgAll = (folder_id: number) => {
        if(setImgFiles){
            setImgFiles((prev) => {
                const newState = { ...prev }; // 이전 상태를 복사
                delete newState[folder_id];    // 특정 key(folderId)를 삭제
                return newState;              // 새로운 객체 반환
            })
        }
    }

    // 모든 사진 제거 버튼 동작 함수
    const handleRemoveAll = (folder?: ProductDetail_Folder_FolderListEle_Interface) => {
        if(isUploading){
            customToast.info({msg: "잠시 기다려주세요"});
            return ;
        }

        if(!!imgFiles && !!imgFiles[folderId] && imgFiles[folderId].length > 0){ 
            let confirm_text: string = "모든 사진을 제거하시겠습니까?";

            if(folder){
                confirm_text = `${folder.folder_name}의 모든 사진을 제거하시겠습니까?`
            }

            if(!window.confirm(confirm_text)){
                return ;
            }
        }

        removeImgAll(folderId);
    }

    // 폴더 이미지 보기 오버레이 출력 핸들러
    const overlayOpenHandler = (detailImgSrc: string) => {
        setFolderDetailImgSrc(detailImgSrc);
        setIsOverlayOpen(true);
    }

    // 폴더 이미지 보기 오버레이 닫기 핸들러
    const overlayCloseHandler = () => {
        setIsOverlayOpen(false);
        setFolderDetailImgSrc("");
    }

    useEffect(() => {
        // 폴더 id: 폴더의 정보가 없는경우(폴더 구분 없이 업로드) 0으로 처리한다.
        const folder_id: number = folder ? folder.folder_id : 0;
        setFolderId(folder_id)
    }, [folder])

    // 초기에 렌더링됬을 때 이미지 데이터가 있다면 이를 미리보기 이미지에 추가
    useEffect(() => {
        const files = getImgFileList(folderId);
        const urls = files.map((file) => {
            const result: viewerImageInterface = {
                file_name: file.name,
                thumbnail_url: URL.createObjectURL(file),
                origin_url: URL.createObjectURL(file),
                file_type: FILE_CODE.img,    //  고객은 이미지 외의 파일을 업로드하는 일이 없으므로 무조건 이미지로 처리
                folder_id: folderId,
            }

            return result;
        });
        setPreviewUrls(urls);
    }, [folderId])

    useEffect(() => {
        if(setIsSatisfiedPhotoNums && folderId > -1){
            setIsSatisfiedPhotoNums((prev) => {

                const newStatus = isBetweenNum({
                    min: needPhotoNum.min,
                    max: needPhotoNum.max,
                    value: previewUrls.length,
                    inclusive: true
                });
        
                // 상태가 이전과 동일한 경우 업데이트하지 않음(무한 리렌더링 방지)
                if (prev[folderId] === newStatus) {
                    return prev; // 상태 변경 없음
                }

                return {
                    ...prev,
                    [folderId]: newStatus
                };
            });
        }
    }, [folderId, needPhotoNum, previewUrls, setIsSatisfiedPhotoNums])

    // 파일데이터에 변동이 생기면 미리보기 이미지를 새로 생성한다.
    useEffect(() => {
        let urls: viewerImageInterface[] = [];
        if(imgFiles && folder && imgFiles[folder.folder_id]){
            urls = imgFiles[folder.folder_id].map((file) => {
                const result: viewerImageInterface = {
                    file_name: file.name,
                    thumbnail_url: URL.createObjectURL(file),
                    origin_url: URL.createObjectURL(file),
                    file_type: FILE_CODE.img,
                    folder_id: folder.folder_id,
                }
    
                return result;
            });
        }
        setPreviewUrls(urls);
    }, [imgFiles, folder])

    return(
        <ImageGalleryContainer class_name_list={[CSS.l_UploadImageContainer_main]}>
            <div className={CSS.l_UploadImageContainer_header}>
                <div className={CSS.l_UploadImageContainer_header_left}>
                    <ListCardHeaderText
                        header_text={`${folder.folder_name} ${previewUrls.length}`}
                        copy_function_target_text={`[${folder.folder_name}]`}
                        is_view_essential_icon={true}
                        isSatisfiedPhotoNums={isSatisfiedPhotoNums && isSatisfiedPhotoNums[folder.folder_id]}
                    />

                    <Text
                        size={"size2"}
                        color={"gray2"}
                    >
                        {howManySelectPictuerText(needPhotoNum)}
                    </Text>

                    {
                        folder.middle_img_url
                        &&  <OvalIconButton
                                preset={"detail_view"}
                                onClick={overlayOpenHandler.bind(null, folder.middle_img_url)}
                            />
                    }
                </div>

                <div className={CSS.l_UploadImageContainer_header_right}>
                    <OvalIconButton
                        preset={"delete"}
                        onClick={handleRemoveAll.bind(null, folder)}
                    />
                    <OvalIconButton
                        preset={"upload"}
                        onClick={handleClick}
                    />
                </div>
            </div>

            <DragAndDrop
                onDropHandler={onDropHandler}
                innerText="여기에 업로드 하고 싶은 사진을 드래그 해 보세요"
                isLoading={isUploading}
            >
                {
                    previewUrls.length > 0
                    &&  <Flex>
                            {
                                previewUrls.map((item, index) => {
                                    return(
                                        <UploadImagePhotoEle
                                            key={item.origin_url}
                                            img_src={item.origin_url}
                                            img_name={item.file_name}
                                            deleteIconClickHandler={handleRemove.bind(null, index, item.folder_id)}
                                            copy_function_target_text={`[${folder.folder_name} - ${item.file_name}]`}
                                            copy_message={"파일명이 클립보드에 추가되었습니다."}
                                        />
                                    )
                                })
                            }
                        </Flex>
                }
            </DragAndDrop>

            <input 
                type="file"
                multiple
                onChange={handleFileChange}
                ref={uploadInputRef}
                style={{
                    display: "none"
                }}
                // 개발을 위해 임시적으로 flutter 환경이라면 모든 이미지를 업로드 할 수 있게 처리
                accept={is_flutter_div_mode ? "image/*" : ".jpg"}
            />

            <FolderImageOverlay 
                isOverlayOpen={isOverlayOpen}
                overlayCloseHandler={overlayCloseHandler}
                folderDetailImgSrc={folderDetailImgSrc}
            />
        </ImageGalleryContainer>
    )
}

export default UploadImageContainer;