import CSS from "./static/css/MonthlyScheduleCalendar.module.css";
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import CustomFullCalendar from "../../../../Component/Elements/CustomFullCalendar/CustomFullCalendar"
import NoSchedule from "../elements/NoSchedule/NoSchedule";
import { fullCalendarEventInterface } from "../../../../Component/Elements/CustomFullCalendar/interface/CustomFullCalendar.interface";
import { dateObjectManagerClass } from "../../../../Common/ts/commonTools";
import MonthlyScheduleListItem from "../MonthlyScheduleListItem/MonthlyScheduleListItem";

const dateObjectManager = new dateObjectManagerClass();

const MonthlyScheduleCalendar = (
    {
        fullCalendarEventList,
        selectedDate, 
        setSelectedDate,
        setStartDate,
        setEndDate,
        viewingDate,
        setViewingDate,
    }:{
        fullCalendarEventList: fullCalendarEventInterface[];
        selectedDate: Date | null;
        setSelectedDate: Dispatch<SetStateAction<Date | null>>;
        setStartDate: Dispatch<SetStateAction<Date | null>>;
        setEndDate: Dispatch<SetStateAction<Date | null>>;
        viewingDate: Date | null;
        setViewingDate: Dispatch<SetStateAction<Date | null>>;
    }
) => {
    // 선택한 날짜에 있는 일정목록
    const [selectedDateScheduleList, setSelectedDateScheduleList] = useState<fullCalendarEventInterface[]>([]);

    useEffect(() => {
        if(selectedDate){
            const m_selectedDate_RemovedTime = dateObjectManager.removeTime(selectedDate);
            
            const m_ScheduleList = fullCalendarEventList.filter((item) => {
                const m_item_date = new Date(item.start);
                const m_item_RemovedTime = dateObjectManager.removeTime(m_item_date);
                
                if(m_item_RemovedTime && m_selectedDate_RemovedTime){
                    return (m_item_RemovedTime.getTime() === m_selectedDate_RemovedTime.getTime())
                }else{
                    return false;
                }
            })
            

            setSelectedDateScheduleList(m_ScheduleList);
        }else{
            setSelectedDateScheduleList([]);
        }
    }, [fullCalendarEventList, selectedDate])
    
    return(
        <div className={CSS.l_MonthlyScheduleCalendar_main}>
            <div className={CSS.l_MonthlyScheduleCalendar_calendarContainer}>
                <CustomFullCalendar
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    events={fullCalendarEventList}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    viewingDate={viewingDate}
                    setViewingDate={setViewingDate}
                />
            </div>

            <div className={CSS.l_MonthlyScheduleCalendar_scheduleContainer}>
                {
                    selectedDateScheduleList.length > 0
                    ?   selectedDateScheduleList.map((item) => {
                            return(
                                <MonthlyScheduleListItem 
                                    date={item.start}
                                    start_time={item.time || ""}
                                    shoot_title={item.title}
                                    type={item.type}
                                />
                            )
                        })
                    :   <NoSchedule />
                }
            </div>
        </div>
    )
}

export default MonthlyScheduleCalendar;