import { useEffect } from "react";
import ImageZipDownload from "../ImageZipDownload/ImageZipDownload";
import useUploadDate from "../../hooks/useUploadDate.hook";
import useDateString from "../../../../Common/hooks/useDateString.hook";
import useExpirationDate from "../../hooks/useExpirationDate.hook";
import Text from "../../../../Component/Elements/Text/Text";
import CSS from "./static/css/DownloadBtnContainer.module.css";

const DownloadBtnContainer = (
    {
        download_type,
        file_name,
        upload_date,
        photo_upload_id,
    }:{
        download_type: number;
        file_name: string;
        upload_date: string;
        photo_upload_id: number;
    }
) => {
    const { downloadExpirationDate } = useUploadDate(upload_date);
    const { dateString: downloadExpirationDateString, setOriginDateData} = useDateString(downloadExpirationDate);
    const { isExpirationDate, setDateData } = useExpirationDate(downloadExpirationDate);

    useEffect(() => {
        setOriginDateData(downloadExpirationDate);
        setDateData(downloadExpirationDate);
    }, [downloadExpirationDate])
    
    return (
        <div className={CSS.l_download_btn_container_main}>
            {
                !isExpirationDate
                &&  <ImageZipDownload
                        download_type={download_type}
                        file_name={file_name}
                        photo_upload_id={photo_upload_id}
                    />
            }
            {
                downloadExpirationDateString
                &&  <Text color={"secondary"}>
                        {`${downloadExpirationDateString}까지 다운로드 가능`}
                    </Text>
            }
        </div>
    )
}

export default DownloadBtnContainer;