import { useLocation, useNavigate } from "react-router-dom";
import { whoAmI } from "../../../../Common/ts/commonTools";
import ExtensionItem from "../ExtensionItem/ExtensionItem";
import CSS from "./static/css/ExtensionContainer.module.css"
import { backPointUrlManagerClass, logout } from "../../../../Common/ts/appTools";
import { useEffect, useState } from "react";

const backPointUrlManager = new backPointUrlManagerClass();

const ExtensionContainer = (
    {
        is_extension,
    }:{
        is_extension: boolean;
    }
) => {
    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    const navigate = useNavigate();
    const location = useLocation();
    
    function classNameSetter(){
        const RTN: string[] = [];

        RTN.push(CSS.l_ExtensionContainer_main);

        if(is_extension){
            RTN.push(CSS.extension);
        }

        return RTN;
    }

    const goToMyPageHandler = () => {
        const m_url = backPointUrlManager.addSelfAddBackPointQueryParams({
            url: "/myPage",
            location
        })

        navigate(m_url);
    }

    const goToChangePasswordHandler = () => {
        navigate("/changePassword");
    }

    const logoutHandler = () => {
        logout();
    }

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "));
    }, [is_extension])
    
    return(
        <div className={mainClassName}>
            {
                whoAmI.isCustomer()
                &&  <ExtensionItem 
                        text={"마이페이지"}
                        onClick={goToMyPageHandler}
                    />
            }

            <ExtensionItem 
                text={"로그아웃"}
                onClick={logoutHandler}
            />
        </div>
    )
}

export default ExtensionContainer;