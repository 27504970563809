import { usePhotoDetailDataSetter_shootData_interface } from "../../static/interface/usePhotoDetailDataSetter.interface";
import ShootPhotoInfoCard from "./ShootPhotoInfoCard/ShootPhotoInfoCard";

const PhotoInfoCard = (
    {
        shoot_data,
    }:{
        shoot_data: usePhotoDetailDataSetter_shootData_interface;
    }
) => {
    return(                
        <ShootPhotoInfoCard
            title={shoot_data.title}
            contract_info={shoot_data.contractInfo}
            shoot_date={shoot_data.photoShootTime.date}
            shoot_start_time={shoot_data.photoShootTime.start_time}
            shoot_end_time={shoot_data.photoShootTime.end_time}
            time_length={shoot_data.photoShootTime.length}
            office={shoot_data.officeName}
        />
    );
}

export default PhotoInfoCard;