import CSS from "./static/css/EditRequestTextArea.module.css";
import LabelAndContent from "../../../../Component/Elements/LabelAndContent/LabelAndContent";
import Textarea from "../../../../Component/Elements/Textarea/Textarea";
import { EXAMPLE_TEXT_LIST } from "../../../../Common/ts/const";
import { ChangeEvent, Dispatch, SetStateAction, useCallback } from "react";

const EditRequestTextArea = (
    {
        request,
        setRequest,
        is_request_view_essential_icon,
    }:{
        request: string;
        setRequest: Dispatch<SetStateAction<string>>;
        is_request_view_essential_icon?: boolean;
    }
) => {
    const changeHandler = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
        setRequest(event.target.value);
    }, [])

    return(
        <LabelAndContent
            label="편집 요청 사항 작성"
            flex_direction={"column"}
            is_view_essential_icon={is_request_view_essential_icon}
        >
            <div className={CSS.l_EditRequestTextArea_textareaContainer}>
                <Textarea 
                    value={request}
                    onChange={changeHandler}
                    placeholder={EXAMPLE_TEXT_LIST.join(`\n`)}
                />
            </div>
        </LabelAndContent>
    )
}

export default EditRequestTextArea;