import { ReactNode } from "react";
import { addHyphens } from "../../../../Common/ts/commonTools";
import LabelAndContent from "../../../../Component/Elements/LabelAndContent/LabelAndContent";

const AddressInfoView = (
    {
        headerNode,
        recipient_name,
        recipient_phone,
        zip_code,
        addr,
        addr_detail,
    }:{
        headerNode?: ReactNode;
        recipient_name: string;
        recipient_phone: string;
        zip_code: string;
        addr: string;
        addr_detail: string;
    }
) => {
    return(
        <LabelAndContent
            headerNode={headerNode}
            flex_direction={"column"}
        >
            <LabelAndContent 
                label="받는이"
                content={[recipient_name]}
            />
            <LabelAndContent 
                label="연락처"
                content={[addHyphens(recipient_phone)]}
            />
            <LabelAndContent 
                label="주소"
                content={`(${zip_code}) ${addr} ${addr_detail}`}
                is_content_text_truncation_on={false}
            />
        </LabelAndContent>
    )
}

export default AddressInfoView;