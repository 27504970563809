import { useEffect, useState } from "react";
import CSS from "./static/css/DetailIcon.module.css";
import detail_icon_img from "./static/img/detail_icon_24@2x.png";
import { isHiddenType } from "../../../../Common/interface/Common.interface";
import { isHiddenClassNameSetter } from "../../../../Common/ts/commonTools";

const DetailIcon = (
    {
        onClick,
        size,
        isHidden,
    }:{
        onClick?: () => void;
        size?: "large" | "small" | "full_heigth"
        isHidden?: isHiddenType;
    }
) => {
    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    function classNameSetter(){
        let RTN: string[] = [];

        if(onClick){
            RTN.push("g_click_event_item");
        }

        if(isHidden){
            RTN = isHiddenClassNameSetter({
                classNameList: RTN,
                isHidden
            })
        }

        RTN.push(CSS.l_DetailIcon_main);
        
        if(size){
            RTN.push(CSS.size);
            RTN.push(CSS[size]);
        }

        return RTN;
    }

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "));
    }, [size, isHidden, onClick])

    return(
        <div 
            className={mainClassName}
            onClick={onClick}
        >
            <img 
                className={CSS.l_DetailIcon_img}
                src={detail_icon_img}
            />
        </div>
    )
}

export default DetailIcon;