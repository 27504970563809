import { useMediaQuery } from "react-responsive";
import PayDate from "../elements/ContractDate/ContractDate";
import ContractId from "../elements/ContractId/ContractId";
import ProductName from "../elements/ProductName/ProductName";
import CSS from "./static/css/PaymentInfoItem.module.css"
import Hr from "../../../../Component/Elements/Hr/Hr";
import ContractAmountDetailPart from "../elements/ContractAmountDetailPart/ContractAmountDetailPart";
import MorePayInfo from "../elements/MorePayInfo/MorePayInfo";
import { MouseEventHandler } from "react";
import ContractDocsImgViewIconBtn from "../ContractDocsImgViewIconBtn/ContractDocsImgViewIconBtn";

const PaymentInfoItem = (
    {
        contractDate,
        contractId,
        productName,
        contractTotamount,
        contractBalance,
        contractPayamount,
        contractImageList,
        is_extended,
        extendBtnHandler,
        is_has_payment_detail_list,
    }:{
        contractDate: string;
        contractId: string;
        productName: string;
        contractTotamount: string;
        contractBalance: string;
        contractPayamount: string;
        contractImageList: string[];
        is_extended: boolean;
        extendBtnHandler?: MouseEventHandler<HTMLDivElement>;
        is_has_payment_detail_list: boolean;
    }
) => {
    const isMobile = useMediaQuery({ maxWidth: 700 });

    return(
        <div className={CSS.l_PaymentInfoItem_main}>
            <PayDate className={CSS.l_PayDate}>
                {contractDate}
            </PayDate>

            {
                !isMobile
                &&  <ContractId className={CSS.l_ContractId}>
                        {contractId}
                    </ContractId>
            }

            <ProductName className={CSS.l_ProductName}>
                {
                    isMobile
                    ?   `[${contractId}]${productName}`
                    :   productName
                }
            </ProductName>
            
            <ContractDocsImgViewIconBtn 
                className={CSS.l_DescriptionIcon}
                contractImageList={contractImageList}
            />

            {
                isMobile
                &&  <Hr class_name_list={[CSS.l_SplitBar]} />
            }

            <ContractAmountDetailPart
                className={CSS.l_ContractTotamount}
                label="총액"
                content={contractTotamount}
            />

            <ContractAmountDetailPart 
                className={CSS.l_ContractPayamount}
                label="결제액"
                content={contractPayamount}
            />

            <ContractAmountDetailPart 
                className={CSS.l_ContractBalance}
                label="잔액"
                content={contractBalance}
            />

            {
                !isMobile
                &&  <MorePayInfo
                        label={"결제 내역 더 보기"}
                        className={CSS.l_MorePayInfo}
                        is_extended={is_extended}
                        onClick={extendBtnHandler}
                        is_has_payment_detail_list={is_has_payment_detail_list}
                    />
            }
        </div>
    )
}

export default PaymentInfoItem;