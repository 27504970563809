import { forwardRef, useCallback, useEffect, useState } from "react";
import { schedulePhotoProductThemeInterface, themaInterface, themeItemOnClickHandlerType } from "../../static/interface/selectTheme.interface";
import CSS from "./static/css/ThemeList.module.css";
import Text from "../../../../Component/Elements/Text/Text";
import AllThemeList from "../AllThemeList/AllThemeList";
import EmptyData from "../../../../Component/Elements/EmptyData/EmptyData";
import ThemaDetailPageOverlay from "../../../../PublicPage/ThemeBook/components/ThemaDetailPageOverlay/ThemaDetailPageModal";
import { findNextTheme } from "../../../../PublicPage/ThemeBook/static/ts/ThemeBook.tools";
import useHistoryBackContralOverlayState from "../../../../Component/Elements/Overlay/hooks/useHistoryBackContralOverlayState.hook";

interface themeListInterface{
    isReadOnly: boolean;
    schedule_photo_product: schedulePhotoProductThemeInterface | null; // SelectTheme페이지에서 별도로 테마를 선택하는 기능 구현을 위해 null허용이 필요했다.
    addThemeHandler: themeItemOnClickHandlerType;
    subThemeHandler: themeItemOnClickHandlerType;
    office_name: string;
    mobileGoToThemeInfoPage?: () => void;
}

const ThemeList = forwardRef<HTMLDivElement, themeListInterface>((
    {
        isReadOnly,
        schedule_photo_product,
        addThemeHandler,
        subThemeHandler,
        office_name,
        mobileGoToThemeInfoPage
    }, ref
) => {    
    const [themeList, setThemeList] = useState<themaInterface[]>([]);
    const [detailTheme, setDetailTheme] = useState<themaInterface | null>(null);
    const [themeIndex, setThemeIndex] = useState<number>(0);

    const [themeMax, setThemeMax] = useState<number>(0);

    const [label, setLabel] = useState<string>("");

    const [nodeState, setNodeState] = useState<number>(0);

    const {
        isOpen,
        overlayHandler,
    } = useHistoryBackContralOverlayState(false);

    const detailModalOpenHandler = (theme: themaInterface) => {
        FindIndex:{
            if(themeList.length === 0){
                break FindIndex;
            }

            const m_theme_index = themeList.findIndex((item) =>
                item.thema_id === theme.thema_id
            )

            if(m_theme_index < 0){
                break FindIndex;
            }

            setThemeIndex(m_theme_index)
            overlayHandler(true);
        }
    }

    const detailModalCloseHandler = useCallback(() => {
        overlayHandler(false);
    }, [])

    const prevThemeSettingHandler = () => {
        const result = findNextTheme({
            theme_index: themeIndex, 
            theme_list: themeList,
            diraction: "prev",
        });

        if(result.sign){
            setThemeIndex(result.data)
        }
    }

    const nextThemeSettingHandler = () => {
        const result = findNextTheme({
            theme_index: themeIndex, 
            theme_list: themeList,
            diraction: "next",
        });

        if(result.sign){
            setThemeIndex(result.data)
        }
    }

    useEffect(() => {
        if(themeList.length > themeIndex){
            setDetailTheme(themeList[themeIndex]);
        }
    }, [themeIndex, themeList])

    useEffect(() => {
        ThemeListSetting:{
            if(!schedule_photo_product){            
                break ThemeListSetting;
            }
            
            if(isReadOnly){
                setThemeList(schedule_photo_product.selected_theme_list);
            }else{
                setThemeList(schedule_photo_product.theme_list);
            }
        }
    }, [isReadOnly, schedule_photo_product])

    useEffect(() => {
        let label_text:string = `${schedule_photo_product?.photo_name} 촬영`;
        const theme_max = parseInt(schedule_photo_product?.theme_num || "0");

        if(isNaN(theme_max)){
            setThemeMax(0);
        }else{
            setThemeMax(theme_max);
        }

        let state: number = 0;

        let m_theme_list: themaInterface[] = [];
        if(isReadOnly){
            m_theme_list = schedule_photo_product ? schedule_photo_product.selected_theme_list : [];
        }else{
            m_theme_list = schedule_photo_product ? schedule_photo_product.theme_list : [];
            label_text += ` (${schedule_photo_product?.selected_theme_list.length} / ${themeMax})`
        }

        if(m_theme_list.length > 0){
            state = 1;
        }else{
            state = 2;
        }

        setLabel(label_text);
        setNodeState(state);
    }, [schedule_photo_product, isReadOnly, themeMax])

    return (
        <div 
            className={CSS.l_theme_list_main}
            ref={ref}
        >
            <div className={CSS.l_expandable_component_main}>
                <div className={CSS.l_button_container}>
                    <Text
                        preset={"select_theme_shoot_title"}
                        bold={true}
                    >
                        {label}
                    </Text>
                </div>
                <div className={CSS.l_node_container}>
                    {
                        nodeState === 1 && schedule_photo_product
                        ?   <AllThemeList
                                theme_list={
                                    isReadOnly
                                    ?   schedule_photo_product.selected_theme_list
                                    :   schedule_photo_product.theme_list
                                }
                                selected_theme_list={schedule_photo_product.selected_theme_list}
                                schedule_photo_product_id={schedule_photo_product.schedule_photo_product_id}
                                addThemeHandler={addThemeHandler}
                                subThemeHandler={subThemeHandler}
                                detailModalOpenHandler={detailModalOpenHandler}
                                themeMax={themeMax}
                                isReadOnly={isReadOnly}
                                mobileGoToThemeInfoPage={mobileGoToThemeInfoPage}
                            />
                        :   nodeState === 2
                            ?   <EmptyData />
                            :   null
                    }
                </div>
            </div>

            <ThemaDetailPageOverlay
                isOverlayOpen={isOpen}
                overlayCloseHandler={detailModalCloseHandler}
                photo_name={detailTheme?.photo_name || ""}
                title={detailTheme?.thema_name || ""}
                office_name={office_name || ""}
                content={detailTheme?.content || ""}
                office_id={0}
                thema_id={detailTheme?.thema_id || 0}
                type={0}
                // 이전, 다음테마로 이동이 가능할때만 버튼을 노출시키기 위해 처리
                // 이 조건을 제거하고 헨들러 함수를 무조건적으로 할당할경우, 버튼은 순환방식으로 동작하게된다.
                prevThemeHandler={
                    themeIndex > 0 
                    ?   prevThemeSettingHandler 
                    :   undefined
                }
                nextThemeHandler={
                    themeIndex < themeList.length - 1
                    ?   nextThemeSettingHandler
                    :   undefined
                }
            />
        </div>
    )
})

export default ThemeList;