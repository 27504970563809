import { Location, NavigateFunction } from "react-router-dom";
import { userDataStorageManagerClass } from "../../CustomerPage/ProductManufacturing/static/ts/common.tools";
import { getDeviceToken_interface, RTN_interface } from "../interface/App.interface";
import { customParseInt, dev_console, getAccountData } from "./commonTools";
import { sign } from "crypto";

export const totalLogout = () => {
    deleteAllCookies();
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
}

export const logout = () => {
    deleteAllCookies();
    sessionStorage.clear();
    window.location.href = "/";
}

export class ActiveRequestsManager_Class {
    public activeRequestsRef: React.MutableRefObject<string[]>;

    constructor(activeRequestsRef: React.MutableRefObject<string[]>){
        this.activeRequestsRef = activeRequestsRef;
    }

    add(path: string) {
        if (path) {
            const list = [...this.activeRequestsRef.current];
            list.push(path);
            this.activeRequestsRef.current = list;
        } else {
            console.error("path를 입력해주세요.");
        }
    }

    sub(path: string) {
        if (path) {
            const list = [...this.activeRequestsRef.current];
            this.activeRequestsRef.current = list.filter((item) => item !== path);
        } else {
            console.error("path를 입력해주세요.");
        }
    }

    clear() {
        this.activeRequestsRef.current = [];
    }

    status() {
        return this.activeRequestsRef.current.length > 0;
    }
}

// 쿠키를 설정하는 함수
export const setCookie = (
    {
        name, 
        value, 
        days,
        path,
    }:{
        name: string;
        value: string;
        days?: number;
        path?: string;
    }
) => {
    let path_value = "/";
    if(path){
        path_value = path
    }

    const cookie_parts: string[] = [
        `${name}=${encodeURIComponent(value)}`, // 값 인코딩
        `path=${path_value}`
    ];

    // HTTPS 환경에서만 'secure' 추가
    if (window.location.protocol === 'https:') {
        cookie_parts.push("secure")
        cookie_parts.push("SameSite=Strict")
    }else{
        cookie_parts.push("SameSite=Lax")
    }
    
    if (days !== undefined && days > 0) {
        const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
        cookie_parts.push(`expires=${expires}`)
    }
    
    document.cookie = cookie_parts.join("; ");
}

// 쿠키값을 가져옴
export const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return decodeURIComponent(parts[1].split(';').shift() || ""); // 값 디코딩
    }
    return null;
}

// 특정 쿠키 제거 함수
export const deleteCookie = ({name, path}:{name: string; path?: string;}) => {
    let path_value = '/';
    if(path){
        path_value = path;
    }

    const cookie_parts: string[] = [`${name}=`, `path=${path_value}`, "expires=Thu, 01 Jan 1970 00:00:00 GMT"];

    // HTTPS 환경에서만 'secure' 추가
    if (window.location.protocol === 'https:') {
        cookie_parts.push("secure")
        cookie_parts.push("SameSite=Strict")
    }else{
        cookie_parts.push("SameSite=Lax")
    }

    document.cookie = cookie_parts.join("; ");
};

// 모든 쿠키 제거 함수
export const deleteAllCookies = () => {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.slice(0, eqPos) : cookie;

        deleteCookie({name, path: "/"})
    }
};

export class localStorageManagerClass{
    parent: userDataStorageManagerClass;
    user_id: number;
    user_login_id: string

    constructor(parent: userDataStorageManagerClass) {
        this.parent = parent;
        this.user_id = getAccountData.user_id();
        this.user_login_id = getAccountData.oops_id();
    }

    set<T>(
        {
            key,
            data,
        }:{
            key: string, 
            data: T
        }
    ){
        try{
            const m_key:string = `${this.user_id}-${this.user_login_id}-${key}`;
            const m_string_data = JSON.stringify(data);

            if(m_string_data.length > 0){
                localStorage.setItem(m_key, m_string_data);

                return({
                    key: m_key,
                    data,
                })
            }else{
                return null;
            }
        }catch(error){
            dev_console.error(error);
            return null;
        }
    }

    get<T>(key: string): T | null{
        try{
            const m_key:string = `${this.user_id}-${this.user_login_id}-${key}`;
            const m_reuslt_data = localStorage.getItem(m_key);
    
            if(m_reuslt_data){
                return (JSON.parse(m_reuslt_data));
            }else{
                return null;
            }
        }catch(error){
            dev_console.error(error);
            return null;
        }
    }

    remove(key: string){
        try{
            const m_key:string = `${this.user_id}-${this.user_login_id}-${key}`;
            localStorage.removeItem(m_key);
            return true;
        }catch(error){
            dev_console.error(error);
            return false;
        }
    }
}

// 쿼리파라미터로 넘어오는 값이 존재하는지 검증하고, 정해진 code범위인지 확인하는 함수
// T : code_data에서 다루는 값의 자료형
// query: 확인하려는 쿼리파라미터 값
// code_data: query로 사용 가능한 값 정의 상수객체
export const getCodeQueryParams = <T>(
    query: string | null | undefined, 
    code_data: Record<string, T>,
) => {
    // 쿼리파라미터가 존재하는지 확인하고, 존재하지 않으면 null을 반환한다.
    if(!query){
        return null;
    }

    // code_data의 항목이 문자열인지 숫자인치 확인하기위해 첫 번째 항목을 추출하여 타입을 확인한다.
    const m_valuse = Object.values(code_data);
    if(m_valuse.length === 0){
        return null;
    }

    const m_first_value = m_valuse[0];

    // query를 비교하는 code_data값의 자료형에 맞게 변환단다.
    let m_query_value: number | string | null = null;
    if(typeof m_first_value === "number"){
        m_query_value = customParseInt(query);
        if(m_query_value === null){
            return null;
        }
    }else if(typeof m_first_value === "string"){
        m_query_value = query;
    }else{
        // 문자열, 숫자 외의 경우는 예외처리
        return null;
    }

    // 변환된 값이 code_data에 포함되어 있는지 확인한다.
    if(Object.values(code_data).includes(m_query_value as T)){
        return m_query_value as T;
    }else{
        return null;
    }
}

// flutter와 통신하기위해 사용하는 클래스
export class webViewBridgeClass{
    flutterInAppWebView: FlutterInAppWebView | undefined;

    constructor(){
        this.flutterInAppWebView = window.flutter_inappwebview;
    }

    async getAuthToken(){
        const RTN: RTN_interface<null | getDeviceToken_interface> = {
            sign: false,
            data: null,
            msg: "",
        }

        if(this.flutterInAppWebView){
            let m_response: null | getDeviceToken_interface = null;

            try{
                m_response = await this.flutterInAppWebView.callHandler("getAuthToken");

                RTN.data = m_response;
                RTN.sign = true;
                RTN.msg = "success";
            }catch(error){
                dev_console.error(error, "error when get getAuthToken handler function from flutter");

                RTN.data = null;
                RTN.sign = false;
                RTN.msg = `${error}`;
            }
        }else{
            RTN.sign = false;
            RTN.data = null;
            RTN.msg = "flutterInAppWebView is NULL"
        }

        dev_console.log("webViewBridgeClass getAuthToken RTN ===>", RTN);

        return RTN;
    }

    async setDownloadUrl(pull_url: string){
        const RTN: {
            sign: boolean;
            msg: string;
        } = {
            sign: true,
            msg: "",
        }

        setDownloadUrl:{
            if(!this.flutterInAppWebView){
                RTN.sign = false;
                RTN.msg = "flutterInAppWebView is NULL";

                break setDownloadUrl;
            }

            const m_response = await this.flutterInAppWebView.callHandler("setDownloadUrl", {
                url: pull_url
            });

            if(!m_response){
                RTN.sign = false;
                RTN.msg = "setDownloadUrl response Error";

                break setDownloadUrl;
            }

            RTN.sign = true;
            RTN.msg = "success";
        }

        dev_console.log("webViewBridgeClass setDownloadUrl RTN ===>", RTN);

        return RTN;
    }

    async checkAppVersion(app_version: string){
        if(this.flutterInAppWebView){
            await this.flutterInAppWebView.callHandler("getAuthToken", {
                latestAppVersion: app_version
            });
        }
    }
}

export class backPointUrlManagerClass{
    // 주소문자열 혹은 location객체를 받아서 인코딩된 문자열을 반환한다.
    encoded(
        {
            url,
            location,
        }:{
            url?: string;
            location?: Location;
        }
    ){
        let RTN: string = "";

        Encode:{
            if(url){
                RTN = url;
                break Encode;
            }
            
            if(location){
                RTN = `${location.pathname}${location.search}`;
                break Encode;
            }
        }

        RTN = encodeURIComponent(RTN);

        return RTN;
    } // encoded

    // 인코딩된 문자열을 디코딩한후 반환한다.
    decoded(url: string){
        let RTN: string = "";

        Decode:{
            if(url){
                RTN = encodeURIComponent(url);
                break Decode;
            }
        }

        return RTN;
    } // decoded

    // 현재 주소의 쿼리파라미터에서 back_point값을 추출하여 디코딩한 후 반환한다.
    getBackPointUrlAndDecode(URLSearchParams: URLSearchParams){
        let RTN: string = "";

        BackPointGet:{
            if(!URLSearchParams){
                break BackPointGet;
            }

            const m_back_point = URLSearchParams.get("back_point");

            if(!m_back_point){
                break BackPointGet;
            }

            RTN = decodeURIComponent(m_back_point);
        }

        return RTN;   
    } // getBackPointUrlAndDecode

    // 주소에 붙어있는 push_id값을 추출한다.
    getPushId(URLSearchParams: URLSearchParams){
        let RTN: string = "";

        PushIdGet:{
            if(!URLSearchParams){
                break PushIdGet;
            }

            const m_push_id = URLSearchParams.get("push_id");

            if(!m_push_id){
                break PushIdGet;
            }

            RTN = decodeURIComponent(m_push_id);
        }

        return RTN; 
    } // getPushId

    // 원하는주소에 현재주소를 back_point로 붙여준다
    // 뒤로가기에 활용된다.
    addSelfAddBackPointQueryParams(
        {
            url,
            location,
        }:{
            url: string;
            location: Location;
        }
    ){
        let RTN: string = "";
        
        //  메인 주소 세팅
        const m_main_url: string = `${location.pathname}${location.search}`;

        // 구분자 설정
        let m_delimiter: string = "";
        SetDelimiter:{
            if(!url){
                break SetDelimiter;
            }

            const m_url_parts = url.split("?");
            const m_url_parts_length = m_url_parts.length;

            if(m_url_parts_length === 1){
                m_delimiter = "?";
                break SetDelimiter;
            }

            if(m_url_parts_length === 2){
                m_delimiter = "&";
                break SetDelimiter;
            }
        }

        if(m_main_url && m_delimiter){
            RTN = `${url}${m_delimiter}back_point=${this.encoded({url: m_main_url})}`;
        }

        return RTN;
    } // addBackPointQueryParams
}

export class appVersionManagerClass{
    latestAppVersion = process.env.REACT_APP_LATEST_APP_VERSION || "";

    #stringSplitToNumberList = (
        {
            str,
            separator = "."
        }:{
            str: string,
            separator?: string
        }
    ) => {
        const RTN: number[] =   str
                                .split(separator)
                                .map((item) => {
                                    const m_number_data = customParseInt(item);
    
                                    let RTN: number = 0;
    
                                    if(typeof m_number_data === "number"){
                                        RTN = m_number_data;
                                    }
    
                                    return RTN;
                                });
        
        return RTN;
    }

    // app_version이 최신버전인지 검증하는 함수
    isLatestAppVersion(app_version: string){

        const RTN: RTN_interface<boolean | null> = {
            sign: false,
            data: null,
            msg: "",
        }

        const m_latest_app_version = this.latestAppVersion;
        
        let m_latest_app_version_split: number[] = [];
        let m_app_version_split: number[] = [];

        GetLatestAppVersion:{
            if(!m_latest_app_version){
                RTN.msg = "can't read REACT_APP_LATEST_APP_VERSION env";
                
                break GetLatestAppVersion;
            }

            m_latest_app_version_split = this.#stringSplitToNumberList({str: m_latest_app_version});
            m_app_version_split = this.#stringSplitToNumberList({str: app_version});

            RTN.sign = true;
        }

        CheckData: {
            if(!RTN.sign){
                break CheckData;
            }

            if(m_latest_app_version_split.length === 0){
                RTN.sign = false;
                RTN.msg = "REACT_APP_LATEST_APP_VERSION env length 0";
                break CheckData;
            }

            if(m_app_version_split.length === 0){
                RTN.sign = false;
                RTN.msg = "device app version length 0";
                break CheckData;
            }
        }

        if(RTN.sign){
            let m_index: number = 0;

            RTN.data = true;
            RTN.msg = "success"

            while(true){
                if(m_latest_app_version_split.length <= m_index || m_app_version_split.length <= m_index){
                    RTN.data = m_latest_app_version_split.length >= m_app_version_split.length

                    break;
                }

                const m_latest_version_number: number = m_latest_app_version_split[m_index];
                const m_app_version_number: number = m_app_version_split[m_index];

                if(m_latest_version_number === m_app_version_number){
                    continue;
                }

                if(m_latest_version_number > m_app_version_number){
                    RTN.data = false;
                    break ;
                }

                m_index += 1;
            }
        }else{
            dev_console.error(RTN.msg);
        }

        return RTN.data;
    }
}