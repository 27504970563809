import CardContainer from "../CardContainer/CardContainer";
import Hr from "../Hr/Hr";
import UploadImageContainerViewMode from "../../../CustomerPage/ProductManufacturing/components/UploadImageContainerViewMode/UploadImageContainerViewMode";
import { uploadImageListViewMode_folderInfo_Interface } from "./static/interface/UploadImageListViewMode.interface";

const UploadImageListViewMode = (
    {
        folderInfoList,
        isComfrimMode,
        isRemoveOutline,
        isSingle
    }:{
        folderInfoList: uploadImageListViewMode_folderInfo_Interface[];
        isComfrimMode?: boolean;
        isRemoveOutline?: boolean;
        isSingle: boolean;
    }
) => {
    return(
        <CardContainer 
            preset={"SelectProduct_SelectArea"}
            padding={(isSingle || isRemoveOutline) ? "none" : undefined}
            outline={(isSingle || isRemoveOutline) ? "none" : undefined}
            borderRadius={(isSingle || isRemoveOutline) ? "radius0" : undefined}
        >
            {
                folderInfoList.length > 0
                &&  folderInfoList.map((item, index) => {
                        return(
                            <div 
                                key={item.folder_id}
                                // FolderImageViewer컴포넌트에서 읽어서 사용한다.
                                data-folder-id={item.folder_id}
                            >
                                <UploadImageContainerViewMode
                                    folder_name={item.folder_name}
                                    folder_middle_img_url={item.middle_img_url}
                                    initialImgList={item.img_list}
                                    isComfrimMode={isComfrimMode}
                                    isSingle={isSingle}
                                />

                                {
                                    // 조금 고전적인 방식이지만, 사이에 줄 삽입
                                    folderInfoList.length > index + 1
                                    &&  <Hr />
                                }
                            </div>
                        )
                    })
            }
        </CardContainer>
    )
}

export default UploadImageListViewMode;