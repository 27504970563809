import CSS from "./static/css/TriangleIcon.module.css";
import { MouseEventHandler, useEffect, useState } from "react";

const TriangleIcon = (
    {
        onClick,
        direction,
        color,
        size,
    }:{
        onClick?: MouseEventHandler<HTMLDivElement>;
        direction?: "top" | "right" | "bottom" | "left";
        color?: "black" | "gray";
        size?: "small" | "middle";
    }
) => {
    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    function classNameSetter(){
        const RTN: string[] = [];

        if(onClick){
            RTN.push("g_click_event_item");
        }

        RTN.push(CSS.l_TriangleIcon_main);

        if(size){
            RTN.push(CSS.size);
            RTN.push(CSS[size]);
        }

        if(color){
            RTN.push(CSS.color);
            RTN.push(CSS[color]);
        }

        if(direction){
            RTN.push(CSS.direction);
            RTN.push(CSS[direction]);
        }

        return RTN;
    }

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "))
    },[onClick, direction, color, size])

    return(
        <div 
            className={mainClassName} 
            onClick={onClick}    
        />
    )
}

export default TriangleIcon;