import LabelAndContent from "../../../../../Component/Elements/LabelAndContent/LabelAndContent";
import LabelAndContentContainer from "../../../../../Component/Elements/LabelAndContentContainer/LabelAndContentContainer";
import MainInfoCard from "../../../../../Component/Elements/MainInfoCard/MainInfoCard";
import DdayIcon from "../../../../Reservation/components/DdayIcon/DdayIcon";
import useShootPhotoInfoCardSetter from "./hook/useShootPhotoInfoCardSetter.hook";

const ShootPhotoInfoCard = (
    {
        title,
        contract_info,
        shoot_date,
        shoot_start_time,
        shoot_end_time,
        time_length,
        office,
    }:{
        title: string;
        contract_info: string;
        shoot_date: string;
        shoot_start_time: string;
        shoot_end_time: string;
        time_length: number;
        office: string;
    }
) => {
    
    const {d_day} = useShootPhotoInfoCardSetter({
        shoot_date
    })

    return(
        <MainInfoCard
            header={d_day && <DdayIcon d_day={d_day} />}
            title={title}
        >
            <LabelAndContentContainer>
                {
                    contract_info
                    &&  <LabelAndContent
                            label={"계약 내용"}
                            content={[contract_info]}
                        />
                }
                <LabelAndContent 
                    label={"촬영 날짜"}
                    content={[shoot_date]}
                />
                <LabelAndContent 
                    label={"촬영 시간"}
                    content={[`${shoot_start_time} ~ ${shoot_end_time} / ${time_length}분 소요`]}
                />
                <LabelAndContent 
                    label={"촬영 지점"}
                    content={[`${office}점`]}
                />
            </LabelAndContentContainer>
        </MainInfoCard>
    )
}

export default ShootPhotoInfoCard;