import { useEffect, useState } from "react";
import CSS from "./static/css/WasteBasket.module.css";
import deleteWH_icon_14 from "./static/img/deleteWH_icon_14@3x.png";

const WasteBasket = (
    {
        onClick,
    }:{
        onClick?: React.MouseEventHandler<HTMLDivElement>;
    }
) => {
    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    function classNameSetter(){
        const RTN: string[] = [];

        if(onClick){
            RTN.push("g_click_event_item");
        }

        RTN.push(CSS.l_WasteBasket_main);

        return RTN;
    }

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "));
    }, [onClick])

    return(
        <div 
            className={mainClassName}
            onClick={onClick}
        >
            <img
                className={CSS.l_WasteBasket_icon}
                src={deleteWH_icon_14}
            />
        </div>
    )
}

export default WasteBasket;