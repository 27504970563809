import { useQuery } from "@tanstack/react-query";
import { callAxios, customToast, dev_console, isSuccess, whoAmI } from "../../../../Common/ts/commonTools";
import { GC_TIME, STALE_TIME } from "../../../../Common/ts/const";
import { useEffect } from "react";
import { productMenuCountInterface } from "../../static/interface/common.interface";
import { defaultApiReaponseInterface } from "../../../../Common/interface/Common.interface";
import { RTN_interface } from "../../../../Common/interface/App.interface";

const TOAST_ID = "useProductMenuCount";

const useProductMenuCount = () => {

    const isAbleToRequest = () => {
        let RTN: boolean = false;

        if(whoAmI.isCustomer()){
            RTN = true;
        }

        return RTN;
    }
    
    const { 
        data: productMenuCountData = null, 
        error: productMenuCountError, 
        isLoading: productMenuCountIsLoading, 
        refetch: productMenuCountRefetch 
    } = useQuery({
        queryKey: ["useProductMenuCount"],
        queryFn: getProductMenuCountData,
        enabled: isAbleToRequest,
        select: (response) => {
            const RTN: RTN_interface<productMenuCountInterface | null> = {
                sign: false,
                data: null,
                msg: "",
            };

            SetData:{
                if(!isSuccess(response)){
                    RTN.msg = "데이터를 불러오는 과정에서 문제가 발생했습니다.";
                    break SetData;
                }

                const data: defaultApiReaponseInterface<productMenuCountInterface> = response.data;
                RTN.sign = true;
                RTN.data = data.result;
                RTN.msg = "success";
            }
            
            return RTN.data;
        },

        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
    });

    async function getProductMenuCountData(){
        const m_product_menu_count_url = "api/v3/oops/product-menu-count";

        const response = await callAxios.api({
            url: m_product_menu_count_url,
            method: "get",
        });

        dev_console.log(m_product_menu_count_url);
        dev_console.log(response);

        return response;
    };

    useEffect(() => {
        if(productMenuCountError){
            customToast.error({
                msg: "데이터를 불러오는데 실패했습니다.",
                toastId: TOAST_ID
            });

            dev_console.error("productMenuCountError ===>", productMenuCountError);
        }
    }, [productMenuCountError]);

    return {
        productMenuCountData,
        productMenuCountError,
        productMenuCountIsLoading,
        productMenuCountRefetch
    }
};

export default useProductMenuCount;