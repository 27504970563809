import CSS from "./static/css/LabelAndContent.module.css";
import Text from "../Text/Text";
import Label from "./Label";
import { Fragment, ReactNode, useMemo } from "react";

const LabelAndContent = (
    {
        label,
        headerNode,
        content,
        children,
        flex_direction,
        is_view_essential_icon,
        is_disabled_style,
        class_name_list,
        is_content_text_truncation_on,
        is_use_separator,
    }:{
        label?: string;
        headerNode?: ReactNode;
        content?: string | string[];
        children?: ReactNode;
        flex_direction?: "row" | "column";
        is_view_essential_icon?: boolean;
        is_disabled_style?: boolean;
        class_name_list?: string[];
        is_content_text_truncation_on?: boolean;
        is_use_separator?: boolean;
    }
) => {
    const mainClassName = useMemo(() => {
        const RTN: string[] = [];

        if(class_name_list){
            RTN.push(...class_name_list);
        }

        if(flex_direction){
            RTN.push(CSS.flex_direction);
            RTN.push(CSS[flex_direction]);
        }

        RTN.push(CSS.l_LabelAndContent_main)

        return RTN.join(" ");
    }, [flex_direction, class_name_list])

    const headerContainerClassName = useMemo(() => {
        const RTN: string[] = [];

        RTN.push(CSS.l_LabelAndContent_headerContainer);

        if(headerNode){
            RTN.push(CSS.use_header_node);
        }

        return RTN.join(" ");
    }, [headerNode])

    return(
        <div className={mainClassName}>
            <div className={headerContainerClassName}>
                {
                    label
                    &&  <Label is_view_essential_icon={is_view_essential_icon}>
                            {label}
                        </Label>
                }

                <div className={CSS.l_LabelAndContent_headerNode}>
                    {headerNode}
                </div>
            </div>
            
            {
                content
                &&  <div className={CSS.l_LabelAndContent_contentContianer}>
                        {
                            typeof content === "string"
                            ?   <Text
                                    class_name_list={[CSS.l_LabelAndContent_content]}
                                    color={is_disabled_style ? "gray3" : "gray0"}
                                    is_text_truncation_on={typeof is_content_text_truncation_on === "boolean" ? is_content_text_truncation_on : true}
                                    size={"size3"}
                                >
                                    {content}
                                </Text>
                            :   content.map((item, index) => {
                                    return(
                                        <Fragment key={index}>
                                            <Text
                                                class_name_list={[CSS.l_LabelAndContent_content]}
                                                color={is_disabled_style ? "gray3" : "gray0"}
                                                is_text_truncation_on={typeof is_content_text_truncation_on === "boolean" ? is_content_text_truncation_on : true}
                                                size={"size3"}
                                            >
                                                {item}
                                            </Text>

                                            {
                                                is_use_separator && content.length > index + 1
                                                &&  <Text color={"gray3"} size={"size1"}>|</Text>
                                            }
                                        </Fragment>
                                    )
                                })
                        }
                    </div>
            }

            {children}
        </div>
    )
}

export default LabelAndContent;