import CSS from "./static/css/dayCell.module.css"
import { DayCellContentArg } from "@fullcalendar/core";
import Text from "../../../Text/Text";
import useDayCellSetter from "./hooks/useDayCellSetter.hook";
import { textColorType } from "../../../Text/interface/Text.interface";
import { EVENT_CODE } from "../../../../../CustomerPage/MainHome/components/MonthlySchedule/static/ts/const";

const DayCell = (
    {
        dayCellInfo,
        selectedDate
    }:{
        dayCellInfo: DayCellContentArg;
        selectedDate: Date | null;
    }
) => {

    const {
        isToday,
        today_events,
        isSelected,
        isSunday,
    } = useDayCellSetter({
        dayCellInfo,
        selectedDate
    })

    let m_text_color: textColorType | undefined = undefined; 
    
    if(isSunday){
        m_text_color = "red_gray"
    }
    
    const total_class_name_list: string[] = [CSS.l_DayCell];

    if(isSelected){
        // 오늘인가?
        total_class_name_list.push(CSS.l_selected);
        m_text_color = "white"
    }else if(isToday){
        // 오늘인가?
        total_class_name_list.push(CSS.l_today);
    }

    return (
        <div className={CSS.l_DayCell_main}>
            <div className={CSS.l_contentContainer}>
                <Text 
                    size={"size1"}
                    class_name_list={total_class_name_list}
                    color={m_text_color}
                >
                    {dayCellInfo.date.getDate()}
                </Text>
                {
                    today_events.length > 0
                    &&  <div className={CSS.l_DayCell_dotContainer}>
                            {/* 도트를 일정 개수만큼 보여주되 최대 3개만 보여주는 방식 */}
                            {/* 
                                {
                                    today_events.splice(0, 3).map((item, index) => {
                                        return(
                                            <div className={CSS.l_DayCell_dot} key={index} />
                                        )
                                    })
                                }                             
                            */}

                            {
                                today_events.some((item) => item.extendedProps.type === EVENT_CODE.holiday)
                                ?   <div className={`${CSS.l_DayCell_dot} ${CSS.holiday}`} />
                                :   <div className={CSS.l_DayCell_dot} />
                            }
                        </div>
                }
            </div>
        </div>
    );
}

export default DayCell;