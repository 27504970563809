import { MouseEventHandler } from "react";
import CSS from "./static/css/ExtensionItem.module.css";
import Text from "../../../../Component/Elements/Text/Text";

const ExtensionItem = (
    {
        text,
        onClick,
    }:{
        text: string;
        onClick: MouseEventHandler<HTMLDivElement>;
    }
) => {
    return(
        <div 
            className={`g_click_event_item ${CSS.l_ExtensionItem_main}`}
            onClick={onClick}
        >
            <Text>
                {text}
            </Text>
        </div>
    )
}

export default ExtensionItem;