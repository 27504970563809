import { useEffect, useState } from "react";
import CSS from "./static/css/CommonIcon.module.css";

const CommonIcon = (
    {
        src,
        class_name_list,
        onClick,
    }:{
        src: string;
        class_name_list?: string[];
        onClick?: () => void;
    }
) => {
    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    function classNameSetter(){
        const RTN: string[] = [];

        if(class_name_list){
            for(const class_name of class_name_list){
                RTN.push(class_name);
            }
        }

        if(onClick){
            RTN.push("g_click_event_item");
        }

        RTN.push(CSS.l_CommonIcon_main);

        return RTN;
    }

    const clickHandler = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        event.stopPropagation();

        if(onClick){
            onClick();
        }
    }

    useEffect(() =>{
        setMainClassName(classNameSetter().join(" "));
    }, [class_name_list, onClick])

    return(
        <span 
            className={mainClassName}
            onClick={clickHandler}
        >
            <img 
                className={CSS.l_CommonIcon_img}
                src={src}
            />
        </span>
    )
}

export default CommonIcon;