import Text from "../../../../../Component/Elements/Text/Text";
import CSS from "./static/css/NoSchedule.module.css";

const NoSchedule = () => {
    return(
        <div className={CSS.l_NoSchedule_main}>
            <Text color={"gray4"}>
                아직 예정된 일정이 없어요
            </Text>
        </div>
    )
}

export default NoSchedule;