import NoSchedule from "../elements/NoSchedule/NoSchedule";
import MonthlyScheduleListItem from "../MonthlyScheduleListItem/MonthlyScheduleListItem";
import { MonthlyScheduleListItem_Interface } from "../MonthlyScheduleListItem/static/interface/MonthlyScheduleListItem.interface";
import CSS from "./static/css/MonthlyScheduleList.module.css"

const MonthlyScheduleList = (
    {
        schedule_data_list
    }:{
        schedule_data_list: MonthlyScheduleListItem_Interface[];
    }
) => {
    return(
        <div className={CSS.l_MonthlyScheduleList_main}>
            {
                schedule_data_list.length > 0
                ?   schedule_data_list.map((item) => {
                        return(
                            <MonthlyScheduleListItem 
                                date={item.date}
                                start_time={item.start_time}
                                shoot_title={item.shoot_title}
                                type={item.type}
                            />
                        )
                    })
                :   <NoSchedule />
            }
        </div>
    )
}

export default MonthlyScheduleList;