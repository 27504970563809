import CSS from "./static/css/ContractId.module.css";
import { ReactNode, useMemo } from "react";
import Text from "../../../../../Component/Elements/Text/Text";

const ContractId = (
    {
        children,
        className,
        is_header,
    }:{
        children?: ReactNode,
        className?: string;
        is_header?: boolean;
    }
) => {
    const mainClassName = useMemo(() => {
        const RTN: string[] = [];

        if(className){
            RTN.push(className);
        }

        RTN.push(CSS.l_ContractId_main)

        return RTN.join(" ");
    }, [className])

    return(
        <div className={mainClassName}>
            <Text 
                size={"size3"}
                color={is_header ? "gray2" : undefined}
            >
                {children}
            </Text>
        </div>
    )
}

export default ContractId