export const RESERVATION_LIST_FILTER_CODE = {
    all: "0",
    reservat: "1",
    end: "2"
} as const;

export const VIEWING_MOBILE_PAGE_CODE = {
    info: 1,
    selectTheme: 2,
} as const;

export const SHOOT_TYPE_CODE = {
    normal_shoot: 0,    //  일반촬영
    contract_shoot: 1,  //  계약촬영
}