import Text from "../../../../../Component/Elements/Text/Text";

const CurrencyUnitText = (
    {
        pay,
        isMobile,
        is_header,
    }:{
        pay: string;
        isMobile: boolean;
        is_header?: boolean;
    }
) => {
    return(
        <Text
            size={isMobile ? "size1" : "size3"}
            color={is_header ? "gray2" : undefined}
        >
            {pay}
        </Text>
    )
}

export default CurrencyUnitText;