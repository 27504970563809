import CSS from "./static/css/TrashIconBtn.module.css";
import Button from "../Button/Normal/Button"
import Text from "../Text/Text"
import TrashcanIcon from "../Icon/TrashcanIcon/TrashcanIcon";

const TrashIconBtn = (
    {
        deleteFC
    }:{
        deleteFC: () => void;
    }
) => {
    const viewDetailHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();

        deleteFC();
    }

    return(
        <Button
            preset={"trashcan_icon_btn"}
            onClick={viewDetailHandler}
        >
            <div className={CSS.l_TrashIconBtn_innerContainer}>
                <Text
                    text_wrap={"nowrap"}
                    preset={"detail_modal"}
                    color={"red_gray"}
                >
                    제거
                </Text>

                <TrashcanIcon />
            </div>
        </Button>
    )
}

export default TrashIconBtn;