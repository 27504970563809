import { useEffect, useState } from "react";
import ThemeItemCard from "./components/ThemeItemCard/ThemeItemCard";
import { photoProductTypeCodeType, themaBookDetailListInterface } from "./static/interface/themeBook.interface";
import { useLocation, useNavigate } from "react-router-dom";
import RadioBtnWrap from "../../Component/Elements/RadioBtnWrap/RadioBtnWrap";
import CSS from "./static/css/themeBook.module.css";
import ThemeDetailModalBtnContainer from "./components/ThemeDetailModalBtnContainer";
import { whoAmI } from "../../Common/ts/commonTools";
import Flex from "../../Component/Elements/Flex/Flex";
import MainPage from "../../Component/Elements/Page/MainPage/MainPage";
import EmptyData from "../../Component/Elements/EmptyData/EmptyData";
import ThemaDetailPageOverlay from "./components/ThemaDetailPageOverlay/ThemaDetailPageModal";
import { findNextTheme, sethemeManagerPageQueryParams } from "./static/ts/ThemeBook.tools";
import { themaInterface } from "../../CustomerPage/Reservation/static/interface/selectTheme.interface";
import useHistoryBackContralOverlayState from "../../Component/Elements/Overlay/hooks/useHistoryBackContralOverlayState.hook";
import { backPointUrlManagerClass } from "../../Common/ts/appTools";

const backPointUrlManager = new backPointUrlManagerClass();

const transfromThemeOverlay = (theme: themaBookDetailListInterface) => {
    const RTN: themaInterface = {
        thema_id: theme.thema_id,
        thema_name: theme.thema_name,
        thema_thumbnail: theme.thema_thumbnail,
        content: theme.content || "",
        photo_id: theme.photo_id,
        photo_name: theme.photo_name,
    }

    return RTN;
}

const ThemeBook = (
    {
        themeList,
        isLoading,
        children,
    }:{
        themeList: themaBookDetailListInterface[];
        isLoading: boolean;
        children?: React.ReactNode;
    }
) => {

    const [modelTheme, setModelTheme] = useState<themaBookDetailListInterface | null>(null);
    const [themeIndex, setThemeIndex] = useState<number>(0);
    const [viewMode, setViewMode] = useState<number>(0);
    const [filtedSubThemeList, setFiltedSubThemeList] = useState<themaBookDetailListInterface[]>([]);

    const {
        isOpen,
        overlayHandler,
    } = useHistoryBackContralOverlayState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const prevThemeSettingHandler = () => {
        const result = findNextTheme({
            theme_index: themeIndex, 
            theme_list: filtedSubThemeList.map((item) => {
                return(transfromThemeOverlay(item))
            }),
            diraction: "prev",
        });

        if(result.sign){
            setThemeIndex(result.data)
        }
    }

    const nextThemeSettingHandler = () => {
        const result = findNextTheme({
            theme_index: themeIndex, 
            theme_list: filtedSubThemeList.map((item) => {
                return(transfromThemeOverlay(item))
            }),
            diraction: "next",
        });

        if(result.sign){
            setThemeIndex(result.data)
        }
    }

    // 관리자 계정일 때 신규 글 작성버튼 온클릭 이벤트
    const handleOnNewContent = (selected_sub_theme: themaBookDetailListInterface) => {
        const l_themeUrlSearchParams = sethemeManagerPageQueryParams({
            type: selected_sub_theme.type,
            office_id: selected_sub_theme.office_id,
            photo_id: selected_sub_theme.photo_id,
            thema_id: selected_sub_theme.thema_id,
        })

        const m_url = backPointUrlManager.addSelfAddBackPointQueryParams({
            url: `/ThemeBookManagement?${l_themeUrlSearchParams}`,
            location
        })

        navigate(m_url, {
            state: selected_sub_theme
        });
    };

    const handleOnGoToDetailPage = (selected_sub_theme: themaBookDetailListInterface) => {
        setModelTheme(selected_sub_theme);
        const m_theme_index = filtedSubThemeList.findIndex((item) => item.thema_id === selected_sub_theme.thema_id);
        if(m_theme_index > 0){
            setThemeIndex(m_theme_index);
        }
        overlayHandler(true);
    }

    useEffect(() => {
        if(!!location.state && !!location.state.theme_id && filtedSubThemeList.length > 0){
            const theme_id = location.state.theme_id;

            if(!isNaN(theme_id)){
                const theme = filtedSubThemeList.find((item) => item.thema_id === theme_id);
                const m_theme_index = filtedSubThemeList.findIndex((item) => item.thema_id === theme_id);
    
                // 테마를 찾고 해당 테마에 컨텐츠가 있을경우에만 모달을 자동활성화 시킴(ThemeBookManagement에서 '취소' 버튼으로 다시 돌아왔을때, 출력할수 있는 컨텐츠가 없음에도 모달이 활성화 되는것을 막기위함)
                if(!!theme && !!theme.content && m_theme_index > -1){
                    setModelTheme(theme);
                    setThemeIndex(m_theme_index);
                    overlayHandler(true);
                }
            }

            // 전달받은 location.state값을 제거하여 새로고침이 일어났을 때 동작이 반복적으로 실행되는것을 막는다.
            navigate(".", { replace: true });
        }
    }, [filtedSubThemeList, location])

    useEffect(() => {
        if(whoAmI.isAdmin()){
            if(viewMode === 0){
                setFiltedSubThemeList(themeList);
            }else if(viewMode === 1){
                setFiltedSubThemeList(themeList.filter((item) => item.content));
            }else if(viewMode === 2){
                setFiltedSubThemeList(themeList.filter((item) => !item.content));
            }
        }else if(whoAmI.isCustomer()){
            setFiltedSubThemeList(themeList.filter((item) => item.content));
        }
    }, [themeList, viewMode])

    useEffect(() => {
        if(themeIndex > -1){
            setModelTheme(filtedSubThemeList[themeIndex]);
        }
    }, [filtedSubThemeList, themeIndex])

    return (
        <MainPage title="촬영 테마 둘러보기">
            <div className={CSS.l_ThemeBook_main}>
                {children}
                
                <div className={CSS.l_ThemeBook_contents}>
                    {
                        whoAmI.isAdmin()
                        &&  <div className={CSS.l_ThemeBook_adminFilterContainer}>
                                <RadioBtnWrap 
                                    item_list={[
                                        {
                                            label: "전체",
                                            value: 0,
                                        },
                                        {
                                            label: "내용있음",
                                            value: 1,
                                        },
                                        {
                                            label: "미작성",
                                            value: 2,
                                        },
                                    ]}
                                    name={"view_mode"}
                                    onChange={(e) => {setViewMode(parseInt(e.target.value))}}
                                    value={viewMode}
                                />
                            </div>
                    }

                    {
                        filtedSubThemeList.length > 0
                        ?   <Flex class_name_list={[CSS.l_themeSelecetContents_filtedSubThemeListContainer]}>
                                {
                                    filtedSubThemeList.map((item, index) => {
                                        if(item.content && item.content.length > 0){
                                            return (
                                                <ThemeItemCard 
                                                    key={index}
                                                    img_src={item.thema_thumbnail}
                                                    photo_product_name={item.photo_name}
                                                    theme_name={item.thema_name}
                                                    office_name={item.office_name}
                                                    onClick={handleOnGoToDetailPage.bind(null, item)}
                                                    is_empty_content={false}
                                                />
                                            )
                                        }else{
                                            if(whoAmI.isAdmin()){
                                                return (
                                                    <ThemeItemCard 
                                                        key={index}
                                                        img_src={item.thema_thumbnail}
                                                        photo_product_name={item.photo_name}
                                                        theme_name={item.thema_name}
                                                        office_name={item.office_name}
                                                        onClick={handleOnNewContent.bind(null, item)}
                                                        is_empty_content={true}
                                                    />
                                                );
                                            }else{
                                                return null;
                                            }
                                        }
                                    })
                                }
                            </Flex>
                        :   !isLoading
                            ?   <EmptyData />
                            :   null
                    }
                </div>
            </div>

            <ThemaDetailPageOverlay 
                isOverlayOpen={isOpen}
                overlayCloseHandler={overlayHandler.bind(null, false)}
                photo_name={modelTheme?.photo_name || ""}
                title={modelTheme?.thema_name || ""}
                office_name={modelTheme?.office_name || ""}
                content={modelTheme?.content || ""}
                office_id={modelTheme?.office_id || 0}
                thema_id={modelTheme?.thema_id || 0}
                type={modelTheme?.type || 0}

                // 이전, 다음테마로 이동이 가능할때만 버튼을 노출시키기 위해 처리
                // 이 조건을 제거하고 헨들러 함수를 무조건적으로 할당할경우, 버튼은 순환방식으로 동작하게된다.
                prevThemeHandler={
                    themeIndex > 0 
                    ?   prevThemeSettingHandler 
                    :   undefined
                }
                nextThemeHandler={
                    themeIndex < filtedSubThemeList.length - 1
                    ?   nextThemeSettingHandler
                    :   undefined
                }
            >
                {
                    whoAmI.isAdmin() && modelTheme
                    &&  <div className={CSS.l_themeSelecetContents_btnContainer}>
                            <ThemeDetailModalBtnContainer 
                                themeData={modelTheme}
                                isInEditPage={false}
                                closeModalHandler={overlayHandler.bind(null, false)}
                                uploadedThumbnailFile={null}
                            />
                        </div>
                }
            </ThemaDetailPageOverlay>

            {/* <LoopAnimationModal 
                isModalOpen={isLoading}
            /> */}
        </MainPage>
    )
}

export default ThemeBook;