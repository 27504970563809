import { Link } from "react-router-dom";
import edrp_oops_logo from "./static/img/EDRP-OOPS-02.png";
import CSS from "./static/css/HomeLinkLogo.module.css";

const HomeLinkLogo = () => {
    return(
        <Link 
            to="/"
            className={CSS.l_HomeLinkLogo_main}
        >
            <img 
                src={edrp_oops_logo}
                className={CSS.l_HomeLinkLogo__logo_img}
            />
        </Link>
    )
}

export default HomeLinkLogo;