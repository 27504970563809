import { Gallery } from "react-photoswipe-gallery";
import { PHOTO_SWIPE_OPTIONS } from "./static/ts/const";
import { ReactNode } from "react";
import PhotoSwipeGalleryIconVersionItem from "./components/PhotoSwipeGalleryIconVersionItem/PhotoSwipeGalleryIconVersionItem";

const ReactPhotoSwipeIconVersion = (
    {
        img_list,
        children,
    }:{
        img_list: string[];
        children?: ReactNode;
    }
) => {
    return(
        <Gallery
            options={PHOTO_SWIPE_OPTIONS} 
            withCaption
            withDownloadButton
        >
            <PhotoSwipeGalleryIconVersionItem img_list={img_list}>
                {children}
            </PhotoSwipeGalleryIconVersionItem>
        </Gallery>
    )
}

export default ReactPhotoSwipeIconVersion;