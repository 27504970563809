import CSS from "./static/css/ThemeSelectInfoCardChildren.module.css"
import LabelAndContent from "../../../../Component/Elements/LabelAndContent/LabelAndContent";
import LabelAndContentContainer from "../../../../Component/Elements/LabelAndContentContainer/LabelAndContentContainer";

const ThemeSelectInfoCardChildren = (
    {
        contract_info,
        schedule_date_string,
        photo_time,
        office_name,
    }:{
        contract_info: string;
        schedule_date_string: string;
        photo_time: string;
        office_name: string;
    }
) => {

    return(
        <div className={CSS.l_ThemeSelectInfoCardChildren_main}>
            <LabelAndContentContainer>
                {
                    contract_info
                    &&  <LabelAndContent 
                            label={"계약 내용"}
                            content={[contract_info]}
                        />                
                }

                <LabelAndContent 
                    label={"촬영 날짜"}
                    content={[schedule_date_string]}
                />
                
                <LabelAndContent 
                    label={"촬영 시간"}
                    content={[photo_time]}
                />

                <LabelAndContent 
                    label={"촬영 지점"}
                    content={[`${office_name}점`]}
                />
            </LabelAndContentContainer>
        </div>
    )
}

export default ThemeSelectInfoCardChildren;