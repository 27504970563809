import Button from "../../../../../Component/Elements/Button/Normal/Button";
import CSS from "./static/css/DetailPageComfirmBtnContainer.module.css";
import { productStateCodeType } from "../../../static/interface/ProductList.interface";
import { useNavigate } from "react-router-dom";
import { confirmBtnHandler } from "../../../static/ts/ProductConfrim.tools";
import { getQueryParamsUrl } from "../../../../../Common/ts/commonTools";

const DetailPageComfirmBtnContainer = (
    {
        status_cd,
        contract_product_id,
        album_save_id,
        photo_upload_id,
    }:{
        status_cd: productStateCodeType;
        contract_product_id: number;
        album_save_id: number | null;
        photo_upload_id: number | null;
    }
) => {
    const navigate = useNavigate();

    const goToConfirmPage = () => {
        const l_productConfirm_url = getQueryParamsUrl({
            main_url: "/productConfirm",
            queryParams: {
                status_cd: `${status_cd}`,
                contract_product_id: `${contract_product_id}`,
                album_save_id: `${album_save_id}`,
                photo_upload_id: `${photo_upload_id}`,
            }
        })

        navigate(l_productConfirm_url);
    }

    return(
        <div className={CSS.l_DetailPageComfirmBtnContainer_main}>
            <Button 
                preset={"normal_gray"}
                onClick={goToConfirmPage}
            >
                사진을 다시 편집해 주세요
            </Button>
            <Button onClick={confirmBtnHandler.bind(null, {
                photo_upload_id,
                contract_product_id,
            })}>
                사진이 마음에 들어요
            </Button>
        </div>
    )
}

export default DetailPageComfirmBtnContainer;