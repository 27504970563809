import { RTN_interface } from "../../../../Common/interface/App.interface";
import { customParseInt, findRegionByAddress, getAccountData } from "../../../../Common/ts/commonTools";
import { REGION_LIST } from "../../../../Common/ts/const";
import { themaInterface } from "../../../../CustomerPage/Reservation/static/interface/selectTheme.interface";
import { photoProductTypeCodeType } from "../interface/themeBook.interface";

export const findNextTheme = (
    {
        theme_index,
        theme_list,
        diraction,
    }:{
        theme_index: number, 
        theme_list: themaInterface[], 
        diraction: "prev" | "next"
    }
) => {

    const RTN: RTN_interface<number> = {
        sign: false,
        data: -1,
        msg: "",
    }

    let m_find_point_index = diraction === "prev" ? theme_index - 1 : theme_index + 1;

    const m_list_length = theme_list.length

    // 탐색을 시작하는 index를 설정한다.
    InitIndex: {
        if(m_list_length === 0){
            m_find_point_index = -1;
            RTN.sign = false;
            RTN.data = -1;
            RTN.msg = "탐색하려는 목록의 길이가 0입니다.";
            break InitIndex;
        }
        
        if(m_find_point_index < 0){
            m_find_point_index = m_list_length - 1;
            break InitIndex;
        }

        if(m_find_point_index >= m_list_length){
            m_find_point_index = 0;
            break InitIndex;
        }
    }

    // 탐색을 시작한다.
    FindNextIndex:{
        // 탐색이 불가능한 시작점이다.
        if(m_find_point_index === -1){
            break FindNextIndex;
        }

        while(true){
            // 탐색중인 index와 처음 시작한 index가 같다면 한바퀴를 다 돌았는데도 컨텐츠가 존재하는 테마를 못 찾았다는 이야기
            if(m_find_point_index === theme_index){
                RTN.sign = false;
                RTN.msg = "컨텐츠가 존재하는 다른 테마가 없습니다.";
                RTN.data = -1;
                break;
            }

            // list의 끝에 도달하면 순환시키기위해 index를 반대편으로 설정해준다.
            if(m_find_point_index < 0){
                m_find_point_index = m_list_length - 1;
            }else if(m_find_point_index >= m_list_length){
                m_find_point_index = 0;
            }

            const m_theme = theme_list[m_find_point_index];
            // 컨텐츠가 존재한다면 원하는 다음테마 탐색에 성공한것이다.
            if(m_theme.content){
                RTN.data = m_find_point_index;
                RTN.sign = true;
                RTN.msg = "success";
                break;
            }

            // 탐색하는 방향에따라 적절하게 index를 증감시킨다
            m_find_point_index = diraction === "prev" ? m_find_point_index - 1 : m_find_point_index + 1;
        }
    }

    return RTN;
}

// 전달받은 쿼리파라미터의 데이터를 정제하는 함수
export const themeBookParamsData = (
    {
        URLSearchParams,
    }:{
        URLSearchParams: URLSearchParams
    }
) => {
    const l_tab1 = customParseInt(URLSearchParams.get("tab1"));
    const l_tab2 = customParseInt(URLSearchParams.get("tab2"));
    const l_tab3 = customParseInt(URLSearchParams.get("tab3"));

    const l_customer_office_address = getAccountData.office_addr();
    const l_office_region = findRegionByAddress(l_customer_office_address);

    let l_region_id: number = 0;
    RegionIdSettring:{
        if(typeof l_tab1 === "number"){
            l_region_id = l_tab1;
            break RegionIdSettring;
        }

        if(l_office_region){
            l_region_id = l_office_region.id;
        }
    }

    let l_region_value: string = "";
    const m_finded_region = REGION_LIST.find(item => item.id === l_region_id);
    if(m_finded_region){
        l_region_value = m_finded_region.value;
    }
    
    let l_office_id: number = 0;
    OfficeIdSetting:{
        if(typeof l_tab2 === "number"){
            l_office_id = l_tab2;
            break OfficeIdSetting;
        }

        l_office_id = getAccountData.office_id();
    }

    let l_photo_product_id: number = 0;
    if(typeof l_tab3 === "number"){
        l_photo_product_id = l_tab3;
    }

    return({
        l_region_id,
        l_region_value,
        l_office_id,
        l_photo_product_id,
    })
}

export function sethemeManagerPageQueryParams(
    {
        type,
        office_id,
        photo_id,
        thema_id
    }:{
        type: photoProductTypeCodeType;
        office_id: number;
        photo_id: number;
        thema_id: number;
    }
){
    const RTN = new URLSearchParams();
    
    RTN.set("type", `${type}`);
    RTN.set("office_id", `${office_id}`);
    RTN.set("photo_id", `${photo_id}`);
    RTN.set("thema_id", `${thema_id}`);

    return RTN.toString();
}