import CardContainer from "../CardContainer/CardContainer";
import DetailIconBtn from "../DetailIconBtn/DetailIconBtn";
import Cross from "../Icon/Cross/Cross";
import { aspectRatioType } from "../Media/interface/Media.interface";
import Media from "../Media/Media";
import QuantityController from "../QuantityController/QuantityController";
import Text from "../Text/Text";
import HoverScreen from "./HoverScreen";
import CSS from "./static/css/MediaItemCard.module.css"
import { useMediaQuery } from 'react-responsive';
import { forwardRef, useEffect, useState } from "react";

interface mediaItemCardInterface{
    src?: string;
    is_highlight: boolean;
    viewDetailFC?: () => void;
    isReadOnly?: boolean;
    fileName?: string;
    preset?: "selected_theme_item" | "all_theme_list_item"
    is_use_cross_icon?: boolean;
    aspect_ratio?: aspectRatioType;
    hoverClickHanler?: () => void;
    customHoverIcon?: React.ReactNode;
    isComfrimMode?: boolean;
    onClick?: React.MouseEventHandler<HTMLDivElement>;

    selected_theme_length?: number;
    themeMaxQty?: number;
    addThemeHandler?: React.MouseEventHandler<HTMLDivElement>;
    subThemeHandler?: React.MouseEventHandler<HTMLDivElement>;
}

const MediaItemCard = forwardRef<HTMLImageElement | HTMLDivElement | HTMLVideoElement, mediaItemCardInterface>((
    {
        src,
        is_highlight,
        viewDetailFC,
        isReadOnly,
        fileName,
        preset,
        is_use_cross_icon,
        aspect_ratio,
        hoverClickHanler,
        customHoverIcon,
        isComfrimMode,
        selected_theme_length,
        themeMaxQty,
        addThemeHandler,
        subThemeHandler,
        onClick,
    }, ref
) => {
    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));
    const [imgContainerClassNameList, setImgContainerClassNameList] = useState<string[]>(imgContainerClassNameSetter());

    // 화면의 최대 크기가 700px를 넘지 않는가?
    const isMobile = useMediaQuery({ maxWidth: 700 });

    function classNameSetter(){
        const RTN: string[] = [CSS.l_ImgItemCard_main];

        if(preset){
            RTN.push(CSS.preset);
            RTN.push(CSS[preset]);
        }

        return RTN;
    }

    function imgContainerClassNameSetter(){
        const RTN: string[] = [];

        RTN.push(CSS.l_ImgItemCard__img_container);

        if(is_highlight){
            RTN.push(CSS.is_highlight);
        }

        if(preset){
            RTN.push(CSS.preset);
            RTN.push(CSS[preset]);
        }

        return RTN;
    }

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "))
        setImgContainerClassNameList(imgContainerClassNameSetter());
    }, [preset])

    useEffect(() => {
        setImgContainerClassNameList(imgContainerClassNameSetter());
    }, [is_highlight])

    return (
        <div className={mainClassName}>
            <CardContainer
                class_name_list={imgContainerClassNameList}
                preset={"img"}
                onClick={isReadOnly ? undefined : onClick}
            >
                {
                    (is_use_cross_icon && !(!!src))
                    ?   <Cross />
                    :   <Media
                            src={src}
                            object_fit={"contain"}
                            ref={ref}
                            aspect_ratio={preset === "all_theme_list_item" || preset === "selected_theme_item" ? "aspect_ratio1_1" : aspect_ratio}
                        />
                }

                {
                    customHoverIcon
                    &&  <HoverScreen onClick={hoverClickHanler}>
                            {customHoverIcon}
                        </HoverScreen>
                }
            </CardContainer>

            {
                fileName
                &&  <Text
                        class_name_list={[CSS.l_fileName_container]}
                        preset={"main_content"}
                        is_text_truncation_on={true}
                        color={"gray3"}
                        justify_content={"center"}
                        copy_function_target_text={isComfrimMode ? fileName : undefined}
                        copy_message={isComfrimMode ? "파일명이 클립보드에 추가되었습니다." : undefined}
                    >
                        {fileName}
                    </Text>
            }

            {
                !!viewDetailFC && isMobile
                &&  <div className={`${CSS.l_ImgItemCard_detailBtn}`}>
                        <DetailIconBtn viewDetailFC={viewDetailFC} />
                    </div>
            }

            {
                (preset === "all_theme_list_item" || preset === "selected_theme_item") && !isReadOnly && addThemeHandler && subThemeHandler && !isMobile
                &&  <QuantityController 
                        number={selected_theme_length || 0}
                        addNum={addThemeHandler}
                        subNum={subThemeHandler}
                        min={0}
                        max={themeMaxQty || 0}
                    />
            }
        </div>
    )
})

export default MediaItemCard;