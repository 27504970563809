import { useNavigate } from "react-router-dom";
import { isHiddenType } from "../../../../Common/interface/Common.interface";
import { getQueryParamsUrl, isHiddenClassNameSetter } from "../../../../Common/ts/commonTools";
import CSS from "./static/css/EditIcon.module.css";
import modify_icon from "./static/img/Modify_icon_26@3x.png";
import { useCallback, useEffect, useState } from "react";
import { ProductDetail_LocationState_Interface } from "../../../../CustomerPage/ProductManufacturing/static/interface/ProductDetail.interface";
import { productStateCodeType } from "../../../../CustomerPage/ProductManufacturing/static/interface/ProductList.interface";

const EditIcon = (
    {
        status_cd,
        contract_product_id,
        album_save_id,
        photo_upload_id,
        isHidden,
    }:{
        status_cd: productStateCodeType;
        contract_product_id: number;
        album_save_id: number | null;
        photo_upload_id: number | null;
        isHidden?: isHiddenType;
    }
) => {
    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    const navigate = useNavigate();

    const clickHandler = useCallback(() => {
        if(!window.confirm("사진이나 요청사항 변경을 원하실 경우 이미지를 다시 업로드하셔야 합니다.\n 요청사항을 변경하시겠습니까?")){
            return ;
        }

        const m_productRequestEdit_url = getQueryParamsUrl({
            main_url: "/productRequestEdit",
            queryParams: {
                status_cd: `${status_cd}`,
                contract_product_id: `${contract_product_id}`,
                album_save_id: `${album_save_id}`,
                photo_upload_id: `${photo_upload_id}`,
            }
        })

        navigate(m_productRequestEdit_url);
    }, [status_cd, contract_product_id, album_save_id, photo_upload_id])

    function classNameSetter(){
        let RTN: string[] = ["g_click_event_item"];

        if(isHidden){
            RTN = isHiddenClassNameSetter({
                classNameList: RTN,
                isHidden,
                display: "flex",
            })
        }

        RTN.push(CSS.l_EditIcon_main);
        
        return RTN;
    }

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "));
    }, [isHidden])
    
    return(
        <div
            className={mainClassName}
            onClick={clickHandler}
        >
            <img 
                className={CSS.l_EditIcon_iconImg}
                src={modify_icon} 
            />
        </div>
    )
}

export default EditIcon;