import CSS from "./static/css/DetailPageBtnTotalContainer.module.css";
import { productStateCodeType } from "../../static/interface/ProductList.interface";
import { PRODUCT_STATE_CODE_OBJ } from "../../static/ts/const";
import DetailPageComfirmBtnContainer from "./DetailPageComfirmBtnContainer/DetailPageComfirmBtnContainer";
import DetailPageEditRequestBtnContainer from "./DetailPageEditRequestBtnContainer/DetailPageEditRequestBtnContainer";

const DetailPageBtnTotalContainer = (
    {
        contract_product_id,
        album_save_id,
        photo_upload_id,
        status_cd,
        isCanReupload,
    }:{
        contract_product_id: number;
        album_save_id: number | null;
        photo_upload_id: number | null;
        status_cd: productStateCodeType;
        isCanReupload?: boolean;
    }
) => {
    switch(status_cd){
        case PRODUCT_STATE_CODE_OBJ.application_not_possible:
            // 상세페이지로 접근이 불가능한 상태다.
            return(null);
        case PRODUCT_STATE_CODE_OBJ.application_possible:
            // 상세페이지로 접근이 불가능한 상태다.
            return(null);
        case PRODUCT_STATE_CODE_OBJ.application_in_progress:
            // 편집 시작 전 사진을 다시 업로드하는 페이지로 이동하는 버튼
            // 모바일환경에서는 다른 버튼을 사용하므로 웹에서만 보이게 한다.
            if(isCanReupload){
                return(
                    <div className={`g_display_none_object mobile ${CSS.l_DetailPageBtnTotalContainer_BtnContainer}`}>
                        <DetailPageEditRequestBtnContainer
                            status_cd={status_cd}
                            contract_product_id={contract_product_id} 
                            album_save_id={album_save_id}
                            photo_upload_id={photo_upload_id}
                        />
                    </div>
                );
            }else{
                return(null);
            }
        case PRODUCT_STATE_CODE_OBJ.editing:
            // 사용자가 선택할 수 있는 기능이 없는 상태다.
            return(null);
        case PRODUCT_STATE_CODE_OBJ.confirm_request:
            return(
                <DetailPageComfirmBtnContainer 
                    status_cd={status_cd}
                    contract_product_id={contract_product_id} 
                    album_save_id={album_save_id}
                    photo_upload_id={photo_upload_id}
                />
            );
        case PRODUCT_STATE_CODE_OBJ.edit_end:
            return(null);
        case PRODUCT_STATE_CODE_OBJ.confirm_rejected:
            return(null);
        default:
            return(null);
    }
}

export default DetailPageBtnTotalContainer;