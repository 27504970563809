export const PHOTO_SWIPE_OPTIONS = {
    arrowPrev: true,
    arrowNext: true,
    zoom: true,
    close: true,
    counter: true,
    bgOpacity: 1,
    // padding: { top: 20, bottom: 40, left: 100, right: 100 },
    wheelToZoom: true,
    showHideAnimationType: "none" as "none",
    // showAnimationDuration: 0,
    // hideAnimationDuration: 0,
    pswpModule: () => import('photoswipe'),
}