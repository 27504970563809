import { useNavigate } from "react-router-dom";
import Text from "../../../../Component/Elements/Text/Text";
import useMonthlyScheduleListItemSetter from "./hooks/useMonthlyScheduleListItemSetter.hook";
import CSS from "./static/css/MonthlyScheduleListItem.module.css";
import { MonthlyScheduleListItem_Interface } from "./static/interface/MonthlyScheduleListItem.interface";
import { RESERVATION_LIST_FILTER_CODE } from "../../../Reservation/static/ts/const";
import { getQueryParamsUrl } from "../../../../Common/ts/commonTools";
import { backPointUrlManagerClass } from "../../../../Common/ts/appTools";
import { EVENT_CODE } from "../MonthlySchedule/static/ts/const";

const backPointUrlManager = new backPointUrlManagerClass();

const MonthlyScheduleListItem = (
    {
        date,
        start_time,
        shoot_title,
        type,
    }: MonthlyScheduleListItem_Interface
) => {
    const navigate = useNavigate()

    const {
        month_day,
        date_format,
    } = useMonthlyScheduleListItemSetter({
        date,
        start_time,
    })

    const clickEventHandler = () => {
        const m_reservationHome_url = getQueryParamsUrl({
            main_url: "/reservationHome",
            queryParams: {
                tab1: RESERVATION_LIST_FILTER_CODE.reservat,
                back_point: backPointUrlManager.encoded({url: "/"}),
            }
        })

        navigate(m_reservationHome_url)
    }

    return(
        <div 
            className={`g_click_event_item ${CSS.l_MonthlyScheduleListItem_main}`}
            onClick={clickEventHandler}
        >
            <Text class_name_list={[CSS.l_MonthlyScheduleListItem_dateText]}>
                {month_day}
            </Text>
            <div className={CSS.l_MonthlyScheduleListItem_infoContainer}>
                <Text 
                    class_name_list={[CSS.l_shootTitleText]}
                    bold={true}
                    is_text_truncation_on={true}
                    color={type === EVENT_CODE.holiday ? "red_gray" : undefined}
                >
                    {shoot_title}
                </Text>
                <Text 
                    class_name_list={[CSS.l_dateFormatText]}
                    color={"gray3"}
                    is_text_truncation_on={true}
                >
                    {date_format}
                </Text>
            </div>
        </div>
    )
}

export default MonthlyScheduleListItem;