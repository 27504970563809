import { MouseEventHandler, useEffect, useState } from "react";
import CSS from "./static/css/QuantityControllBtnIcon.module.css";

const QuantityControllBtnIcon = (
    {
        type,
        is_disable,
        onClick,
    }:{
        type: "+" | "-"
        is_disable?: boolean;
        onClick?: MouseEventHandler<HTMLDivElement>;
    }
) => {
    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    function classNameSetter(){
        const RTN: string[] = [];

        RTN.push("g_click_event_item");

        RTN.push(CSS.l_QuantityControllBtnIcon_main)

        if(is_disable || !onClick){
            RTN.push(CSS.disable);
            RTN.push("not_allowed");
        }

        return RTN;
    }

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "));
    }, [is_disable])

    return(
        <div 
            className={mainClassName}
            onClick={is_disable ? undefined : onClick}
        >
            {type}
        </div>
    )
}

export default QuantityControllBtnIcon;