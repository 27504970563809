import { MouseEventHandler, useMemo } from "react";
import CSS from "./static/css/IconX.module.css";
import x_icon from "./static/img/remove_icon_8.svg";
import x_icon_big_black from "./static/img/close_icon_24@3x.png";

const IconX = (
    {
        class_name_list,
        onClick,
        preset,
    }:{
        class_name_list?: string[];
        onClick?: MouseEventHandler<HTMLDivElement>
        preset?: "default" | "big_black"
    }
) => {
    const l_className = useMemo (() => {
        const RTN: string[] = [];

        if(onClick){
            RTN.push("g_click_event_item");
        }

        if(class_name_list){
            RTN.push(...class_name_list);
        }

        RTN.push(CSS.l_IconX_main);

        if(preset){
            RTN.push(CSS.preset);
            RTN.push(CSS[preset]);
        }

        return RTN;
    }, [onClick, class_name_list]);

    const l_img = useMemo(() => {
        let RTN: string = x_icon;

        if(preset === "big_black"){
            RTN = x_icon_big_black;
        }

        return RTN;
    }, [preset]);

    return(
        <div 
            className={l_className.join(" ")}
            onClick={onClick}
        >
            <img 
                className={CSS.l_IconX_iconImg}
                src={l_img}
            />
        </div>
    )
}

export default IconX;