import { useQuery } from "@tanstack/react-query";
import { callAxios, customToast, dev_console, getQueryParamsUrl, isSuccess, printStateMsg, whoAmI } from "../../../Common/ts/commonTools";
import { defaultApiReaponseInterface, regionOfficeInterface } from "../../../Common/interface/Common.interface";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";
import { RTN_interface } from "../../../Common/interface/App.interface";
import { useEffect } from "react";

const EMPTY_LIST: regionOfficeInterface[] = [];

const TOAST_ID = "useOfficeDataREST";

const useOfficeDataREST = (
    {
        region
    }:{
        region: string;
    }
) => {
    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer()){
            if(region){
                result = true;
            }
        }

        return result;
    }

    const { 
        data: officeList = EMPTY_LIST,
        error: officeListError,
        isLoading: isOfficeListLoading,
        refetch: officeListRefetch
    } = useQuery({
        queryKey: ["theme_book_office", region],
        queryFn: callOfficeList,
        enabled: isAbleToRequest,
        select: (response) => {
            const RTN: RTN_interface<regionOfficeInterface[]> = {
                sign: false,
                data: EMPTY_LIST,
                msg: "",
            };

            SetData:{
                if(!response){
                    RTN.sign = true;
                    RTN.msg = "Can't Request Theme Book Data";
                    break SetData;
                }

                if(!isSuccess(response)){
                    RTN.sign = true;
                    RTN.msg = "테마북 데이터를 불러오는데 실패했습니다.";
                    break SetData;
                }

                const data: defaultApiReaponseInterface<regionOfficeInterface[]> = response.data;
                RTN.sign = true;
                RTN.data = data.result;
                RTN.msg = "success";
            }
                

            if(!RTN.sign){
                if(response){
                    printStateMsg(response);
                }
            }

            return RTN.data;
        },
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
    });

    // 고객이 지역에 있는 지점 정보들을 서버에 요청하는 함수
    async function callOfficeList(){
        if(region){
            const m_region_office_url = getQueryParamsUrl({
                main_url: "api/v3/customers/region_office",
                queryParams: {
                    region: region,
                }
            })

            const response = await callAxios.api({
                url: m_region_office_url,
                method: "get"
            })

            dev_console.log(m_region_office_url);
            dev_console.log(response);

            return response;
        }else{
            return null;
        }
    }

    useEffect(() => {
        if(officeListError){
            customToast.error({
                msg: "테마북 데이터를 불러오는데 실패했습니다.",
                toastId: TOAST_ID
            })
        }
    }, [officeListError])

    return({
        officeList,
        officeListError,
        isOfficeListLoading,
        officeListRefetch
    })
}

export default useOfficeDataREST;