import CSS from "./static/css/ProductList.module.css";
import { useEffect, useState } from "react";
import TabMenu from "../../Component/Elements/TabMenu/TabMenu";
import Flex from "../../Component/Elements/Flex/Flex";
import ProductBlock from "./components/ProductBlock/ProductBlock";
import { PRODUCT_LIST_FILTER_CODE_OBJ, PRODUCT_LIST_FILTER_TEXT_OBJ, PRODUCT_TYPE_CODE_OBJ } from "./static/ts/const";
import { productListFilterCodeType } from "./static/interface/ProductList.interface";
import { tebMenuItemInterface } from "../../Component/Elements/TabMenu/static/interface/TabMenu.interface";
import useProductListREST from "./hooks/REST/useProductListREST";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getGotoPageClickEvent } from "./static/ts/common.tools";
import LoopAnimationModal from "../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import MainPage from "../../Component/Elements/Page/MainPage/MainPage";
import { isAbleAccesser } from "../../Common/ts/commonTools";
import EmptyData from "../../Component/Elements/EmptyData/EmptyData";
import { getCodeQueryParams } from "../../Common/ts/appTools";
import useProductMenuCount from "./hooks/REST/useProductMenuCount";

const ProductList = () => {
    const [productMenuList, setProductMenuList] = useState<tebMenuItemInterface<productListFilterCodeType>[]>([
        {
            label: PRODUCT_LIST_FILTER_TEXT_OBJ[PRODUCT_LIST_FILTER_CODE_OBJ.all_product],
            badge: "",
            value: PRODUCT_LIST_FILTER_CODE_OBJ.all_product,
        }
    ]);

    const navigate = useNavigate();

    const [URLSearchParams, SetURLSearchParams] = useSearchParams();

    const l_tab1 = getCodeQueryParams<productListFilterCodeType>(URLSearchParams.get("tab1"), PRODUCT_LIST_FILTER_CODE_OBJ);

    const [productListFilterCode, setProductListFilterCode] = useState<productListFilterCodeType>(l_tab1 || PRODUCT_LIST_FILTER_CODE_OBJ.all_product);

    const {
        productMenuCountData,
        productMenuCountIsLoading,
        productMenuCountError,
        productMenuCountRefetch
    } = useProductMenuCount();

    const {
        productListData,
        productListIsLoading,
        productListError,
        productListRefetch
    } = useProductListREST({
        search_type: productListFilterCode
    })

    useEffect(() => {
        isAbleAccesser({allowedUserTypeList: ["C"]})
    }, [])

    useEffect(() => {
        const m_tab_menu_list: tebMenuItemInterface<productListFilterCodeType>[] = [
            {
                label: PRODUCT_LIST_FILTER_TEXT_OBJ[PRODUCT_LIST_FILTER_CODE_OBJ.all_product],
                badge: "",
                value: PRODUCT_LIST_FILTER_CODE_OBJ.all_product,
            }
        ]

        if(productMenuCountData){

            if(productMenuCountData.select_photo_count > 0){
                m_tab_menu_list.push({
                    label: PRODUCT_LIST_FILTER_TEXT_OBJ[PRODUCT_LIST_FILTER_CODE_OBJ.need_to_request],
                    badge: "",
                    value: PRODUCT_LIST_FILTER_CODE_OBJ.need_to_request,
                })
            }

            if(productMenuCountData.edit_photo_count > 0){
                m_tab_menu_list.push({
                    label: PRODUCT_LIST_FILTER_TEXT_OBJ[PRODUCT_LIST_FILTER_CODE_OBJ.editing],
                    badge: "",
                    value: PRODUCT_LIST_FILTER_CODE_OBJ.editing,
                })
            }

            if(productMenuCountData.conf_photo_count > 0){
                m_tab_menu_list.push({
                    label: PRODUCT_LIST_FILTER_TEXT_OBJ[PRODUCT_LIST_FILTER_CODE_OBJ.need_to_confirm],
                    badge: "",
                    value: PRODUCT_LIST_FILTER_CODE_OBJ.need_to_confirm,
                })
            }

            if(productMenuCountData.ready_photo_count > 0){
                m_tab_menu_list.push({
                    label: PRODUCT_LIST_FILTER_TEXT_OBJ[PRODUCT_LIST_FILTER_CODE_OBJ.complete],
                    badge: "",
                    value: PRODUCT_LIST_FILTER_CODE_OBJ.complete,
                })
            }   

            if(productMenuCountData.goout_photo_count > 0){
                m_tab_menu_list.push({
                    label: PRODUCT_LIST_FILTER_TEXT_OBJ[PRODUCT_LIST_FILTER_CODE_OBJ.shipping_complete],
                    badge: "",
                    value: PRODUCT_LIST_FILTER_CODE_OBJ.shipping_complete,
                })
            }
        }

        setProductMenuList(m_tab_menu_list);
    }, [productMenuCountData]);

    // 뒤로가기버튼으로 통해 페이지에 진입했을경우, 쿼리파라미터에 적용된 값을 다시 읽어서 적용해야 하므로 이렇게 처리한다.
    useEffect(() => {
        // 주소에 탭 정보가 있는 경우 해당 탭을 선택한 상태로 초기화 시킨다.
        const m_tab1 = getCodeQueryParams<productListFilterCodeType>(URLSearchParams.get("tab1"), PRODUCT_LIST_FILTER_CODE_OBJ);
        if(m_tab1){
            setProductListFilterCode(m_tab1);
        }
    }, [URLSearchParams])

    // 선택한 탭을 주소에 반영한다.
    useEffect(() => {
        SetURLSearchParams((prev) => {
            prev.set("tab1", productListFilterCode);
            return prev;
        }, {replace: true});
    }, [productListFilterCode])

    return(
        <MainPage
            title="제품 제작 관리"
        >
            <TabMenu
                <productListFilterCodeType>
                tab_list={productMenuList}
                selectedTab={productListFilterCode}
                setSelectedTab={setProductListFilterCode}
            />

            <div className={CSS.l_ProductList_flexContainer}>
                {
                    productListData.length > 0
                    ?   <Flex preset={"photo_or_product_list"}>
                            {
                                productListData.map((item) => {
                                    return(
                                        <ProductBlock
                                            key={`${item.contract_product_id}-${item.album_save_id}-${item.photo_upload_id}`}
                                            product_name={item.product_name}
                                            product_type={item.product_type || PRODUCT_TYPE_CODE_OBJ.other}
                                            thumbnail_src={item.image_url.middle_url}
                                            product_state={item.status_cd}
                                            product_qty={item.product_qty}
                                            onClick={
                                                getGotoPageClickEvent({
                                                    status_cd: item.status_cd,
                                                    contract_product_id: item.contract_product_id,
                                                    album_save_id: item.album_save_id,
                                                    photo_upload_id: item.photo_upload_id,
                                                    navigate,
                                                    tab1: productListFilterCode,
                                                })
                                            }
                                            options={item.option_name}
                                        />
                                    )
                                })
                            }
                        </Flex>
                    :   <EmptyData />
                }
            </div>

            {/* 로딩 애니메이션 모달 */}
            <LoopAnimationModal
                isModalOpen={productListIsLoading}
            />
        </MainPage>
    )
}

export default ProductList;