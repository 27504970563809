import CardContainer from "../CardContainer/CardContainer";
import CSS from "./static/css/wideCard.module.css";

const WideCard = (
    {
        children,
        background_color,
        cover_layer,
    }:{
        children: React.ReactNode;
        background_color?: "white" | "sky_blue";
        cover_layer?: boolean;
    }
) => {

    const total_class_name_list: string[] = [CSS.l_wide_card_main];

    if(background_color){
        total_class_name_list.push(CSS.background_color)
        total_class_name_list.push(CSS[background_color]);
    }
    
    if(cover_layer){
        total_class_name_list.push(CSS.cover_layer);
    }

    return (
        <CardContainer class_name_list={total_class_name_list}>
            {children}
        </CardContainer>
    )
}

export default WideCard;