// ViewerToolBar는 스타일을 활용해서 가로700px 이하의 화면 크기에선 숨겨지게 처리함 -> (실제 모바일환경에서 툴바가 정상적으로 출력되지 않는 문제 + 구지 모바일에서 사용할 필요가 없다는 판단)

import { handleDownloadImage, handleNextImage, handlePrevImage, handleResetImage, handleZoomIn, handleZoomOut } from "./static/ts/ImageGallery.tools";
import ImageGalleryToolbarBtn from "../ImageGalleryToolbarBtn/ImageGalleryToolbarBtn";
import CSS from "./static/css/ViewerToolBar.module.css";
import left from "./static/img/left.svg";
import right from "./static/img/right.svg";
import download from "./static/img/download-02.svg";

const ViewerToolBar = (
    {
        viewerInstanceRef
    }:{
        viewerInstanceRef: React.MutableRefObject<Viewer | null>
    }
) => {
    return(
        <div className={CSS.l_viewer_toolbar_main}>
            <ImageGalleryToolbarBtn 
                src={"+"}
                src_type={"text"}
                onClick={handleZoomIn.bind(null, viewerInstanceRef)}
            />
            <ImageGalleryToolbarBtn 
                src={"-"}
                src_type={"text"}
                onClick={handleZoomOut.bind(null, viewerInstanceRef)}
            />
            <ImageGalleryToolbarBtn 
                src={left}
                src_type={"img"}
                onClick={handlePrevImage.bind(null, viewerInstanceRef)}
            />
            <ImageGalleryToolbarBtn 
                src={right}
                src_type={"img"}
                onClick={handleNextImage.bind(null, viewerInstanceRef)}
            />
            <ImageGalleryToolbarBtn 
                src={"초기화"}
                src_type={"text"}
                onClick={handleResetImage.bind(null, viewerInstanceRef)}
            />
            <ImageGalleryToolbarBtn
                src={download}
                src_type={"img"}
                onClick={handleDownloadImage.bind(null, viewerInstanceRef)}
            />
        </div>
    )
}

export default ViewerToolBar;