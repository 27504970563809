import { useEffect, useState } from "react";
import { getViewportSize } from "../../../../Common/ts/commonTools";

const ReactPhotoSwipeSetter = () => {
    // 사진 확대기능을 위해서 필수적으로 사진의 가로/세로길이를 고정값으로 정해줘야한다.
    // css를 통해 사진에 contain속성을 적용시켰다.

    const { viewport_width, viewport_height } = getViewportSize()

    const [viewportSize, setViewportSize] = useState<{width: number, height: number}>({
        width: viewport_width,
        height: viewport_height,
    })

    useEffect(()=>{
        let m_debounceTimer: number | null = null;
        const m_debounceDelay = 200;

        const handleResize = () => {
            if(m_debounceTimer){
                clearTimeout(m_debounceTimer);
            }

            // 새 타이머를 설정
            m_debounceTimer = window.setTimeout(() => {
                const { viewport_width, viewport_height } = getViewportSize();
                setViewportSize({ width: viewport_width, height: viewport_height });
            }, m_debounceDelay);
        }

        window.addEventListener("resize", handleResize);

        // 컴포넌트 언마운트 시 이벤트 리스너와 타이머 클리어
        return () => {
            window.removeEventListener('resize', handleResize);
            if (m_debounceTimer) {
                clearTimeout(m_debounceTimer);
            }
        };
    }, [])

    return({viewportSize})
}

export default ReactPhotoSwipeSetter;