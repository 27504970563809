import { DragEvent, forwardRef, MouseEvent, MouseEventHandler, ReactNode, useEffect, useState } from "react";
import CSS from "./static/css/CardContainer.module.css";
import { cardContainerPresetType } from "./interface/CardContainer.interface";

interface cardContainerPropsInterface{
    children?: ReactNode;
    class_name_list?: string[];
    
    padding?: "none";
    outline?: "none";
    borderRadius?: "radius0";

    preset?: cardContainerPresetType;

    is_highlight?: boolean;

    onClick?: MouseEventHandler<HTMLDivElement>;
    onDragEnter?: (event: DragEvent<HTMLDivElement>) => void;
    onDragLeave?: (event: DragEvent<HTMLDivElement>) => void;
    onDragOver?: (event: DragEvent<HTMLDivElement>) => void;
    onDrop?: (event: DragEvent<HTMLDivElement>) => void;
}

const CardContainer = forwardRef<HTMLDivElement, cardContainerPropsInterface>((
    {
        children,
        class_name_list,

        padding,
        outline,
        borderRadius,

        preset,

        is_highlight,

        onClick,
        onDragEnter,
        onDragLeave,
        onDragOver,
        onDrop,
    }, ref
) => {
    const classNameSetter = () => {
        const m_total_class_name_list: string[] = [];

        if(onClick){
            m_total_class_name_list.push("g_click_event_item");
        }

        if(class_name_list){
            for(const class_name of class_name_list){
                m_total_class_name_list.push(class_name);
            }
        }

        m_total_class_name_list.push(CSS.l_card_container_main);

        if(padding){
            m_total_class_name_list.push(CSS.padding);
            m_total_class_name_list.push(CSS[padding]);
        }

        if(outline){
            m_total_class_name_list.push(CSS.outline);
            m_total_class_name_list.push(CSS[outline]);
        }

        if(borderRadius){
            m_total_class_name_list.push(CSS.borderRadius);
            m_total_class_name_list.push(CSS[borderRadius]);
        }

        if(preset){
            m_total_class_name_list.push(CSS.preset);
            m_total_class_name_list.push(CSS[preset]);
        }

        if(is_highlight){
            m_total_class_name_list.push(CSS.highlight);
        }

        return m_total_class_name_list;
    }

    const [totalClassName, setTotalClassName] = useState<string>(classNameSetter().join(" "));

    useEffect(() => {        
        setTotalClassName(classNameSetter().join(" "));        
    }, [class_name_list, onClick, padding, outline, preset, is_highlight, borderRadius])

    const onClickHander = (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
        // Overlay컴포넌트에서 배경이나 헤더바 외에 다른영역 클릭시 클릭 이벤트 전파에의해서 닫힘 이벤트가 동작하는것을 막기위함
        // event.stopPropagation();

        if(onClick){
            onClick(event);
        }
    }

    return(
        <div 
            className={totalClassName}
            onClick={onClickHander}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDrop={onDrop}
            ref={ref}
        >
            {children}
        </div>
    )
})

export default CardContainer;