import { MouseEventHandler } from "react";
import OvalIconButton from "../../../../../../Component/Elements/Button/OvalIconButton/OvalIconButton";
import Text from "../../../../../../Component/Elements/Text/Text";
import CSS from "./static/css/DeliveryAddressItem.module.css";

const DeliveryAddressItem = (
    {
        addr,
        addr_detail,
        zip_code,
        recipient_name,
        recipient_phone,
        selectOnClickHandler,
    }:{
        addr: string;
        addr_detail: string;
        zip_code: string;
        recipient_name: string;
        recipient_phone: string;
        selectOnClickHandler?: MouseEventHandler<HTMLButtonElement>;
    }
) => {
    return (
        <div className={CSS.l_DeliveryAddressItem_main}>
            <div className={CSS.l_DeliveryAddressItem_addressInfoContainer}>
                <div className={CSS.l_DeliveryAddressItem_receiverInfoContainer}>
                    <Text bold={true}>
                        {recipient_name}
                    </Text>
                    <Text bold={true}>
                        {recipient_phone}
                    </Text>
                </div>
                <Text>
                    {`[${zip_code}] ${addr} ${addr_detail}`}
                </Text>
            </div>
            <div className={CSS.l_DeliveryAddressItem_buttonContainer}>
                {
                    selectOnClickHandler
                    &&  <OvalIconButton
                            onClick={selectOnClickHandler}
                            inner_text="선택하기"
                        />
                }
            </div>
        </div>
    )
}

export default DeliveryAddressItem;