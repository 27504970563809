import { useEffect, useLayoutEffect, useRef, useState } from "react";
import CardContainer from "../CardContainer/CardContainer";
import Text from "../Text/Text";
import CSS from "./static/css/MainInfoCard.module.css";
import { cardContainerPresetType } from "../CardContainer/interface/CardContainer.interface";
import Hr from "../Hr/Hr";
import TriangleIcon from "../Icon/TriangleIcon/TriangleIcon";

const MainInfoCard = (
    {
        title,
        initChildrenContainerOpen,
        width,
        header,
        bottom,
        isInnerComponent,
        children,
        preset = "infoCard",
        is_disabled_style,
    }:{
        title?: React.ReactNode;
        initChildrenContainerOpen?: boolean;
        width?: string;
        header?: React.ReactNode;
        bottom?: React.ReactNode;
        isInnerComponent?: boolean;
        children?: React.ReactNode;
        preset?: cardContainerPresetType;
        is_disabled_style?: boolean;
    }
) => {
    const classNameSetter = () => {
        const RTN: string[] = [CSS.l_MainInfoCard__body_container];

        if(typeof initChildrenContainerOpen === "boolean"){
            RTN.push(CSS.fold_mode);

            if(isChildrenOpen){
                RTN.push(CSS.open);
            }
        }

        return RTN;
    }

    const [childrenClassName, setChildrenClassName] = useState<string>(classNameSetter().join(" "));
    const [isChildrenOpen, setIsChildrenOpen] = useState<boolean>(typeof initChildrenContainerOpen === "boolean" ? initChildrenContainerOpen : true);
    const mainRef = useRef<HTMLDivElement>(null);
    const childrenRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setChildrenClassName(classNameSetter().join(" "))
    }, [initChildrenContainerOpen, isChildrenOpen])

    // 외부에서 가로 크기를 전달받아서 설정시킴
    useEffect(() => {
        if(!!width && mainRef.current){
            mainRef.current.style.width = width;
        }
    }, [width])

    const openChildrenContainerBtnHandler = () => {
        setIsChildrenOpen((prev) => !prev);
    }

    return(
        <CardContainer 
            class_name_list={[CSS.l_MainInfoCard_main]}
            ref={mainRef}
            preset={preset}
            padding={isInnerComponent ? "none" : undefined}
            outline={isInnerComponent ? "none" : undefined}
            borderRadius={isInnerComponent ? "radius0" : undefined}
        >
            <div className={CSS.l_MainInfoCard__header_title_container}>
                {
                    header
                    &&  <div className={CSS.l_MainInfoCard__header_container}>
                            {header}
                        </div>
                }

                {
                    title
                    &&  <div className={CSS.l_MainInfoCard__title_container}>
                            <Text
                                size={"size7"}
                                color={is_disabled_style ? "gray3" : "dark"}
                                bold={true}
                            >
                                {title}
                            </Text>

                            {
                                typeof initChildrenContainerOpen === "boolean"
                                &&  <div 
                                        className={`g_click_event_item ${CSS.l_MainInfoCard__children_container_open_btn}`}
                                        onClick={openChildrenContainerBtnHandler}
                                    >
                                        <TriangleIcon 
                                            direction={
                                                initChildrenContainerOpen
                                                ?   "top"
                                                :   "bottom"
                                            }
                                        />
                                    </div>
                            }
                        </div>
                }
            </div>

            {
                title && children
                &&  <Hr />
            }

            {
                children
                &&  <div 
                        className={childrenClassName}
                        ref={childrenRef}
                    >
                        {children}
                    </div>
            }

            {
                bottom
                &&  <div>
                        {bottom}
                    </div>
            }
        </CardContainer>
    )
}

export default MainInfoCard;