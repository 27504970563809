import CSS from "../static/css/ThemeDetailModalBtnContainer.module.css"
import { themaBookDetailListInterface } from "../static/interface/themeBook.interface";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { callAxios, customToast, dev_console, imageFilesUpload, isSuccess, printStateMsg } from "../../../Common/ts/commonTools";
import LoopAnimationModal from "../../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import { chunkImageUploadDataInterface, uploadRequestfileDataInterface } from "../../../Common/interface/Common.interface";
import Button from "../../../Component/Elements/Button/Normal/Button";
import { backPointUrlManagerClass } from "../../../Common/ts/appTools";
import { sethemeManagerPageQueryParams } from "../static/ts/ThemeBook.tools";

const backPointUrlManager = new backPointUrlManagerClass();

const ThemeDetailModalBtnContainer = (
    {
        themeData,
        isInEditPage,
        removedImageUrlListRef,
        mainContent,
        closeModalHandler,
        uploadedThumbnailFile,
    }:{
        themeData:themaBookDetailListInterface;
        isInEditPage: boolean;
        removedImageUrlListRef?: React.MutableRefObject<string[]>
        mainContent?: string;
        closeModalHandler?: React.MouseEventHandler<HTMLButtonElement>;
        uploadedThumbnailFile: File | null;
    }
) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [URLSearchParams, SetURLSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const location = useLocation();

    const l_back_point_url = useMemo(() => backPointUrlManager.getBackPointUrlAndDecode(URLSearchParams) || "/themeBook", [URLSearchParams]);

    const editBtnHandler = () => {
        const l_themeUrlSearchParams = sethemeManagerPageQueryParams({
            type: themeData.type,
            office_id: themeData.office_id,
            photo_id: themeData.photo_id,
            thema_id: themeData.thema_id,
        })

        const m_url = backPointUrlManager.addSelfAddBackPointQueryParams({
            url: `/ThemeBookManagement?${l_themeUrlSearchParams}`,
            location
        })

        navigate(m_url, {
            state: themeData
        });
    }

    // 저장
    const handleOnUploadContent = async () => {
        if(isLoading){
            alert("저장 작업이 진행 중입니다.");
            return ;
        }

        if(!(!!mainContent) || mainContent.length === 0){
            if(!window.confirm("본문 내용이 작성되지 않았습니다. 이대로 저장하시겠습니까?\n본문이 없을 경우 고객에게 노출되지 않습니다.")){
                return ;
            }
        }else{
            if((!window.confirm("저장하시겠습니까?"))){
                return ;
            }
        }

        const config = {
            type: themeData.type,
            content: mainContent,
            thema_id: themeData.thema_id
        }

        setIsLoading(true);

        dev_console.log("수정 요청 ===>", config);

        const thema_book_content_update = "api/v3/customers/thema-book-content-update";

        dev_console.log(thema_book_content_update);
        dev_console.log(config);

        try{
            const main_content_response = await callAxios.api({
                url: thema_book_content_update,
                method: "post",
                dataType: "json",
                data: config,
            })
    
            dev_console.log(main_content_response);
    
            if(main_content_response){
                if(isSuccess(main_content_response)){
                    // if(removedImageUrlListRef){
                    //     await removeS3ImageList(removedImageUrlListRef.current);
                    // }
                }else{
                    customToast.error({msg: "테마 상세페이지 정보 저장에 실패했습니다."});
                }
            }else{
                customToast.error({msg: "테마 상세페이지 정보 저장에 실패했습니다."});
            }
        }catch(error){
            dev_console.error(error);
            customToast.error({msg: "테마 상세페이지 정보 저장 요청 과정에서 문제가 발생했습니다."});
        }

        if(!!uploadedThumbnailFile){
            const file_data: uploadRequestfileDataInterface[] = [];
            const error_msg = "썸네일 업로드 과정에서 문제가 발생했습니다.";
            const file_dir = `media/group/${themeData.group_id}/detail_product/${themeData.thema_id}/detail_option`;
            try{
                //  이미지를 청크로 업로드하는 부분
                const image_files_upload_result = await imageFilesUpload({
                    files: [uploadedThumbnailFile],
                    file_dir: file_dir,
                })

                if(image_files_upload_result.state === 200){
                    const result_data:chunkImageUploadDataInterface[] = image_files_upload_result.data;
                    for(const data of result_data){
                        file_data.push({
                            file_url: file_dir,
                            file_name: data.file_name,
                            file_ext: data.file_extension,
                            file_size: data.file_size,
                        })
                    }

                    const config = {
                        thema_id: themeData.thema_id,
                        office_id: themeData.office_id,
                        file_data: file_data,
                    }

                    const detail_product_update_url = "api/v3/customers/detail-product/update";

                    dev_console.log(detail_product_update_url);

                    const detail_product_update_response = await callAxios.api({
                        url: detail_product_update_url,
                        method: "post",
                        dataType: "json",
                        data: config,
                    });

                    dev_console.log(detail_product_update_response);

                    if(!!detail_product_update_response){
                    }else{
                        printStateMsg(detail_product_update_response);
                        customToast.error({msg: "썸네일저장 요청 과정에서 문제가 발생했습니다."});
                    }
                }else{
                    customToast.error({
                        msg: error_msg,
                    })
                }

            }catch(error){
                customToast.error({
                    msg: error_msg,
                })
            }
        }

        setIsLoading(false);

        customToast.info({
            msg: "변경하신 썸네일 이미지가 출력되지 않을 경우, 페이지를 새로고침 해주세요."
        })

        navigate(l_back_point_url, {
            state: {
                theme_id: themeData.thema_id
            }
        });
    }

    const deleteBtnHandler = () => {
        if(window.confirm("게시글이 삭제됩니다. 삭제를 진행하시겠습니까?")){
            const config = {
                type: themeData.type,
                content: "",
                thema_id: themeData.thema_id
            }
    
            dev_console.log("삭제 요청 ===>", config);

            setIsLoading(true);
            const thema_book_content_update = "api/v3/customers/thema-book-content-update";

            callAxios.api({
                url: thema_book_content_update,
                method: "post",
                dataType: "json",
                data: config,
            })
            .then(async (response) => {
                dev_console.log(thema_book_content_update);
                dev_console.log(response);
                
                if(isSuccess(response)){
                    navigate(l_back_point_url);
                }else{
                    printStateMsg(response);
                    customToast.error({msg: "게시글 삭제에 실패했습니다."});
                }
            })
            .catch((error) => {
                dev_console.error(error);
                customToast.error({msg: "게시글 삭제에 실패했습니다."})
            })
            .finally(() => {
                setIsLoading(false);
            })
        }
    }

    const cancelHandler = () => {
        if(window.confirm("저장하지 않은 내용들은 반영되지 않습니다.")){

            navigate(l_back_point_url, {
                state: {
                    theme_id: themeData.thema_id
                }
            });

            // const addedImageList = getDifferentImageUrls({
            //     origin: themeData?.content || "",
            //     newContent: mainContent || "",
            //     conditions: "added",
            // });

            // removeS3ImageList(addedImageList)
            // .then(() => {
            //     dev_console.log("all remove clear!");
            // })
            // .catch((error) => {
            //     dev_console.error(error);
            // })
            // .finally(() => {
            //     navigate("/themeBook", {
            //         state: {
            //             theme_id: themeData.thema_id
            //         }
            //     });
            // })
        }
    }

    if(isInEditPage){
        return(
            <div className={CSS.l_theme_detail_modal_btn_container_main}>
                    <Button
                        onClick={deleteBtnHandler}
                        size={"size1"}
                    >
                        삭제
                    </Button>
                    <Button
                        onClick={cancelHandler}
                        size={"size1"}
                    >
                        취소
                    </Button>
                    <Button
                        onClick={handleOnUploadContent}
                        size={"size1"}
                    >
                        저장
                    </Button>
                    <LoopAnimationModal
                        isModalOpen={isLoading}
                    >
                    </LoopAnimationModal>
            </div>
        )
    }else{
        return(
            <div className={CSS.l_theme_detail_modal_btn_container_main}>
                <Button 
                    onClick={editBtnHandler}
                    size={"size1"}
                >
                    수정
                </Button>
                <Button 
                    onClick={closeModalHandler}
                    size={"size1"}
                >
                    닫기
                </Button>
            </div>
        )
    }
}

export default ThemeDetailModalBtnContainer;