import CSS from "./static/css/OptionEle.module.css";
import CardContainer from "../../../../Component/Elements/CardContainer/CardContainer";
import Img from "../../../../Component/Elements/Img/Img";
import Text from "../../../../Component/Elements/Text/Text";
import { useEffect, useState } from "react";

const OptionEle = (
    {
        thumbnail_url,
        name,
        is_highlight,
        onClick
    }:{
        thumbnail_url: string;
        name: string;
        is_highlight?: boolean;
        onClick?: () => void;
    }
) => {
    const [mainCLassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    function classNameSetter(){
        const RTN: string[] = [];

        if(onClick){
            RTN.push("g_click_event_item");
        }

        RTN.push(CSS.l_OptionEle_main);

        return RTN;
    }

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "));
    }, [onClick])

    return(
        <div 
            className={mainCLassName}
            onClick={onClick}
        >
            <CardContainer
                preset={"img"}
                is_highlight={is_highlight}
            >
                <Img
                    src={thumbnail_url}
                    object_fit={"cover"}
                />
            </CardContainer>
            <Text
                preset={"main_content"}
                is_text_truncation_on={true}
            >
                {name}
            </Text>
        </div>
    )
}

export default OptionEle;