import Flex from "../../../../Component/Elements/Flex/Flex";
import { themaInterface, themeItemOnClickHandlerType } from "../../static/interface/selectTheme.interface";
import MediaItemCard from "../../../../Component/Elements/MediaItemCard/MediaItemCard";
import { useMediaQuery } from "react-responsive";
const AllThemeList = (
    {
        theme_list,
        selected_theme_list,
        schedule_photo_product_id,
        addThemeHandler,
        subThemeHandler,
        detailModalOpenHandler,
        themeMax,
        isReadOnly,
        mobileGoToThemeInfoPage,
    }:{
        theme_list: themaInterface[];
        selected_theme_list: themaInterface[];
        schedule_photo_product_id: number;
        addThemeHandler: themeItemOnClickHandlerType;
        subThemeHandler: themeItemOnClickHandlerType;
        detailModalOpenHandler: (item:themaInterface) => void;
        themeMax: number;
        isReadOnly?: boolean;
        mobileGoToThemeInfoPage?: () => void;
    }
) => {
    const isMobile = useMediaQuery({ maxWidth: 700 });

    return(
        <Flex preset={"all_theme_item_list"}>
            {
                theme_list.map((item, index) => {
                    let is_checked: boolean = false;
                    let m_selected_theme_length = 0;

                    // 지금 보고있는 테마을 몇개 중복해서 선택했는지 확인하는 용도
                    const m_selected_theme_list = selected_theme_list.filter((selected_theme) => {
                        return(
                            item.thema_id === selected_theme.thema_id
                        )
                    });

                    if(m_selected_theme_list.length > 0){
                        is_checked = true;
                        m_selected_theme_length = m_selected_theme_list.length;
                    }

                    let is_have_content: boolean = false;
                    if(item.content.length > 0){
                        is_have_content = true
                    }

                    const viewDetailFC = is_have_content ? detailModalOpenHandler.bind(null, item) : undefined;

                    // 남은 선택가능한 테마 수량
                    const m_themeMaxQty = themeMax - selected_theme_list.length;

                    const m_addThemeHandler = isMobile 
                    ?   () => {
                            addThemeHandler({
                                selected_item: item,
                                schedule_photo_product_id: schedule_photo_product_id,
                            });

                            mobileGoToThemeInfoPage?.();
                        } 
                    :   addThemeHandler.bind(null, {
                            selected_item: item,
                            schedule_photo_product_id: schedule_photo_product_id,
                        });

                    // 모바일 화면크기일 땐, 테마 추가 기능을 동작시킨다.
                    // 웹 화면 크기일땐 테마 상세보기 페이지를 출력시킨다.
                    const onClickHandler = isMobile 
                        ?   m_addThemeHandler 
                        :   viewDetailFC;

                    if(isReadOnly){
                        return(
                            <MediaItemCard
                                key={index}
                                src={item.thema_thumbnail.middle_url}
                                is_highlight={true}
                                viewDetailFC={viewDetailFC}
                                preset={"all_theme_list_item"}
                                onClick={onClickHandler}
                            />
                        )
                    }else{
                        return(
                            <MediaItemCard
                                key={index}
                                src={item.thema_thumbnail.middle_url}
                                is_highlight={is_checked}
                                addThemeHandler={
                                    addThemeHandler.bind(null, {
                                        selected_item: item,
                                        schedule_photo_product_id: schedule_photo_product_id,
                                    })
                                }
                                subThemeHandler={
                                    subThemeHandler.bind(null, {
                                        selected_item: item,
                                        schedule_photo_product_id: schedule_photo_product_id,
                                    })
                                }
                                themeMaxQty={m_themeMaxQty}
                                selected_theme_length={m_selected_theme_length}
                                viewDetailFC={viewDetailFC}
                                preset={"all_theme_list_item"}
                                onClick={onClickHandler}
                            />
                        )
                    }

                })
            }
        </Flex>
    )
}

export default AllThemeList;