import CSS from "./static/css/ReservationInfoCardChildren.module.css";
import LabelAndContent from "../../../../Component/Elements/LabelAndContent/LabelAndContent";
import LabelAndContentContainer from "../../../../Component/Elements/LabelAndContentContainer/LabelAndContentContainer";
import useReservationInfoCard from "../ReservationInfoCard/hooks/useReservationInfoCard.hook";
import { progressScheduleInterface } from "../../static/interface/reservationHome.interface";
import TextareaOverlaySet from "../../../../Component/Elements/TextareaOverlaySet/TextareaOverlaySet";

const ReservationInfoCardChildren = (
    {
        selectedSchedule,
        isDisabledStyle,
    }:{
        selectedSchedule: progressScheduleInterface;
        isDisabledStyle: boolean;
    }
) => {
    const {
        photo_type_nm,
        contract_info,
        schedule_date_string,
        photo_time,
        theme,
        office_info,
        memo,
    } = useReservationInfoCard({scheduleData: selectedSchedule});    

    return(
        <div className={CSS.l_ReservationInfoCardChildren_main}>
            <LabelAndContentContainer>
                <LabelAndContent 
                    label={"촬영 종류"}
                    content={[photo_type_nm]}
                    is_disabled_style={isDisabledStyle}
                />

                {
                    contract_info
                    &&  <LabelAndContent 
                            label={"계약 내용"}
                            content={[contract_info]}
                            is_disabled_style={isDisabledStyle}
                        />
                }

                <LabelAndContent 
                    label={"촬영 날짜"}
                    content={[schedule_date_string]}
                    is_disabled_style={isDisabledStyle}
                />
                
                <LabelAndContent 
                    label={"촬영 시간"}
                    content={[photo_time]}
                    is_disabled_style={isDisabledStyle}
                />

                <LabelAndContent 
                    label={"촬영 지점"}
                    content={[`${office_info.office_name}점`]}
                    is_disabled_style={isDisabledStyle}
                />

                <LabelAndContent 
                    label={"촬영 테마"}
                    content={[theme]}
                    is_disabled_style={isDisabledStyle}
                />

            </LabelAndContentContainer>

            {/* <div className={CSS.l_ReservationInfoCardChildren_notificationContainer}> */}
            <LabelAndContent
                label={"스튜디오 안내 사항"}
                flex_direction={"column"}
            >
                <div className={CSS.l_ReservationInfoCardChildren_TextareaOverlaySetContainer}>
                    <TextareaOverlaySet
                        value={memo}
                        disabled={isDisabledStyle}
                        readOnly={true}
                    />
                </div>
            </LabelAndContent>
            {/* </div> */}
        </div>
    )
}

export default ReservationInfoCardChildren;