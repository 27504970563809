import MainInfoCard from "../../../../Component/Elements/MainInfoCard/MainInfoCard";
import { schedulePhotoProductThemeInterface, themeItemOnClickHandlerType } from "../../static/interface/selectTheme.interface";
import SelectedThemeListContainer from "../SelectedThemeListContainer/SelectedThemeListContainer";
import ThemeSelectInfoCardChildren from "../ThemeSelectInfoCardChildren/ThemeSelectInfoCardChildren";
import CSS from "./static/css/ThemeSelectInfoCard.module.css";

const ThemeSelectInfoCard = (
    {
        photoProductList,
        office_name,
        isReadOnly,
        mobileGoToSelectThemePage,
        subThemeHandler,
        contract_info,
        schedule_date_string,
        photo_time,
        photo_name,
    }:{
        photoProductList: schedulePhotoProductThemeInterface[];
        office_name: string;
        isReadOnly?: boolean;
        mobileGoToSelectThemePage: (schedule_photo_product_id: number) => void;
        subThemeHandler: themeItemOnClickHandlerType;
        contract_info: string;
        schedule_date_string: string;
        photo_time: string;
        photo_name: string;
    }
) => {
    return(
        <div className={CSS.l_ThemeSelectInfoCard_main}>
            <MainInfoCard title={photo_name}>
                <div className={CSS.l_ThemeSelectInfoCard__contents_container}>
                    <ThemeSelectInfoCardChildren
                        contract_info={contract_info}
                        schedule_date_string={schedule_date_string}
                        photo_time={photo_time}
                        office_name={office_name}
                    />

                    <SelectedThemeListContainer 
                        photoProductList={photoProductList}
                        office_name={office_name}
                        isReadOnly={isReadOnly}
                        mobileGoToSelectThemePage={mobileGoToSelectThemePage}
                        subThemeHandler={subThemeHandler}
                    />
                </div>
            </MainInfoCard>
        </div>
    )
}

export default ThemeSelectInfoCard;