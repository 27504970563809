import { useState } from "react";
import CSS from "./static/css/DragAndDrop.module.css";
import WideCard from "../WideCard/WideCard";
import LoopAnimation from "../LoopAnimation/LoopAnimation";
import CardContainer from "../CardContainer/CardContainer";
import Text from "../Text/Text";

const DragAndDrop = (
    {
        onDropHandler,
        children,
        innerText,
        isLoading,
    }:{
        onDropHandler?: (event: React.DragEvent<HTMLDivElement>) => void;
        children?: React.ReactNode;
        innerText?: string;
        isLoading?: boolean;
    }
) => {

    const [totalClassName, setTotalClassName] = useState<string>(CSS.l_drag_and_drop_main);

    const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const class_name_list: string[] = [CSS.l_drag_and_drop_main, CSS.l_drag_and_drop__on_drag_enter];
        setTotalClassName(class_name_list.join(" "));
    }
    
    const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const class_name_list: string[] = [CSS.l_drag_and_drop_main];
        setTotalClassName(class_name_list.join(" "));
    }

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if(onDropHandler){
            onDropHandler(event);
        }

        const class_name_list: string[] = [CSS.l_drag_and_drop_main];
        setTotalClassName(class_name_list.join(" "));
    }

    return(
        <CardContainer
            class_name_list={[totalClassName]}
            preset={"drag_and_drop"}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDrop={onDrop}
        >
            {
                children
                ?   children
                :   <Text color={"gray4"}>
                        {innerText}
                    </Text>
            }

            {
                isLoading
                &&  <WideCard cover_layer={true}>
                        <LoopAnimation />
                    </WideCard>
            }
        </CardContainer>
    )
}

export default DragAndDrop;