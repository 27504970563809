import CSS from "./static/css/Label.module.css";
import Text from "../Text/Text";

const Label = (
    {
        is_view_essential_icon,
        children
    }:{
        is_view_essential_icon?: boolean;
        children?: React.ReactNode;
    }
) => {
    return(
        <Text
            class_name_list={[CSS.l_Label_main]}
            color={"secondary"}
            text_wrap={"nowrap"}
            is_view_essential_icon={is_view_essential_icon}
            size={"size3"}
        >
            {children}
        </Text>
    )
}

export default Label;