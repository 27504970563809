import { forwardRef, useEffect, useState } from "react";
import CSS from "./static/css/TextareaViewDiv.module.css";

interface textareaInterface{
    class_name_list?: string[];
    value?: string;
}

const TextareaViewDiv = forwardRef<HTMLDivElement, textareaInterface>((
    {
        class_name_list,
        value,
    }, ref
) => {
    const classNameSetter = () => {
        const RTN: string[] = [];

        if(class_name_list){
            for(const class_name of class_name_list){
                RTN.push(class_name);
            }
        }

        RTN.push(CSS.l_TextareaViewDiv_main)

        return RTN;
    }

    const [totalClassNameList, setTotalClassNameList] = useState<string>(classNameSetter().join(" "));

    useEffect(() => {
        setTotalClassNameList(classNameSetter().join(" "))
    }, [class_name_list])

    return(
        <div 
            className={totalClassNameList}
            ref={ref}
        >
            <pre className={CSS.l_TextareaViewDiv_pre}>
                {value}
            </pre>
        </div>
    )
})

export default TextareaViewDiv;