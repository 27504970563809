import { useEffect, useMemo, useRef, useState } from "react";
import AlertIcon from "../../../../Component/Elements/Icon/AlertIcon/AlertIcon";
import CSS from "./static/css/AlertIconBtn.module.css";
import AlertOverlay from "../AlertOverlay/AlertOverlay";

const AlertIconBtn = (
    {
        is_active,
    }:{
        is_active: boolean;
    }
) => {
    const [isAlertOverlayOpen, setIsAlertOverlayOpen] = useState<boolean>(false);

    const toggleHandler = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setIsAlertOverlayOpen((prev) => !prev);
    }

    const closeHandler = (event?: React.MouseEvent<HTMLDivElement>) => {
        if(event){
            event.stopPropagation();
        }
        setIsAlertOverlayOpen(false);
    }

    const mainClassName = useMemo(() => {
        const RTN: string[] = [];

        RTN.push("g_click_event_item");

        RTN.push(CSS.l_AlertIconBtn_main);
        
        return RTN.join(" ");
    }, [])
    
    const overlayRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                overlayRef.current && 
                !overlayRef.current.contains(event.target as Node)
            ) {
                closeHandler();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return(
        <div 
            className={mainClassName}
            onClick={toggleHandler}
            ref={overlayRef}
        >
            <AlertIcon
                is_active={is_active}
            />

            <AlertOverlay 
                is_open={isAlertOverlayOpen}
                closeHandler={closeHandler}
            />
        </div>
    )
}

export default AlertIconBtn;