import { MouseEventHandler, useEffect, useState } from "react";
import Cross from "../Icon/Cross/Cross";
import CSS from "./static/css/HoverScreen.module.css";

const HoverScreen = (
    {
        children,
        onClick,
    }:{
        children?: React.ReactNode;
        onClick?: MouseEventHandler<HTMLDivElement>;
    }
) => {
    const classNameList = () => {
        const RTN: string[] = [CSS.l_HoverScreen_main];

        if(onClick){
            RTN.push("g_click_event_item");
        }

        return RTN;
    }

    const [mainClassName, setMainClassName] = useState<string>(classNameList().join(" "));

    useEffect(() => {
        setMainClassName(classNameList().join(" "));
    }, [onClick])

    return(
        <div 
            className={mainClassName}
            onClick={onClick}
        >
            <div className={CSS.l_HoverScreen_icon_container}>
                {
                    children
                    ?   children
                    :   <Cross />
                }
            </div>
        </div>
    )
}

export default HoverScreen;