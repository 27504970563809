import { useEffect, useState } from "react";
import { progressScheduleInterface } from "../static/interface/reservationHome.interface";
import { progressScheduleSort } from "../../../Common/ts/hooks.tools";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";

const useProgressScheduleDataAllScheduleType = (
    {
        contractProgressScheduleDataList,
        contractProgressScheduleDataListRefetch,
        contractProgressScheduleDataListIsLoading,

        singleShotProgressScheduleDataList,
        singleShotProgressScheduleDataListRefetch,
        singleShotProgressScheduleDataListIsLoading,
    }:{
        contractProgressScheduleDataList: progressScheduleInterface[];
        contractProgressScheduleDataListRefetch: (options?: RefetchOptions) => Promise<QueryObserverResult<progressScheduleInterface[], Error>>;
        contractProgressScheduleDataListIsLoading: boolean;

        singleShotProgressScheduleDataList: progressScheduleInterface[];
        singleShotProgressScheduleDataListRefetch: (options?: RefetchOptions) => Promise<QueryObserverResult<progressScheduleInterface[], Error>>;
        singleShotProgressScheduleDataListIsLoading: boolean;
    }
) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [progressScheduleDataList, setProgressScheduleDataList] = useState<progressScheduleInterface[]>([]);

    const progressScheduleDataRefetch = () => {
        contractProgressScheduleDataListRefetch();
        singleShotProgressScheduleDataListRefetch();
    }

    useEffect(() => {
        let before_progress_schedule_data: progressScheduleInterface[] = [];

        let contract_progress_schedule_data: progressScheduleInterface[] = [];
        if(!!contractProgressScheduleDataList){
            contract_progress_schedule_data = [...contractProgressScheduleDataList];
        }

        let single_shot_progress_schedule_data: progressScheduleInterface[] = [];
        if(!!singleShotProgressScheduleDataList){
            single_shot_progress_schedule_data = [...singleShotProgressScheduleDataList];
        }

        before_progress_schedule_data = [...contract_progress_schedule_data, ...single_shot_progress_schedule_data];
        before_progress_schedule_data.sort(progressScheduleSort);

        setProgressScheduleDataList(before_progress_schedule_data);
    }, [contractProgressScheduleDataList, singleShotProgressScheduleDataList])

    useEffect(() => {
        setIsLoading(!!contractProgressScheduleDataListIsLoading || !!singleShotProgressScheduleDataListIsLoading);
    }, [contractProgressScheduleDataListIsLoading, singleShotProgressScheduleDataListIsLoading])

    return ({
        progressScheduleDataList,
        isLoading,
        progressScheduleDataRefetch,
    })
}

export default useProgressScheduleDataAllScheduleType;