import CSS from "./static/css/ContractDate.module.css";
import { ReactNode, useMemo } from "react";
import Text from "../../../../../Component/Elements/Text/Text";
import { useMediaQuery } from "react-responsive";

const PayDate = (
    {
        children,
        className,
        is_header,
    }:{
        children?: ReactNode,
        className?: string;
        is_header?: boolean;
    }
) => {
    const isMobile = useMediaQuery({ maxWidth: 700 });

    const mainClassName = useMemo(() => {
        const RTN: string[] = [];

        if(className){
            RTN.push(className);
        }

        RTN.push(CSS.l_ContractDate_main);

        return RTN.join(" ");
    }, [className])

    return(
        <div className={mainClassName}>
            <Text 
                size={isMobile ? "size1" : "size3"}
                color={is_header ? "gray2" : undefined}
            >
                {children}
            </Text>
        </div>
    )
}

export default PayDate;