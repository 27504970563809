import Text from "../Text/Text";
import CSS from "./static/css/MainTitle.module.css";

const MainTitle = (
    {
        title,
        isHeaderTitle,
    }:{
        title: string;
        isHeaderTitle?: boolean; // isHeaderTitle이 적용되면 일반적인 상황과 반대로 웹 환경에서 숨겨지고 모바일 환경에서 보여진다.
    }
) => {

    const l_mainClassNameList: string[] = [
        "g_display_none_object", 
        isHeaderTitle ? "web" : "mobile", 
        CSS.l_MainTitle_main
    ]; 

    return(
        <Text 
            class_name_list={l_mainClassNameList}
            preset={"main_title"}
        >
            {title}
        </Text>
    )
}

export default MainTitle;