import CSS from "./static/css/PaymentDetailHeader.module.css";
import PaymentDetailItmeText from "../elements/PaymentDetailItmeText/PaymentDetailItmeText";

const PaymentDetailHeader = () => {
    return(
        <div className={CSS.l_PaymentDetailHeader_main}>
            <PaymentDetailItmeText
                is_title={true}
                className={CSS.l_PayDate}
            >
                {"결제일"}
            </PaymentDetailItmeText>

            <PaymentDetailItmeText 
                is_title={true}
                className={CSS.l_PayType}
            >
                {"결제 유형"}
            </PaymentDetailItmeText>

            <PaymentDetailItmeText 
                is_title={true}
                className={CSS.l_PayMethod}
            >
                {"결제 수단"}
            </PaymentDetailItmeText>

            <PaymentDetailItmeText
                className={CSS.l_PayAmount}
                is_title={true}
                justify_content={"flex_end"}
            >
                {"결제액"}
            </PaymentDetailItmeText>
        </div>
    )
}

export default PaymentDetailHeader;