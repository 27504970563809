import { useEffect, useState } from "react";
import CSS from "./static/css/Hr.module.css"

const Hr = (
    {
        class_name_list
    }:{
        class_name_list?: string[];
    }
) => {
    const classNameSetter = () => {
        const RTN: string[] = [];

        if(!!class_name_list){
            for(const class_name of class_name_list){
                RTN.push(class_name);
            }
        }

        RTN.push(CSS.l_Hr_main);

        return RTN;
    }

    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "));
    }, [class_name_list])

    return(
        <hr className={mainClassName} />
    )
}

export default Hr;