import { isHiddenClassNameSetter } from "../../../../Common/ts/commonTools";
import CSS from "./static/css/HistoryBackIcon.module.css";
import back_icon from "./static/img/back_icon_16@2x.png";
import back_icon_vr2 from "./static/img/back_icon_8@3x.png";

const HistoryBackIcon = () => {
    const l_mobile_hidden_class_name_list = isHiddenClassNameSetter({
        classNameList: [CSS.l_HistoryBackIcon_iconImg],
        isHidden: "mobile"
    })

    const l_web_hidden_class_name_list = isHiddenClassNameSetter({
        classNameList: [CSS.l_HistoryBackIcon_iconImg],
        isHidden: "web"
    })

    return(
        <div className={CSS.l_HistoryBackIcon_main}>
            <img
                className={l_mobile_hidden_class_name_list.join(" ")}
                src={back_icon}
            />

            <img
                className={l_web_hidden_class_name_list.join(" ")}
                src={back_icon_vr2}
            />
        </div>
    )
}

export default HistoryBackIcon;