import Text from "../Text/Text";
import CSS from "./static/css/EmptyData.module.css";
import empty_logo from "./static/img/logo_empty@2x.png";

const EmptyData = () => {
    return(
        <div className={CSS.l_EmptyData_main}>
            <div className={CSS.l_EmptyData_contentContainer}>
                <div className={CSS.l_EmptyData_logoContainer}>
                    <img 
                        src={empty_logo}
                        className={CSS.l_EmptyData_logoImg}
                    />
                </div>
                <Text
                    color={"gray4"}
                >
                    아직 데이터가 없어요
                </Text>
            </div>
        </div>
    )
}

export default EmptyData;