import CSS from "./static/css/PaymentHistory.module.css";
import MainPage from "../../Component/Elements/Page/MainPage/MainPage"
import useContractListREST from "./hooks/useContractListREST";
import PaymentHistoryLine from "./components/PaymentHistoryLine/PaymentHistoryLine";
import { PaymentDetailItem_Interface } from "./components/PaymentDetailItem/static/interface/PaymentDetailItem.interface";
import { useState } from "react";
import PaymentHistoryListHeader from "./components/PaymentHistoryListHeader/PaymentHistoryListHeader";
import { useMediaQuery } from "react-responsive";
import { customParseInt, dateObjectManagerClass } from "../../Common/ts/commonTools";
import EmptyData from "../../Component/Elements/EmptyData/EmptyData";

const dateObjectManager = new dateObjectManagerClass();

const PaymentHistory = () => {
    const [selectedPaymentId, setSelectedPaymentId] = useState<number>(0);

    const {
        contractListData
    } = useContractListREST();
    
    const isMobile = useMediaQuery({ maxWidth: 700 });

    const paymentOnClickHandler = (contract_id: number) => {
        if(selectedPaymentId === 0 || selectedPaymentId !== contract_id){
            setSelectedPaymentId(contract_id);
        }else{
            setSelectedPaymentId(0);
        }
    }

    const moneyToFormat = (pay: string) => {
        let RTN: string = "";

        const m_pay = customParseInt(pay);

        if(typeof m_pay === "number"){
            RTN = m_pay.toLocaleString('ko-KR');
        }

        return RTN;
    }

    return(
        <MainPage title="계약 내용 확인">
            <div className={CSS.l_PaymentHistory_main}>
                {
                    !isMobile
                    &&  <PaymentHistoryListHeader />
                }

                {
                    contractListData.length > 0
                    ?   contractListData.map((item, index) => {
                            const m_contract_datetime = new Date(item.contract_date);
                            let m_contract_date = item.contract_date;
                            ContractDateSettingProcess:{
                                if(isNaN(m_contract_datetime.getTime())){
                                    break ContractDateSettingProcess;
                                }
                                
                                const m_date = dateObjectManager.customFormatDate(m_contract_datetime, "yyyy-MM-dd");
                                const m_weekday = dateObjectManager.getWeekday(m_contract_datetime);
                                m_contract_date = `${m_date} (${m_weekday})`
                            }

                            const m_paymentDetailList: PaymentDetailItem_Interface[] = item.pay_list.map((pay) => {
                                const m_pay_datetime = new Date(pay.pay_date);

                                let m_pay_date = pay.pay_date;
                                PayDateSettingProcess:{
                                    if(isNaN(m_pay_datetime.getTime())){
                                        break PayDateSettingProcess;
                                    }
                                    
                                    const m_date = dateObjectManager.customFormatDate(m_pay_datetime, "yyyy-MM-dd");
                                    const m_weekday = dateObjectManager.getWeekday(m_pay_datetime);
                                    m_pay_date = `${m_date} (${m_weekday})`
                                }

                                return({
                                    payDate: m_pay_date,
                                    payType: pay.pay_type_name,
                                    payMethod: pay.detail_type_name,
                                    payAmount: `${moneyToFormat(pay.pay_amount)}원`,
                                })
                            })

                            // .toLocaleString('ko-KR');
                            // totamount: 총액
                            const m_totamount = moneyToFormat(item.contract_totamount);
                            // payamount: 결재액
                            const m_payamount = moneyToFormat(item.contract_payamount);
                            // balance: 잔액
                            const m_balance = moneyToFormat(item.contract_balance);

                            return(
                                <PaymentHistoryLine
                                    key={index}
                                    contractDate={m_contract_date}
                                    contractId={`${item.contract_id}`}
                                    productName={item.package_name}
                                    contractTotamount={`${m_totamount}원`}
                                    contractBalance={`${m_payamount}원`}
                                    contractPayamount={`${m_balance}원`}
                                    contractImageList={item.img_list.map((img) => img.middle_url)}
                                    paymentDetailList={m_paymentDetailList}
                                    is_extended={selectedPaymentId === item.contract_id}
                                    extendBtnHandler={paymentOnClickHandler.bind(null, item.contract_id)}
                                />
                            )
                        })
                    :   <EmptyData />
                }
            </div>
        </MainPage>
    )
}

export default PaymentHistory;