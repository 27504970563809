import Text from "../../../../Component/Elements/Text/Text"
import CSS from "./static/css/TitleContainer.module.css";

const TitleContainer = (
    {
        photo_name,
        title,
        branch_name
    }:{
        photo_name: string;
        title: string;
        branch_name: string;
    }
) => {
    return(
        <div className={CSS.l_title_container_main}>
            <Text
                size={"size6"}
                bold={true}
                color={"dark"}
            >
                {photo_name}
            </Text>
            <Text
                size={"size3"}
                color={"gray1"}
            >
                {title}
            </Text>

            <Text
                size={"size3"}
                color={"gray1"}
            >
                {"|"}
            </Text>

            <Text
                size={"size3"}
                color={"gray3"}
            >
                {branch_name}
            </Text>
        </div>
    )
}

export default TitleContainer