import { ovalIconButtonPresetType } from "../interface/OvalIconButton.interface";
import detail_view_icon from "../static/img/detail_view_icon_16@2x.png";
import upload_icon from "../static/img/upload_icon_16@2x.png";
import delete_icon from "../static/img/delete_icon_16@2x.png";
import save_icon from "../static/img/save_icon_16@2x.png";
import history_icon from "../static/img/history_icon_16@2x.png";
import { textColorType } from "../../../Text/interface/Text.interface";

const useOvalIconButtonPreset = (
    preset?: ovalIconButtonPresetType
) => {
    let text_color: textColorType = "primary";
    let icon_src: string = "";
    let btn_text: string = "";

    switch(preset){
        case "detail_view":
            text_color = "secondary";
            icon_src = detail_view_icon;
            btn_text = "예시 이미지 보기";
            break;
        case "upload":
            text_color = "primary";
            icon_src = upload_icon;
            btn_text = "사진 불러오기";
            break;
        case "delete":
            text_color = "red_gray";
            icon_src = delete_icon; 
            btn_text = "전체 삭제";
            break;
        case "save":
            text_color = "primary";
            icon_src = save_icon;
            btn_text = "저장";
            break;
        case "history":
            text_color = "primary";
            icon_src = history_icon;
            btn_text = "지난 주소";
            break;
    }

    return({
        text_color,
        btn_text,
        icon_src
    })
}

export default useOvalIconButtonPreset;