import CSS from "./static/css/Datepicker.module.css"
import { LegacyRef, useMemo, useRef } from "react";
import { getMonth, getYear } from "date-fns";
import ReactDatePicker from "react-datepicker";
import { MONTHS, YEARS } from "./static/ts/const";
import "react-datepicker/dist/react-datepicker.css";
import "./static/css/react-datepicker-custom-style.css";
import { ko } from 'date-fns/locale';
import Time from "../Icon/TimeIcon/TimeIcon";
import CalendarIcon from "../Icon/CalendarIcon/CalendarIcon";

export const DatePicker = (
    {
        dateValue,
        onChange,
        id,
        readOnly,
        placeholderText,
    }:{
        dateValue: Date | null;
        onChange?: (date: Date | null) => void;
        id?: string;
        readOnly?: boolean;
        placeholderText?: string;
    }
) => {
    const datePickerRef = useRef<ReactDatePicker | null>(null);

    const maxDate = useMemo(() => {
        return new Date("9999-12-31");
    }, [])

    const inputContainerClickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();

        if(datePickerRef.current){
            datePickerRef.current.onInputClick();
        }
    }

    return (
        <div 
            className={CSS.l_Datepicker_main}
            onClick={inputContainerClickHandler}
        >
            <ReactDatePicker
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                }) => (
                    <div className={CSS.l_Datepicker_header}>
                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                        </button>
                        
                        <select
                            value={getYear(date)}
                            onChange={({target:{value}}) => {
                                let m_value_num: number = parseInt(value);
                                changeYear(m_value_num)
                            }}
                        >
                            {
                                YEARS.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))
                            }
                        </select>
            
                        <select
                            value={MONTHS[getMonth(date)]}
                            onChange={({target:{value}}) =>
                                changeMonth(MONTHS.indexOf(value))
                            }
                        >
                            {
                                MONTHS.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))
                            }
                        </select>
            
                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                        </button>
                    </div>
                )}

                selected={dateValue}

                onChange={onChange}

                todayButton="오늘"
                dateFormat={[
                    "yyyy-MM-dd", 
                    "yyyy.MM.dd", 
                    "yyyyMMdd", 
                    "yyyy MM dd", 
                    "yyyy/MM/dd", 
                    "yyyy_MM_dd"
                ]}
                maxDate={maxDate}
                id={id}
                className={"react-datepicker-ignore-onclickoutside"}
                locale={ko}
                placeholderText={placeholderText}
                readOnly={readOnly}
                ref={datePickerRef}

                popperPlacement={"bottom-end"}
            />

            <div className={CSS.l_Datepicker_childrenContainer}>
                <CalendarIcon />
            </div>
        </div>
    );
}

export const TimePicker = (
    {
        dateValue,
        onChange,
        id,
        placeholderText,
        readOnly,
    }:{
        dateValue: Date | null;
        onChange?: (date: Date | null) => void;
        id?: string;
        placeholderText?: string;
        readOnly?: boolean;
    }
) => {
    const datePickerRef = useRef<ReactDatePicker | null>(null);

    const inputContainerClickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();

        if(datePickerRef.current){
            datePickerRef.current.onInputClick();
        }
    }

    return (
        <div 
            className={CSS.l_Datepicker_main}
            onClick={inputContainerClickHandler}
        >
            <ReactDatePicker
                selected={dateValue}
                onChange={onChange}
                dateFormat={[
                    "HH:mm", 
                    "HHmm",
                    "HH-mm",
                    "HH_mm",
                    "HH/mm",
                ]}
                id={id}
                className={"react-datepicker-ignore-onclickoutside"}
                locale={ko}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={1}
                showTimeCaption={false}
                placeholderText={placeholderText}
                readOnly={readOnly}
                ref={datePickerRef}

                popperPlacement={"bottom-end"}

                shouldCloseOnSelect={true}
            />

            <div className={CSS.l_Datepicker_childrenContainer}>
                <Time />
            </div>
        </div>
    );
}