import { useQuery } from "@tanstack/react-query";
import { callAxios, dev_console, isSuccess, printStateMsg, whoAmI } from "../../../Common/ts/commonTools";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";
import { defaultApiReaponseInterface, regionOfficeInterface } from "../../../Common/interface/Common.interface";
import { RTN_interface } from "../../../Common/interface/App.interface";
import { useEffect } from "react";

const EMPTY_LIST: regionOfficeInterface[] = [];

const useStaffOfficeListREST = () => {
    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isAdmin()){
            result = true;
        }

        return result;
    }

    const { 
        data: staffoOfficeList = EMPTY_LIST,
        error: staffoOfficeListError,
        isLoading: isStaffoOfficeListLoading,
        refetch: staffoOfficeListRefetch
    } = useQuery({
        queryKey: ["useStaffOfficeListREST"],
        queryFn: callOfficeList,
        enabled: isAbleToRequest,
        select: (response) => {
            const RTN: RTN_interface<regionOfficeInterface[]> = {
                sign: false,
                data: EMPTY_LIST,
                msg: "",
            };

            SetData:{
                if(!response){
                    RTN.sign = true;
                    break SetData;
                }
                
                if(!isSuccess(response)){
                    RTN.msg = "지점 목록을 불러오는데 실패했습니다.";
                    break SetData;
                }

                const data: defaultApiReaponseInterface<regionOfficeInterface[]> = response.data;
                RTN.sign = true;
                RTN.data = data.result;
                RTN.msg = "success";
            }

            if(!RTN.sign){
                if(response){
                    printStateMsg(response);
                }
            }

            return RTN.data;
        },
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
    });

    // 고객이 지역에 있는 지점 정보들을 서버에 요청하는 함수
    async function callOfficeList(){
        const m_staff_office_url = "api/v3/oops/staff/office/list";

        const response = await callAxios.api({
            url: m_staff_office_url,
            method: "get"
        })

        dev_console.log(m_staff_office_url);
        dev_console.log(response);

        return response;
    }

    useEffect(() => {
        if(staffoOfficeListError){
            dev_console.error(staffoOfficeListError);
        }
    }, [staffoOfficeListError])
    return({
        staffoOfficeList,
        staffoOfficeListError,
        isStaffoOfficeListLoading,
        staffoOfficeListRefetch
    })
}

export default useStaffOfficeListREST;