import { forwardRef, useEffect, useState } from "react";
import Img from "../Img/Img";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import useMedia from "./hooks/useMedia.hook";
import CardContainer from "../CardContainer/CardContainer";
import { aspectRatioType } from "./interface/Media.interface";
import { FILE_CODE } from "../../../Common/ts/const";
import { fileCodeType } from "../../../Common/interface/Common.interface";

interface mediaInterface{
    origin_src?: string;
    src?: string;
    id?: string;
    className?: string;
    onClick?: () => void;
    loading?: "eager" | "lazy";
    title?: string,
    object_fit?: "cover" | "fill" | "contain" | "scale-down" | "none";
    height_standard?: boolean;
    file_type?: fileCodeType;
    class_name_list?: string[];
    video_controls?: boolean;
    customdata?: string;
    aspect_ratio?: aspectRatioType;
}

const Media = forwardRef<HTMLImageElement | HTMLDivElement | HTMLVideoElement, mediaInterface> ((
    {
        origin_src, // file_type의 자동설정이 필요한 경우 경로를통해 자료형을 가져온다.
        src,
        id,
        onClick,
        loading,
        title,
        object_fit,
        height_standard,
        file_type, // file_type을 직접 설정하고싶은경우 사용
        class_name_list,
        video_controls,
        customdata,
        aspect_ratio,
    }, ref
) => {

    const {
        finalFileType,
        mainClassNameList
    } = useMedia({
        file_type,
        src,
        origin_src,
        class_name_list,
        aspect_ratio,
    })

    const nodeSetter = () => {
        let RTN: React.ReactNode | null = null;

        switch(finalFileType){
            case FILE_CODE.img:
                RTN = (
                    <Img
                        src={src}
                        id={id}
                        loading={loading}
                        title={title}
                        object_fit={object_fit}
                        height_standard={height_standard}
                        customdata={customdata}
                        ref={ref as React.Ref<HTMLImageElement>}
                    />
                )    
                break;
            case FILE_CODE.video:
                RTN = (
                    <VideoPlayer
                        ref={ref as React.Ref<HTMLVideoElement>}
                        src={src}
                        video_controls={video_controls}
                    />
                )    
                break;
            case FILE_CODE.zip:
                RTN = (
                    <div 
                        ref={ref as React.Ref<HTMLDivElement>}
                        id={id}
                    >
                        ZIP
                    </div>
                )   
                break;
            default:
                RTN = (
                    <Img
                        src={src}
                        id={id}
                        loading={loading}
                        title={title}
                        object_fit={object_fit}
                        height_standard={height_standard}
                        customdata={customdata}
                        ref={ref as React.Ref<HTMLImageElement>}
                    />
                )
            
        }

        return RTN;
    }

    const [node, setNode] = useState<React.ReactNode | null>(nodeSetter());

    useEffect(() => {
        setNode(nodeSetter());
    }, [
        origin_src,
        src,
        id,
        onClick,
        loading,
        title,
        object_fit,
        height_standard,
        file_type,
        class_name_list,
        video_controls,
        customdata,
        ref,
    ])

    return(
        <CardContainer
            class_name_list={mainClassNameList}
            onClick={onClick}
            preset={"img"}
            outline={"none"}
        >
            {node}
        </CardContainer>
    )
})

export default Media;