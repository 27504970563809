import { useQuery } from "@tanstack/react-query";
import { callAxios, dev_console, isSuccess, whoAmI } from "../../../Common/ts/commonTools";
import { RTN_interface } from "../../../Common/interface/App.interface";
import { defaultApiReaponseInterface } from "../../../Common/interface/Common.interface";
import { alarmNewCountData_Interface } from "../static/interface/AlarmList.interface";
import { useEffect } from "react";

const useAlarmNewCountREST = () => {
    const {
        data: alarmNewCountData = 0,
        isLoading: alarmNewCountIsLoading,
        error: alarmNewCountError,
        refetch: alarmNewCountRefetch,
    } = useQuery({
        queryKey: ["useAlarmNewCountREST"],
        queryFn: getAlarmNewCountData,
        enabled: isAbleToRequest,
        select: (response) => {
            const RTN: RTN_interface<number> = {
                sign: false,
                data: 0,
                msg: "",
            }

            SetData:{
                if(!isSuccess(response)){
                    RTN.msg = "알림의 수를 불러오는데 실패했습니다.";
                    break SetData;
                }

                const data: defaultApiReaponseInterface<alarmNewCountData_Interface> = response.data;

                RTN.sign = true;
                RTN.data = data.result.count;
                RTN.msg = "success";
            }

            if(!RTN.sign){
                dev_console.error(RTN.msg);
            }

            return RTN.data;
        }
    })

    function isAbleToRequest(){
        let RNT: boolean = false;

        if(whoAmI.isCustomer() || whoAmI.isAdmin()){
            RNT = true;
        }

        return RNT;
    }
    
    async function getAlarmNewCountData(){
        const m_alarm_new_count_url = "api/v3/oops/alarm/new-count";

        const response = await callAxios.api({
            url: m_alarm_new_count_url,
            method: "get",
        })

        dev_console.log(m_alarm_new_count_url);
        dev_console.log(response);

        return response;
    }

    useEffect(() => {
        if(alarmNewCountError){
            dev_console.error(alarmNewCountError);
        }
    }, [alarmNewCountError])

    return({
        alarmNewCountData,
        alarmNewCountIsLoading,
        alarmNewCountError,
        alarmNewCountRefetch,
    })
}

export default useAlarmNewCountREST;