import { useQuery } from "@tanstack/react-query";
import { callAxios, dev_console, isSuccess, whoAmI } from "../../../Common/ts/commonTools";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";
import { defaultApiReaponseInterface } from "../../../Common/interface/Common.interface";
import { contractListREST_Interface } from "../static/interface/contractListREST.interface";

const EMPTY_LIST: contractListREST_Interface[] = [];

const useContractListREST = () => {

    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer()){
            result = true;
        }

        return result;
    }

    const {
        data: contractListData = EMPTY_LIST,
        error: contractListDataError,
        isLoading: contractListDataLoading,
        refetch: contractListDataRefetch,
    } = useQuery({
        queryKey: ["useContractListREST"],
        queryFn: getContractListData,
        enabled: isAbleToRequest,
        select: (response) => {
            let RTN: contractListREST_Interface[] = EMPTY_LIST;

            if (isSuccess(response)) {
                const data: defaultApiReaponseInterface<contractListREST_Interface[]> = response.data;
                RTN = data.result;
            }

            return RTN;
        },
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
    })

    // 유저 개인 정보를 호출하는 함수
    async function getContractListData(){
        const m_contract_list_url = "api/v3/customers/contract/list";

        const response = await callAxios.api({
            url: m_contract_list_url,
            method: "get",
        })

        dev_console.log(m_contract_list_url);
        dev_console.log(response);

        return response;
    }
    
    return({
        contractListData,
        contractListDataError,
        contractListDataLoading,
        contractListDataRefetch,
    })
}

export default useContractListREST;