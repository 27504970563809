import CSS from "./static/css/DetailIconBtn.module.css";
import Button from "../Button/Normal/Button"
import DetailIcon from "../Icon/DetailIcon/DetailIcon"
import Text from "../Text/Text"

const DetailIconBtn = (
    {
        viewDetailFC
    }:{
        viewDetailFC: () => void;
    }
) => {
    const viewDetailHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();

        if(viewDetailFC){
            viewDetailFC();
        }
    }

    return(
        <Button
            preset={"detail_view"}
            onClick={viewDetailHandler}
        >
            <div className={CSS.l_DetailIconBtn_innerContainer}>
                <Text
                    text_wrap={"nowrap"}
                    preset={"detail_modal"}
                >
                    자세히 보기
                </Text>

                <DetailIcon />
            </div>
        </Button>
    )
}

export default DetailIconBtn;