import { forwardRef, useEffect, useState } from "react";
import CSS from "./static/css/Textarea.module.css";

interface textareaInterface{
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
    placeholder?: string;
    class_name_list?: string[];
    readOnly?: boolean;
    disabled?: boolean;
    value?: string;
}

const Textarea = forwardRef<HTMLTextAreaElement, textareaInterface>((
    {
        onChange,
        placeholder,
        class_name_list,
        readOnly,
        disabled,
        value,
    }, ref
) => {
    const [totalClassNameList, setTotalClassNameList] = useState<string>(classNameSetter().join(" "));

    function classNameSetter(){
        const RTN: string[] = [];

        if(class_name_list){
            for(const class_name of class_name_list){
                RTN.push(class_name);
            }
        }

        RTN.push(CSS.l_text_area_main);

        if(disabled){
            RTN.push(CSS.disabled);
        }
        
        return RTN;
    }

    useEffect(() => {
        setTotalClassNameList(classNameSetter().join(" "))
    }, [class_name_list, disabled])

    return(
        <textarea
            className={totalClassNameList}
            onChange={onChange}
            placeholder={placeholder}
            readOnly={readOnly}
            disabled={disabled}
            value={value}
            ref={ref}
        />
    )
})

export default Textarea;