import { useEffect, useState } from "react";
import { ProductDetail_Folder_PhotoListEle_Interface, ProductDetail_Folder_FolderListEle_Interface, ProductDetail_Interface, ProductDetail_OptionListEle_Interface, ProductDetail_productDetailData_Interface, ProductDetail_SelectedOptionListEle_Interface, ProductDetail_SelectInformationDate_AddedInfo_Interface, ProductDetail_SelectInformationDate_Address_Interface, ProductDetail_SelectInformationDate_Interface } from "../static/interface/ProductDetail.interface";
import { dateObjectManagerClass, dev_console } from "../../../Common/ts/commonTools";
import { PRODUCT_STATE_CODE_OBJ, PRODUCT_TYPE_CODE_OBJ } from "../static/ts/const";

const dateObjectManager = new dateObjectManagerClass();

const useProductDetailSetting = (
    {
        productDetailData,
    }:{        
        productDetailData: ProductDetail_Interface | null | undefined;
    }
) => {
    const [productDetailInfoData, setProductDetailInfoData] = useState<ProductDetail_productDetailData_Interface | null>(null);

    // 선택가능한 옵션들
    const [productOptionDataList, setProductOptionDataList] = useState<ProductDetail_OptionListEle_Interface[]>([]);
    
    // 폴더(사진 업로드 구분) 관련
    const [folderList, setFolderList] = useState<ProductDetail_Folder_FolderListEle_Interface[]>([]);
    
    // 선택한 옵션들
    const [selectOptionDataList, setSelectOptionDataList] = useState<ProductDetail_SelectedOptionListEle_Interface[]>([]);
    
    // 사용자가 추가 입력한 정보
    const [addedInfo, setAddedInfo] = useState<ProductDetail_SelectInformationDate_AddedInfo_Interface | null>(null);

    // 사용자가 추가한 주소 정보
    const [address, setAddress] = useState<ProductDetail_SelectInformationDate_Address_Interface | null>(null);
    
    // 선택한 사진 목록
    const [selectPhotoList, setSelectPhotoList] = useState<ProductDetail_Folder_PhotoListEle_Interface[]>([]);
    
    // 편집 진행중인 사진 목록?
    const [editPhotoList, setEditPhotoList] = useState<ProductDetail_Folder_PhotoListEle_Interface[]>([]);

    // 폴더구조가 아닌 단일로 처리되어야 하는가?
    const [isSingle, setIsSingle] = useState<boolean>(false);

    const [selectInformationDate, setSelectInformationDate] = useState<ProductDetail_SelectInformationDate_Interface | null>(null);

    useEffect(() => {
        if(productDetailData){
            setProductDetailInfoData(productDetailData.product_detail_data);

            setProductOptionDataList(productDetailData.product_option_data || []);

            const m_folderListData = [...productDetailData.folder_list_data || []];
            if(m_folderListData.length === 1 && m_folderListData[0].folder_id === 0){
                m_folderListData[0].folder_name = "사진 업로드";
                setIsSingle(true);
            }else{
                setIsSingle(false);
            }
            setFolderList(productDetailData.folder_list_data || []);
            
            setSelectOptionDataList(productDetailData.select_option_data || []);
            setSelectPhotoList(productDetailData.select_photo_list || []);
            setEditPhotoList(productDetailData.edit_photo_list || []);

            let m_addedInfo: ProductDetail_SelectInformationDate_AddedInfo_Interface | null = null;
            let m_address: ProductDetail_SelectInformationDate_Address_Interface | null = null;

            if(productDetailData.select_information_data && productDetailData.select_information_data.length > 0){
                const m_select_info = productDetailData.select_information_data[0];
                setSelectInformationDate(m_select_info);

                const l_baby_birth_date = new Date(m_select_info.baby_birth_day)
                const l_baby_birth_day_weekday = dateObjectManager.getWeekday(l_baby_birth_date);
                const l_baby_birth_day = `${m_select_info.baby_birth_day} (${l_baby_birth_day_weekday})`;

                m_addedInfo = {
                    baby_name: m_select_info.baby_name,
                    baby_birth_day: l_baby_birth_day,
                    baby_birth_time: m_select_info.baby_birth_time,
                    baby_height: m_select_info.baby_height,
                    baby_weight: m_select_info.baby_weight,
                    common_memo: m_select_info.common_memo,
                }
                
                m_address = {
                    addr: m_select_info.addr,
                    addr_detail: m_select_info.addr_detail,
                    building_no: m_select_info.building_no,
                    zip_code: m_select_info.zip_code,
                    recipient_name: m_select_info.recipient_name,
                    recipient_phone: m_select_info.recipient_phone,
                }
            }
            
            setAddedInfo(m_addedInfo);
            setAddress(m_address);
        }else{
            setProductDetailInfoData(null);
            setProductOptionDataList([]);
            setFolderList([]);
            setSelectOptionDataList([]);
            setSelectPhotoList([]);
            setEditPhotoList([]);
            setAddedInfo(null);
            setAddress(null);
        }

    }, [productDetailData])

    return({
        productDetailInfoData,
        productOptionDataList,
        folderList,
        initialSelectOptionDataList: selectOptionDataList,
        initialSelectPhotoList: selectPhotoList,
        initialEditPhotoList: editPhotoList,
        addedInfo,
        address,
        isSingle,
        isAlbum: productDetailInfoData?.product_type === PRODUCT_TYPE_CODE_OBJ.album,
        selectInformationDate,
        isCanReupload: 
            selectInformationDate?.status_cd === PRODUCT_STATE_CODE_OBJ.application_in_progress
            && (selectInformationDate?.request_num && selectInformationDate.request_num < 2) ? true : false,
    })
}

export default useProductDetailSetting;