
import Text from "../../../../../Component/Elements/Text/Text";
import CSS from "./static/css/OrderBoard.module.css";

const OrderBoard = (
    {
        order,
        maximum,
    }:{
        order: number;
        maximum: number;
    }
) => {
    return(
        <div className={CSS.l_order_board_main}>
            <Text
                size={"size1"}
                color={"white"}
            >
                {order}/{maximum}
            </Text>
        </div>
    )
}

export default OrderBoard;