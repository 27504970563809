import { useEffect, useState } from "react";
import { textCopy } from "../../../../Common/ts/commonTools";
import CSS from "./static/css/Copy.module.css";
import copy_coin from "./static/img/icon_copy_16@3x.png";

const Copy = (
    {
        copy_target_text,
        copy_message,
    }:{
        copy_target_text: string;
        copy_message?: string;
    }
) => {
    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    function classNameSetter(){
        const RTN: string[] = [];

        RTN.push("g_click_event_item");
        if(!copy_target_text){
            RTN.push("not_allowed");
        }

        RTN.push(CSS.l_Copy_main);

        return RTN;
    }

    const l_toastId: string = "copy_result_toast";

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "));
    }, [copy_target_text])

    return(
        <span 
            className={mainClassName}
            onClick={textCopy.bind(null, {copy_target_text, toast_id: l_toastId, copy_message})}
        >
            <img
                className={CSS.l_Copy_icon}
                src={copy_coin}
            />
        </span>
    )
}

export default Copy;