import { useQuery } from "@tanstack/react-query";
import { callAxios, customToast, dev_console, whoAmI } from "../../../Common/ts/commonTools";
import { useEffect } from "react";
import { photoReadDataInterface } from "../static/interface/PhotoList.interface";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";
import { photoDetailsTypeCodeType, photoTypeCodeType } from "../static/interface/const.interface";
import { RTN_interface } from "../../../Common/interface/App.interface";

const EMPTY_LIST: photoReadDataInterface[] = [];

const MAIN_TYPE_VALUE_LIST = ["", "나의 사진", "후기 사진"];
const STATUS_VALUE_LIST = ["", "원본", "편집 진행중", "편집 완료"];

const TOAST_ID = "usePhotoListDataREST";

const usePhotoListDataREST = (
    {
        main_type,
        status,
    }:{
        main_type: photoTypeCodeType
        status: photoDetailsTypeCodeType
    }
) => {
    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer()){
            result = true;
        }

        return result;
    }

    const {
        data: photoListData = EMPTY_LIST,
        error: photoListDataError,
        isLoading: photoListDataIsLoading,
        refetch: photoListDataRefetch,
    } = useQuery({
        queryKey: ["photoListData", main_type, status],
        queryFn: callPhotoListData,
        enabled: isAbleToRequest,
        select: (response) => {
            const RTN: RTN_interface<photoReadDataInterface[]> = {
                sign: false,
                data: EMPTY_LIST,
                msg: "",
            };

            SetData:{
                if(!response){
                    RTN.msg = `${MAIN_TYPE_VALUE_LIST[main_type]}의 ${STATUS_VALUE_LIST[status]} 사진 목록 요청 과정에서 문제가 발생했습니다.`;
                    break SetData;
                }
                
                if((response.status !== 200 || response.data.msg !== "success") && response.data.msg !== "empty"){
                    RTN.msg = `${MAIN_TYPE_VALUE_LIST[main_type]}의 ${STATUS_VALUE_LIST[status]} 사진 목록 요청에 실패했습니다.`;
                    break SetData;
                }

                const result_data: photoReadDataInterface[]  = response.data.datas

                RTN.sign = true;
                RTN.data = result_data;
            }

            if(!RTN.sign){
                customToast.error({
                    msg: RTN.msg,
                    toastId: TOAST_ID,
                });
            }

            return RTN.data;
        },
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
    })
    
    async function callPhotoListData(){
        const photo_read_url = `api/v3/customers/photo_read?main_type=${main_type}&status=${status}`;
        const response = await callAxios.api({
            url: photo_read_url,
            method: "get",
        })  

        dev_console.log(photo_read_url);
        dev_console.log(response);

        return response;
    }

    useEffect(() => {
        if(!!photoListDataError){
            dev_console.error(photoListDataError);
            customToast.error({
                msg: `${MAIN_TYPE_VALUE_LIST[main_type]}의 ${STATUS_VALUE_LIST[status]} 사진 목록을 요청하는 과정에서 문제가 발생했습니다.`,
                toastId: TOAST_ID
            });
        }
    }, [photoListDataError, main_type, status])

    return ({
        photoListData,
        photoListDataError,
        photoListDataIsLoading,
        photoListDataRefetch,
    })
}

export default usePhotoListDataREST;