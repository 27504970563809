import { useNavigate } from "react-router-dom";
import Button from "../../../../Component/Elements/Button/Normal/Button";
import CSS from "./static/css/ConfrimBtnContainer.module.css";
import { useCallback } from "react";
import { productStateCodeType } from "../../static/interface/ProductList.interface";
import { refusalBtnHandler } from "../../static/ts/ProductConfrim.tools";

const ConfrimBtnContainer = (
    {
        status_cd,
        contract_product_id,
        album_save_id,
        photo_upload_id,
        request_num,
        request,
    }:{
        status_cd?: productStateCodeType | undefined
        contract_product_id: number;
        album_save_id: number | null;
        photo_upload_id: number | null;
        request_num: number;
        request: string;
    }
) => {
    const navigate = useNavigate();

    const cancelBtnHandler = useCallback(() => {
        const m_queryParams = new URLSearchParams({ 
            status_cd: `${status_cd || ""}`,
            contract_product_id: `${contract_product_id || ""}`,
            album_save_id: `${album_save_id || ""}`,
            photo_upload_id: `${photo_upload_id || ""}`,
        }).toString();

        navigate(`/productDetail?${m_queryParams}`)
    }, [])

    return(
        <div className={CSS.l_ConfrimBtnContainer_main}>
            <Button
                size={"size1"}
                preset={"normal_gray"}
                onClick={cancelBtnHandler}
            >
                취소
            </Button>
            <Button 
                size={"size2"}
                onClick={refusalBtnHandler.bind(null, {
                    photo_upload_id,
                    contract_product_id,
                    request_num,
                    request,
                    completActionHandler: cancelBtnHandler,
                })}
            >
                편집 요청
            </Button>
        </div>
    )
}

export default ConfrimBtnContainer;