import { createContext, useEffect, useRef, useState } from 'react';
import { Routes, Route, Navigate, useSearchParams } from 'react-router-dom';
import { appVersionManagerClass, backPointUrlManagerClass, setCookie, webViewBridgeClass } from './Common/ts/appTools';
import { callAxios, dev_console, getAccountData, isMaintenancePageOn, isSuccess, openInExternalBrowser, printStateMsg, whatIsMode, whichBrowserDoYouUse } from './Common/ts/commonTools';
// import * as Sentry from "@sentry/react";

// 공용 페이지
import Login from './PublicPage/Login/Login';
import Header from './PublicPage/Header/Header';

// 고객 페이지
import ReservationHome from './CustomerPage/Reservation/ReservationHome';

import ResetUserPassword from './PublicPage/ResetUserPassword/ResetUserPassword';

import SelectTheme from './CustomerPage/Reservation/SelectTheme';
import PhotoListMain from './CustomerPage/PhotoList/PhotoListMain';
import PhotoDetail from './CustomerPage/PhotoList/PhotoDetail';
import ThemeBookHome from './PublicPage/ThemeBook/ThemeBookHome';

import ThemeBookManagement from './AdminPage/ThemeBookManagement/ThemeBookManagement';

import CSS from "./Common/css/common.module.css";
import { defaultApiReaponseInterface, tokenCheckInterface } from './Common/interface/Common.interface';
import DeleteURL from './PublicPage/DeleteURL/DeleteURL';
import TestPage from './TestPage/TestPage';
import Toast from './Component/Elements/Toast/Toast';
import CustomerNavi from './PublicPage/Navi/CustomerNavi';
import AdminNavi from './PublicPage/Navi/AdminNavi';
import PaymentHistory from './CustomerPage/PaymentHistory/PaymentHistory';
import RedirectToAnotherBrowser from './PublicPage/RedirectToAnotherBrowser/RedirectToAnotherBrowser';
import ProductList from './CustomerPage/ProductManufacturing/ProductList';
import ProductDetail from './CustomerPage/ProductManufacturing/ProductDetail';
import { mobileBackgroundColorType } from './Common/interface/style.interface';
import { BROWSER_CODE } from './Common/ts/const';
import ProductRequestEdit from './CustomerPage/ProductManufacturing/ProductRequestEdit';
import ProductConfirm from './CustomerPage/ProductManufacturing/ProductConfirm';
import MainHome from './CustomerPage/MainHome/MainHome';
import ChangePassword from './CustomerPage/ChangePassword/ChangePassword';
import MaintenancePage from './PublicPage/MaintenancePage/MaintenancePage';
import MyPage from './CustomerPage/MyPage/MyPage';
import { RTN_interface } from './Common/interface/App.interface';
import useAlarmNewCountREST from './PublicPage/Header/hooks/useAlarmNewCountREST.hook';
import useAlarmListREST from './PublicPage/Header/hooks/useAlarmListREST.hook';

const webViewBridge = new webViewBridgeClass();

const appVersionManager = new appVersionManagerClass();

const backPointUrlManager = new backPointUrlManagerClass();

type headerNodeSetterType = React.Dispatch<React.SetStateAction<React.ReactNode | null>>;

export interface commonContextInterface{
    setIsHeaderHidden: React.Dispatch<React.SetStateAction<boolean>>;
    setHeaderLeftNode: headerNodeSetterType;
    setMobileHeaderTitle: React.Dispatch<React.SetStateAction<string>>;
    setHeaderRightNode: headerNodeSetterType;
    setHeaderBackgroundColorPreset: React.Dispatch<React.SetStateAction<mobileBackgroundColorType>>;
    setIsNaviHidden: React.Dispatch<React.SetStateAction<boolean>>;
}

const commonContextDafuiltValue:commonContextInterface = {
    setIsHeaderHidden: () => {},
    setHeaderLeftNode: () => {},
    setMobileHeaderTitle: () => {},
    setHeaderRightNode: () => {},
    setHeaderBackgroundColorPreset: () => {},
    setIsNaviHidden: () => {},
}

// 고객 계정에서 사용하는 context
export const commonContext = createContext<commonContextInterface>(commonContextDafuiltValue);

function App() {

    // Sentry.init({
    //     dsn: "http://7a7a5b86a4a24b1548ee7ccc299c247d@124.111.208.229:9009/3",
    //     integrations: [
    //       Sentry.browserTracingIntegration(),
    //     //   Sentry.replayIntegration(),
    //     ],
    //     // Performance Monitoring
    //     tracesSampleRate: 1.0, //  Capture 100% of the transactions
    //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    //     // Session Replay
    //     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    //     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // });

    // 접속한 환경이 카카오톡, 라인 인앱브라우저인 경우 외부 브라우저에서 열리게 한다.
    openInExternalBrowser.auto_open();

    const pageRouterCode_obj = {
        none: 0,
        login: 1,
        customer: 2,
        admin: 3,
    } as const;

    type pageRouterCode_type = typeof pageRouterCode_obj [keyof typeof pageRouterCode_obj];

    const [pageRouterCode, setPageRouterCode] = useState<pageRouterCode_type>(pageRouterCode_obj.none);

    // 네비게이션바 컨트롤
    const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
    // 헤더 숨김 여부
    const [isHeaderHidden, setIsHeaderHidden] = useState<boolean>(false);
    // 헤더의 왼쪽에 삽입할 컴포넌트
    const [headerLeftNode, setHeaderLeftNode] = useState<React.ReactNode | null>(null);
    // 헤더의 가운데 영역에 출력할 타이틀 텍스트
    const [mobileHeaderTitle, setMobileHeaderTitle] = useState<string>("");
    // 헤더의 오른쪽영역에 삽입할 컴포넌트
    const [headerRightNode, setHeaderRightNode] = useState<React.ReactNode | null>(null);
    // 헤더의 배경색(모바일에서)
    const [headerBackgroundColorPreset, setHeaderBackgroundColorPreset] = useState<mobileBackgroundColorType>("");
    // 네비게이션바 숨김여부
    const [isNaviHidden, setIsNaviHidden] = useState<boolean>(false);

    const [URLSearchParamsValue, SetURLSearchParamsValue] = useSearchParams();

    // 실제로 컨텐츠들이 출력되는 영역의 ref
    const containerRef = useRef<HTMLDivElement>(null);

    // 서버 개발중 페에지 출력 여부
    const l_isMaintenancePageOn = isMaintenancePageOn();

    async function sendDeviceInfo(){
        // flutter에 모바일기기 고유 토큰값을 요청
        const m_deviceToken_data = await webViewBridge.getAuthToken();

        SendDeviceData:{
            // flutter에서 정상적으로 토큰을 확보한 경우에만 서버로 요청을 보낸다.(즉 웹환경 등일때는 동작 안함)
            if(!m_deviceToken_data.sign || !m_deviceToken_data.data){
                break SendDeviceData;
            }

            try{
                const m_device_save_url = "api/v3/oops/customer/device/save";
        
                const m_config = {
                    device_token: m_deviceToken_data.data.fcmToken,
                    device_type: m_deviceToken_data.data.deviceType,
                    app_version: m_deviceToken_data.data.appVersion,
                }

                const m_response = await callAxios.api({
                    url: m_device_save_url,
                    method: "post",
                    dataType: "json",
                    data: m_config,
                })
    
                dev_console.log(m_device_save_url);
                dev_console.log(m_response);
            }catch(error){
                dev_console.error(error, "Error sendDeviceInfo");
            }

            // 버전 체크
            if(m_deviceToken_data.data.appVersion){
                // flutter에 app의 최신 버전 정보를 넘긴다.
                await webViewBridge.checkAppVersion(appVersionManager.latestAppVersion);
            }else{
                // App Version관리 패치가 적용되지 않는 app사용자가 있을 경우를 대비하여 해당경우에만 alert생성
                alert("OOPS의 버전이 낮습니다.\n\nOOPS를 최신 버전으로 업데이트해 주세요.");
            }
        }
    }

    // 토큰을 서버로 보내서 검증하는 함수.
    async function authTokenChecker(token: string){
        const RTN: RTN_interface<number> = {
            sign: false,
            data: 0,
            msg: ""
        }

        IsHaveToken:{
            if(!token){
                RTN.sign = true;
                RTN.data = -1;
                RTN.msg = "have not token";
                break IsHaveToken;
            }
        }

        let l_data: defaultApiReaponseInterface<tokenCheckInterface> | null = null;
        AccountDataReqeuset:{
            if(RTN.sign){
                break AccountDataReqeuset;
            }

            // 토큰값 검증
            const web_token_api = 'api/v3/accounts/web-token';

            const response = await callAxios.api({
                url: web_token_api,
                method: 'post',
                self_token: token,
            })
    
            dev_console.log(web_token_api);
            dev_console.log(response);

            l_data = response.data;

            if(!response){
                RTN.sign = true;
                RTN.data = -2;
                RTN.msg = "empty reponse";

                break AccountDataReqeuset;
            }

            if(!isSuccess(response)){
                RTN.sign = true;
                RTN.data = -3;
                RTN.msg = "not nomal response";
    
                break AccountDataReqeuset;
            }
        }

        CheckAccountData:{
            if(RTN.sign){
                break CheckAccountData;
            }

            if(l_data === null){
                RTN.sign = true;
                RTN.data = -4;
                RTN.msg = "response data is null";

                break CheckAccountData;
            }

            if(l_data.result.msg !== "valid"){
                RTN.sign = true;
                RTN.data = -5;
                RTN.msg = "invalid token";

                break CheckAccountData;
            }
        }

        // 토큰 검증과정에서 새로받은 유저 타입
        const m_new_user_type = l_data?.result.user_type || "";

        SetAccountData:{
            if(RTN.sign || !l_data){
                break SetAccountData;
            }

            // 모바일환경일경우 기기 고유 토큰값을 서버로 보낸다.
            await sendDeviceInfo();

    
            const m_days:number = 0;
    
            setCookie({name: "user_type", value: m_new_user_type, days: m_days});
            setCookie({name: "oops_id", value: l_data.result.oops_id, days: m_days});
            setCookie({name: "name", value: l_data.result.name, days: m_days});
            setCookie({name: "user_id", value: `${l_data.result.user_id || ""}`, days: m_days});
            setCookie({name: "office_id", value: `${l_data.result.office_id || ""}`, days: m_days});
            setCookie({name: "office_name", value: l_data.result.office_name, days: m_days});
            setCookie({name: "office_addr", value: l_data.result.office_addr, days: m_days});
            setCookie({name: "office_addr_detail", value: l_data.result.office_addr_detail, days: m_days});
            setCookie({name: "brand_id", value: `${l_data.result.brand_id || ""}`, days: m_days});
            setCookie({name: "brand_name", value: l_data.result.brand_name, days: m_days});

            RTN.sign = true;
            RTN.data = 1;
            RTN.msg = "success";
        }

        SetPage:{
            if(RTN.data < 1){
                setPageRouterCode(pageRouterCode_obj.login);
                break SetPage;
            }

            switch(m_new_user_type){
                case "E":
                    setPageRouterCode(pageRouterCode_obj.admin);
                    break;
                case "C":
                    setPageRouterCode(pageRouterCode_obj.customer);
                    break;
                default:
                    setPageRouterCode(pageRouterCode_obj.login);
            }
        }
    }

    useEffect(() => {
        const m_token = getAccountData.token();

        // 서비스 접근을 막은상태에선 토큰검증처리를 할 필요가 없다고 판단됨.
        if(!l_isMaintenancePageOn){
            authTokenChecker(m_token);
        }

        // service worker
        if ('serviceWorker' in navigator) {
            const registInit = async () => {
                const registration = await navigator.serviceWorker.register('/service-worker.js');

                registration.waiting?.postMessage('SKIP_WATING');
            };
            registInit();
        }
    }, []);

    const {
        alarmNewCountRefetch,
    } = useAlarmNewCountREST();

    const {
        alarmListRefetch
    } = useAlarmListREST();

    // 접속한 주소에 push_id가 있다면, 해당 값을 서버로 보냄
    useEffect(() => {
        const m_push_id = backPointUrlManager.getPushId(URLSearchParamsValue);

        if(m_push_id){
            const m_url = "api/v3/oops/customer/push/read"

            const config = {
                push_id: m_push_id,
            }

            callAxios.api({
                url: m_url,
                method: "post",
                dataType: "json",
                data: config
            })
            .then((response) => {
                // 성공이던, 실패던, oops에서 취해야할동작은 딱히 없겠지???
                dev_console.error(`${m_url} requset response ===>`, response);

                // 알림 목록 갱신
                alarmListRefetch();

                // 알림 개수 갱신
                alarmNewCountRefetch();
            })
            .catch((error) => {
                dev_console.error(`${m_url} requset error ===>`, error);
            })
            .finally(() => {
                const newParams = new URLSearchParams(URLSearchParamsValue.toString());
                newParams.delete("push_id");

                SetURLSearchParamsValue(newParams, {replace: true});
            })
        }
    }, [URLSearchParamsValue])

    // 브라우저 환경 감지
    const l_browserCode = whichBrowserDoYouUse();
    if(l_browserCode === BROWSER_CODE.KakaoTalk || l_browserCode === BROWSER_CODE.LINE){
        return(
            <div className={CSS.l_body}>
                <RedirectToAnotherBrowser />
            </div>
        )
    }

    // 페이지에 접근 못하게 막음
    if(l_isMaintenancePageOn){
        return(
            <div className={CSS.l_body}>
                <div 
                    className={CSS.l_container}
                    ref={containerRef}
                >
                    <main className={CSS.l_main_contents}>
                        <Routes>
                            <Route path="/maintenancePage" element={<MaintenancePage />} />
                            <Route path='*' element={<Navigate replace to='/maintenancePage' />} />
                        </Routes>
                    </main>
                </div>
            </div>
        )
    }else if (pageRouterCode === pageRouterCode_obj.customer) {
        // 로그인한 사람이 고객
        return (
            <commonContext.Provider value={{
                setIsHeaderHidden,
                setHeaderLeftNode,
                setMobileHeaderTitle,
                setHeaderRightNode,
                setHeaderBackgroundColorPreset,
                setIsNaviHidden,
            }}>
                <div className={CSS.l_body}>
                    <Toast position={"top-center"}/>

                    {/* 네비게이션 바 */}
                    {
                        !isNaviHidden
                        &&  <CustomerNavi 
                                isOpenMenu={isOpenMenu}
                                setIsOpenMenu={setIsOpenMenu}
                            />
                    }

                    <div 
                        className={CSS.l_container}
                        ref={containerRef}
                    >
                        {
                            !isHeaderHidden
                            &&  <Header 
                                    setIsOpenMenu={setIsOpenMenu}
                                    headerLeftNode={headerLeftNode}
                                    headerRightNode={headerRightNode}
                                    mobileHeaderTitle={mobileHeaderTitle}
                                    headerBackgroundColorPreset={headerBackgroundColorPreset}
                                />
                        }

                        <main className={CSS.l_main_contents}>
                            {/* 라우팅 */}
                            <Routes>
                                <Route path="/" element={<MainHome />} />
                                
                                <Route path="/myPage" element={<MyPage />} />
                                <Route path="/changePassword" element={<ChangePassword />} />

                                <Route path="/reservationHome" element={<ReservationHome />} />
                                <Route path="/selectTheme" element={<SelectTheme />} />

                                <Route path="/photoList" element={<PhotoListMain />} />
                                <Route path="/photoDetail" element={<PhotoDetail />} />

                                <Route path="/productList" element={<ProductList />} />
                                <Route path="/productDetail" element={<ProductDetail />} />
                                <Route path="/productConfirm" element={<ProductConfirm />} />
                                <Route path="/productRequestEdit" element={<ProductRequestEdit />} />

                                <Route path="/themeBook" element={<ThemeBookHome />} />

                                <Route path="/paymentHistory" element={<PaymentHistory />} />

                                {
                                    whatIsMode().is_not_product_mode_safe
                                    &&  <Route path="/test_page" element={<TestPage />} />
                                }

                                {
                                    whatIsMode().is_not_product_mode_safe
                                    &&  <Route path="/deleteM" element={<DeleteURL />} />
                                }

                                <Route path='*' element={<Navigate replace to='/' />} />
                            </Routes>
                        </main>
                    </div>
                </div>
            </commonContext.Provider>
        );
    }else if(pageRouterCode === pageRouterCode_obj.admin){
        return (
            <commonContext.Provider 
                value={{
                    setIsHeaderHidden,
                    setHeaderLeftNode,
                    setMobileHeaderTitle,
                    setHeaderRightNode,
                    setHeaderBackgroundColorPreset,
                    setIsNaviHidden,
                }}
            >
                <div className={CSS.l_body}>
                    <Toast position={"top-center"}/>

                    {
                        !isNaviHidden
                        &&  <AdminNavi 
                                isOpenMenu={isOpenMenu}
                                setIsOpenMenu={setIsOpenMenu}
                            />
                    }

                    <div 
                        className={CSS.l_container}
                        ref={containerRef}
                    >
                        {
                            !isHeaderHidden
                            &&  <Header 
                                    setIsOpenMenu={setIsOpenMenu}
                                    headerLeftNode={headerLeftNode}
                                    headerRightNode={headerRightNode}
                                    mobileHeaderTitle={mobileHeaderTitle}
                                    headerBackgroundColorPreset={headerBackgroundColorPreset}
                                />
                        }

                        <main className={CSS.l_main_contents}>
                            <Routes>
                                <Route path='/' element={<ThemeBookHome />} />
                                <Route path="/themeBook" element={<ThemeBookHome />} />
                                <Route path="/themeBookManagement" element={<ThemeBookManagement />} />
                                
                                {
                                    whatIsMode().is_not_product_mode_safe
                                    &&  <Route path="/test_page" element={<TestPage />} />
                                }

                                {
                                    whatIsMode().is_not_product_mode_safe
                                    &&  <Route path="/deleteM" element={<DeleteURL />} />
                                }

                                <Route path='*' element={<Navigate replace to='/' />} />
                            </Routes>
                        </main>
                    </div>
                </div>
            </commonContext.Provider>
        )
    }else if(pageRouterCode === pageRouterCode_obj.login){
        // 로그인 페이지
        return (
            <div className={CSS.l_body}>
                <Toast position={"top-left"}/>
                <Routes>
                    <Route path='/' element={<Login />} />
                    <Route path="/resetUserPassword" element={<ResetUserPassword />} />
                    <Route path="/deleteM" element={<DeleteURL />} />

                    {
                        whatIsMode().is_not_product_mode_safe
                        &&  <Route path="/test_page" element={<TestPage />} />
                    }

                    <Route path='*' element={<Login />} />
                </Routes>
            </div>
        );
    }else{
        return (
            <div className={CSS.l_body}>
                <Toast position={"top-left"}/>
                <Routes>
                    <Route path='*' element={null}/>
                </Routes>
            </div>
        );
    }
}

export default App;
