import CSS from "./static/css/BottomSheet.module.css";
import { MouseEventHandler, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import SlideControlBar from "./components/BottomSheetControlBar/BottomSheetControlBar";
import { isHiddenType } from "../../../Common/interface/Common.interface";
import { isHiddenClassNameSetter } from "../../../Common/ts/commonTools";
import MoveArrowBtn from "../Overlay/components/MoveArrowBtn/MoveArrowBtn";

const BottomSheet = (
    {
        isViewing,
        closeHandler,
        isHidden,
        children,
        prevThemeHandler,
        nextThemeHandler,
        min_heigth_full
    }:{
        isViewing: boolean;
        closeHandler?: () => void;
        isHidden?: isHiddenType;
        children?: ReactNode;
        prevThemeHandler?: MouseEventHandler<HTMLDivElement>;
        nextThemeHandler?: MouseEventHandler<HTMLDivElement>;
        min_heigth_full?: boolean;
    }
) => {
    const mainClassName = useMemo(() => {
        let RTN: string[] = [];

        if(isHidden){
            RTN = isHiddenClassNameSetter({
                classNameList: RTN,
                isHidden,
                display: "flex"
            })
        }

        RTN.push(CSS.l_BottomSheet_main);

        if(isViewing){
            RTN.push(CSS.open);
        }

        if(min_heigth_full){
            RTN.push(CSS.min_heigth_full);
        }

        return RTN.join(" ");
    }, [isHidden, isViewing, min_heigth_full]);

    const bottomSheetRef = useRef<HTMLDivElement | null>(null);

    return(
        <div 
            className={mainClassName}
            ref={bottomSheetRef}
        >
            <SlideControlBar onClick={closeHandler}/>

            <div className={CSS.l_BottomSheet_childrenContainer}>
                {children}

                {
                    prevThemeHandler
                    &&  <MoveArrowBtn
                            diraction={"left"}
                            onClick={prevThemeHandler}
                        />
                }

                {
                    nextThemeHandler
                    &&  <MoveArrowBtn 
                            diraction={"right"}
                            onClick={nextThemeHandler}
                        />
                }
            </div>
        </div>
    )
}

export default BottomSheet;