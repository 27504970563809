import CSS from "./static/css/MyPage.module.css"
import { useEffect, useState } from "react";
import AccountPage from "../../Component/Elements/Page/AccountPage/AccountPage";
import { isAbleAccesser } from "../../Common/ts/commonTools";
import AccountPageContentsCardContainer from "../../Component/Elements/AccountPageContentsCardContainer/AccountPageContentsCardContainer";
import Button from "../../Component/Elements/Button/Normal/Button";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { backPointUrlManagerClass } from "../../Common/ts/appTools";
import LabelAndContent from "../../Component/Elements/LabelAndContent/LabelAndContent";
import NameInput from "../../PublicPage/ResetUserPassword/components/NameInput";
import PhoneNumberInput from "../../PublicPage/ResetUserPassword/components/PhoneNumberInput";
import IDInput from "../../PublicPage/ResetUserPassword/components/IDInput";
import useUserInfoDataREST from "../../Common/hooks/useUserInfoDataREST.hook";
import Hr from "../../Component/Elements/Hr/Hr";

const backPointUrlManager = new backPointUrlManagerClass();

const MyPage = () => {

    const [name, setName] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [id, setId] = useState<string>("");

    const [URLSearchParams, SetURLSearchParams] = useSearchParams();

    const {userInfoData} = useUserInfoDataREST();

    const navigate = useNavigate();
    const location = useLocation();

    const goToOutHandler = () => {
        let m_back_point_url = backPointUrlManager.getBackPointUrlAndDecode(URLSearchParams);

        if(!m_back_point_url){
            m_back_point_url = "/";
        }

        navigate(m_back_point_url, {replace: true});
    }

    const goToChangePassword = () => {
        let m_back_point_url = backPointUrlManager.addSelfAddBackPointQueryParams({
            url: "/changePassword",
            location,
        });

        navigate(m_back_point_url);
    }

    useEffect(() => {
        isAbleAccesser({
            allowedUserTypeList: ["C"]
        })
    }, [])

    useEffect(() => {
        if(userInfoData){
            setName(userInfoData.mom_name);
            setPhone(userInfoData.phone);
            setId(userInfoData.login_id);
        }
    }, [userInfoData])

    return(
        <AccountPage>
            <div className={CSS.l_MyPage_main}>
                <AccountPageContentsCardContainer
                    title="마이페이지"
                    contents={
                        <div className={CSS.l_MyPage_mainContainer}>
                            <div className={CSS.l_MyPage_localContainer}>
                                <LabelAndContent
                                    label={"성함"}
                                    flex_direction={"column"}
                                >
                                    <NameInput 
                                        value={name}
                                        disabled={true}
                                    />
                                </LabelAndContent>
                                <LabelAndContent
                                    label={"전화번호"}
                                    flex_direction={"column"}
                                >
                                    <PhoneNumberInput
                                        value={phone}
                                        disabled={true}
                                    />
                                </LabelAndContent>
                            </div>

                            <Hr />

                            <div className={CSS.l_MyPage_localContainer}>
                                <LabelAndContent
                                    label={"ID"}
                                    flex_direction={"column"}
                                >
                                    <IDInput 
                                        value={id}
                                        disabled={true}
                                    />
                                </LabelAndContent>
                                <LabelAndContent
                                    label={"비밀번호"}
                                    flex_direction={"column"}
                                >
                                    <Button
                                        preset={"normal_gray"}
                                        size={"auto"}
                                        onClick={goToChangePassword}
                                    >
                                        비밀번호 변경
                                    </Button>
                                </LabelAndContent>
                            </div>
                        </div>
                    }
                    bottom_content={
                        <div className={CSS.l_MyPage_btnContainer}>
                            <Button
                                size={"size3"}
                                onClick={goToOutHandler}
                            >
                                마이페이지 나가기
                            </Button>
                        </div> 
                    }
                />
            </div>
        </AccountPage>
    )
}

export default MyPage;