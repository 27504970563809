import Text from "../Text/Text";
import CSS from "./static/css/AccountPageContentsCardContainer.module.css";

const AccountPageContentsCardContainer = (
    {
        title,
        contents,
        bottom_content
    }: {
        title?: string,
        contents?: React.ReactNode,
        bottom_content?: React.ReactNode,
    }
) => {
    return (
        <div className={CSS.l_AccountPageContentsCardContainer_main}>
            <div className={CSS.l_AccountPageContentsCardContainer_mainWrapper}>
                {
                    title
                    &&  <div className={CSS.l_AccountPageContentsCardContainer_title}>
                            <Text 
                                preset={"AccoutPageTitle"}>
                                {title}
                            </Text>
                        </div>
                }
                
                {
                    contents
                    &&  <div className={CSS.l_AccountPageContentsCardContainer_contentContainer}>
                            {contents}
                        </div>
                }
            </div>
            {
                bottom_content
                &&  <div className={CSS.l_AccountPageContentsCardContainer_buttonContainer}>
                        {bottom_content}
                    </div>
            }
        </div>
    );
}

export default AccountPageContentsCardContainer;