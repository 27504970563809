import CSS from "./static/css/ViewAndHidenIcon.module.css";
import { useEffect, useState } from "react";
import view_icon from "./static/img/View.svg";
import hidden_icon from "./static/img/View_hide.svg";

const ViewAndHidenIcon = (
    {
        onClick,
        is_enable,
    }:{
        onClick: React.MouseEventHandler<HTMLDivElement>; 
        is_enable: boolean;
    }
) => {
    const [totalClassName, setTotalClassName] = useState<string>(CSS.l_ViewAndHidenIcon_main);

    useEffect(() => {
        const total_class_name_list: string[] = [];

        total_class_name_list.push(CSS.l_ViewAndHidenIcon_main);

        setTotalClassName(total_class_name_list.join(" "));
    }, [])

    return(
        <img 
            className={totalClassName}
            src={
                is_enable
                ?   view_icon
                :   hidden_icon
            } 
            title={
                is_enable
                ?   "비밀번호 보임"
                :   "비밀번호 숨김"
            } 
            onClick={onClick}
        />
    )
}

export default ViewAndHidenIcon;