import CSS from "./static/css/ThemeItemCard.module.css";
import { imageUrlInterface } from "../../../../Common/interface/Common.interface";
import CardContainer from "../../../../Component/Elements/CardContainer/CardContainer";
import Img from "../../../../Component/Elements/Img/Img";
import Text from "../../../../Component/Elements/Text/Text";

const ThemeItemCard = (
    {
        img_src,
        photo_product_name,
        theme_name,
        office_name,
        onClick,
        is_empty_content,
    }:{
        img_src: imageUrlInterface | null;
        photo_product_name: string;
        theme_name: string;
        office_name: string;
        onClick?: React.MouseEventHandler<HTMLDivElement>;
        is_empty_content?: boolean;
    }
) => {
    return (
        <CardContainer 
            class_name_list={[CSS.l_ThemeItemCard_main]}
            onClick={onClick}
        >
            <CardContainer
                class_name_list={[CSS.l_ThemeItemCard_thumbnailContainer]}
                padding="none"
                preset="img"
            >
                <Img 
                    src={img_src?.middle_url}
                    object_fit={"cover"}
                />
            </CardContainer>
            <div className={CSS.l_ThemeItemCard_infoContainer}>
                <Text
                    class_name_list={[CSS.l_ThemeItemCard_photoProductName]}
                    is_text_truncation_on={true}
                >
                    {photo_product_name}
                </Text>
                <Text 
                    class_name_list={[CSS.l_ThemeItemCard_themeName]}
                    is_text_truncation_on={true}
                    color={"dark"}
                    bold={true}
                >
                    {theme_name}
                </Text>
                <Text 
                    class_name_list={[CSS.l_ThemeItemCard_officeName]}
                    is_text_truncation_on={true}
                    color={"gray2"}
                >
                    {office_name}
                </Text>
            </div>

            {
                is_empty_content
                ?   <div className={CSS.l_emptyContentLayer}>
                        <div className={CSS.l_emptyContentLayer_text}>
                            상세 페이지 내용 없음
                        </div>
                        <div className={CSS.l_emptyContentLayer_text}>
                            상세 페이지 작성을 위해 클릭
                        </div>
                    </div>
                :   null
            }
        </CardContainer>
    )
}

export default ThemeItemCard;