import { useQuery } from "@tanstack/react-query";
import { defaultApiReaponseInterface } from "../../../Common/interface/Common.interface";
import { themaBookPhotoListInterface } from "../static/interface/themeBook.interface";
import { callAxios, customToast, dev_console, getQueryParamsUrl, isSuccess, printStateMsg, whoAmI } from "../../../Common/ts/commonTools";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";
import { useEffect } from "react";
import { RTN_interface } from "../../../Common/interface/App.interface";

const EMPTY_LIST: themaBookPhotoListInterface[] = [];
const TOAST_ID = "usePhotoProductDataREST";

const usePhotoProductDataREST = (
    {
        is_has_office_list,
        office_id
    }:{
        is_has_office_list: boolean,
        office_id: number
    }
) => {

    const isAbleToRequest = () => {
        let RTN: boolean = false;

        if(whoAmI.isCustomer() || whoAmI.isAdmin()){
            RTN = true;
        }

        return RTN;
    }

    // "전체" 필터 처리를 위해 필요한 부분
    const all_item: themaBookPhotoListInterface = {
        office_id: office_id,
        photo_id: 0,
        photo_name: "전체",
        type: 3,
    }

    const {
        data: photoProductList = EMPTY_LIST, 
        error: photoProductError, 
        isLoading: isPhotoProductLoading,
        refetch: photoProductRefetch,
    } = useQuery({
        queryKey: ["theme_book_photo_product", office_id, is_has_office_list],
        queryFn: callPhotoProductList,
        enabled: isAbleToRequest,
        select: (response) => {
            const RTN: RTN_interface<themaBookPhotoListInterface[]> = {
                sign: false,
                data: EMPTY_LIST,
                msg: "",
            };

            SetData:{
                if(!response){
                    RTN.sign = true;
                    break SetData;
                }

                if(!isSuccess(response)){
                    RTN.msg = "테마북 데이터를 불러오는데 실패했습니다.";
                    break SetData;
                }

                const data: defaultApiReaponseInterface<themaBookPhotoListInterface[]> = response.data;
                RTN.sign = true;
                RTN.data = [all_item, ...data.result];
                RTN.msg = "success";
            }

            if(!RTN.sign){
                if(response){
                    printStateMsg(response);
                }
            }

            return RTN.data;
        },
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
    });

    async function callPhotoProductList(){
        if(office_id > 0 && is_has_office_list){
            // office_id가 없는경우엔 전 지점에 대한 데이터를 가져온다.
            const m_thema_book_photo_list_url = getQueryParamsUrl({
                main_url: "api/v3/customers/thema_book_photo_list",
                queryParams: {
                    office_id: `${office_id || ""}`,
                }
            })

            const response = await callAxios.api({
                url: m_thema_book_photo_list_url,
                method: 'get',
            })

            dev_console.log(m_thema_book_photo_list_url);
            dev_console.log(response);

            return response;
        }else{
            return null;
        }
    }

    useEffect(() => {
        if(photoProductError){
            customToast.error({
                msg: "테마북 데이터를 불러오는데 실패했습니다.",
                toastId: TOAST_ID
            })
        }
    }, [photoProductError])

    return({
        photoProductList, 
        photoProductError, 
        isPhotoProductLoading,
        photoProductRefetch,
    })
}

export default usePhotoProductDataREST