import CSS from "./static/css/EventButtonSet.module.css";
import EventApplicationBtn from "../EventApplicationBtn/EventApplicationBtn";
import { joinedEventType } from "../../static/interface/PhotoList.interface";
import Button from "../../../../Component/Elements/Button/Normal/Button";
import { PRODUCT_LIST_FILTER_CODE_OBJ } from "../../../ProductManufacturing/static/ts/const";
import { useNavigate } from "react-router-dom";
import { getQueryParamsUrl } from "../../../../Common/ts/commonTools";

const EventButtonSet = (
    {
        isEventApplication,

        scheduleId,
        upload_date,
        joined_event,
        photo_upload_id,
        
        isPrintProductRequestBtn
    }:{
        isEventApplication: boolean;

        scheduleId: number;
        upload_date: string;
        joined_event: joinedEventType;
        photo_upload_id: number;

        isPrintProductRequestBtn: boolean;
    }
) => {
    const navigate = useNavigate();

    const goToProductListPageHandler = () => {
        const m_productList_url = getQueryParamsUrl({
            main_url: "/productList",
            queryParams: {
                tab1: PRODUCT_LIST_FILTER_CODE_OBJ.need_to_request
            }
        })
      
        navigate(m_productList_url);
    }

    return(
        <div className={CSS.l_EventButtonSet_main}>
            <EventApplicationBtn
                isEventApplication={isEventApplication}
                schedule_id={scheduleId}
                upload_date={upload_date}
                joined_event={joined_event}
                photo_upload_id={photo_upload_id}
            />

            {
                isPrintProductRequestBtn
                &&  <Button
                        onClick={goToProductListPageHandler}
                        preset={"move_page"}
                        size={"size3"}
                    >
                        제품 제작 신청
                    </Button>
            }
        </div>
    )
}

export default EventButtonSet;