import { useNavigate } from "react-router-dom";
import MainListItemCard from "../../../../Component/Elements/MainListItemCard/MainListItemCard";
import Text from "../../../../Component/Elements/Text/Text";
import { photoDetailsTypeCodeType, photoTypeCodeType } from "../../static/interface/const.interface";
import CSS from "./static/css/PhotoListItemCard.module.css";
import { locationStateDataInterface } from "../../static/interface/PhotoDetail.interface";

const PhotoListItemCard = (
    {
        img_url,
        title,
        info,
        upload_date,
        // photo_upload_id,
        // photo_type_code,
        // photo_details_type_code,
        onClick,
    }:{
        img_url: string;
        title: string;
        info: string;
        upload_date: string;
        // photo_upload_id: number;
        // photo_type_code: photoTypeCodeType;
        // photo_details_type_code: photoDetailsTypeCodeType;
        onClick?: React.MouseEventHandler<HTMLDivElement>
    }
) => {
    return(
        <MainListItemCard 
            img_src={img_url}
            onClick={onClick}
        >
            <Text 
                preset={"content_title"}
                is_text_truncation_on={true}
                class_name_list={[CSS.l_PhotoListItemCard__title]}
            >
                {title}
            </Text>
            <Text 
                preset={"main_content"}
                is_text_truncation_on={true}
                class_name_list={[CSS.l_PhotoListItemCard__date]}
            >
                {info}
            </Text>
            <Text 
                preset={"table_main_content"}
                color={"gray3"}
                is_text_truncation_on={true}
            >
                {upload_date}
            </Text>
        </MainListItemCard>
    )
}

export default PhotoListItemCard;