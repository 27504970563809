import React, { useEffect, useState } from "react";
import CSS from "./static/css/iconButtonCheck.module.css"

const IconButtonCheckCheckMark = (
    {
        src,
        onClick,
        size,
    }:{
        src: string;
        onClick?: React.MouseEventHandler<HTMLDivElement>;
        size?: "small" | "medium" | "large"
    }
) => {
    const [totalClassName, setTotalClassName] = useState<string>(CSS.l_icon_button_check_main);

    useEffect(() => {

        const class_name_list: string[] = [];

        if(onClick){
            class_name_list.push("g_click_event_item");
        }

        class_name_list.push(CSS.l_icon_button_check_main);
        
        if(size){
            class_name_list.push(CSS.size);
            class_name_list.push(CSS[size]);
        }

        setTotalClassName(class_name_list.join(" "));

    }, [onClick, size])

    return (
        <div
            className={totalClassName}
            onClick={onClick}
        >
            <img 
                className={CSS.l_icon_button_check__img}
                src={src}
            />
        </div>
    )
}

export default IconButtonCheckCheckMark;