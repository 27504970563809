import React, { useEffect, useMemo, useRef, useState } from 'react';

import ReactQuill, {Quill} from "react-quill";
import "react-quill/dist/quill.snow.css";

import "./static/css/textEditer.css";
import { customToast, dev_console, printFormData } from '../../../Common/ts/commonTools';
import Modal from '../Modal/Modal';
import SettingImgWidth from './components/SettingImgWidth';
import Text from '../Text/Text';
import LoopAnimationModal from '../LoopAnimationModal/LoopAnimationModal';
import { textEditerHandleImageUpload } from '../../../PublicPage/ThemeBook/static/ts/imageUpload';

// 이미지 사이즈를 설정할 수 있게 하기위한 과정
// 1. Quill과 Parchment 모듈 가져오기
const Embed = Quill.import('blots/block/embed');  // Quill의 Embed 블롯을 가져옵니다.
// const Parchment = Quill.import('parchment');  // Parchment를 가져옵니다.

interface CustomImageValue {
    url: string;
    alt?: string;
    width?: string;
}

// // 2. 커스텀 이미지 블롯 정의하기
// class CustomImageBlot extends Embed {
//     static blotName = 'customImage';  // 블롯의 이름을 정의합니다.
//     static tagName = 'div';  // 이 블롯이 사용할 태그를 정의합니다. 여기서는 'img' 태그를 사용합니다.

//     static create(value: CustomImageValue): HTMLElement {
//         const node = super.create() as HTMLElement;

//         // img 요소 생성
//         const img = document.createElement('img');
//         img.setAttribute('src', value.url);
//         img.setAttribute('alt', value.alt || 'image');
//         img.setAttribute('width', value.width || '400px');

//         // img를 div에 추가
//         node.appendChild(img);
//         return node;
//     }

//     static value(node: HTMLElement): CustomImageValue{
//         return{
//             url: node.getAttribute('src') || '',
//             alt: node.getAttribute('alt') || '',
//             width: node.getAttribute('width') || '400px',
//         }
//     }
// }

// 2. 커스텀 이미지 블롯 정의하기
class CustomImageBlot extends Embed {
    static blotName = 'customImage';
    static tagName = 'img';

    static create(value: CustomImageValue): HTMLElement {
        const node = document.createElement('img');
        node.setAttribute('src', value.url);
        node.setAttribute('alt', value.alt || 'image');
        node.setAttribute('width', value.width || '400px');
        return node;
    }

    static value(node: HTMLElement): CustomImageValue {
        return {
            url: node.getAttribute('src') || '',
            alt: node.getAttribute('alt') || '',
            width: node.getAttribute('width') || '400px',
        };
    }
}

// 3. 커스텀 블롯 Quill에 등록하기
Quill.register({
    'formats/customImage': CustomImageBlot,
});

const TextEditerQuill = (
    {
        read_only,
        content,
        setContent,
        classNameList,

        // office_id, thema_id, type값은 서버에 이미지를 업로드할 때 필요하다. 따라서 단순히 출력만 할 경우에는 필요없다.
        office_id,
        thema_id,
        type,
    }:{
        read_only: boolean;
        content: string;
        setContent?: React.Dispatch<React.SetStateAction<string>>;
        classNameList?: string[];

        office_id?: number;
        thema_id?: number;
        type?: number;
    }
) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [imgUploadModalOpen, setImgUploadModalOpen] = useState<boolean>(false);
    
    /**
     * 마지막 커서의 위치를 저장
     * - 사진을 업로드할 때 사진의 크기를 설정하면서 포커스가 이동되기 때문에, 마지막 커서의 위치를 저장해둬야 올바른 위치에 이미지를 삽입시킬 수 있다.
     */
    // const [cursorPosition, setCursorPosition] = useState<number | null>(null);
    const m_cursorPositionRef = useRef<number | null>(null);

    const quillRef = useRef<ReactQuill>();

    // read_only상태가 변화함에 따라 컴포넌트가 증발하는 현상을 막기 위해 key값으로 컴포넌트를 컨트롤하여 컨포넌트를 리랜더링시킨다.
    const [editorKey, setEditorKey] = useState(0);

    useEffect(() => {
        // 이 부분이 제대로 동작하지 않는것 같으니... 다시 확인할것
        if(!read_only && (!office_id || !thema_id || !type)){
            dev_console.error("본문에 사진을 업로드하는 기능을 사용하기 위해서는 TextEditerQuill 컴포넌트의 props값으로 office_id, thema_id, type값이 필요합니다.");
        }

        if(!!quillRef.current){
            quillRef.current.getEditor().on("selection-change", (range) => {
                if(range){
                    m_cursorPositionRef.current = range.index;
                }
            })
        }
    }, [])

    useEffect(() => {
        setEditorKey(prevKey => prevKey + 1);
    }, [read_only]);

    const openModalHandler = () => {
        if(quillRef.current){
            const range = quillRef.current.getEditor().getSelection();
            if(!!range){
                m_cursorPositionRef.current = range.index;
            }
        }

        setImgUploadModalOpen(true);
    }

    const closeModalHandler = () => {
        setImgUploadModalOpen(false);
    }

    // 파일 이름을 정규화하고 새로운 파일 객체를 생성하는 함수
    function sanitizeFile(file: File): File {
        const originalFileName = file.name;
        const sanitizedFileName = sanitizeFileName(originalFileName);

        // 새로운 파일 객체 생성 (Blob 사용)
        return new File([file], sanitizedFileName, { type: file.type });
    }

    // 파일 이름에서 URL에 사용할 수 없는 문자를 제거하거나 변환하는 함수
    function sanitizeFileName(fileName: string): string {
        // 여기서는 간단히 정규식을 사용하여 알파벳, 숫자, '.', '-', '_'를 제외한 문자를 '_'로 대체합니다.
        return fileName.replace(/[^a-zA-Z0-9.\-_\s]/g, '_').replace(/\s+/g, '_');
    }

    // 이미지를 업로드 하기 위한 함수
    const imageHandler = async (width: string) => {
        // 파일을 업로드 하기 위한 input 태그 생성
        const input = document.createElement("input");

        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.setAttribute("multiple", "");
        input.click();

        // 파일이 input 태그에 담기면 실행 될 함수 
        input.onchange = async () => {
            const files = input.files;
            
            if (files && files.length > 0) {

                setLoading(true);
                
                for(const file of files){
                    try{

                        const sanitizedFile = sanitizeFile(file);
                        
                        const formData = new FormData();
                        
                        // 특히 테마북내용을 작성중일때 처리를위해 테마 정보 사용
                        formData.append("office_id", `${office_id}`);
                        formData.append("thema_id", `${thema_id}`);
                        formData.append("type", `${type}`);
                        formData.append("file_field", sanitizedFile);

                        // fromdata값 conosle에 출력
                        printFormData(formData);
        
                        await textEditerHandleImageUpload({formData, quillRef, width, cursorPositionRef: m_cursorPositionRef});
                    }catch(error){
                        customToast.error({msg: `${file.name}의 처리 과정에서 문제가 발생했습니다.`});
                    }
                }

                setLoading(false);
                closeModalHandler();
                input.value = "";
            }
        };
    };

    const modules = useMemo(() => ({
        toolbar: !read_only 
            ? {
                container: [
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                        { size: ["small", false, "large", "huge"] }, 
                        { color: [] }
                    ],
                    [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                        { align: [] },
                    ],
                    ["image"],
                ],
                handlers: {
                    image: openModalHandler,
                },
            }
            : false,
    }), [read_only]);

    return (
        <>
            <ReactQuill
                key={editorKey}
                ref={(element) => {
                    if (element !== null) {
                        quillRef.current = element;
                    }
                }}
                readOnly={read_only}
                modules={modules}
                className={classNameList && classNameList.join(" ")}
                value={content}
                onChange={setContent}
            />

            <Modal
                isModalOpen={imgUploadModalOpen}
            >
                <SettingImgWidth 
                    imageHandler={imageHandler}
                    closeFC={closeModalHandler}
                />
            </Modal>

            <LoopAnimationModal
                isModalOpen={loading}
            >
                <Text
                    color={"white"}
                >
                    잠시만 기다려주세요.
                </Text>
            </LoopAnimationModal>
        </>
    );
}

export default TextEditerQuill