import CSS from "./static/css/ProductDetail.module.css";
import { useSearchParams } from "react-router-dom";
import useProductDetailSetting from "./hooks/useProductDetailSetting";
import { ProductDetail_Folder_PhotoListEle_Interface } from "./static/interface/ProductDetail.interface";
import { customParseInt, customToast, isAbleAccesser } from "../../Common/ts/commonTools";
import useProductDetailREST from "./hooks/REST/useProductDetailREST";
import { PRODUCT_STATE_CODE_OBJ } from "./static/ts/const";
import { useEffect, useState } from "react";
import ProductDetailPageWebViewing from "./components/ProductDetailPage/ProductDetailPageWebViewing/ProductDetailPageWebViewing";
import ProductDetailPageMobileViewing from "./components/ProductDetailPage/ProductDetailPageMobileViewing/ProductDetailPageMobileViewing";
import { productStateCodeType } from "./static/interface/ProductList.interface";
import LoopAnimationModal from "../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import { backPointUrlManagerClass } from "../../Common/ts/appTools";

const backPointUrlManager = new backPointUrlManagerClass();

const ProductDetail = () => {    
    const [textTitle, setTextTitle] = useState<string>("");
    const [photoList, setPhotoList] = useState<ProductDetail_Folder_PhotoListEle_Interface[]>([]);
    const [statusCd, setStatusCd] = useState<productStateCodeType>(PRODUCT_STATE_CODE_OBJ.application_not_possible);

    const [URLSearchParamsData, SetURLSearchParamsData] = useSearchParams();

    // 선택이 완료된 직후에 진입한 경우인지 체크
    let l_is_done = URLSearchParamsData.get("is_done");

    let l_contract_product_id: number | null = customParseInt(URLSearchParamsData.get("contract_product_id"));

    const l_album_save_id: number | null = customParseInt(URLSearchParamsData.get("album_save_id"));

    const l_photo_upload_id: number | null = customParseInt(URLSearchParamsData.get("photo_upload_id"))

    // 뒤로가기 주소
    const m_back_point = backPointUrlManager.getBackPointUrlAndDecode(URLSearchParamsData);
    
    // album_save_id는 고객이 사진을 업로드하면 생성된다.
    // photo_upload_id는 편집 사진이 업로드되면 생성된다.
    // 따라서 contract_product_id와 status_cd값이 넘어오는지만 확인한다.
    if(!l_contract_product_id){
        if(m_back_point){
            window.location.href = m_back_point;
        }else{
            window.location.href = "/productList";
        }
    }

    // 위의 null조건을 통과한 후에는 무조건 값이 있는 상태이다. -> 자료형을 교정해준다.
    l_contract_product_id = l_contract_product_id as number;

    const {        
        productDetailData,
        productDetailError,
        productDetailIsLoading,
        productDetailRefetch,
    } = useProductDetailREST({
        contract_product_id: l_contract_product_id,
        album_save_id: l_album_save_id,
        photo_upload_id: l_photo_upload_id
    })

    const {
        productDetailInfoData,
        productOptionDataList,
        folderList,
        initialSelectOptionDataList,
        initialSelectPhotoList,
        initialEditPhotoList,
        addedInfo,
        address,
        isSingle,
        isAlbum,
        selectInformationDate,
        isCanReupload
    } = useProductDetailSetting({
        productDetailData,
    })

    function getProductDetailTitleText(status_cd: productStateCodeType){
        let RTN: string = "";

        switch(status_cd){
            case PRODUCT_STATE_CODE_OBJ.application_not_possible:
                break;
            case PRODUCT_STATE_CODE_OBJ.application_possible:
                break;
            case PRODUCT_STATE_CODE_OBJ.application_in_progress:
                RTN = "사진 선택 완료"
                break;
            case PRODUCT_STATE_CODE_OBJ.editing:
                RTN = "사진 편집 중"
                break;
            case PRODUCT_STATE_CODE_OBJ.confirm_request:
                RTN = "사진 편집 승인 요청"
                break;
            case PRODUCT_STATE_CODE_OBJ.edit_end:
                RTN = "편집 완료(상품 제작)"
                break;
            case PRODUCT_STATE_CODE_OBJ.confirm_rejected:
                break;
        }

        return RTN;
    }

    function getUseingPhotoList(status_cd: productStateCodeType){
        let RTN: ProductDetail_Folder_PhotoListEle_Interface[] = [];

        switch(status_cd){
            case PRODUCT_STATE_CODE_OBJ.application_not_possible:
                break;
            case PRODUCT_STATE_CODE_OBJ.application_possible:
                break;
            case PRODUCT_STATE_CODE_OBJ.application_in_progress:
                RTN = initialSelectPhotoList;
                break;
            case PRODUCT_STATE_CODE_OBJ.editing:
                RTN = initialSelectPhotoList;
                break;
            case PRODUCT_STATE_CODE_OBJ.confirm_request:
                RTN = initialEditPhotoList;
                break;
            case PRODUCT_STATE_CODE_OBJ.edit_end:
                RTN = initialEditPhotoList;
                break;
            case PRODUCT_STATE_CODE_OBJ.confirm_rejected:
                RTN = initialEditPhotoList;
                break;
        }

        return RTN;
    }

    const refetchHandler = () => {
        productDetailRefetch();
    }

    useEffect(() => {
        isAbleAccesser({
            allowedUserTypeList: ["C"]
        })

        if(l_is_done === "Y"){
            customToast.info({
                msg: "신청하신 사진이 정상적으로 출력되지 않을 경우 새로고침을 진행해 주세요."
            });

            const m_URLSearchParamsData = new URLSearchParams(URLSearchParamsData);
            m_URLSearchParamsData.delete("is_done");
            SetURLSearchParamsData(m_URLSearchParamsData);
        }
    }, [])

    useEffect(() => {
        if(selectInformationDate){
            setTextTitle(getProductDetailTitleText(selectInformationDate.status_cd));
            setPhotoList(getUseingPhotoList(selectInformationDate.status_cd));
            setStatusCd(selectInformationDate.status_cd);
        }
    }, [selectInformationDate])

    return(
        <div className={CSS.l_ProductDetail_main}>
            <ProductDetailPageWebViewing 
                textTitle={textTitle}
                folderList={folderList}
                photoList={photoList}
                product_name={productDetailInfoData?.product_name || ""}
                is_has_babyinfo={isAlbum}
                selectOptionList={initialSelectOptionDataList}
                addedInfo={addedInfo}
                address={address}
                contract_product_id={l_contract_product_id}
                album_save_id={l_album_save_id}
                photo_upload_id={l_photo_upload_id}
                status_cd={statusCd}
                isSingle={isSingle}
                refetchHandler={refetchHandler}
                isCanReupload={isCanReupload}
            />

            <ProductDetailPageMobileViewing
                textTitle={textTitle}
                folderList={folderList}
                photoList={photoList}
                product_name={productDetailInfoData?.product_name || ""}
                is_has_babyinfo={isAlbum}
                selectOptionList={initialSelectOptionDataList}
                addedInfo={addedInfo}
                address={address}
                contract_product_id={l_contract_product_id}
                album_save_id={l_album_save_id}
                photo_upload_id={l_photo_upload_id}
                status_cd={statusCd}
                isSingle={isSingle}
                refetchHandler={refetchHandler}
                isCanReupload={isCanReupload}
            />

            <LoopAnimationModal 
                isModalOpen={productDetailIsLoading}
            />
        </div>
    )
}

export default ProductDetail;