import CSS from "./static/css/ProductName.module.css";
import { ReactNode, useMemo } from "react";
import Text from "../../../../../Component/Elements/Text/Text";
import { useMediaQuery } from "react-responsive";

const ProductName = (
    {
        children,
        className,
        justify_content,
        is_header,
    }:{
        children?: ReactNode,
        className?: string;
        justify_content?: "flex_start" | "flex_end" | "center";
        is_header?: boolean;
    }
) => {
    const isMobile = useMediaQuery({ maxWidth: 700 });

    const mainClassName = useMemo(() => {
        const RTN: string[] = [];

        if(className){
            RTN.push(className);
        }

        RTN.push(CSS.l_ProductName_main);

        if(justify_content){
            RTN.push(CSS.justify_content);
            RTN.push(CSS[justify_content]);
        }

        return RTN.join(" ");
    }, [className, justify_content])

    return(
        <div className={mainClassName}>
            <Text
                size={"size3"}
                bold={isMobile}
                color={is_header ? "gray2" : undefined}
                is_text_truncation_on={true}
            >
                {children}
            </Text>
        </div>
    )
}

export default ProductName;