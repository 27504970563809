import CSS from "./static/css/FolderEle.module.css";
import Text from "../../../../Component/Elements/Text/Text";
import DetailIcon from "../../../../Component/Elements/Icon/DetailIcon/DetailIcon";
import { forwardRef, useEffect, useState } from "react";

interface folderElePropsInterface{
    foler_name: string;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    detail_click_handler?: () => void;
    is_active?: boolean;
}

const FolderEle = forwardRef<HTMLDivElement, folderElePropsInterface>((
    {
        foler_name,
        onClick,
        detail_click_handler,
        is_active,
    }, ref
) => {
    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    function classNameSetter(){
        const RTN: string[] = [];

        if(onClick){
            RTN.push("g_click_event_item");
        }

        RTN.push(CSS.l_FolderEle_main);

        if(is_active){
            RTN.push(CSS.active);
        }

        return RTN;
    }

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "))
    }, [is_active, onClick])

    return(
        <div 
            className={mainClassName}
            onClick={onClick}
            ref={ref}
        >
            <Text 
                preset={"navi"}
                bold={is_active}
            >
                {foler_name}
            </Text>

            {
                detail_click_handler
                &&  <div className={CSS.l_FolderEle__icon_container}>
                        <DetailIcon 
                            onClick={detail_click_handler}
                        />
                    </div>
            }
        </div>
    )
})

export default FolderEle;