import CSS from "./static/css/WrokRequiredMainItem.module.css";
import WrokRequiredItemContainer from "../WrokRequiredItemContainer/WrokRequiredItemContainer";
import Text from "../../../../../Component/Elements/Text/Text";

const WrokRequiredMainItem = (
    {
        item_num,
        class_name_list,
    }:{
        item_num: number;
        class_name_list?: string[]
    }
) => {
    return(
        <WrokRequiredItemContainer
            preset={"main_item"}
            class_name_list={class_name_list}
        >
            <div className={CSS.l_WrokRequiredMainItem_main}>
                <Text class_name_list={[CSS.l_WrokRequiredMainItem_text]}>
                    고객님의 선택을 기다리는 항목이
                </Text>
                <div className={CSS.l_WrokRequiredMainItem_textLineContainer}>
                    <Text 
                        class_name_list={[CSS.l_WrokRequiredMainItem_highlightsText]}
                        color={"red_gray"}
                        bold={true}
                    >
                        {item_num}건
                    </Text>
                    <Text 
                        class_name_list={[CSS.l_WrokRequiredMainItem_highlightsText]}
                        color={"dark"}
                        bold={true}
                    >
                        있어요!
                    </Text>
                </div>
            </div>
        </WrokRequiredItemContainer>
    )
}

export default WrokRequiredMainItem;