import { useMutation } from "@tanstack/react-query";
import { contractScheduleReservationConfigInterface } from "../static/interface/selectTheme.interface";
import { callAxios, dev_console, printStateMsg } from "../../../Common/ts/commonTools";

const useSelectedThemeSubmitREST = (
    {
        progressScheduleAllDataRefetch,
        progressScheduleBeforeDataRefetch,
    }:{
        progressScheduleAllDataRefetch: () => void;
        progressScheduleBeforeDataRefetch: () => void;
    }
) => {

    const {
        mutate: selectedThemeSubmitMutate
    } = useMutation({
        mutationFn: selectedThemeUpdate,
        onSuccess: (data, variables, context) => {
            if(data.status === 200){
                progressScheduleAllDataRefetch();
                progressScheduleBeforeDataRefetch();
            }else{
                printStateMsg(data);
            }
        },
    })
    
    async function selectedThemeUpdate(
        {
            config
        }:{
            config: contractScheduleReservationConfigInterface
        }
    ){
        const contract_schedule_reservation_url =  "api/v3/oops/thema/save";

        const response = await callAxios.api({
            url: contract_schedule_reservation_url,
            method: "post",
            dataType: "json",
            data: config,
        })

        dev_console.log(contract_schedule_reservation_url);
        dev_console.log(config);
        dev_console.log(response);

        return response
    }

    return({ selectedThemeSubmitMutate })
}

export default useSelectedThemeSubmitREST;