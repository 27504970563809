import 'photoswipe/dist/photoswipe.css'
import { Gallery } from 'react-photoswipe-gallery';
import { viewerImageInterface } from '../ImageGallery/interface/ImageGallery.interface';
import ReactPhotoSwipeGalleryContents from './components/ReactPhotoSwipeGalleryContents/ReactPhotoSwipeGalleryContents';

// 커스텀 css. 
import "./static/css/PhotoSwipe.css";
import { PHOTO_SWIPE_OPTIONS } from './static/ts/const';
import ReactPhotoSwipeSetter from './hooks/ReactPhotoSwipeSetter.hook';

const ReactPhotoSwipeGallery = (
    {
        img_list,
        isComfrimMode,
    }:{
        img_list: viewerImageInterface[]
        isComfrimMode?: boolean;
    }
) => {
    const {viewportSize} = ReactPhotoSwipeSetter();

    return(
        <Gallery 
            options={PHOTO_SWIPE_OPTIONS}
            withCaption
        >
            <ReactPhotoSwipeGalleryContents 
                img_list={img_list} 
                isComfrimMode={isComfrimMode}
                viewport_width={viewportSize.width}
                viewport_height={viewportSize.height}
            />
        </Gallery>
    )
}

export default ReactPhotoSwipeGallery;