// 뒤로가기버튼의 기능을 컨트롤하기 위해 만든 함수
// 더이상 사용하지 않음

import { historyMapInterface } from "../interface/Header.interface";

export const getBackPath = (path: string) => {
    const history_map: historyMapInterface[] = [
        {
            parents: "/",
            children: [],
        },
        {
            parents: "/reservationHome",
            children: ["/selectTheme"],
        },
        {
            parents: "/photoList",
            children: ["/photoDetail",],
        },
        {
            parents: "/productList",
            children: [
                "/productDetail",
                "/productConfirm",
                "/productRequestEdit",
            ]
        },
        {
            parents: "/themeBook",
            children: ["/themeBookDetailPage"],
        },
        {
            parents: "/contractDocs",
            children: ["/contractDetailViewerMobile"],
        },
        {
            parents: "/myInfo",
            children: [],
        },
    ]
    return history_map.find(item => item.children.includes(path));
};