import { ChangeEvent, useRef, useState } from 'react';
import { customToast, dev_console, validateInput } from '../../Common/ts/commonTools';
import PhoneNumberInput from './components/PhoneNumberInput';
import NameInput from './components/NameInput';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import IDInput from './components/IDInput';
import CSS from "./static/css/resetUserPassword.module.css";
import { toast } from 'react-toastify';
import Text from '../../Component/Elements/Text/Text';
import AccountPage from '../../Component/Elements/Page/AccountPage/AccountPage';
import Button from '../../Component/Elements/Button/Normal/Button';
import AccountPageContentsCardContainer from '../../Component/Elements/AccountPageContentsCardContainer/AccountPageContentsCardContainer';

const ResetUserPassword = () => {

    const [userId, setUserId] = useState<string>("");
    const [userName, setUserName] = useState<string>("");
    const [userPhone, setUserPhone] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [resultData, setResultData] = useState<{msg:string, sign: boolean} | null>();

    const idInputRef = useRef<HTMLInputElement>(null);
    const nameInputRef = useRef<HTMLInputElement>(null);
    const phoneInputRef = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();

    const handleIDInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setResultData(null);
        setUserId(event.target.value);
    }

    const handleNameInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setResultData(null);
        setUserName(event.target.value)
    }

    const handlePhoneInputOnChange = (number: string) => {
        setResultData(null);
        setUserPhone(number);
    }

    const onSubmite = () => {

        if(isLoading){
            return ;
        }

        if(userId.length === 0){
            idInputRef.current?.focus();
            toast.error("ID를 입력해 주세요.");
            return ;
        }else if(!validateInput(userId)){
            setUserId("");
            idInputRef.current?.focus();
            toast.error("사용할 수 없는 문자가 감지되었습니다.");
            return ;
        }

        if(userName.length === 0){
            nameInputRef.current?.focus();
            toast.error("이름을 입력해 주세요.");
            return ;
        }else if(!validateInput(userName, true)){
            setUserName("");
            nameInputRef.current?.focus();
            toast.error("사용할 수 없는 문자가 감지되었습니다.");
            return ;
        }

        if(userPhone.length === 0){
            phoneInputRef.current?.focus();
            toast.error("연락처를 입력해 주세요.");
            return ;
        }else if(!validateInput(userPhone)){
            setUserPhone("")
            phoneInputRef.current?.focus();
            toast.error("사용할 수 없는 문자가 감지되었습니다.");
            return ;
        }

        if(!window.confirm("비밀번호를 초기화하시겠습니까?")){
            return ;
        }

        setIsLoading(true);
        setResultData(null);

        const data = {
            username: userId,
            phone: userPhone,
            name: userName,
        }

        const url = `${process.env.REACT_APP_API_URL}/api/v1/accounts/new_password/`
        axios.post(url, data)
        .then((response) => {
            dev_console.log(url);
            dev_console.log(response);

            if(response.status === 200){
                setResultData({
                    msg: `${response.data.password}`,
                    sign: true,
                });
            }else{
                customToast.error({msg: "입력하신 정보를 확인해 주세요."});
            }

        })
        .catch((error) => {
            dev_console.error(error);
            
            if(error.response.status === 400){
                if(error.response.data.msg === "user info error"){
                    customToast.error({msg: "입력하신 정보를 확인해 주세요."});
                }
            }else{
                customToast.error({msg: "요청 중에 문제가 발생했습니다. 다시 시도해 주세요."});
            }
        })
        .finally(() => {
            setIsLoading(false);
        })

    }

    return (
        <AccountPage>
            <div className={CSS.l_ResetUserPassword_main}>
                <AccountPageContentsCardContainer
                    title="비밀번호 초기화"
                    contents={
                        <>
                            {
                                resultData
                                ?   <div className={CSS.l_ResetUserPassword_resultContainer}>
                                        <Text
                                            size={"size7"}
                                            bold={true}
                                            copy_function_target_text={resultData.msg}
                                        >
                                            {resultData.msg}
                                        </Text>
                                        <div className={CSS.l_ResetUserPassword_resultContainer_textContainer}>
                                            <Text>
                                                임시비밀번호로 로그인 하신 후
                                            </Text>
                                            <Text>
                                                비밀번호를 변경해주세요.
                                            </Text>
                                        </div>
                                    </div>
                                :   <div className={CSS.l_ResetUserPassword_inputContainer}>
                                        <div className={CSS.l_ResetUserPassword_inputContainer_inputWrap}>
                                            <Text color={"secondary"}>
                                                ID
                                            </Text>
                                            <IDInput 
                                                value={userId}
                                                onChange={handleIDInputOnChange}
                                                ref={idInputRef}
                                            />
                                        </div>

                                        <div className={CSS.l_ResetUserPassword_inputContainer_inputWrap}>
                                            <Text color={"secondary"}>
                                                이름
                                            </Text>
                                            <NameInput 
                                                value={userName}
                                                onChange={handleNameInputOnChange}
                                                ref={nameInputRef}
                                            />
                                        </div>

                                        <div className={CSS.l_ResetUserPassword_inputContainer_inputWrap}>
                                            <Text color={"secondary"}>
                                                전화번호
                                            </Text>
                                            <PhoneNumberInput 
                                                value={userPhone} 
                                                changeNumberHandler={handlePhoneInputOnChange} 
                                                ref={phoneInputRef}
                                            />
                                        </div>
                                    </div>
                            }
                        </>
                    }

                    bottom_content={
                        <div className={CSS.l_ResetUserPassword_btnContainer}>
                            {
                                resultData
                                ?   <Button
                                        size={"size3"}
                                        onClick={() => {
                                            navigate("/")
                                        }}
                                    >
                                        홈으로
                                    </Button>
                                :   <>
                                        <Button
                                            preset={"normal_gray"}
                                            size={"size3"}
                                            onClick={() => {
                                                navigate("/");
                                            }}
                                        >
                                            취소
                                        </Button>
        
                                        <Button
                                            size={"size3"}
                                            onClick={onSubmite}
                                        >
                                            비밀번호 초기화
                                        </Button>
                                    </>
                            }
                        </div>
                    }
                />
            </div>
        </AccountPage>
    );
}

export default ResetUserPassword;
