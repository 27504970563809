import { openInExternalBrowser } from "../../Common/ts/commonTools";
import Button from "../../Component/Elements/Button/Normal/Button";
import Text from "../../Component/Elements/Text/Text";
import CSS from "./static/css/RedirectToAnotherBrowser.module.css";

const RedirectToAnotherBrowser = () => {
    const moveBtnOnClickHandler = () => {
        openInExternalBrowser.auto_open();
    }

    return(
        <div className={CSS.l_redirect_to_another_browser_main}>
            <div className={CSS.l_redirect_to_another_browser__content_container}>
                <div className={CSS.l_redirect_to_another_browser__content_container__text_container}>
                    <Text
                        color={"dark"}
                    >
                        정상적인 이용을 위해서 다른 브라우저로 이동합니다.
                    </Text>
                </div>
                <div className={CSS.l_redirect_to_another_browser__content_container__btn_container}>
                    <Button
                        // purpose={"dev"}
                        onClick={moveBtnOnClickHandler}
                    >
                        다른 브라우저로 이동
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default RedirectToAnotherBrowser;