import Flex from "../../../../Component/Elements/Flex/Flex";
import PhotoListItemCard from "../PhotoListItemCard/PhotoListItemCard";
import { photoDetailsTypeCodeType, photoTypeCodeType } from "../../static/interface/const.interface";
import CSS from "./static/css/PhotoList.module.css";
import EmptyData from "../../../../Component/Elements/EmptyData/EmptyData";
import LoopAnimationModal from "../../../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import useMyPhotoREST from "../../hooks/useMyPhotoREST.hook";
import useProductListREST from "../../../ProductManufacturing/hooks/REST/useProductListREST";
import { PRODUCT_LIST_FILTER_CODE_OBJ } from "../../../ProductManufacturing/static/ts/const";
import usePhotoListSetter from "../../hooks/usePhotoListSetter.hook";
import { PHOTO_DETAILS_TYPE_CODE, PHOTO_TYPE_CODE } from "../../static/ts/const";
import ProductBlock from "../../../ProductManufacturing/components/ProductBlock/ProductBlock";
import { useNavigate } from "react-router-dom";
import { getQueryParamsUrl } from "../../../../Common/ts/commonTools";
import { backPointUrlManagerClass } from "../../../../Common/ts/appTools";

const backPointUrlManager = new backPointUrlManagerClass();

const PhotoList = (
    {
        photo_type,
        photo_details_type,
    }:{
        photo_type: photoTypeCodeType;
        photo_details_type: photoDetailsTypeCodeType;
    }
) => {
    const navigate = useNavigate();

    const {
        myPhotoDataList,
        myPhotoDataListIsLoading,
        myPhotoDataListError,
        myPhotoDataListRefetch,
    } = useMyPhotoREST({
        main_type: photo_type
    })

    const {
        productListData,
        productListIsLoading,
        productListError,
        productListRefetch
    } = useProductListREST({
        search_type: PRODUCT_LIST_FILTER_CODE_OBJ.complete
    })  
    
    const {
        photoListData
    } = usePhotoListSetter({
        myPhotoDataList,
        productListData,
        main_type: photo_type,
        photo_details_type: photo_details_type,
    })
    
    
    const moveToProductDetailHandler = (contract_product_id: number, album_save_id: number | null, photo_upload_id: number | null) => {
        const l_back_point = backPointUrlManager.encoded({url: `/photoList?tab1=2`});
        const m_detail_url: string = getQueryParamsUrl({
            main_url: "/productDetail",
            queryParams: {
                contract_product_id: `${contract_product_id}`,
                album_save_id: `${album_save_id || 0}`,
                photo_upload_id: `${photo_upload_id || 0}`,
                back_point: l_back_point
            }
        });

        navigate(m_detail_url);
    }


    const moveToPhotoDetailHandler = (photo_upload_id: number, photo_type_code: photoTypeCodeType, photo_details_type_code: photoDetailsTypeCodeType) => {
        let m_tab1: string = "";
        if(photo_type === PHOTO_TYPE_CODE.contract_shoot){
            m_tab1 = "0";
        }else{
            m_tab1 = "1";
        }

        const l_back_point = backPointUrlManager.encoded({url: `/photoList?tab1=${m_tab1}`});
        const m_detail_url: string = getQueryParamsUrl({
            main_url: "/photoDetail",
            queryParams: {
                photo_upload_id: `${photo_upload_id}`,
                photo_type: `${photo_type_code}`,
                viewRange_type: `${photo_details_type_code}`,
                back_point: l_back_point,
            }
        });

        navigate(m_detail_url);
    }

    return(
        <div className={CSS.l_PhotoList_main}>
            {
                photoListData.length > 0
                ?   <Flex preset={"photo_or_product_list"}>
                        {
                            photoListData.map((item, index) => {
                                if(photo_type === PHOTO_TYPE_CODE.contract_shoot && photo_details_type === PHOTO_DETAILS_TYPE_CODE.edit_end){
                                    return(
                                        <ProductBlock
                                            key={index}
                                            product_name={item.title}
                                            product_type={item.product_type}
                                            product_state={item.product_state}
                                            product_qty={item.product_qty}
                                            options={item.options}
                                            thumbnail_src={item.img_url}
                                            onClick={moveToProductDetailHandler.bind(null, item.contract_product_id, item.album_save_id, item.photo_upload_id)}
                                        />  
                                    )
                                }else{
                                    return (
                                        <PhotoListItemCard
                                            key={index}
                                            img_url={item.img_url}
                                            title={`${item.title} ${item.photo_type}`}
                                            info={`${item.info} ∙ ${item.office_name} 지점 촬영본`}
                                            upload_date={`${item.upload_date} (등록일)`}
                                            onClick={moveToPhotoDetailHandler.bind(null, item.photo_upload_id, photo_type, photo_details_type)}
                                        />
                                    )
                                }
                            })
                        }
                    </Flex>
                :   !myPhotoDataListIsLoading && !productListIsLoading
                    ?   <EmptyData />
                    :   null
            }

            <LoopAnimationModal 
                isModalOpen={myPhotoDataListIsLoading}
            />
        </div>
    )
}

export default PhotoList;