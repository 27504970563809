import { schedulePhotoProductThemeInterface, themaListInterface, themeItemOnClickHandlerType } from "../../static/interface/selectTheme.interface";
import ThemeList from "../ThemeList/ThemeList";
import CSS from "./static/css/ThemeListContainer.module.css"
import CardContainer from "../../../../Component/Elements/CardContainer/CardContainer";
import { useEffect } from "react";
import EmptyData from "../../../../Component/Elements/EmptyData/EmptyData";

const ThemeListContainer = (
    {
        isReadOnly,
        photoProductList,
        office_name,
        addThemeHandler,
        subThemeHandler,
        ref_list,
        setTopEleIndex,
        parentsRef,
    }:{
        isReadOnly: boolean;
        photoProductList: schedulePhotoProductThemeInterface[];
        office_name: string;
        addThemeHandler: themeItemOnClickHandlerType;
        subThemeHandler: themeItemOnClickHandlerType;
        ref_list: React.MutableRefObject<Array<(HTMLDivElement | null)>>;
        setTopEleIndex: React.Dispatch<React.SetStateAction<number>>;
        parentsRef: React.MutableRefObject<HTMLDivElement | null>;
    }
) => {
    const observerTopViewingEleHandler = (entries: IntersectionObserverEntry[]) => {
        if(!ref_list.current){
            return ;
        }
        
        const m_visibleEntries = entries.filter((entry) => entry.isIntersecting)

        if(m_visibleEntries.length > 0){
            // 가장 상단 요소 찾기
            const m_topEleEntry = m_visibleEntries.reduce((prev, curr) =>
                prev.boundingClientRect.top < curr.boundingClientRect.top ? prev : curr
            );

            // 요소의 인덱스 계산
            const m_topEleIndex = ref_list.current.indexOf(
                m_topEleEntry.target as HTMLDivElement
            );

            // 최종적인 인덱스 값을 state에 설정
            setTopEleIndex((prev) => 
                prev === m_topEleIndex ? prev : m_topEleIndex
            )
        }
    }

    useEffect(() => {
        const m_observer = new IntersectionObserver(observerTopViewingEleHandler, {
            root: parentsRef.current,
            threshold: 0,
        })

        ref_list.current.forEach((item) => {
            if(item){
                m_observer.observe(item);
            }
        })

        return(() => {
            ref_list.current.forEach((item) => {
                if(item){
                    m_observer.unobserve(item);
                }
            })    
        })
    }, [ref_list, parentsRef])

    if(photoProductList.length > 0){
        return(
            <CardContainer class_name_list={[CSS.l_theme_list_container_main]}>
                {
                    photoProductList.map((schedule_photo_product_item, index) => {
                        return(
                            <ThemeList
                                key={schedule_photo_product_item.schedule_photo_product_id}
                                isReadOnly={isReadOnly}
                                schedule_photo_product={schedule_photo_product_item}
                                office_name={office_name}
                                addThemeHandler={addThemeHandler}
                                subThemeHandler={subThemeHandler}
                                ref={(el) => (ref_list.current[index] = el)}
                            />
                        )
                    })
                }
            </CardContainer>
        )
    }else{
        return(
            <CardContainer class_name_list={[CSS.l_theme_list__WideCard_container]}>
                <EmptyData />
            </CardContainer>
        )
    }
}

export default ThemeListContainer;