// 웹 환경에서 사용하는 제품 제작 신청 페이지

import ThreeSplitPages from "../../../../../Component/Elements/Page/ThreeSplitPages/ThreeSplitPages";
import AdditionalInfoContainer from "../../AdditionalInfoContainer/AdditionalInfoContainer";
import AddressInfoCard from "../../AddressInfoCard/AddressInfoCard";
import FolderList from "../../FolderList/FolderList";
import OptionList from "../../OptionList/OptionList";
import ProductInfoCard from "../../ProductInfoCard/ProductInfoCard";
import SubmitBtnContainer from "../../SubmitBtnContainer/SubmitBtnContainer";
import UploadImageList from "../../UploadImageList/UploadImageList";
import { ProductDetail_Folder_FolderListEle_Interface, ProductDetail_OptionListEle_Interface, ProductDetail_productDetailData_Interface, ProductDetail_SelectedOptionListEle_Interface } from "../../../static/interface/ProductDetail.interface";
import { addedInfoDataInterface, satisfiedPhotoNumsInterface, uploadImgFilesInterface } from "../../../static/interface/ProductRequestEdit.interface";
import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { addressDataInterface } from "../../../../../Component/Elements/Postcode/interface/useDaumPostcode.interface";
import { Address } from "react-daum-postcode";
import { recipientInfoInterface } from "../../../static/interface/common.interface";
import SplitPagesContainer from "../../../../../Component/Elements/Page/ThreeSplitPages/components/SplitPagesContainer/SplitPagesContainer";
import { commonContext } from "../../../../../App";

const ProductRequestEditWebViewing = (
    {
        folderList,
        leftContainerEleRefList,
        centerContainerEleRefList,
        productOptionDataList,
        imgFiles,
        setImgFiles,
        satisfiedPhotoNums,
        setSatisfiedPhotoNums,
        selectedOptionList,
        productDetailInfoData,
        newAddedInfo,
        setNewAddedInfo,
        request,
        setRequest,
        customAddressData,
        setCustomAddressData,
        setRowAddressData,
        cancelHandler,
        requesetSelectHandler,
        selectOptionItemClickHandler,
        subOptionItemHandler,
        scrollToFirstFalseItem,
        isAlbum,
        recipientInfo,
        recipientInfoChangeHandler,
        min_resolution,
        min_long_size,
        min_short_size,
        deliveryAddressListOverlayOpenHandler,
    }:{
        folderList: ProductDetail_Folder_FolderListEle_Interface[];
        leftContainerEleRefList: React.MutableRefObject<(HTMLDivElement | null)[]>;
        centerContainerEleRefList: React.MutableRefObject<(HTMLDivElement | null)[]>;
        productOptionDataList: ProductDetail_OptionListEle_Interface[];
        imgFiles: uploadImgFilesInterface;
        setImgFiles: Dispatch<SetStateAction<uploadImgFilesInterface>>;
        satisfiedPhotoNums: satisfiedPhotoNumsInterface;
        setSatisfiedPhotoNums: Dispatch<SetStateAction<satisfiedPhotoNumsInterface>>;
        selectedOptionList: ProductDetail_SelectedOptionListEle_Interface[];
        productDetailInfoData: ProductDetail_productDetailData_Interface | null;
        newAddedInfo: addedInfoDataInterface
        setNewAddedInfo: Dispatch<SetStateAction<addedInfoDataInterface>>;
        request: string;
        setRequest: Dispatch<SetStateAction<string>>;
        customAddressData: addressDataInterface;
        setCustomAddressData: Dispatch<SetStateAction<addressDataInterface>>;
        setRowAddressData: Dispatch<SetStateAction<Address | null>>
        cancelHandler: React.MouseEventHandler<HTMLButtonElement>;
        requesetSelectHandler: React.MouseEventHandler<HTMLButtonElement>;
        selectOptionItemClickHandler: (option: ProductDetail_SelectedOptionListEle_Interface) => void;
        subOptionItemHandler: (option: ProductDetail_SelectedOptionListEle_Interface) => void;
        scrollToFirstFalseItem: (folder_id: number) => void;
        isAlbum: boolean;
        recipientInfo: recipientInfoInterface;
        recipientInfoChangeHandler: (value: string, target: "name" | "phone") => void;
        min_resolution: number;
        min_long_size: number;
        min_short_size: number;
        deliveryAddressListOverlayOpenHandler: () => void;
    }
) => {
    const l_commonContext = useContext(commonContext);

    useEffect(() => {
        l_commonContext.setHeaderRightNode(<></>);
    }, [])

    return(
        <ThreeSplitPages 
            title=""
            is_navi_hidden={true}
            background_color={"select_page"}
            isMainHidden={"mobile"}
            
            leftContainerNode={
                <SplitPagesContainer>
                    <FolderList 
                        folder_list={folderList} 
                        ele_refs={leftContainerEleRefList}
                        is_have_options={productOptionDataList.length > 0}
                        eleClickHandler={scrollToFirstFalseItem}
                    />
                </SplitPagesContainer>
            }

            centerContainerNode={
                <SplitPagesContainer>
                    <UploadImageList 
                        folderList={folderList}
                        imgFiles={imgFiles}
                        setImgFiles={setImgFiles}
                        isSatisfiedPhotoNums={satisfiedPhotoNums}
                        setIsSatisfiedPhotoNums={setSatisfiedPhotoNums}
                        cardRefs={centerContainerEleRefList}
                        min_resolution={min_resolution}
                        min_long_size={min_long_size}
                        min_short_size={min_short_size}
                    />
                    
                    <OptionList
                        productOptionDataList={productOptionDataList}
                        selectedOptionList={selectedOptionList}
                        itemOnClickHandler={selectOptionItemClickHandler}
                        cardRefs={centerContainerEleRefList}
                    />
                </SplitPagesContainer>
            }

            rightContainerNode={
                <SplitPagesContainer>
                    <ProductInfoCard 
                        product_name={productDetailInfoData?.product_name || ""}
                        is_has_options={productOptionDataList.length > 0}
                        selectedOptionData={selectedOptionList}
                        subOptionItemHandler={subOptionItemHandler}
                    />

                    <AdditionalInfoContainer
                        addedInfo={newAddedInfo}
                        setAddedInfo={setNewAddedInfo}
                        request={request}
                        setRequest={setRequest}
                        isAlbum={isAlbum}
                    />
                    
                    <AddressInfoCard
                        recipientInfo={recipientInfo}
                        recipientInfoChangeHandler={recipientInfoChangeHandler}
                        customAddressData={customAddressData}
                        setCustomAddressData={setCustomAddressData}
                        setRowAddressData={setRowAddressData}
                        overlayHandler={deliveryAddressListOverlayOpenHandler}
                    />

                    <SubmitBtnContainer 
                        cancelBtnHandler={cancelHandler}
                        selectBtnHandler={requesetSelectHandler}
                    />
                </SplitPagesContainer>
            }
        />
    )
}

export default ProductRequestEditWebViewing;