import AlertList from "../AlertList/AlertList";
import CSS from "./static/css/AlertOverlay.module.css";
import Text from "../../../../Component/Elements/Text/Text";
import IconX from "../../../../Component/Elements/Icon/IconX/IconX";

const AlertOverlay = (
    {
        is_open,
        closeHandler,
    }:{
        is_open: boolean; 
        closeHandler?: () => void;
    }
) => {
    if(is_open){
        return(
            <div className={CSS.l_AlertOverlay_main}>
                <div className={CSS.l_AlertOverlay_titleContainer}>
                    <Text 
                        class_name_list={[CSS.l_AlertOverlay_title]}
                        size={"size6"}
                        bold={true}
                    >
                        알림
                    </Text>

                    <IconX
                        class_name_list={["g_display_none_object", "web", CSS.l_AlertOverlay_closeBtn]}
                        preset={"big_black"}
                        onClick={closeHandler}

                    />
                </div>
                <div className={CSS.l_AlertOverlay_listContainer}>
                    <AlertList />
                </div>
            </div>
        )
    }else{
        return null;
    }
}

export default AlertOverlay;