import { useQuery } from "@tanstack/react-query";
import { callAxios, dev_console, isSuccess, whoAmI } from "../../../../Common/ts/commonTools";
import { GC_TIME, STALE_TIME } from "../../../../Common/ts/const";
import { ProductDetail_SelectInformationDate_Address_Interface } from "../../static/interface/ProductDetail.interface";
import { defaultApiReaponseInterface } from "../../../../Common/interface/Common.interface";
import { RTN_interface } from "../../../../Common/interface/App.interface";

const EMTPY_LIST: ProductDetail_SelectInformationDate_Address_Interface[] = [];

const useDeliveryAddressListREST = () => {

    const {
        data: deliveryAddressListData = EMTPY_LIST,
        isLoading: deliveryAddressListIsLoading,
        error: deliveryAddressListError,
        refetch: deliveryAddressListRefetch,
    } = useQuery({
        queryKey: ["useDeliveryAddressListREST"],
        queryFn: getAddressListData,
        enabled: isAbleToRequest(),
        select: (response) => {
            const RTN: RTN_interface<ProductDetail_SelectInformationDate_Address_Interface[]> = {
                sign: false,
                data: EMTPY_LIST,
                msg: "",
            }

            SetData:{
                if(!response){
                    RTN.msg = "데이터를 불러오지 못했습니다.";
                    break SetData;
                }

                if(!isSuccess(response)){
                    RTN.msg = "성공적인 응답이 아닙니다.";
                    break SetData;
                }

                const data: defaultApiReaponseInterface<ProductDetail_SelectInformationDate_Address_Interface[]> = response.data;

                RTN.sign = true;
                RTN.msg = "success";
                RTN.data = data.result;
            }

            if(!RTN.sign){
                dev_console.error(RTN.msg);
            }

            return RTN.data;
        },
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
    })

    function isAbleToRequest(){
        let RTN = false;

        if(whoAmI.isCustomer()){
            RTN = true;
        }

        return RTN;
    }
    
    async function getAddressListData(){
        const m_delivery_address_url = "api/v3/oops/customer/delivery/address";

        const response = await callAxios.api({
            url: m_delivery_address_url,
            method: "get",
        })

        dev_console.log(m_delivery_address_url);
        dev_console.log(response);

        return response;
    } 

    return({
        deliveryAddressListData,
        deliveryAddressListIsLoading,
        deliveryAddressListError,
        deliveryAddressListRefetch,
    })
}

export default useDeliveryAddressListREST;