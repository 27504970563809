import CSS from "./static/css/PageChangeTabMenu.module.css";
import Text from "../../../../Component/Elements/Text/Text";
import { useEffect, useState } from "react";
import { onChange } from "react-toastify/dist/core/store";

const PageChangeTabButton = (
    {
        isHighlight,
        innerText,
        onClick,
    }:{
        isHighlight?: boolean;
        innerText?: string;
        onClick?: React.MouseEventHandler<HTMLDivElement>;
    }
) => {
    const [mainClassNameList, setMainClassNameList] = useState<string[]>(classNameSetter());
    
    function classNameSetter(){
        const RTN: string[] = [];

        if(onClick){
            RTN.push("g_click_event_item");
        }

        RTN.push(CSS.l_PageChangeTabButton_main);
        if(isHighlight){
            RTN.push(CSS.highlight);
        }

        return RTN;
    }

    useEffect(() => {
        setMainClassNameList(classNameSetter());
    }, [onClick, isHighlight])

    return(
        <Text 
            class_name_list={mainClassNameList}
            text_wrap={"nowrap"}
            color={isHighlight ? "primary" : "dark"}
            onClick={onClick}
        >
            {innerText}
        </Text>
    )
}

const PageChangeTabMenu = (
    {
        pageNum,
        isHaveOption,
        clickHandler
    }:{
        pageNum: number;
        isHaveOption: boolean;
        clickHandler?: (pageNum: number) => void;
    }
) => {
    return(
        <div className={CSS.l_PageChangeTabMenu_main}>
            <PageChangeTabButton 
                innerText="사진 선택"
                isHighlight={pageNum === 1}
                onClick={clickHandler?.bind(null, 1)}
            />
            {
                isHaveOption
                &&  <PageChangeTabButton 
                        innerText="옵션 선택"
                        isHighlight={pageNum === 2}
                        onClick={clickHandler?.bind(null, 2)}
                    />
            }
            <PageChangeTabButton 
                innerText="정보 입력"
                isHighlight={pageNum === 3}
                onClick={clickHandler?.bind(null, 3)}
            />
        </div>
    )
}

export default PageChangeTabMenu;