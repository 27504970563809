import { useQuery } from "@tanstack/react-query";
import { callAxios, customToast, dev_console, isSuccess, whoAmI } from "../../../../Common/ts/commonTools";
import { GC_TIME, STALE_TIME } from "../../../../Common/ts/const";
import { ProductDetail_Interface } from "../../static/interface/ProductDetail.interface";
import { defaultApiReaponseInterface } from "../../../../Common/interface/Common.interface";
import { useEffect } from "react";
import { RTN_interface } from "../../../../Common/interface/App.interface";

const TOAST_ID = "useProductDetailREST";

const useProductDetailREST = (
    {
        contract_product_id,
        album_save_id,
        photo_upload_id
    }:{        
        contract_product_id: number;
        album_save_id: number | null;
        photo_upload_id: number | null;
    }
) => {
    const {
        data: productDetailData,
        error: productDetailError,
        isLoading: productDetailIsLoading,
        refetch: productDetailRefetch,
    } = useQuery({
        queryKey: ["useProductDetailREST", contract_product_id, album_save_id, photo_upload_id],
        queryFn: callProductDetailData,
        enabled: isAbleToRequest,
        select: (response) => {
            const RTN: RTN_interface<ProductDetail_Interface | null> = {
                sign: false,
                data: null,
                msg: "",
            };

            SetData:{
                if(!isSuccess(response)){
                    RTN.msg = "제품의 상세정보 요청 과정에서 문제가 발생했습니다.";
                    break SetData;
                }
                
                const data: defaultApiReaponseInterface<ProductDetail_Interface> = response.data;
                RTN.sign = true;
                RTN.data = data.result;
            }

            if(!RTN.sign){
                customToast.error({
                    msg: RTN.msg,
                    toastId: TOAST_ID
                })
            }

            return RTN.data;
        },
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
    })

    function isAbleToRequest(){
        let RTN: boolean = false;

        if(whoAmI.isCustomer() && contract_product_id){
            RTN = true;
        }

        return RTN;
    }
    
    async function callProductDetailData(){
        const product_detail_url = `api/v3/oops/product-detail?contract_product_id=${contract_product_id || ""}&album_save_id=${album_save_id || ""}&photo_upload_id=${photo_upload_id || ""}`
    
        const RTN = await callAxios.api({
            url: product_detail_url,
            method: "get"
        })

        dev_console.log(product_detail_url);
        dev_console.log(RTN);

        return RTN;
    }

    useEffect(() => {
        if(productDetailError){
            customToast.error({
                msg: "제품의 상세정보 요청 과정에서 문제가 발생했습니다.",
                toastId: TOAST_ID
            })
        }
    }, [productDetailError])

    return({
        productDetailData,
        productDetailError,
        productDetailIsLoading,
        productDetailRefetch
    })
}

export default useProductDetailREST;