import { useQuery } from "@tanstack/react-query";
import { callAxios, dev_console, isSuccess, whoAmI } from "../../../../../Common/ts/commonTools";
import { defaultApiReaponseInterface } from "../../../../../Common/interface/Common.interface";
import { mainREST_Interface } from "../static/interface/wrokRequiredNotificationDataREST.interface";
import { GC_TIME, STALE_TIME } from "../../../../../Common/ts/const";

const useMainREST = () => {
    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer()){
            result = true;
        }

        return result;
    }

    const {
        data: mainData = null,
        error: mainError,
        isLoading: mainIsLoading,
        refetch: mainRefetch
    } = useQuery({
        queryKey: ["useMainREST"],
        queryFn: callMainData,
        enabled: isAbleToRequest,
        select: (response) => {
            if(response){
                const data: defaultApiReaponseInterface<mainREST_Interface> = response.data;

                if(isSuccess(response)){
                    return data.result;
                }else{
                    return null;
                }
            }else{
                return null;
            }
        },
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
    })

    async function callMainData(){
        const main_url = "api/v3/oops/main";

        const response = await callAxios.api({
            url: main_url,
            method: "get",
        })

        dev_console.log(main_url);
        dev_console.log(response);

        return response;
    }
    
    return({
        mainData,
        mainError,
        mainIsLoading,
        mainRefetch,
    })
}

export default useMainREST;