import { Fragment, MouseEventHandler } from "react";
import EmptyData from "../../../../../../Component/Elements/EmptyData/EmptyData";
import Text from "../../../../../../Component/Elements/Text/Text";
import { ProductDetail_SelectInformationDate_Address_Interface } from "../../../../static/interface/ProductDetail.interface";
import DeliveryAddressItem from "../DeliveryAddressItem/DeliveryAddressItem";
import CSS from "./static/css/DeliveryAddressList.module.css";
import Hr from "../../../../../../Component/Elements/Hr/Hr";

// const TEST_DATA: ProductDetail_SelectInformationDate_Address_Interface[] = [];

// for(let index = 0; index < 20; index++){
//     TEST_DATA.push({
//         recipient_name: "홍길동",
//         recipient_phone: "010-1234-5678",
//         zip_code: "12345",
//         addr: "서울특별시 강남구 테헤란로 14길 6 남도빌딩 2층",
//         addr_detail: "201호",
//         building_no: "201",
//     })
// }

const DeliveryAddressList = (
    {
        deliveryAddressList,
        onClickHandler,
    }:{
        deliveryAddressList: ProductDetail_SelectInformationDate_Address_Interface[];
        onClickHandler?: (
            {
                recipient_name,
                recipient_phone,
                zonecode,
                mainAddress,
                buildingCode,
                detailAddress
            }:{
                recipient_name: string;
                recipient_phone: string;
                zonecode: string;
                mainAddress: string;
                buildingCode: string;
                detailAddress: string;
            }
        ) => void;
    }
) => {
    return (
        <div className={CSS.l_DeliveryAddressList_main}>
            <Text
                size={"size6"}
                bold={true}
            >
                사용한 배송지 목록
            </Text>

            <div>
                {
                    deliveryAddressList.length > 0
                    ?   deliveryAddressList.map((item, index) => {
                            return (
                                <Fragment key={index}>
                                    <DeliveryAddressItem 
                                        addr={item.addr}
                                        addr_detail={item.addr_detail}
                                        zip_code={item.zip_code}
                                        recipient_name={item.recipient_name}
                                        recipient_phone={item.recipient_phone}
                                        selectOnClickHandler={onClickHandler?.bind(null, {
                                            recipient_name: item.recipient_name,
                                            recipient_phone: item.recipient_phone,
                                            zonecode: item.zip_code,
                                            mainAddress: item.addr,
                                            buildingCode: item.building_no,
                                            detailAddress: item.addr_detail,
                                        })}
                                    />

                                    {
                                        deliveryAddressList.length > index + 1
                                        &&  <Hr />
                                    }
                                </Fragment>
                            )
                        })
                    :   <EmptyData />
                }
            </div>
        </div>
    )
}

export default DeliveryAddressList;