import CSS from "./static/css/ProductDetailPageMobileViewing.module.css";
import { useCallback, useContext, useEffect, useState } from "react";
import { ProductDetail_Folder_FolderListEle_Interface, ProductDetail_Folder_PhotoListEle_Interface, ProductDetail_SelectedOptionListEle_Interface, ProductDetail_SelectInformationDate_AddedInfo_Interface, ProductDetail_SelectInformationDate_Address_Interface } from "../../../static/interface/ProductDetail.interface";
import { productStateCodeType } from "../../../static/interface/ProductList.interface";
import UploadImageListViewMode from "../../../../../Component/Elements/UploadImageListViewMode/UploadImageListViewMode";
import Overlay from "../../../../../Component/Elements/Overlay/Overlay";
import ProductInfoCardViewMode from "../../ProductInfoCardViewMode/ProductInfoCardViewMode";
import Button from "../../../../../Component/Elements/Button/Normal/Button";
import DetailPageBtnTotalContainer from "../../DetailPageBtnTotalContainer/DetailPageBtnTotalContainer";
import MainPage from "../../../../../Component/Elements/Page/MainPage/MainPage";
import { uploadImageListViewMode_imgListSetter } from "../../../../../Component/Elements/UploadImageListViewMode/static/ts/common.tools";
import Text from "../../../../../Component/Elements/Text/Text";
import EditIcon from "../../../../../Component/Elements/Icon/EditIcon/EditIcon";
import { commonContext } from "../../../../../App";

const ProductDetailPageMobileViewing = (
    {
        textTitle,
        folderList,
        photoList,
        product_name,
        is_has_babyinfo,
        selectOptionList,
        addedInfo,
        address,
        contract_product_id,
        album_save_id,
        photo_upload_id,
        status_cd,
        isSingle,
        refetchHandler,
        isCanReupload,
    }:{
        textTitle: string;
        folderList: ProductDetail_Folder_FolderListEle_Interface[];
        photoList: ProductDetail_Folder_PhotoListEle_Interface[];
        product_name: string;
        is_has_babyinfo: boolean;
        selectOptionList: ProductDetail_SelectedOptionListEle_Interface[];
        addedInfo: ProductDetail_SelectInformationDate_AddedInfo_Interface | null;
        address: ProductDetail_SelectInformationDate_Address_Interface | null;
        contract_product_id: number;
        album_save_id: number | null;
        photo_upload_id: number | null;
        status_cd: productStateCodeType;
        isSingle: boolean;
        refetchHandler: () => void;
        isCanReupload: boolean;
    }
) => {
    const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);

    const l_commonContext = useContext(commonContext);

    const overlayOpenHandler = useCallback(() => {
        setIsOverlayOpen(true);
    }, [])

    const overlayCloseHandler = useCallback(() => {
        setIsOverlayOpen(false);
    }, [])

    useEffect(() => {
        // 업로드가 가능한 경우에는 업로드 버튼을 출력시켜준다.
        if(isCanReupload){
            l_commonContext.setHeaderRightNode(
                <EditIcon 
                    status_cd={status_cd}
                    contract_product_id={contract_product_id}
                    album_save_id={album_save_id}
                    photo_upload_id={photo_upload_id}
                    isHidden={"web"}
                />
            )
        }else{
            l_commonContext.setHeaderRightNode(<></>);
        }
    }, [isCanReupload, status_cd, contract_product_id, album_save_id, photo_upload_id])

    return(
        <MainPage
            title={textTitle}
            isHidden={"web"}
        >
            <div className={CSS.l_ProductDetailPageMobileViewing_main}>
                <UploadImageListViewMode
                    folderInfoList={
                        folderList.map((folder_item) => {
                            return({
                                folder_name: folder_item.folder_name,
                                folder_id: folder_item.folder_id,
                                middle_img_url: folder_item.middle_img_url,
                                img_list: uploadImageListViewMode_imgListSetter({
                                    folder_id: folder_item.folder_id,
                                    all_photo_list: photoList
                                })
                            })
                        })
                    }
                    isComfrimMode={false}
                    isRemoveOutline={true}
                    isSingle={isSingle}
                />
            </div>

            <div className={CSS.l_ProductDetailPageMobileViewing_buttonContainer}>
                <Button 
                    onClick={overlayOpenHandler}
                    preset={"detail_modal"}
                >
                    <Text color={"secondary"}>
                        상세 정보 보기
                    </Text>
                </Button>

                <DetailPageBtnTotalContainer
                    contract_product_id={contract_product_id}
                    album_save_id={album_save_id}
                    photo_upload_id={photo_upload_id}
                    status_cd={status_cd}
                />
            </div>

            <Overlay
                isOverlayOpen={isOverlayOpen}
                overlayCloseHandler={overlayCloseHandler}
            >
                <ProductInfoCardViewMode
                    product_name={product_name}
                    is_view_babyinfo={is_has_babyinfo}
                    selectedOptionData={selectOptionList}
                    addedInfo={addedInfo}
                    address={address}
                    isRemoveOutline={true}
                    status_cd={status_cd}
                    album_save_id={album_save_id}
                    refetchHandler={refetchHandler}
                />
            </Overlay>
        </MainPage>
    )
}

export default ProductDetailPageMobileViewing;