import CSS from "./static/css/PaymentHistoryLine.module.css";
import { useMediaQuery } from "react-responsive";
import PaymentInfoItem from "../PaymentInfoItem/PaymentInfoItem";
import PaymentDetail from "../PaymentDetail/PaymentDetail";
import { PaymentDetailItem_Interface } from "../PaymentDetailItem/static/interface/PaymentDetailItem.interface";
import MorePayInfo from "../elements/MorePayInfo/MorePayInfo";
import { MouseEventHandler } from "react";

const PaymentHistoryLine = (
    {
        contractDate,
        contractId,
        productName,
        contractTotamount,
        contractBalance,
        contractPayamount,
        contractImageList,
        paymentDetailList,
        is_extended,
        extendBtnHandler,
    }:{
        contractDate: string;
        contractId: string;
        productName: string;
        contractTotamount: string;
        contractBalance: string;
        contractPayamount: string;
        contractImageList: string[];
        paymentDetailList: PaymentDetailItem_Interface[];
        is_extended: boolean;
        extendBtnHandler: MouseEventHandler<HTMLDivElement>;
    }
) => {
    const isMobile = useMediaQuery({ maxWidth: 700 });

    return(
        <div className={CSS.l_PaymentHistoryLine_main}>
            <PaymentInfoItem 
                contractDate={contractDate}
                contractId={contractId}
                productName={productName}
                contractTotamount={contractTotamount}
                contractBalance={contractBalance}
                contractPayamount={contractPayamount}
                contractImageList={contractImageList}
                is_has_payment_detail_list={paymentDetailList.length > 0}
                is_extended={is_extended}
                extendBtnHandler={extendBtnHandler}
            />

            {
                isMobile
                ?   paymentDetailList.length > 0
                    ?   <MorePayInfo
                            label={"결제 내역 더 보기"}
                            className={CSS.l_MorePayInfo}
                            is_extended={is_extended}
                            onClick={extendBtnHandler}
                            is_has_payment_detail_list={true}
                        >
                            <PaymentDetail 
                                paymentDetailList={paymentDetailList}
                            />
                        </MorePayInfo>
                    :   <MorePayInfo
                            label={""}
                            className={CSS.l_MorePayInfo}
                            is_extended={false}
                            is_has_payment_detail_list={false}
                        />
                :   is_extended
                    &&  <PaymentDetail
                            paymentDetailList={paymentDetailList}
                        />
            }
        </div>
    )
}

export default PaymentHistoryLine;