import { useEffect, useState } from "react";
import { locationStateDataInterface, photoDetailDataInterface } from "../static/interface/PhotoDetail.interface";
import useDateString from "../../../Common/hooks/useDateString.hook";
import { customerConfirmedType, joinedEventType, managerConfirmedType } from "../static/interface/PhotoList.interface";
import { dateObjectManagerClass, getAccountDataClass } from "../../../Common/ts/commonTools";
import { uploadImageListViewMode_folderInfo_Interface } from "../../../Component/Elements/UploadImageListViewMode/static/interface/UploadImageListViewMode.interface";
import { viewerImageInterface } from "../../../Component/Elements/ImageGallery/interface/ImageGallery.interface";
import { fileCodeType } from "../../../Common/interface/Common.interface";
import { FILE_CODE } from "../../../Common/ts/const";
import { usePhotoDetailDataSetter_photoShootTime_interface, usePhotoDetailDataSetter_reviewTerms_interface } from "../static/interface/usePhotoDetailDataSetter.interface";
import { PHOTO_DETAILS_TYPE_CODE, PHOTO_LIST_FILTER_CODE, PHOTO_TYPE_CODE, REVIEW_EVENT_READY_CODE } from "../static/ts/const";
import { photoListFilterCodeType } from "../static/interface/PhotoListMain.interface";
import { ProductDetail_SelectedOptionListEle_Interface, ProductDetail_SelectInformationDate_AddedInfo_Interface, ProductDetail_SelectInformationDate_Address_Interface } from "../../ProductManufacturing/static/interface/ProductDetail.interface";

const getAccountData = new getAccountDataClass();
const dateObjectManager = new dateObjectManagerClass();

const usePhotoDetailDataSetter = (
    {
        photoDetailData,
        location_state_data
    }:{
        photoDetailData: photoDetailDataInterface | null;
        location_state_data: locationStateDataInterface
    }
) => {
    // 사진 데이터
    const [folderDataList, setFolderDataList] = useState<uploadImageListViewMode_folderInfo_Interface[]>([]);

    // 다운로드되는 파일 이름 설정값
    const [fileName, setFileName] = useState<string>("");

    const [contractProductId, setContractProductId] = useState<number>(0);
    // photo_upload_id
    const [photoUploadId, setPhotoUploadId] = useState<number>(0);

    const [uploadDate, setUploadDate] = useState<string>("");

    const [scheduleId, setScheduleId] = useState<number>(0);

    const [joinedEvent, setJoinedEvent] = useState<joinedEventType | null>(null);
    const [customerConfirmed, setCustomerConfirmed] = useState<customerConfirmedType | null>(null);
    const [managerConfirmed, setManagerConfirmed] = useState<managerConfirmedType>("N");
    const [requestNum, setRequestNum] = useState<number | null>(null);

    const [officeName, setOfficeName] = useState<string>("");

    const [confirmReason, setConfirmReason] = useState<string>("");

    const [contractInfo, setContractInfo] = useState<string>("");

    // 다운로드 버튼이 출력될 수 있는가?
    const [isPrintDownloadBtn, setIsPrintDownloadBtn] = useState<boolean>(false);

    const [reviewDate, setReviewDate] = useState<string>("");
    const [reviewDeadline, setReviewDeadline] = useState<string>("");
    const review_terms: usePhotoDetailDataSetter_reviewTerms_interface[] = [
        {
            text: "업로드된 사진을"
        },{
            text: "기한 내에 블로그 및 SNS에 업로드",
            is_bold: true,
        },{
            text: "해주시면 됩니다."
        }
    ]

    // 선택한 옵션 정보
    const [selectedOptionNameList, setSelectedOptionNameList] = useState<ProductDetail_SelectedOptionListEle_Interface[]>([]);

    const [babyInfo, setBabyInfo] = useState<ProductDetail_SelectInformationDate_AddedInfo_Interface | null>(null)

    const [address, setAddress] = useState<ProductDetail_SelectInformationDate_Address_Interface | null>(null);

    // 촬영시간
    const [photoShootTime, setPhotoShootTime] = useState<usePhotoDetailDataSetter_photoShootTime_interface>({
        date: "",
        weekday: "",
        start_time: "",
        end_time: "",
        length: 0,
    });

    const [downloadIconData, setDownloadIconData] = useState<{
        download_type_code: number;
        photoUploadId: number;
        fileName: string;
    }>({
        download_type_code: 0,
        photoUploadId: 0,
        fileName: "",
    })

    // 업로드 날짜를 한글로 보기 좋게 변환
    const { dateString: uploadDateString, setOriginDateData: setUploadOriginDateData } = useDateString(null);

    // 다운로드 날짜를 한글로 보기 좋게 변환
    const { dateString: downloadDateString, setOriginDateData: setDownloadOriginDateData } = useDateString(null);

    // 폴더구조가 아닌가?(정확히는 보이기에 폴더 구조가 아닌가?)
    const [isSingle, setIsSingle] = useState<boolean>(false);

    const l_photo_type = location_state_data.photo_type;
    const l_viewRange_type = location_state_data.viewRange_type;

    let download_type_code = 0;
    if(l_photo_type === PHOTO_TYPE_CODE.contract_shoot){
        download_type_code = 1;
    }else if(l_photo_type === PHOTO_TYPE_CODE.event_shoot){
        download_type_code = 3;
    }

    const title: string = 
        l_viewRange_type === PHOTO_DETAILS_TYPE_CODE.origin 
        ?   (photoDetailData?.photo_shoot_name || "")
        :   "상품명";

    let isEventApplication: boolean = false;
    if(photoDetailData?.event_ready === REVIEW_EVENT_READY_CODE.ready){
        isEventApplication = true;
    }

    let photo_data_type: photoListFilterCodeType = PHOTO_LIST_FILTER_CODE.shoot;
    let pageTitle: string = "";
    if(l_photo_type === PHOTO_TYPE_CODE.contract_shoot && l_viewRange_type === PHOTO_DETAILS_TYPE_CODE.origin){
        photo_data_type = PHOTO_LIST_FILTER_CODE.shoot;
        pageTitle = "원본 사진";
    }else if(l_photo_type === PHOTO_TYPE_CODE.contract_shoot && l_viewRange_type === PHOTO_DETAILS_TYPE_CODE.edit_end){
        photo_data_type = PHOTO_LIST_FILTER_CODE.product_request;
        pageTitle = "제품 제작 사진";
    }else if(l_photo_type === PHOTO_TYPE_CODE.event_shoot){
        photo_data_type = PHOTO_LIST_FILTER_CODE.review_event;
        pageTitle = "후기 이벤트 사진";
    }

    const isPrintProductRequestBtn = photo_data_type === PHOTO_LIST_FILTER_CODE.shoot;

    useEffect(() => {
        if(photoDetailData){

            const m_file_name_part_list: string[] = [];

            if(photoDetailData){
                if(photoDetailData.schedule_date){
                    m_file_name_part_list.push(photoDetailData.schedule_date)
                }
                m_file_name_part_list.push(getAccountData.name());
                m_file_name_part_list.push(photoDetailData.photo_shoot_name);
                // m_file_name_part_list.push(photoDetailData.);    //  서버에서 선택한 테마 정보를 줘야할듯...
            }

            setFileName(m_file_name_part_list.join("_"))

            setScheduleId(photoDetailData.schedule_id || 0);

            setContractProductId(photoDetailData.contract_product_id || 0);
            setPhotoUploadId(photoDetailData.photo_upload_id || 0);

            setUploadDate(photoDetailData.upload_date);

            setJoinedEvent(photoDetailData.joined_event);
            setCustomerConfirmed(photoDetailData.customer_confirmed);
            setManagerConfirmed(photoDetailData.manager_confirmed);
            setConfirmReason(photoDetailData.confirm_reason);
            setOfficeName(`${photoDetailData.office_name || ""}`);

            if(photo_data_type === PHOTO_LIST_FILTER_CODE.product_request){
                setBabyInfo({
                    baby_name: photoDetailData.baby_name,
                    baby_birth_day: photoDetailData.baby_birth_day,
                    baby_birth_time: photoDetailData.baby_birth_time,
                    baby_height: photoDetailData.baby_height,
                    baby_weight: photoDetailData.baby_weight,
                    common_memo: photoDetailData.common_memo,
                })
            }else{
                setBabyInfo(null);
            }

            setAddress({
                addr: photoDetailData.addr,
                addr_detail: photoDetailData.addr_detail,
                zip_code: photoDetailData.zip_code,
                building_no: photoDetailData.building_no,
                recipient_name: "", // 전화번호는 언제... 받지?
                recipient_phone: "", // 전화번호는 언제... 받지?
            })

            let m_requesetNum: number | null = parseInt(photoDetailData.request_num || "");
            if(isNaN(m_requesetNum)){
                m_requesetNum = null;
            }
            setRequestNum(m_requesetNum);

            if(photoDetailData.contract_id && photoDetailData.package_name){
                setContractInfo(`[${photoDetailData.contract_id}] ${photoDetailData.package_name}`)
            }

            if(photoDetailData.img_list){
                // 여기서부터 하면 되겠네...
                setFolderDataList(photoDetailData.img_list.map((item) => {

                    // 이미지목록을 뷰어에서 사용할 수 있는 형식으로 변환해준다.
                    const m_image_list:viewerImageInterface[] = item.select_photo_list.map((photo_list_item) => {                        
                        let m_file_type: fileCodeType = FILE_CODE.none;

                        // 서버에서 소문자로 줄수도 대문자로 줄 수도 있어서 소문자로 통일시키는 작업을 거친다
                        const m_photo_list_item_file_type = photo_list_item.file_type.toLowerCase();
                        switch(m_photo_list_item_file_type){
                            case "jpg":
                                m_file_type = FILE_CODE.img;
                                break;
                            case "mp4":
                                m_file_type = FILE_CODE.video;
                                break;
                            case "zip":
                                m_file_type = FILE_CODE.zip;
                                break;
                            default:
                                m_file_type = FILE_CODE.none;
                        }

                        return({
                            thumbnail_url: photo_list_item.middle_url,
                            origin_url: photo_list_item.middle_url,
                            file_type: m_file_type,
                            file_name: photo_list_item.file_name,
                            folder_id: item.folder_id,
                        })
                    });

                    return({
                        folder_name: item.folder_name,
                        folder_id: item.folder_id,
                        middle_img_url: item.folder_img_url.middle_url,
                        img_list: m_image_list,
                    })
                }));

                if(photoDetailData.img_list.length > 0 && photoDetailData.img_list[0].folder_id === 0){
                    // 폴더 구분이 없는경우 파일의 수
                    setIsSingle(true);
                }
            }

            // 다운로드일자
            setDownloadOriginDateData(photoDetailData.download_at);
            // 업로드일자
            setUploadOriginDateData(photoDetailData.upload_date);

            // 다운로드버튼 출력 조건 
            let is_print_download_btn: boolean = false;
            if(photoDetailData && photoDetailData.img_list && photoDetailData.img_list.length > 0 && !!photoDetailData.photo_upload_id){
                is_print_download_btn = true;
            }
            setIsPrintDownloadBtn(is_print_download_btn)

            setPhotoShootTime({
                date: photoDetailData.schedule_date || "",
                start_time: photoDetailData.start_time || "",
                end_time: photoDetailData.end_time || "",
                weekday: dateObjectManager.getWeekday(photoDetailData.schedule_date || ""),
                length: dateObjectManager.getMinuteDifference(`1990-01-01 ${photoDetailData.start_time}`, `1990-01-01 ${photoDetailData.end_time}`)
            });
        }
    }, [photoDetailData])

    useEffect(() => {
        setDownloadIconData({
            download_type_code,
            photoUploadId,
            fileName,
        })
    }, [download_type_code, photoUploadId, fileName])

    return({
        common_data: {
            pageTitle,
            uploadDateString,
            downloadDateString,
            photo_data_type,
        },

        downloadIcon_data: downloadIconData,

        uploadImageListViewMode_data: {
            folderDataList,
            isSingle
        },

        photoInfoCard_data: {
            isPrintDownloadBtn,
            download_type_code,
            fileName,
            uploadDate,
            photoUploadId,
    
            title,
            isEventApplication,
            isPrintProductRequestBtn,
            scheduleId,
            joinedEvent,
            photo_type: l_photo_type,
            customerConfirmed,
            managerConfirmed,
            confirmReason,
            requestNum,
            contractProductId,
        },

        eventButtonSet_data: {
            isEventApplication,
            isPrintProductRequestBtn,

            scheduleId,
            uploadDate,
            joinedEvent,
            photoUploadId,
    
            photo_type:l_photo_type,
            customerConfirmed,
            managerConfirmed,
            confirmReason,
            requestNum,
            contractProductId,
        },

        shoot_data: {
            title,
            contractInfo,
            photoShootTime,
            officeName,
        },
    })
}

export default usePhotoDetailDataSetter;