import { monthlyScheduleBtnCodeType } from "../../../MonthlySchedule/static/interface/common.interface";
import { MONTHLY_SCHEDULE_BTN_CODE_OBJECT } from "../../../MonthlySchedule/static/ts/const";
import calendar_icon from "../static/img/calendar_icon_16@2x.png";
import list_icon from "../static/img/list_icon_16@2x.png";

const useMonthlyScheduleChangeBtnSetter = (
    {
        state
    }:{
        state: monthlyScheduleBtnCodeType;
    }
) => {
    let text: string = "";
    let img_src: string = "";

    switch(state){
        case MONTHLY_SCHEDULE_BTN_CODE_OBJECT.calendar:
            text = "리스트형 보기"
            img_src = list_icon;
            break;
        case MONTHLY_SCHEDULE_BTN_CODE_OBJECT.list:
            text = "캘린더형 보기"
            img_src = calendar_icon;
            break;
    }

    return({
        text,
        img_src,
    })
}

export default useMonthlyScheduleChangeBtnSetter;