import CSS from "./static/css/MorePayInfo.module.css";
import { MouseEventHandler, ReactNode, useMemo } from "react";
import TriangleIcon from "../../../../../Component/Elements/Icon/TriangleIcon/TriangleIcon";
import Text from "../../../../../Component/Elements/Text/Text";
import { useMediaQuery } from "react-responsive";

const MorePayInfo = (
    {
        label,
        className,
        is_extended,
        onClick,
        children,
        is_has_payment_detail_list,
    }:{
        label: string;
        className?: string;
        is_extended: boolean;
        onClick?: MouseEventHandler<HTMLDivElement>;
        children?: ReactNode;
        is_has_payment_detail_list: boolean;
    }
) => {
    const isMobile = useMediaQuery({ maxWidth: 700 });

    const mainClassName = useMemo(() => {
        const RTN: string[] = [];

        if(className){
            RTN.push(className);
        }

        RTN.push(CSS.l_MorePayInfo_main);

        if(is_extended){
            RTN.push(CSS.open);
        }

        return RTN.join(" ");
    }, [className, is_extended])

    const textContainerClassName = useMemo(() => {
        const RTN: string[] = [];

        if(onClick){
            RTN.push("g_click_event_item");
        }

        RTN.push(CSS.l_TextContainer);

        if(is_extended){
            RTN.push(CSS.open);
        }

        return RTN.join(" ");
    }, [onClick, is_extended])

    return(
        <div className={mainClassName}>
            <div 
                className={textContainerClassName}
                onClick={onClick}
            >
                {
                    is_has_payment_detail_list
                    &&  <>
                            <Text 
                                size={isMobile ? "size1" : "size3"}
                                color={"gray2"}
                            >
                                {label}
                            </Text>

                            {
                                onClick
                                &&  <TriangleIcon
                                        direction={is_extended ? "top" : "bottom"}
                                        size={"small"}
                                        color={"gray"}
                                    />
                            }
                        </>
                }
            </div>

            {
                children
                &&  <div className={
                        is_extended
                        ?   `${CSS.l_ExtentContainer} ${CSS.open}`
                        :   CSS.l_ExtentContainer
                    }>
                        {children}
                    </div>
            }
        </div>
    )
}

export default MorePayInfo;