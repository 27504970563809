import { MutableRefObject, ReactNode, useEffect, useState } from "react";
import { isHiddenType } from "../../../../Common/interface/Common.interface";
import { mobileBackgroundColorType } from "../../../../Common/interface/style.interface";
import MainPage from "../MainPage/MainPage";
import CSS from "./static/css/ThreeSplitPages.module.css";

const ThreeSplitPages = (
    {
        title,
        background_color,
        is_navi_hidden,

        leftContainerNode,
        centerContainerNode,
        rightContainerNode,

        isLeftHidden,
        isCenterHidden,
        isRightHidden,

        leftRef,
        centerRef,
        rightRef,

        isMainHidden,

        children,
    }:{
        title: string;
        background_color?: mobileBackgroundColorType;
        is_navi_hidden?: boolean;

        leftContainerNode?: ReactNode;
        centerContainerNode?: ReactNode;
        rightContainerNode?: ReactNode;

        isLeftHidden?: isHiddenType;
        isCenterHidden?: isHiddenType;
        isRightHidden?: isHiddenType;

        leftRef?: MutableRefObject<HTMLDivElement | null>;
        centerRef?: MutableRefObject<HTMLDivElement | null>;
        rightRef?: MutableRefObject<HTMLDivElement | null>;

        isMainHidden?: isHiddenType;

        children?: ReactNode;
    }
) => {
    const [leftClassName, setLeftClassName] = useState<string>(classNameSetter("left").join(" "))
    const [centerClassName, setCenterClassName] = useState<string>(classNameSetter("center").join(" "))
    const [rightClassName, setRightClassName] = useState<string>(classNameSetter("right").join(" "))

    function classNameSetter(container: "left" | "center" | "right"){
        const RTN: string[] = [];

        let m_isHidden: isHiddenType | undefined = undefined;
        switch (container) {
            case "left":
                m_isHidden = isLeftHidden;
                break;
            case "center":
                m_isHidden = isCenterHidden;
                break;
            case "right":
                m_isHidden = isRightHidden;
                break;
        }

        if(m_isHidden){
            RTN.push("g_display_none_object");
            RTN.push(m_isHidden);
        }

        RTN.push(CSS.l_ThreeSplitPages_nodeContainer);
        RTN.push(CSS[container]);

        return RTN;
    }

    useEffect(() => {
        setLeftClassName(classNameSetter("left").join(" "));
    }, [isLeftHidden])

    useEffect(() => {
        setCenterClassName(classNameSetter("center").join(" "));
    }, [isCenterHidden])

    useEffect(() => {
        setRightClassName(classNameSetter("right").join(" "));
    }, [isRightHidden])

    return(
        <MainPage
            title={title}
            background_color={background_color}
            is_navi_hidden={is_navi_hidden}
            isHidden={isMainHidden}
        >
            <div className={CSS.l_ThreeSplitPages_main}>
                {
                    leftContainerNode
                    &&  <div 
                            className={leftClassName}
                            ref={leftRef}
                        >
                            {leftContainerNode}
                        </div>
                }

                {
                    centerContainerNode
                    &&  <div 
                            className={centerClassName}
                            ref={centerRef}
                        >
                            {centerContainerNode}
                        </div>
                }

                {
                    rightContainerNode
                    &&  <div 
                            className={rightClassName}
                            ref={rightRef}
                        >
                            {rightContainerNode}
                        </div>
                }
            </div>

            {/* overlay컴포넌트를 추가하는 목적으로 사용되는 부분이다. */}
            {children}
        </MainPage>
    )
}

export default ThreeSplitPages;