import CSS from "./static/css/MainHome.module.css";
import MainPage from "../../Component/Elements/Page/MainPage/MainPage";
import AdvertisingBanner from "./components/AdvertisingBanner/AdvertisingBanner";
import WrokRequiredNotification from "./components/WrokRequiredNotification/WrokRequiredNotification";
import MonthlySchedule from "./components/MonthlySchedule/MonthlySchedule";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { backPointUrlManagerClass } from "../../Common/ts/appTools";

const backPointUrlManager = new backPointUrlManagerClass();

const MainHome = () => {
    const [URLSearchParams, SetURLSearchParams] = useSearchParams();
    
    const navigate = useNavigate();
    
    useEffect(() => {
        const m_back_point = backPointUrlManager.getBackPointUrlAndDecode(URLSearchParams)

        if(m_back_point){
            navigate(m_back_point, {replace: true});
        }
    }, [URLSearchParams])

    return(
        <MainPage title="홈">
            <div className={CSS.l_MainHome_main}>
                <WrokRequiredNotification />
                <MonthlySchedule />
                <AdvertisingBanner />
            </div>
        </MainPage>
    )
}

export default MainHome;