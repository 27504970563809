import CSS from "./static/css/WrokRequiredNotification.module.css";
import CardContainer from "../../../../Component/Elements/CardContainer/CardContainer";
import WrokRequiredMainItem from "../elements/WrokRequiredMainItem/WrokRequiredMainItem";
import WrokRequiredItem from "../elements/WrokRequiredItem/WrokRequiredItem";
import { useNavigate } from "react-router-dom";
import { PRODUCT_LIST_FILTER_CODE_OBJ } from "../../../ProductManufacturing/static/ts/const";
import useMainREST from "./hooks/useMainREST.hook";
import useWrokRequiredNotificationDataSetter from "./hooks/useWrokRequiredNotificationDataSetter.hook";
import { RESERVATION_LIST_FILTER_CODE } from "../../../Reservation/static/ts/const";
import { PHOTO_LIST_FILTER_CODE } from "../../../PhotoList/static/ts/const";
import { getQueryParamsUrl } from "../../../../Common/ts/commonTools";
import { backPointUrlManagerClass } from "../../../../Common/ts/appTools";

const backPointUrlManager = new backPointUrlManagerClass();

const WrokRequiredNotification = () => {
    const navigate = useNavigate();

    const {
        mainData,
        mainError,
        mainIsLoading,
        mainRefetch,
    } = useMainREST();

    const {
        totalCount,
        selectPhotoCount,
        editPhotoCount,
        confPhotoCount,
        gooutPhotoCount,
        selectThemeCount,
        eventReviewCount,
    } = useWrokRequiredNotificationDataSetter({mainData})

    const l_back_point_url = backPointUrlManager.encoded({url: "/"})

    const moveNeedToRequestTagHandler = () => {
        const m_productList_url = getQueryParamsUrl({
            main_url: "/productList",
            queryParams: {
                tab1: PRODUCT_LIST_FILTER_CODE_OBJ.need_to_request,
                back_point: l_back_point_url,
            }
        })

        navigate(m_productList_url)
    }

    const moveNeedToConfirmTagHandler = () => {
        const m_productList_url = getQueryParamsUrl({
            main_url: "/productList",
            queryParams: {
                tab1: PRODUCT_LIST_FILTER_CODE_OBJ.need_to_confirm,
                back_point: l_back_point_url,
            }
        })

        navigate(m_productList_url)
    }

    const moveThemeSelectTagHandler = () => {
        const m_reservationHome_url = getQueryParamsUrl({
            main_url: "/reservationHome",
            queryParams: {
                tab1: RESERVATION_LIST_FILTER_CODE.reservat,
                back_point: l_back_point_url,
            }
        })

        navigate(m_reservationHome_url)
    }

    const moveReviewEventTagHandler = () => {
        const m_photoList_url = getQueryParamsUrl({
            main_url: "/photoList",
            queryParams: {
                tab1: `${PHOTO_LIST_FILTER_CODE.shoot}`,
                back_point: l_back_point_url,
            }
        })

        navigate(m_photoList_url)
    }

    return(
        <CardContainer 
            class_name_list={[CSS.l_WrokRequiredNotification_main]}
        >
            <WrokRequiredMainItem 
                item_num={totalCount}
                class_name_list={[CSS.l_headerItme]}
            />

            <WrokRequiredItem
                text="테마 선택 요청"
                item_num={selectThemeCount} 
                class_name_list={[CSS.l_area1]}
                onClick={moveThemeSelectTagHandler}
            />

            <WrokRequiredItem
                text="사진 선택 요청"
                item_num={selectPhotoCount} 
                class_name_list={[CSS.l_area2]}
                onClick={moveNeedToRequestTagHandler}
            />

            <WrokRequiredItem 
                text="사진 승인 요청"
                item_num={confPhotoCount}
                class_name_list={[CSS.l_area3]}
                onClick={moveNeedToConfirmTagHandler}
            />

            <WrokRequiredItem 
                text="후기 이벤트 신청"
                item_num={eventReviewCount}
                class_name_list={[CSS.l_shipped]}
                onClick={moveReviewEventTagHandler}
            />
        </CardContainer>
    )
}

export default WrokRequiredNotification;