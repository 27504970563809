import { useEffect, useState } from "react";
import Badge from "../../../Badge/Badge";
import CSS from "./static/css/swipeTextItem.module.css"
import Text from "../../../Text/Text";

const SwipeTextItem = (
    {
        text,
        onClick,
        is_highlight,
        is_print_badge,
    }:{
        text: string;
        onClick?: React.MouseEventHandler<HTMLDivElement>;
        is_highlight?: boolean;
        is_print_badge: boolean;
    }
) => {
    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    function classNameSetter(){
        const RTN: string[] = ["l_swipe_item"];

        if(onClick){
            RTN.push("g_click_event_item");
        }

        RTN.push(CSS.l_SwipeTextItem_main);

        if(is_highlight){
            RTN.push(CSS.highlight);
        }

        return RTN;
    }
    
    useEffect(() => {
        setMainClassName(classNameSetter().join(" "));
    }, [onClick])

    return (
        <div
            className={mainClassName}
            onClick={onClick}
            data-highlight={is_highlight}   // swipeLine에서 선택된 탭을 인식하기 위해 사용하는 속성
        >
            <Badge is_print_badge={is_print_badge}>
                <Text 
                    preset={"swipe_text_item"}
                    color={is_highlight ? "primary" : "gray0"}
                    text_wrap={"nowrap"}
                >
                    {text}
                </Text>
            </Badge>
        </div>
    )
}

export default SwipeTextItem;