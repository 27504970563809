import CSS from "./static/css/OvalIconButton.module.css";
import useOvalIconButtonPreset from "./hooks/useOvalIconButtonPreset.hook";
import { ovalIconButtonPresetType } from "./interface/OvalIconButton.interface";
import { MouseEventHandler, useEffect, useState } from "react";
import Text from "../../Text/Text";

const OvalIconButton = (
    {
        onClick,
        preset,
        inner_text,
    }:{
        onClick: MouseEventHandler<HTMLButtonElement>;
        preset?: ovalIconButtonPresetType;
        inner_text?: string;
    }
) => {
    const [mainClassName, setMainClassName] = useState<string>(mainClassNameSetter().join(" "));

    const {
        text_color,
        btn_text,
        icon_src,
    } = useOvalIconButtonPreset(preset);

    function mainClassNameSetter(){
        const RTN: string[] = [];

        RTN.push("g_click_event_item");
        
        RTN.push(CSS.l_OvalIconButton_main);

        if(preset){
            RTN.push(CSS.preset);
            RTN.push(CSS[preset]);
        }

        return RTN;
    }

    useEffect(() => {
        setMainClassName(mainClassNameSetter().join(" "));
    }, [preset, onClick])

    return(
        <button 
            className={mainClassName}
            onClick={onClick}
            type="button"
        >
            <div className={CSS.l_OvalIconButton_innerContainer}>
                <Text
                    preset={"oval_icon_button"}
                    color={text_color}
                    text_wrap={"nowrap"}
                >
                    {inner_text ||btn_text}
                </Text>
                {
                    icon_src
                    &&  <div className={CSS.l_OvalIconButton_iconContainer}>
                            <img 
                                className={CSS.l_OvalIconButton_iconImg}
                                src={icon_src} 
                            />
                        </div>
                }
            </div>
        </button>
    )
}

export default OvalIconButton;