import { useEffect, useState } from "react";
import CSS from "./static/css/Checkbox.module.css";
import check_icon from "./static/img/check_small.png";

const Checkbox = (
    {
        isChecked,
        onClickHander,
        disabled,
    }:{
        isChecked: boolean;
        onClickHander?: React.MouseEventHandler<HTMLDivElement>;
        disabled?: boolean;
    }
) => {
    const [totalClassName, setTotalClassName] = useState<string>(classNameSetter().join(" "));

    function classNameSetter(){
        const RTN: string[] = ["g_click_event_item"];

        if(disabled){
            RTN.push("not_allowed");
        }

        RTN.push(CSS.l_Checkbox_main);

        return RTN;
    }

    const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if(disabled){
            return ;
        }

        if(onClickHander){
            onClickHander(event);
        }
    }

    useEffect(() => {
        setTotalClassName(classNameSetter().join(" "))
    }, [disabled])

    return(
        <div
            className={totalClassName}
            onClick={onClick}
        >
            {
                isChecked
                &&  <div className={CSS.l_Checkbox_markIconContainer}>
                        <img
                            className={CSS.l_Checkbox_markIconImg} 
                            src={check_icon}
                        />
                    </div>
            }
        </div>
    )
}

export default Checkbox