import { useContext, useEffect, useMemo, useState } from "react";
import CSS from "./static/css/MainPage.module.css";
import { mobileBackgroundColorType } from "../../../../Common/interface/style.interface";
import { isHiddenType } from "../../../../Common/interface/Common.interface";
import { commonContext } from "../../../../App";
import { isHiddenClassNameSetter } from "../../../../Common/ts/commonTools";
import MainTitle from "../../MainTitle/MainTitle";

const MainPage = (
    {
        title,
        background_color,
        children,
        is_navi_hidden,
        preset,
        isHidden,
    }:{
        title: string;
        background_color?: mobileBackgroundColorType;
        children?: React.ReactNode;
        is_navi_hidden?: boolean;
        preset?: "ThreeSplitPages";
        isHidden?: isHiddenType;
    }
) => {
    const  classNameSetter = useMemo(() => {
        let RTN: string[] = [];

        if(isHidden){
            RTN = isHiddenClassNameSetter({
                classNameList: RTN,
                isHidden,
                display: "flex",
            })
        }

        RTN.push(CSS.l_MainPage_main)

        if(background_color){
            RTN.push(CSS.background_color);
            RTN.push(CSS[background_color]);
        }

        if(preset){
            RTN.push(CSS.preset);
            RTN.push(CSS[preset]);
        }

        return RTN;
    }, [isHidden, background_color, preset])

    const [mainClassName, setMainClassName] = useState<string>(classNameSetter.join(" "));

    const l_commonContext = useContext(commonContext);

    useEffect(() => {
        l_commonContext.setIsNaviHidden(!!is_navi_hidden);

        // 페이지가 소멸될 때 마다 헤더에 추가한 내용을 초기화 시킨다.
        return(() => {
            l_commonContext.setHeaderLeftNode(null);
            l_commonContext.setMobileHeaderTitle("");
            l_commonContext.setHeaderRightNode(null);
            l_commonContext.setHeaderBackgroundColorPreset("");
            l_commonContext.setIsNaviHidden(false);
        })
    }, [])

    // 모바일 환경에서의 헤더 타이틀을 설정해준다.
    useEffect(() => {
        l_commonContext.setMobileHeaderTitle(title);
    }, [title])

    useEffect(() => {
        setMainClassName(classNameSetter.join(" "));
    }, [isHidden, background_color, preset])

    useEffect(() => {
        if(background_color){
            l_commonContext.setHeaderBackgroundColorPreset(background_color);
        }else{
            l_commonContext.setHeaderBackgroundColorPreset("");
        }
    }, [background_color])

    return(
        <div className={mainClassName}>
            {/* 모바일환경에선 여기가 아니라 상단의 l_commonContext.setMobileHeaderTitle를 통해 헤더 타이틀을 설정해준다. */}

            {/* 웹환경에서의 타이틀 설정 */}
            {
                title
                &&  <MainTitle title={title} />
            }
            
            <div className={CSS.l_MainPage_childrenContainer}>
                {children}
            </div>
        </div>
    )
}

export default MainPage;