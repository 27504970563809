import CSS from "./static/css/WeekDays.module.css";
import { DayHeaderContentArg } from "@fullcalendar/core";
import Text from "../../../Text/Text";

const WeekDays = (dayHeaderCellInfo: DayHeaderContentArg) => {

    const day_header_list: string[] = ["S", "M", "T", "W", "T", "F", "S"];

    const m_weekdayCode = dayHeaderCellInfo.date.getDay();

    return(
        <Text
            class_name_list={[CSS.l_WeekDays_main]}
            color={m_weekdayCode === 0 ? "red_gray" : "gray4"}
        >
            {day_header_list[dayHeaderCellInfo.dow]}
        </Text>
    )
}

export default WeekDays;