import CSS from "./static/css/SplitPagesContainer.module.css";

const SplitPagesContainer = (
    {
        children,
    }:{
        children: React.ReactNode;
    }
) => {
    return(
        <div className={CSS.l_SplitPagesContainer_main}>
            {children}
        </div>
    )
}

export default SplitPagesContainer;