import { useEffect, useState } from "react"
import { isAbleAccesser } from "../../Common/ts/commonTools";
import ScheduleList from "./components/ScheduleList/ScheduleList";
import useProgressScheduleDataAllScheduleType from "./hooks/useProgressScheduleDataAllScheduleType";
import TabMenu from "../../Component/Elements/TabMenu/TabMenu";
import { reservationListFilterCodeType } from "./static/interface/reservationHome.interface";
import { RESERVATION_LIST_FILTER_CODE } from "./static/ts/const";
import MainPage from "../../Component/Elements/Page/MainPage/MainPage";
import { useSearchParams } from "react-router-dom";
import useProgressScheduleDataREST from "./hooks/useProgressScheduleDataREST.hook";
import { getCodeQueryParams } from "../../Common/ts/appTools";

const ReservationHome = () => {

    const [URLSearchParams, SetURLSearchParams] = useSearchParams();

    // 주소에 탭 정보가 있는 경우 해당 탭을 선택한 상태로 초기화 시킨다.
    const m_tab1 = getCodeQueryParams<reservationListFilterCodeType>(URLSearchParams.get("tab1"), RESERVATION_LIST_FILTER_CODE);

    const [statusType, setStatusType] = useState<reservationListFilterCodeType>(m_tab1 || RESERVATION_LIST_FILTER_CODE.all);

    const {
        progressScheduleDataList: contractProgressScheduleDataList,
        progressScheduleDataListIsLoading: contractProgressScheduleDataListIsLoading,
        progressScheduleDataListRefetch: contractProgressScheduleDataListRefetch,
    } = useProgressScheduleDataREST({
        main_type: 0,
        status_type: statusType,
    })

    const {
        progressScheduleDataList: singleShotProgressScheduleDataList,
        progressScheduleDataListIsLoading: singleShotProgressScheduleDataListIsLoading,
        progressScheduleDataListRefetch: singleShotProgressScheduleDataListRefetch,
    } = useProgressScheduleDataREST({
        main_type: 1,
        status_type: statusType,
    })

    // 촬영 일정정보를 서버에 요청
    const {
        progressScheduleDataList,
        isLoading,
    } = useProgressScheduleDataAllScheduleType({
        contractProgressScheduleDataList,
        contractProgressScheduleDataListRefetch,
        contractProgressScheduleDataListIsLoading,

        singleShotProgressScheduleDataList,
        singleShotProgressScheduleDataListRefetch,
        singleShotProgressScheduleDataListIsLoading,
    });

    useEffect(() => {
        isAbleAccesser({
            allowedUserTypeList: ["C"],
        });
    }, [])

    // 뒤로가기버튼으로 통해 페이지에 진입했을경우, 쿼리파라미터에 적용된 값을 다시 읽어서 적용해야 하므로 이렇게 처리한다.
    useEffect(() => {
        // 주소에 탭 정보가 있는 경우 해당 탭을 선택한 상태로 초기화 시킨다.
        const m_tab1 = getCodeQueryParams<reservationListFilterCodeType>(URLSearchParams.get("tab1"), RESERVATION_LIST_FILTER_CODE);
        if(m_tab1){
            setStatusType(m_tab1);
        }
    }, [URLSearchParams])
        
    useEffect(() => {
        // 선택한 탭을 바로 주소에 반영해준다.
        SetURLSearchParams((prev) => {
            prev.set("tab1", statusType);
            return prev;
        }, {replace: true});
    }, [statusType])

    return (
        <MainPage title={"촬영 일정"}>            
            <TabMenu
                <reservationListFilterCodeType>
                tab_list={[
                    {
                        label: "전체 일정",
                        badge: "",
                        value: RESERVATION_LIST_FILTER_CODE.all,
                    },
                    {
                        label: "예정된 일정",
                        badge: "",
                        value: RESERVATION_LIST_FILTER_CODE.reservat,
                    },
                    {
                        label: "완료된 일정",
                        badge: "",
                        value: RESERVATION_LIST_FILTER_CODE.end,
                    },
                ]}
                selectedTab={statusType}
                setSelectedTab={setStatusType}
            />

            <ScheduleList
                tab1={statusType}
                progressSchedule={progressScheduleDataList}
                isLoading={isLoading}
            />
        </MainPage>
    )

}

export default ReservationHome