import { useEffect, useState } from "react";
import { isAbleAccesser } from "../../Common/ts/commonTools";
import { PHOTO_LIST_FILTER_CODE, PHOTO_TYPE_SELECTER_ITEM_LIST } from "./static/ts/const";
import TabMenu from "../../Component/Elements/TabMenu/TabMenu";
import PhotoList from "./components/PhotoList/PhotoList";
import { photoListFilterCodeType } from "./static/interface/PhotoListMain.interface";
import MainPage from "../../Component/Elements/Page/MainPage/MainPage";
import { useSearchParams } from "react-router-dom";
import { getCodeQueryParams } from "../../Common/ts/appTools";

const PhotoListMain = () => {
    const [URLSearchParams, SetURLSearchParams] = useSearchParams();

    const l_tab1 = getCodeQueryParams<photoListFilterCodeType>(URLSearchParams.get("tab1"), PHOTO_LIST_FILTER_CODE);

    const [photoTypeIndex, setPhotoTypeIndex] = useState<photoListFilterCodeType>(l_tab1 || PHOTO_LIST_FILTER_CODE.shoot);

    useEffect(() => {
        isAbleAccesser({
            allowedUserTypeList: ["C"],
        });
    }, []);

    // 뒤로가기버튼으로 통해 페이지에 진입했을경우, 쿼리파라미터에 적용된 값을 다시 읽어서 적용해야 하므로 이렇게 처리한다.
    useEffect(() => {
        // 주소에 탭 정보가 있는 경우 해당 탭을 선택한 상태로 초기화 시킨다.
        const m_tab1 = getCodeQueryParams<photoListFilterCodeType>(URLSearchParams.get("tab1"), PHOTO_LIST_FILTER_CODE);
        if(m_tab1){
            setPhotoTypeIndex(m_tab1);
        }
    }, [URLSearchParams])

    useEffect(() => {
        SetURLSearchParams((prev) =>{
            prev.set("tab1", `${photoTypeIndex}`);

            return prev
        }, {replace: true});
    }, [photoTypeIndex]);
    
    return (
        <MainPage title={"내 사진 모아보기"}>
            <TabMenu
                <photoListFilterCodeType>
                tab_list={[
                    {
                        label: "원본 사진",
                        badge: "",
                        value: PHOTO_LIST_FILTER_CODE.shoot
                    },{
                        label: "후기 이벤트 사진",
                        badge: "",
                        value: PHOTO_LIST_FILTER_CODE.review_event
                    },{
                        label: "제품 제작 사진",
                        badge: "",
                        value: PHOTO_LIST_FILTER_CODE.product_request
                    }
                ]}
                selectedTab={photoTypeIndex}
                setSelectedTab={setPhotoTypeIndex}
            />

            <PhotoList
                photo_type={PHOTO_TYPE_SELECTER_ITEM_LIST[photoTypeIndex].photo_type}
                photo_details_type={PHOTO_TYPE_SELECTER_ITEM_LIST[photoTypeIndex].photo_details_type}
            />
        </MainPage>
    )
}

export default PhotoListMain;