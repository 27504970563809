import CSS from "./static/css/FolderImageOverlay.module.css";
import Img from "../../../../Component/Elements/Img/Img";
import Overlay from "../../../../Component/Elements/Overlay/Overlay";
import ReactPhotoSwipeSingleViewer from "../../../../Component/Elements/ReactPhotoSwipeGallery/ReactPhotoSwipeSingleViewer";

const FolderImageOverlay = (
    {
        isOverlayOpen,
        overlayCloseHandler,
        folderDetailImgSrc,
    }:{
        isOverlayOpen: boolean;
        overlayCloseHandler: (() => void);
        folderDetailImgSrc: string
    }
) => {
    return(
        <Overlay 
            isOverlayOpen={isOverlayOpen}
            overlayCloseHandler={overlayCloseHandler}
        >
            <ReactPhotoSwipeSingleViewer
                origin_url={folderDetailImgSrc}
                thumbnail_url={folderDetailImgSrc}
            >
                <div className={CSS.l_FolderImageOverlay_main}>
                    <Img 
                        class_name_list={[CSS.l_FolderImageOverlay_img]}
                        object_fit="contain"
                        src={folderDetailImgSrc}
                    />
                </div>
            </ReactPhotoSwipeSingleViewer>
        </Overlay>
    )
}

export default FolderImageOverlay;