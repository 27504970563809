export const PRODUCT_LIST_FILTER_CODE_OBJ = {
    all_product: "0",
    need_to_request: "1",
    editing: "2",
    need_to_confirm: "3",
    complete: "4",
    shipping_complete: "5",
} as const;

export const PRODUCT_LIST_FILTER_TEXT_OBJ = {
    "0": "전체 제품",
    "1": "사진 선택 요청",
    "2": "사진 편집 중",
    "3": "편집 사진 승인 요청",
    "4": "편집완료(상품제작)",
    "5": "출고 완료",
} as const;

// 제품 타입
// 1: "촬영"
// 2: "앨범"
// 3: "액자"
// 4: "기타상품"
// 5: "파일
// 6: "메이크업"
// 7: "의상대여"
export const PRODUCT_TYPE_CODE_OBJ = {
    shoot: "1",
    album: "2",
    frame: "3",
    other: "4",
    file: "5",
    makeup: "6",
    costume_rental: "7"
} as const;

export const PRODUCT_TYPE_TEXT_OBJ = {
    "1": "촬영",
    "2": "앨범",
    "3": "액자",
    "4": "기타상품",
    "5": "파일",
    "6": "메이크업",
    "7": "의상대여",
} as const;

// 제품 상태
// 1 신청불가
// 2 신청가능
// 3 신청중
// 4 편집중
// 5. 승인요청
// 6. 편집완료(제품제작)
// 7. 승인거절
export const PRODUCT_STATE_CODE_OBJ = {
    application_not_possible: "1",
    application_possible: "2",
    application_in_progress: "3",
    editing: "4",
    confirm_request: "5",
    edit_end: "6",
    confirm_rejected: "7",
} as const;

// 실제 서버에서 응답으로 오는 문구
// "1": "신청불가",
// "2": "신청가능",
// "3": "신청중",
// "4": "편집중",
// "5": "승인요청",
// "6": "편집완료(상품제작)",
// "7": "승인거절",
export const PRODUCT_STATE_TEXT_OBJ = {
    "1": "모든 촬영 완료 후 진행",
    "2": "사진 선택하기",
    "3": "신청완료 (편집 진행 전)",
    "4": "사진 편집중",
    "5": "편집 사진 승인하기",
    "6": "편집완료(상품제작)",
    "7": "승인거절",
} as const;