import CSS from "./static/css/OptionList.module.css";
import CardContainer from "../../../../Component/Elements/CardContainer/CardContainer";
import OptionEle from "../OptionEle/OptionEle";
import { ProductDetail_OptionListEle_Interface, ProductDetail_SelectedOptionListEle_Interface } from "../../static/interface/ProductDetail.interface";
import Flex from "../../../../Component/Elements/Flex/Flex";
import ListCardHeaderText from "../../../../Component/Elements/ListCardHeaderText/ListCardHeaderText";

const OptionList = (
    {
        productOptionDataList,
        selectedOptionList,
        itemOnClickHandler,
        cardRefs
    }:{
        productOptionDataList: ProductDetail_OptionListEle_Interface[]
        selectedOptionList: ProductDetail_SelectedOptionListEle_Interface[];
        itemOnClickHandler: (option: ProductDetail_SelectedOptionListEle_Interface) => void;
        cardRefs?: React.MutableRefObject<(HTMLDivElement | null)[]>;
    }
) => {
    if(productOptionDataList.length > 0){
        return(
            // index가 -1인경우는 없을테니 이를 옵션의 key값으로 설정한다.
            <CardContainer 
                class_name_list={[CSS.l_OptionList_main]}
                ref={
                    cardRefs 
                    ?   (el) => cardRefs.current[-1] = el 
                    :   undefined
                }
                preset={"SelectProduct_SelectArea"}
            >
                <div className={CSS.l_OptionList_contentContainer}>
                    <ListCardHeaderText
                        header_text={"옵션 선택"}
                        is_view_essential_icon={true}
                    />

                    <Flex preset={"OptionList"}>
                        {
                            productOptionDataList.map((optionData) => {
                                const is_highlight = selectedOptionList.some((item) => item.option_id === optionData.option_id);

                                const m_option: ProductDetail_SelectedOptionListEle_Interface = {
                                    option_id: optionData.option_id,
                                    option_name: optionData.option_name,
                                    option_img: optionData.image_url,
                                    small_img_url: optionData.small_img_url,
                                    middle_img_url: optionData.middle_img_url,
                                }

                                return(
                                    <OptionEle
                                        key={optionData.option_id}
                                        thumbnail_url={optionData.middle_img_url}
                                        name={optionData.option_name}
                                        is_highlight={is_highlight}
                                        onClick={itemOnClickHandler.bind(null, m_option)}
                                    />
                                )
                            })
                        }
                    </Flex>
                </div>
            </CardContainer>
        )
    }else{
        return null;
    }
}

export default OptionList;