import { useLocation } from "react-router-dom";
import { getBackPath } from "../../../Header/static/ts/Header.tools";
import hamberger_icon from "./static/img/menu-01.svg";
import HistoryBackBtn from "../../../../Component/Elements/HistoryBackBtn/HistoryBackBtn";

const NaviFunctionBtn = (
    {
        onClick
    }:{
        onClick: () => void;
    }
) => {

    // 뒤로가기버튼출현은 외부 조건에따라 처리하게 하자. 너무 자동으로하는것은 좋지못한듯
    const location = useLocation();
    if(getBackPath(location.pathname)){
        return(<HistoryBackBtn />)
    }else{
        return(
            <div
                className="g_click_event_item g_display_none_object web"
                onClick={onClick}
            >
                <img 
                    src={hamberger_icon} 
                />
            </div> 
        )
    }
}

export default NaviFunctionBtn;