import CSS from "./static/css/PhotoInfoContainerSet.module.css";
import DownloadBtnContainer from "../DownloadBtnContainer/DownloadBtnContainer";
import { joinedEventType } from "../../static/interface/PhotoList.interface";
import EventButtonSet from "../EventButtonSet/EventButtonSet";
import PhotoInfoCard from "../PhotoInfoCard/PhotoInfoCard";
import { usePhotoDetailDataSetter_shootData_interface } from "../../static/interface/usePhotoDetailDataSetter.interface";

const PhotoInfoContainerSet = (
    {
        shoot_data,

        isPrintDownloadBtn,
        download_type_code,
        file_name,
        upload_date,
        photo_upload_id,

        isEventApplication,
        isPrintProductRequestBtn,
        scheduleId,
        joined_event,
    }:{
        shoot_data: usePhotoDetailDataSetter_shootData_interface;

        isPrintDownloadBtn: boolean;
        download_type_code: number;
        file_name: string;
        upload_date: string;
        photo_upload_id: number;

        isEventApplication: boolean;
        isPrintProductRequestBtn: boolean;
        scheduleId: number;
        joined_event: joinedEventType;
    }
) => {
    return(
        <div className={CSS.l_PhotoInfoContainerSet_main}>
            <div className={CSS.l_PhotoInfoContainerSet_container}>
                <PhotoInfoCard 
                    shoot_data={shoot_data}
                    // review_data={review_data}
                />
            </div>

            <div className={CSS.l_webBtnContainer}>
                {
                    isPrintDownloadBtn
                    &&  <DownloadBtnContainer
                            download_type={download_type_code}
                            file_name={file_name}
                            upload_date={upload_date}
                            photo_upload_id={photo_upload_id}
                        />
                }

                <EventButtonSet 
                    isEventApplication={isEventApplication}
                    isPrintProductRequestBtn={isPrintProductRequestBtn}

                    scheduleId={scheduleId}
                    upload_date={upload_date}
                    joined_event={joined_event}
                    photo_upload_id={photo_upload_id}
                />
            </div>
        </div>
    )
}

export default PhotoInfoContainerSet;