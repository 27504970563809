import CSS from "./static/css/LabelAndContentContainer.module.css"

const LabelAndContentContainer = (
    {
        children,
    }:{
        children?: React.ReactNode;
    }
) => {
    return(
        <div className={CSS.l_LabelAndContentContainer_main}>
            {children}
        </div>
    )
}

export default LabelAndContentContainer;