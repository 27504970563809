import { useMediaQuery } from "react-responsive";
import PaymentDetailItmeText from "../elements/PaymentDetailItmeText/PaymentDetailItmeText";
import CSS from "./static/css/PaymentDetailItem.module.css"
import { PaymentDetailItem_Interface } from "./static/interface/PaymentDetailItem.interface";

const PaymentDetailItem = (
    {
        payDate,
        payType,
        payMethod,
        payAmount,
    }: PaymentDetailItem_Interface
) => {
    
    const isMobile = useMediaQuery({ maxWidth: 700 });

    return(
        <div className={CSS.l_PaymentDetailItem_main}>
            <PaymentDetailItmeText 
                className={CSS.l_PayDate}
            >
                {payDate}
            </PaymentDetailItmeText>

            <PaymentDetailItmeText 
                className={CSS.l_PayType}
                justify_content={isMobile ? "flex_end" : "flex_start"}
            >
                {payType}
            </PaymentDetailItmeText>

            <PaymentDetailItmeText 
                className={CSS.l_PayMethod}
            >
                {payMethod}
            </PaymentDetailItmeText>

            <PaymentDetailItmeText 
                className={CSS.l_PayAmount}
                justify_content={"flex_end"}
            >
                {payAmount}
            </PaymentDetailItmeText>
        </div>
    )
}

export default PaymentDetailItem;