import { forwardRef, useEffect, useRef, useState } from "react";
import Text from "../../../../Component/Elements/Text/Text";
import CSS from "./static/css/ShootListItem.module.css";

interface shootListItemInterface{
    shoot_name: string;
    is_highlight?: boolean;
    onClick: React.MouseEventHandler<HTMLDivElement>;
}

const ShootListItem = forwardRef<HTMLDivElement, shootListItemInterface>((
    {
        shoot_name,
        is_highlight,
        onClick,
    }, ref
) => {
    const classNameSetter = () => {
        const RTN: string[] = [CSS.l_ShootListItem_main];

        if(is_highlight){
            RTN.push(CSS.highlight);
        }

        return RTN;
    }

    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "));
    }, [is_highlight])

    return(
        <div 
            className={mainClassName}
            ref={ref}
            onClick={onClick}
        >
            <Text
                bold={is_highlight}
            >
                {shoot_name}
            </Text>
        </div>
    )
})

export default ShootListItem;