import { dateObjectManagerClass } from "../../../../../Common/ts/commonTools";

const dateObjectManager = new dateObjectManagerClass();

const useMonthlyScheduleListItemSetter = (
    {
        date,
        start_time
    }:{
        date: string;
        start_time: string;
    }
) => {
    let RTN_month_day: string = ""
    let RTN_date_format: string = "";

    const l_date = new Date(date);
    const l_start_time_date = new Date(`${date} ${start_time}`);

    if(!isNaN(l_date.getTime()) && !isNaN(l_start_time_date.getTime())){
        RTN_month_day = dateObjectManager.customFormatDate(l_date, "MM/dd");
        RTN_date_format = dateObjectManager.customFormatDate(l_start_time_date, "yyyy년 M월 dd일 HH:mm");
    }

    return({
        month_day: RTN_month_day,
        date_format: RTN_date_format,
    })
}

export default useMonthlyScheduleListItemSetter;