import { useQuery } from "@tanstack/react-query";
import { callAxios, customToast, dev_console, getQueryParamsUrl, isSuccess, printStateMsg, whoAmI } from "../../../Common/ts/commonTools";
import { useEffect } from "react";
import { defaultApiReaponseInterface } from "../../../Common/interface/Common.interface";
import { themaListInterface } from "../static/interface/selectTheme.interface";
import { RTN_interface } from "../../../Common/interface/App.interface";

const l_TOAST_ID = "useThemeDataREST";

const useThemeDataREST = (
    {
        schedule_id
    }:{
        schedule_id: number | null;
    }
) => {
    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer()){
            result = true;
        }

        return result
    }

    const {
        data: themeData = null,
        error: themeDataError,
        isLoading: themeDataIsLoading,
        refetch: themeDataRefetch,
    } = useQuery({
        queryKey: ["themeData", schedule_id],
        queryFn: callThemeData,
        enabled: isAbleToRequest,
        select: (response) => {
            const RTN: RTN_interface<themaListInterface | null> = {
                sign: false,
                data: null,
                msg: "",
            };

            SetData:{
                if(!response){
                    RTN.sign = true;
                    RTN.msg = "Can't Request Theme Data";
                    break SetData;
                }

                if(!isSuccess(response)){
                    RTN.msg = "테마 데이터 요청에 실패했습니다.";
                    break SetData;
                }

                const data: defaultApiReaponseInterface<themaListInterface> = response.data;
                RTN.sign = true;
                RTN.data = data.result;
                RTN.msg = "success";
            }

            if(!RTN.sign){
                if(response){
                    printStateMsg(response);
                }

                customToast.error({
                    msg: RTN.msg,
                    toastId: l_TOAST_ID
                });
            }

            return RTN.data;
        },
    })

    async function callThemeData(){
        if(schedule_id){
            const m_thema_list2_url = getQueryParamsUrl({
                main_url: "api/v3/oops/thema_list2",
                queryParams: {
                    schedule_id: `${schedule_id}`,
                }
            })
    
            const response = await callAxios.api({
                url: m_thema_list2_url,
                method: "get",
            })
    
            dev_console.log(m_thema_list2_url);
            dev_console.log(response);

            return response;
        }else{
            return null;
        }
    }

    useEffect(() => {
        if(!!themeDataError){
            dev_console.error(themeDataError);
            customToast.error({
                msg: "테마 데이터 처리 과정에서 문제가 발생했습니다.",
                toastId: l_TOAST_ID
            });
        }
    }, [themeDataError])

    return({
        themeData,
        themeDataIsLoading,
        themeDataRefetch,
    })
}

export default useThemeDataREST