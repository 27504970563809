import LoopAnimationModal from "../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import CSS from "./static/css/photoDetail.module.css";
import { useSearchParams } from "react-router-dom";
import usePhotoDetailDataSetter from "./hooks/usePhotoDetailDataSetter.hook";
import { useCallback, useContext, useEffect, useState } from "react";
import { customParseInt, isAbleAccesser } from "../../Common/ts/commonTools";
import DownloadIcon from "./components/ImageZipDownload/components/DownloadIcon/DownloadIcon";
import PhotoInfoContainerSet from "./components/PhotoInfoContainerSet/PhotoInfoContainerSet";
import BottomSheetOpenButton from "../../Component/Elements/BottomSheetOpenButton/BottomSheetOpenButton";
import Overlay from "../../Component/Elements/Overlay/Overlay";
import EventButtonSet from "./components/EventButtonSet/EventButtonSet";
import usePhotoDetailDataREST from "./hooks/usePhotoDetailDataREST.hook";
import ThreeSplitPages from "../../Component/Elements/Page/ThreeSplitPages/ThreeSplitPages";
import UploadImageListViewMode from "../../Component/Elements/UploadImageListViewMode/UploadImageListViewMode";
import PhotoInfoCard from "./components/PhotoInfoCard/PhotoInfoCard";
import { commonContext } from "../../App";
import { photoDetailsTypeCodeType, photoTypeCodeType } from "./static/interface/const.interface";
import { getCodeQueryParams } from "../../Common/ts/appTools";
import { PHOTO_DETAILS_TYPE_CODE, PHOTO_TYPE_CODE } from "./static/ts/const";
import useProductListREST from "../ProductManufacturing/hooks/REST/useProductListREST";
import { PRODUCT_LIST_FILTER_CODE_OBJ } from "../ProductManufacturing/static/ts/const";

const PhotoDetail = () => {

    const [isViewing, setIsViewing] = useState<boolean>(false);

    const [URLSearchParams, SetURLSearchParams] = useSearchParams();

    // 숫자형 문자열이기만 하면 된다.
    let l_photo_upload_id: number | null = null;
    const l_query_photo_upload_id = URLSearchParams.get("photo_upload_id");
    if(l_query_photo_upload_id){
        l_photo_upload_id = customParseInt(l_query_photo_upload_id);
    }

    // 사용되는 값이 정해져있으므로 해당 부분을 검증하는 getQueryParams함수를 사용해 값을 처리한다.
    let l_photo_type = getCodeQueryParams<photoTypeCodeType>(URLSearchParams.get("photo_type"), PHOTO_TYPE_CODE);

    // 사용되는 값이 정해져있으므로 해당 부분을 검증하는 getQueryParams함수를 사용해 값을 처리한다.
    let l_viewRange_type = getCodeQueryParams<photoDetailsTypeCodeType>(URLSearchParams.get("viewRange_type"), PHOTO_DETAILS_TYPE_CODE);
    
    // 필요한 정보가 넘어오지 않았다면, 되돌려보낸다.
    if(l_photo_upload_id === null || l_photo_type === null || l_viewRange_type === null){
        // useNavigate로 넘기지 않는 이유?
        // navigate로 넘기려고 시도했을때는 이 조건문이 감지된 후에도 코드의 끝까지 동작이 진행되서 발생하는 하위단계의 에러로 인해 코드의 동작 자체가 중단되는 문제가 있었다.
        // 이를 해결하고자 현재 코드로 처리하였다.
        
        window.location.href = "/photoList";
    }
    
    // 검증이 끝난 값의 타입을 확정시켜준다.
    l_photo_upload_id = l_photo_upload_id as number;
    l_photo_type = l_photo_type as photoTypeCodeType;
    l_viewRange_type = l_viewRange_type as photoDetailsTypeCodeType;

    const {
        photoDetailData,
        photoDetailDataIsLoading,
    } = usePhotoDetailDataREST({
        photo_upload_id: l_photo_upload_id,
        main_type: l_photo_type,
    })

    const {
        common_data,
        downloadIcon_data,
        uploadImageListViewMode_data,
        photoInfoCard_data,
        eventButtonSet_data,
        shoot_data,
    } = usePhotoDetailDataSetter({
        photoDetailData,
        location_state_data: {
            selected_photo_upload_id: l_photo_upload_id,
            photo_type: l_photo_type,
            viewRange_type: l_viewRange_type as photoDetailsTypeCodeType,
        }
    })

    // 신청해야할 제품이 있는 경우에만 버튼을 노출하기 위해...
    // 구조상 너무 과하다다고 판단되면 개선하긴 해야할듯...
    // 그래도 캐싱은 되니까... 괜찮지 않을까???
    const {
        productListData,
        productListIsLoading,
        productListError,
        productListRefetch
    } = useProductListREST({
        search_type: PRODUCT_LIST_FILTER_CODE_OBJ.need_to_request
    })

    const l_commonContext = useContext(commonContext);

    const bottomSheetOpenHandler = useCallback(() => {
        setIsViewing(true);
    }, [])

    const bottomSheetCloseHandler = useCallback(() => {
        setIsViewing(false);
    }, [])

    useEffect(() => {
        isAbleAccesser({
            allowedUserTypeList: ["C"],
        });
    }, [])

    useEffect(() => {
        l_commonContext.setHeaderRightNode(
            <DownloadIcon 
                download_type={downloadIcon_data.download_type_code}
                photo_upload_id={downloadIcon_data.photoUploadId}
                file_name={downloadIcon_data.fileName}
                isHidden={"web"}
            />
        )
    }, [downloadIcon_data])

    return (
        <ThreeSplitPages 
            title={common_data.pageTitle}

            centerContainerNode={
                <UploadImageListViewMode 
                    folderInfoList={uploadImageListViewMode_data.folderDataList}
                    isSingle={uploadImageListViewMode_data.isSingle}
                />
            }

            rightContainerNode={
                <div className={CSS.l_right_wrap}>
                    <PhotoInfoContainerSet
                        shoot_data={shoot_data}

                        isPrintDownloadBtn={photoInfoCard_data.isPrintDownloadBtn}
                        download_type_code={photoInfoCard_data.download_type_code}
                        file_name={photoInfoCard_data.fileName}
                        upload_date={photoInfoCard_data.uploadDate}
                        photo_upload_id={photoInfoCard_data.photoUploadId}
                
                        isEventApplication={photoInfoCard_data.isEventApplication}
                        isPrintProductRequestBtn={productListData.length > 0 && photoInfoCard_data.isPrintProductRequestBtn}
                        scheduleId={photoInfoCard_data.scheduleId}
                        joined_event={photoInfoCard_data.joinedEvent}
                    />
                </div>
            }
            isRightHidden={"mobile"}
        >
            {/* Overlay처럼 모달형식으로 처리하는 컴포넌트들은 이 곳에 위치시킨다. */}

            {/* 모바일 화면 사이즈에서 상세정보보기 버튼 */}
            <BottomSheetOpenButton
                title={"상세 정보 보기"}
                onClick={bottomSheetOpenHandler}
                isHidden={"web"}
            />

            <Overlay
                isOverlayOpen={isViewing}
                overlayCloseHandler={bottomSheetCloseHandler}
            >
                {/* PhotoInfoContainerSet안에 PhotoInfoCard가 있으니까... 사실상 같은 컴포넌트를 쓰고 있다고 볼 수 있겠지? */}
                <PhotoInfoCard 
                    shoot_data={shoot_data}
                />
                
                <EventButtonSet
                    isEventApplication={eventButtonSet_data.isEventApplication}
                    isPrintProductRequestBtn={productListData.length > 0 && eventButtonSet_data.isPrintProductRequestBtn}
                    scheduleId={eventButtonSet_data.scheduleId}
                    upload_date={eventButtonSet_data.uploadDate}
                    joined_event={eventButtonSet_data.joinedEvent}
                    photo_upload_id={eventButtonSet_data.photoUploadId}
                />
            </Overlay>

            {/* 로딩상태 처리 모달 */}
            <LoopAnimationModal
                isModalOpen={photoDetailDataIsLoading}
            />
        </ThreeSplitPages>
    )
}

export default PhotoDetail