import { MouseEventHandler } from "react";
import TextEditerQuill from "../../../../Component/Elements/TextEditer/TextEditerQuill";
import CSS from "./static/css/ThemaDetailPageOverlay.module.css";
import TitleContainer from "../TitleContainer/TitleContainer";
import Overlay from "../../../../Component/Elements/Overlay/Overlay";

const ThemaDetailPageOverlay = (
    {
        isOverlayOpen,
        overlayCloseHandler,
        children,
        title,
        photo_name,
        office_name,
        content,
        office_id,
        thema_id,
        type,
        prevThemeHandler,
        nextThemeHandler,
    }:{
        isOverlayOpen: boolean;
        overlayCloseHandler: () => void;
        children?: React.ReactNode;
        title: string;
        photo_name: string;
        office_name: string;
        content: string;
        office_id?: number;
        thema_id?: number;
        type?: number;
        prevThemeHandler?: MouseEventHandler<HTMLDivElement>;
        nextThemeHandler?: MouseEventHandler<HTMLDivElement>;
    }
) => {
    return(
        <Overlay
            isOverlayOpen={isOverlayOpen}
            overlayCloseHandler={overlayCloseHandler}
            modal_padding="none"
            headerNode={
                <TitleContainer 
                    photo_name={photo_name}
                    title={title}
                    branch_name={office_name}
                />
            }
            prevThemeHandler={prevThemeHandler}
            nextThemeHandler={nextThemeHandler}
            min_heigth_full={true}
        >
            <div 
                className={CSS.l_ThemaDetailPageOverlay_main}
                onClick={(event) => {
                    // 본문을 클릭했을 때 바텀시트가 닫히는 현상을 막기위함
                    event.stopPropagation()
                }}
            >
                <div className={CSS.l_ThemaDetailPageOverlay_textEditerContainer}>
                    <TextEditerQuill
                        read_only={true}
                        content={content}
                        classNameList={[CSS.l_ThemaDetailPageOverlay_textEditer]}
                        office_id={office_id}
                        thema_id={thema_id}
                        type={type}
                    />
                </div>
                
                {children}
            </div>
        </Overlay>
    )
}

export default ThemaDetailPageOverlay;