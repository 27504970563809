import { ChangeEventHandler, forwardRef, HTMLInputTypeAttribute, ReactNode, useImperativeHandle, useMemo, useRef, useState } from "react";
import CSS from "./static/css/Input.module.css"
import ViewAndHidenIcon from "../Icon/ViewAndHidenIcon/ViewAndHidenIcon";
import useKeyboardAwareScroll from "../../../Common/hooks/useKeyboardAwareScroll";

interface inputParameterInterface{
    id?: string;
    name?: string;
    value?: string | number;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    readOnly?: boolean;
    placeholder?: string;
    children?: ReactNode;
    type?: HTMLInputTypeAttribute;
    maxLength?: number;
    min?: string | number;
    max?: string | number;
    onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    disabled?: boolean;
    autoComplete?: React.HTMLInputAutoCompleteAttribute;
}

const Input = forwardRef<HTMLInputElement, inputParameterInterface>((
    {
        id,
        name,
        value,
        onChange,
        readOnly,
        placeholder,
        children,
        type,
        maxLength,
        min,
        max,
        onKeyDown,
        onBlur,
        disabled,
        autoComplete,
    }, ref
) => {
    const [isViewText, setIsViewText] = useState<boolean>(false);

    const inputRef = useRef<HTMLInputElement | null>(null);
    
    // 외부 ref에 내부 inputRef를 연결
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    // // 모바일환경에서 keyboard가 나타날 때 키보드 위에 있는 요소가 키보드에 가려지지 않도록 하는 훅
    // useKeyboardAwareScroll(inputRef);

    const inputContianerClassName = useMemo(() => {
        const RTN: string[] = [];

        RTN.push(CSS.l_Input_main);

        if(disabled || readOnly){
            RTN.push(CSS.disabled)
        }

        return RTN.join(" ");
    }, [readOnly, disabled])

    const mainContainerClickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        // event.stopPropagation();
    
        if(inputRef.current){
            inputRef.current.focus();
        }
    }

    return(
        <div 
            className={inputContianerClassName}
            onClick={mainContainerClickHandler}
        >
            <input
                id={id}
                name={name}
                ref={inputRef}
                value={value}
                onChange={onChange}
                className={CSS.l_Input_input}
                placeholder={placeholder}
                readOnly={readOnly}
                type={
                    type && type === "password"
                    ?   isViewText
                        ?   "text"
                        :   "password"
                    :   type
                }
                //  임의로 정한 기본값이다.(변경해도 무관)
                maxLength={typeof maxLength === "number" ? maxLength : 50}
                max={max}
                min={min}

                onKeyDown={onKeyDown}

                onBlur={onBlur}
                disabled={disabled}
                autoComplete={autoComplete}
            />

            {
                children
                &&  <div className={CSS.l_Input_childrenContainer}>
                        {children}
                    </div>
            }

            {
                type && type === "password"
                &&  <ViewAndHidenIcon 
                        is_enable={isViewText}
                        onClick={() => {
                            setIsViewText((is_view_text)=>!is_view_text)
                        }}
                    />
            }
        </div>
    )
})

export default Input;