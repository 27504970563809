import { useEffect, useState } from "react";
import { callAxios, customToast, dateObjectManagerClass, dev_console, isSuccess } from "../../../Common/ts/commonTools";
import { AxiosResponse } from "axios";
import { useQuery } from "@tanstack/react-query";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";
import { RTN_interface } from "../../../Common/interface/App.interface";
import { holidayListData_interface } from "../static/interface/HolidayLIst.interface";
import { defaultApiReaponseInterface } from "../../../Common/interface/Common.interface";

// 왜 EMPTY_LIST를 사용했는가?
// 무한 리렌더링을 방지한다.
const EMPTY_LIST: holidayListData_interface[] = [];

const dateObjectManager = new dateObjectManagerClass();

function changeFormatDate(yyyymmdd: string): string {
    // if (yyyymmdd.length !== 8) {
    //     throw new Error("입력 형식이 올바르지 않습니다. (예: '20250303')");
    // }
    const year = yyyymmdd.slice(0, 4);
    const month = yyyymmdd.slice(4, 6);
    const day = yyyymmdd.slice(6, 8);
    return `${year}-${month}-${day}`;
}

const useGetHolidayListREST = (
    {
        startDate,
        endDate,
    }:{
        startDate: string | Date | null;
        endDate: string | Date | null;
    }
) => {
    const {
        data: holidayListData = EMPTY_LIST,
        isLoading: holidayListIsLoading,
        error: holidayListError,
        refetch: holidayListRefetch,
    } = useQuery({
        queryKey: ["useGetHolidayListREST", startDate, endDate],
        queryFn: useGetHolidayList,
        enabled: isAbleToRequest,
        select: (response) => {
            let RTN: holidayListData_interface[] = EMPTY_LIST;
            
            if(response && isSuccess(response)){
                
                const m_data: defaultApiReaponseInterface<holidayListData_interface[]> = response.data;
                                
                const m_result = m_data.result.map((prev) => {
                    let m_date: string = "";
                    
                    // 날짜 데이터가 yyyyMMdd 형식으로 오므로, 사용하기 좋게 yyyy-MM-dd형식으로 변환해주는 작업을 여기서 진행한다.
                    if(prev.holiday_date.length === 8){
                        m_date = changeFormatDate(prev.holiday_date);
                    }

                    const m_RTN: holidayListData_interface = {
                        holiday_id: prev.holiday_id,
                        holiday_date: m_date,
                        holiday_name: prev.holiday_name,
                    }

                    return(m_RTN)
                })

                RTN = m_result;
            }


            return RTN;
        },
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
    })

    function isAbleToRequest(){
        let RTN: boolean = false;
        
        if(startDate && endDate){
            RTN = true;
        }

        return RTN;
    }

    async function useGetHolidayList(){
        let RTN: RTN_interface<AxiosResponse | null> = {
            sign: false,
            data: null,
            msg: "",
        };

        RequestDate:{
            if(!startDate || !endDate){
                RTN.msg = "설정된 날짜값이 없습니다.";
                break RequestDate;
            }

            const m_start_date = dateObjectManager.customFormatDate(startDate, "yyyyMMdd");
            const m_end_date = dateObjectManager.customFormatDate(endDate, "yyyyMMdd");

            if(!m_start_date || !m_end_date){
                RTN.msg = "날짜 변환에 실패했습니다.";
                break RequestDate;
            }

            const m_holiday_url = `api/v3/oops/holiday/list?start_date=${m_start_date}&end_date=${m_end_date}`

            const response = await callAxios.api({
                url: m_holiday_url,
                method: "get",
            })

            dev_console.log(m_holiday_url);
            dev_console.log(response);

            RTN.sign = true;
            RTN.msg = "success";
            RTN.data = response;
        }

        if(!RTN.sign){
            dev_console.log("useGetHolidayListREST useGetHolidayList RTN ===>",RTN.msg);
        }
        
        return RTN.data;

    }

    useEffect(() => {
        if(holidayListError){
            customToast.error({
                msg: "휴일 정보를 요청하는 과정에서 문제가 발생했습니다.",
                toastId: "useGetHolidayListREST_error",
            })
            dev_console.error(holidayListError);
        }
    }, [holidayListError])

    return({
        holidayListData,
        holidayListIsLoading,
        holidayListError,
        holidayListRefetch,
    })
}

export default useGetHolidayListREST;