import { useEffect, useMemo, useRef, useState } from "react";
import TextEditerQuill from "../../Component/Elements/TextEditer/TextEditerQuill";
import { customParseInt, customToast, dev_console, isAbleAccesser } from "../../Common/ts/commonTools";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getDifferentImageUrls } from "../../Component/Elements/TextEditer/static/ts/tools";
import CSS from "./static/css/themeBookManagement.module.css";
import TitleContainer from "../../PublicPage/ThemeBook/components/TitleContainer/TitleContainer";
import ThemeDetailModalBtnContainer from "../../PublicPage/ThemeBook/components/ThemeDetailModalBtnContainer";
import ThumbnailImageSelect from "./components/ThumbnailImageSelect/ThumbnailImageSelect";
import CardContainer from "../../Component/Elements/CardContainer/CardContainer";
import MainPage from "../../Component/Elements/Page/MainPage/MainPage";
import useThemeDetailDataREST from "../../PublicPage/ThemeBook/hooks/useThemeDetailDataREST.hook";
import { backPointUrlManagerClass } from "../../Common/ts/appTools";

const backPointUrlManager = new backPointUrlManagerClass();

const ThemeBookManagement = () => {
    
    const [mainContent, setMainContent] = useState<string>("");

    const [uploadedThumbnailSrc, setUploadedThumbnailSrc] = useState<string>("");
    const uploadedThumbnailFileRef = useRef<File | null>(null);
    
    const [URLSearchParams, setURLSearchParams] = useSearchParams();

    const l_back_point = useMemo(() => backPointUrlManager.getBackPointUrlAndDecode(URLSearchParams), [URLSearchParams]) || "/themeBook";

    const l_type = useMemo(() => {
        const RTN = URLSearchParams.get("type");

        return RTN;
    }, [URLSearchParams]);

    const l_office_id = useMemo(() => {
        const m_office_id = URLSearchParams.get("office_id");

        const RTN = customParseInt(m_office_id);

        return RTN;
    }, [URLSearchParams]);

    const l_photo_id = useMemo(() => {
        const m_photo_id = URLSearchParams.get("photo_id");

        const RTN = customParseInt(m_photo_id);

        return RTN;
    }, [URLSearchParams]);

    const l_thema_id = useMemo(() => {
        const m_thema_id = URLSearchParams.get("thema_id");

        const RTN = customParseInt(m_thema_id);

        return RTN;
    }, [URLSearchParams]);

    // 삭제되야할 이미지 목록
    const removedImageUrlListRef = useRef<string[]>([]);

    if(typeof l_type === null || typeof l_office_id === null || typeof l_photo_id === null || typeof l_thema_id === null){
        window.location.href = l_back_point;
    }

    const {
        themeDetailData,
        themeDetailDataIsLoading,
        themeDetailDataError,
        themeDetailDataRefetch,
    } = useThemeDetailDataREST({
        type: `${l_type}`,
        office_id: `${l_office_id}`,
        photo_id: `${l_photo_id}`,
        thema_id: `${l_thema_id}`,
    })

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]; // 업로드한 파일 가져오기
        if (file && file.type.startsWith("image/")) {
            const imageUrl = URL.createObjectURL(file); // 이미지 URL 생성
            setUploadedThumbnailSrc(imageUrl); // 상태 업데이트
            uploadedThumbnailFileRef.current = file;
        } else {
            customToast.error({msg: "이미지 파일만 업로드 가능합니다."});
        }
    }
    
    useEffect(() => {
        isAbleAccesser({
            allowedUserTypeList: ["E"],
        });
    }, [])

    useEffect(() => {
        if(themeDetailData){
            setMainContent(themeDetailData.content || "");
            removedImageUrlListRef.current = getDifferentImageUrls({
                origin: themeDetailData.content || "",
                newContent: mainContent,
                conditions: "removed",
            });
        }
    }, [themeDetailData])

    const input_ref = useRef<HTMLInputElement | null>(null);

    return (
        <MainPage title="">            
            <div className={CSS.l_theme_book_management_main}>
                <CardContainer 
                    class_name_list={[CSS.l_theme_book_management__main_container]}
                >
                    <div className={CSS.l_title_container}>
                        <TitleContainer 
                            photo_name={themeDetailData?.photo_name || ""}
                            title={themeDetailData?.thema_name || ""}
                            branch_name={themeDetailData?.office_name || ""}
                        />
                    </div>
                    <div className={CSS.l_text_editer_quill_container}>
                        <TextEditerQuill
                            read_only={false}
                            content={mainContent}
                            setContent={setMainContent}
                            office_id={themeDetailData?.office_id}
                            thema_id={themeDetailData?.thema_id}
                            type={themeDetailData?.type}
                        />
                    </div>
                    <div className={CSS.l_btn_container}>

                        {/* 모든 저장 동작은 이 컴포넌트 안에서 이루어진다. */}
                        {
                            themeDetailData
                            &&  <ThemeDetailModalBtnContainer 
                                    themeData={themeDetailData}
                                    isInEditPage={true}
                                    removedImageUrlListRef={removedImageUrlListRef}
                                    mainContent={mainContent}
                                    uploadedThumbnailFile={uploadedThumbnailFileRef.current}
                                />
                        }
                    </div>
                </CardContainer>

                <ThumbnailImageSelect 
                    origin_src={themeDetailData?.thema_thumbnail.middle_url || ""}
                    input_ref={input_ref}
                    uploadedThumbnailSrc={uploadedThumbnailSrc}
                    setUploadedThumbnailSrc={setUploadedThumbnailSrc}
                    uploadedThumbnailFileRef={uploadedThumbnailFileRef}
                />

                <input 
                    hidden 
                    ref={input_ref} 
                    accept="image/*"
                    type="file"
                    onChange={handleFileChange}
                />
            </div>
        </MainPage>
    )
}

export default ThemeBookManagement;