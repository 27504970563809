import CSS from "./static/css/AddressInfoMain.module.css";
import { useEffect, useState } from "react";
import { recipientInfoInterface } from "../../static/interface/common.interface";
import AddressInfoView from "../AddressInfoView/AddressInfoView";
import AddressInfo from "../AddressInfo/AddressInfo";
import OvalIconButton from "../../../../Component/Elements/Button/OvalIconButton/OvalIconButton";
import { ADDRESS_INFO_MAIN_VIEW_MODE } from "./static/ts/const";
import { addressInfoMainViewModeType } from "./static/interface/AddressInfoMain.interface";
import { callAxios, customToast, dev_console, printStateMsg } from "../../../../Common/ts/commonTools";
import { defaultApiReaponseInterface } from "../../../../Common/interface/Common.interface";
import LoopAnimationModal from "../../../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import useDaumPostcode from "../../../../Component/Elements/Postcode/hooks/useDaumPostcode.hook";
import { ProductDetail_SelectInformationDate_Address_Interface } from "../../static/interface/ProductDetail.interface";
import { RTN_interface } from "../../../../Common/interface/App.interface";
import useHistoryBackContralOverlayState from "../../../../Component/Elements/Overlay/hooks/useHistoryBackContralOverlayState.hook";
import DeliveryAddressListOverlay from "../DeliveryAddressListOverlay/DeliveryAddressListOverlay";

const TOAST_ID = "AddressInfoMain";

const AddressInfoMain = (
    {
        album_save_id,
        address,
        refetchHandler,
        buttonToggleHandler,
    }:{
        album_save_id?: number | null;
        address: ProductDetail_SelectInformationDate_Address_Interface | null;
        refetchHandler?: () => void;
        buttonToggleHandler?: (sign: boolean) => void;
    }
) => {
    const [isLoading, setIsLoading] = useState(false);

    const [viewMode, setViewMode] = useState<addressInfoMainViewModeType>(ADDRESS_INFO_MAIN_VIEW_MODE.view);

    const {
        isOpen,
        overlayHandler,
    } = useHistoryBackContralOverlayState(false);

    const [recipientInfo, setRecipientInfo] = useState<recipientInfoInterface>({
        recipient_name: "",
        recipient_phone: "",
    });

    const {
        customAddressData,
        setCustomAddressData,
        setRowAddressData,
    } = useDaumPostcode(null);

    // 이전 주소에서 배송지를 변경하는 경우 사용할 핸들러 함수
    function deliveryAddressChangeHandler(
        {
            recipient_name,
            recipient_phone,
            zonecode,
            mainAddress,
            buildingCode,
            detailAddress
        }:{
            recipient_name: string;
            recipient_phone: string;
            zonecode: string;
            mainAddress: string;
            buildingCode: string;
            detailAddress: string;
        }
    ){
        if(!window.confirm("배송지를 변경하시겠습니까?")){
            return ;
        }

        setRecipientInfo({
            recipient_name,
            recipient_phone,
        })

        setCustomAddressData({
            is_set: true,
            zonecode,
            mainAddress,            
            buildingCode,
            userSelectedType: "R",
            detailAddress,
            sigunguCode: "",
            bcode: "",
            bname: "",
            buildingName: "",
        })

        overlayHandler(false);
    }

    // 받는 사람 정보 업데이트 헨들러
    const recipientInfoChangeHandler = (value: string, target: "name" | "phone") => {
        if(target === "name"){
            setRecipientInfo((prev) => {
                return({
                    ...prev,
                    recipient_name: value
                })
            })
        }else if(target === "phone"){
            setRecipientInfo((prev) => {
                return({
                    ...prev,
                    recipient_phone: value
                })
            })
        }
    }

    const modeChangeHandler = () => {
        if(viewMode === ADDRESS_INFO_MAIN_VIEW_MODE.view){
            setViewMode(ADDRESS_INFO_MAIN_VIEW_MODE.edit);

            if(buttonToggleHandler){
                buttonToggleHandler(false);
            }
        }else if(viewMode === ADDRESS_INFO_MAIN_VIEW_MODE.edit){
            setViewMode(ADDRESS_INFO_MAIN_VIEW_MODE.view);

            // 한번에 초기화하는 별도의 핸들러함수를 만드는게 더 좋을듯하다.
            recipientInfoChangeHandler(address?.recipient_name || "", "name");
            recipientInfoChangeHandler(address?.recipient_phone || "", "phone");

            if(buttonToggleHandler){
                buttonToggleHandler(true);
            }
        }
    }
    
    const requestSetAddress = (
        {
            album_save_id,
            addr,
            addr_detail,
            zip_code,
            building_no,
            recipient_name,
            recipient_phone,
        }:{
            album_save_id: number | null | undefined;
            addr: string;
            addr_detail: string;
            zip_code: string;
            building_no: string;
            recipient_name: string;
            recipient_phone: string;
        }
    ) => {
        if(!recipient_name || !recipient_phone){
            customToast.error({
                msg: "받는 이 정보를 입력해 주세요.",
                toastId: TOAST_ID,
            });
            return ;
        }

        if(!window.confirm("주소를 변경하시겠습니까?")){
            return ;
        }

        setIsLoading(true);

        const config = {
            album_save_id: album_save_id || 0,
            addr,
            addr_detail,
            building_no,
            zip_code,
            recipient_name,
            recipient_phone: recipient_phone.replaceAll("-", ""),
        }

        const photo_select_add_update_url = "api/v3/customers/photo-select-add-update";

        // dev_console.log(config);

        callAxios.api({
            url: photo_select_add_update_url,
            method: "post",
            data: config,
            dataType: "json",
        })
        .then(async (response) => {
            dev_console.log(photo_select_add_update_url);
            dev_console.log(response);

            const RTN: RTN_interface<null> = {
                sign: false,
                msg: "",
                data: null,
            }

            ResponseAction:{
                const data: defaultApiReaponseInterface<null> = response.data;

                if(data.status.code === 200){
                    RTN.sign = true;
                    RTN.msg = "주소 변경이 완료되었습니다.";
                    break ResponseAction;
                }

                if(data.status.code === 201){
                    RTN.sign = false;
                    RTN.msg = "제품 제작이 시작되어 주소 변경이 불가능합니다.";
                    break ResponseAction;
                }

                RTN.sign = false;
                RTN.msg = "주소 변경에 실패했습니다.";
            }

            if(RTN.sign){
                customToast.success({
                    msg: RTN.msg,
                    toastId: TOAST_ID,
                });
                setViewMode(ADDRESS_INFO_MAIN_VIEW_MODE.view);
            }else{
                printStateMsg(response);
                customToast.error({
                    msg: RTN.msg,
                    toastId: TOAST_ID,
                });
            }                  
        })
        .catch((error) => {
            dev_console.error(error);
            customToast.error({
                msg: "주소 변경 요청 과정에서 문제가 발생했습니다.",
                toastId: TOAST_ID,
            });
        })
        .finally(() => {
            setIsLoading(false);
            refetchHandler?.();
        })
    }

    useEffect(() => {
        if(address){
            setCustomAddressData({
                is_set: true,
                zonecode: address.zip_code,
                mainAddress: address.addr,
                sigunguCode: "",
                bcode: "",
                bname: "",
                buildingName: "",
                buildingCode: address.building_no,
                userSelectedType: "R",
                detailAddress: address.addr_detail,
            });

            setRecipientInfo({
                recipient_name: address.recipient_name,
                recipient_phone: address.recipient_phone,
            });
        }
    }, [address]);

    return(
        <div className={CSS.l_AddressInfoMain_main}>            
            {
                viewMode === ADDRESS_INFO_MAIN_VIEW_MODE.view
                &&  <AddressInfoView 
                        headerNode={
                            <OvalIconButton 
                                onClick={modeChangeHandler}
                                inner_text={"주소 변경"}
                            />
                        }
                        recipient_name={recipientInfo.recipient_name}
                        recipient_phone={recipientInfo.recipient_phone}
                        zip_code={customAddressData.zonecode}
                        addr={customAddressData.mainAddress}
                        addr_detail={customAddressData.detailAddress}    
                    />
            }

            {
                viewMode === ADDRESS_INFO_MAIN_VIEW_MODE.edit
                &&  <AddressInfo
                        headerNode={
                            <>
                                <OvalIconButton 
                                    onClick={overlayHandler.bind(null, true)}
                                    preset={"history"}
                                />
                                <OvalIconButton 
                                    onClick={requestSetAddress.bind(
                                        null, 
                                        {
                                            album_save_id, 
                                            addr: customAddressData.mainAddress,
                                            addr_detail: customAddressData.detailAddress,
                                            zip_code: customAddressData.zonecode,
                                            building_no: customAddressData.buildingCode,
                                            recipient_name: recipientInfo.recipient_name,
                                            recipient_phone: recipientInfo.recipient_phone,
                                        })}
                                    preset={"save"}
                                />
                                <OvalIconButton 
                                    onClick={modeChangeHandler}
                                    inner_text={"취소"}
                                />
                            </>
                        }
                        recipientInfo={recipientInfo}
                        recipientInfoChangeHandler={recipientInfoChangeHandler}
                        customAddressData={customAddressData}
                        setCustomAddressData={setCustomAddressData}
                        setRowAddressData={setRowAddressData}
                    />
            }

            <LoopAnimationModal 
                isModalOpen={isLoading}
            />

            <DeliveryAddressListOverlay
                isOpen={isOpen}
                overlayCloseHandler={overlayHandler.bind(null, false)}
                onClickHandler={deliveryAddressChangeHandler}
            />
        </div>
    )
}

export default AddressInfoMain;