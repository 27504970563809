import { useNavigate } from "react-router-dom";
import Button from "../../../../../Component/Elements/Button/Normal/Button";
import { useCallback } from "react";
import { productStateCodeType } from "../../../static/interface/ProductList.interface";
import { getQueryParamsUrl } from "../../../../../Common/ts/commonTools";

const DetailPageEditRequestBtnContainer = (
    {
        status_cd, 
        contract_product_id, 
        album_save_id, 
        photo_upload_id
    }:{
        status_cd: productStateCodeType;
        contract_product_id: number;
        album_save_id: number | null;
        photo_upload_id: number | null;
    }
) => {
    const navigate = useNavigate();

    const clickHandler = useCallback(() => {
        if(!window.confirm("사진이나 요청사항 변경을 원하실 경우 이미지를 다시 업로드하셔야 합니다.\n 요청사항을 변경하시겠습니까?")){
            return ;
        }

        const m_productRequestEdit_url = getQueryParamsUrl({
            main_url: "/productRequestEdit",
            queryParams: {
                status_cd: `${status_cd}`,
                contract_product_id: `${contract_product_id}`,
                album_save_id: album_save_id !== null ? `${album_save_id}` : "",
                photo_upload_id: photo_upload_id !== null ? `${photo_upload_id}` : "",
            }
        })

        navigate(m_productRequestEdit_url);
    }, [status_cd, contract_product_id, album_save_id, photo_upload_id])
    
    return(
        <Button
            size={"size3"}
            onClick={clickHandler}
        >
            요청 사항 수정
        </Button>
    )
}

export default DetailPageEditRequestBtnContainer;