import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MainButton from "./components/MainButton/MainButton";
import CSS from "./static/css/navi.module.css";
import HomeLinkLogo from "../../Component/Elements/HomeLinkLogo/HomeLinkLogo";
import IconX from "../../Component/Elements/Icon/IconX/IconX";

const Navi = (
    {
        isOpenMenu, 
        setIsOpenMenu,
        navi_item_list,
    }:{
        isOpenMenu: boolean;
        setIsOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
        navi_item_list: {
            name: string;
            img_src: string;
            activated_img_src: string;
            navigate: string;
            badge?: string;
            is_badge_on: boolean;
        }[] 
    }
) => {

    const [activatedIndex, setActivatedIndex] = useState<number>(0);

    const location = useLocation();
    const pathname = location.pathname;

    const navigate = useNavigate();
    
    const closeNaviHandler = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();

        setIsOpenMenu(false);
    }

    useEffect(()=>{

        const result_index = navi_item_list.findIndex((item) => item.navigate === pathname);
        setActivatedIndex(result_index + 1);

    }, [pathname])

    return (
        <nav
            className={
                isOpenMenu
                ?   CSS.l_Navi_main
                :   `${CSS.l_Navi_main} ${CSS.navi_close}`
            }

            onClick={closeNaviHandler}
        >
            <div className={`g_display_none_object web flex ${CSS.l_closeBtnContainer}`}>
                <IconX onClick={closeNaviHandler} preset="big_black"/>
            </div>
            <div className={`g_display_none_object mobile flex ${CSS.l_Navi__logo_container}`}>
                <HomeLinkLogo />
            </div>
            <div className={CSS.l_Navi__btn_container}>
                {
                    navi_item_list.map((item, index) => {
                        return (
                            <MainButton
                                key={index}
                                name={item.name}
                                img_src={item.img_src}
                                activated_img_src={item.activated_img_src}
                                onClickFc={()=>{
                                    navigate(item.navigate);
                                    setIsOpenMenu(false);
                                }}
                                is_activated={activatedIndex === index + 1}
                                is_print_badge={item.is_badge_on}
                            />
                        )
                    })
                }
            </div>
        </nav>
    )
}

export default Navi;