import { useNavigate } from "react-router-dom";
import { progressScheduleInterface, reservationListFilterCodeType } from "../../static/interface/reservationHome.interface";
import { setProgressScheduleBtn } from "../../static/ts/reservation.tool";
import DdayIcon from "../DdayIcon/DdayIcon";
import useReservationInfoCard from "./hooks/useReservationInfoCard.hook";
import CSS from "./static/css/ReservationInfoCard.module.css";
import MainInfoCard from "../../../../Component/Elements/MainInfoCard/MainInfoCard";
import Button from "../../../../Component/Elements/Button/Normal/Button";
import ReservationInfoCardChildren from "../ReservationInfoCardChildren/ReservationInfoCardChildren";

const ReservationInfoCard = (
    {
        selectedSchedule,
        tab1
    }:{
        selectedSchedule: progressScheduleInterface;
        tab1: reservationListFilterCodeType;
    }
) => {

    const navigate = useNavigate();

    const {
        d_day,
        photo_name,
    } = useReservationInfoCard({scheduleData: selectedSchedule});

    const m_btn_result = setProgressScheduleBtn({
        item: selectedSchedule, 
        navigate,
        tab1,
    })

    let l_disabeld_style: boolean = (typeof d_day === "number" && d_day > 0) || d_day === null;

    return(
        <div className={CSS.l_ReservationInfoCard_main}>
            <MainInfoCard
                header={
                    <DdayIcon 
                        d_day={d_day}  
                    />
                }
                title={photo_name}
                is_disabled_style={l_disabeld_style}
            >
                <ReservationInfoCardChildren 
                    selectedSchedule={selectedSchedule}
                    isDisabledStyle={l_disabeld_style}
                />

                <div className={
                    m_btn_result.is_use_btn
                    ?   CSS.l_ReservationInfoCard__btn_container
                    :   `${CSS.l_ReservationInfoCard__btn_container} ${CSS.hidden}`
                }>
                    <Button
                        size={"size3"}
                        onClick={m_btn_result.onClick_btn}
                        preset={m_btn_result.btn_preset}
                    >
                        {m_btn_result.btn_title}
                    </Button>
                </div>
            </MainInfoCard>
        </div>
    )
}

export default ReservationInfoCard;