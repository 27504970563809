import CardContainer from "../CardContainer/CardContainer";
import Img from "../Img/Img";
import CSS from "./static/css/MainListItemCard.module.css";

const MainListItemCard = (
    {
        img_src,
        children,
        onClick,
    }:{
        img_src: string;
        children?: React.ReactNode;
        onClick?: React.MouseEventHandler<HTMLDivElement>;
    }
) => {
    return(
        <CardContainer 
            class_name_list={[CSS.l_MainListItemCard_main]}
            onClick={onClick}
        >
            <CardContainer 
                class_name_list={[CSS.l_MainListItemCard__img_container]}
                preset={"thumbnail_img"}
            >
                <Img
                    src={img_src}
                    object_fit={"contain"}
                />
            </CardContainer>
            {
                children
                &&  <div className={CSS.l_MainListItemCard__children_container}>
                        {children}
                    </div>
            }
        </CardContainer>
    )
}

export default MainListItemCard;