import { MouseEventHandler } from "react";
import QuantityControllBtnIcon from "../Icon/QuantityControllBtnIcon/QuantityControllBtnIcon";
import Text from "../Text/Text";
import CSS from "./static/css/QuantityController.module.css";

const QuantityController = (
    {
        number,
        addNum,
        subNum,
        min,
        max,
    }:{
        number: number;
        addNum?: MouseEventHandler<HTMLDivElement>;
        subNum?: MouseEventHandler<HTMLDivElement>;
        min: number;
        max: number;
    }
) => {    
    return(
        <div className={CSS.l_QuantityController_main}>
            <QuantityControllBtnIcon 
                type="-"
                is_disable={number <= min}
                onClick={subNum}
            />

            <Text>
                {`${number}`}
            </Text>

            <QuantityControllBtnIcon 
                type="+"
                is_disable={!(max > 0)}
                onClick={addNum}
            />
        </div>
    )
}

export default QuantityController;