import CSS from "./static/css/MonthlySchedule.module.css"
import CardContainer from "../../../../Component/Elements/CardContainer/CardContainer";
import Text from "../../../../Component/Elements/Text/Text";
import { useEffect, useState } from "react";
import { EVENT_CODE, MONTHLY_SCHEDULE_BTN_CODE_OBJECT } from "./static/ts/const";
import { monthlyScheduleBtnCodeType } from "./static/interface/common.interface";
import MonthlyScheduleChangeBtn from "../elements/MonthlyScheduleChangeBtn/MonthlyScheduleChangeBtn";
import MonthlyScheduleList from "../MonthlyScheduleList/MonthlyScheduleList";
import MonthlyScheduleCalendar from "../MonthlyScheduleCalendar/MonthlyScheduleCalendar";
import { RESERVATION_LIST_FILTER_CODE } from "../../../Reservation/static/ts/const";
import useProgressScheduleDataREST from "../../../Reservation/hooks/useProgressScheduleDataREST.hook";
import { progressScheduleInterface } from "../../../Reservation/static/interface/reservationHome.interface";
import useGetHolidayListREST from "../../hooks/useGetHolidayLIstREST";
import { fullCalendarEventInterface } from "../../../../Component/Elements/CustomFullCalendar/interface/CustomFullCalendar.interface";

const MonthlySchedule = () => {
    const [btnState, setBtnState] = useState<monthlyScheduleBtnCodeType>(MONTHLY_SCHEDULE_BTN_CODE_OBJECT.calendar)
    const [scheduleDataList, setScheduleDataList] = useState<progressScheduleInterface[]>([]);

    // 선택된 날짜
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

    // 휴일정보를 호출하기 위해 사용
    const [viewingDate, setViewingDate] = useState<Date | null>(null); 
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null); 

    const [fullCalendarEventList, setFullCalendarEventList] = useState<fullCalendarEventInterface[]>([]);

    const {
        holidayListData,
        holidayListIsLoading,
        holidayListError,
        holidayListRefetch,
    } = useGetHolidayListREST({
        startDate,
        endDate,
    })

    const {
        progressScheduleDataList: progressScheduleContractDataList,
        progressScheduleDataListIsLoading: progressScheduleContractDataIsLoading,
    } = useProgressScheduleDataREST({
        main_type: 0,
        status_type: RESERVATION_LIST_FILTER_CODE.reservat,
    })

    const {
        progressScheduleDataList: progressScheduleSingleDataList,
        progressScheduleDataListIsLoading: progressScheduleSingleDataListIsLoading,
    } = useProgressScheduleDataREST({
        main_type: 1,
        status_type: RESERVATION_LIST_FILTER_CODE.reservat,
    })

    const stateChangeClickHander = () => {
        setBtnState((prev) => {
            if(prev === MONTHLY_SCHEDULE_BTN_CODE_OBJECT.calendar){
                return MONTHLY_SCHEDULE_BTN_CODE_OBJECT.list;
            }else if(prev === MONTHLY_SCHEDULE_BTN_CODE_OBJECT.list){
                return MONTHLY_SCHEDULE_BTN_CODE_OBJECT.calendar;
            }else{
                return MONTHLY_SCHEDULE_BTN_CODE_OBJECT.calendar;
            }
        })
    }

    // 달력에 보이는 일정들로 필터링해주는 함수
    const monthFilter = (item: string) => {
        const m_date_time = new Date(item).getTime();
        if(isNaN(m_date_time)){
            return false;
        }

        if(!startDate || !endDate){
            return false;
        }

        const m_startDate_time = startDate.getTime();
        const m_endDate_time = endDate.getTime();

        if((m_date_time >= m_startDate_time) && (m_date_time <= m_endDate_time)){
            return true;
        }else{
            return false;
        }
    }

    useEffect(() => {
        let m_ProgressScheduleDataList_new: progressScheduleInterface[] = [];

        m_ProgressScheduleDataList_new.push(...progressScheduleContractDataList);
        m_ProgressScheduleDataList_new.push(...progressScheduleSingleDataList);

        m_ProgressScheduleDataList_new = m_ProgressScheduleDataList_new.filter((item) => monthFilter(item.schedule_date));

        setScheduleDataList(m_ProgressScheduleDataList_new);
    }, [progressScheduleContractDataList, progressScheduleSingleDataList, startDate, endDate])

    useEffect(() => {
        let m_fullCalendarEventList: fullCalendarEventInterface[] = [];

        m_fullCalendarEventList.push(...scheduleDataList.map((prev) => {
            const RTN: fullCalendarEventInterface = {
                title: prev.photo_name,
                start: prev.schedule_date,
                type: EVENT_CODE.schedule,
                time: prev.detail?.photo_start || undefined,
            }

            return RTN;
        }));

        m_fullCalendarEventList.push(...holidayListData.map((prev) => {
            const RTN: fullCalendarEventInterface = {
                title: prev.holiday_name,
                start: prev.holiday_date,
                type: EVENT_CODE.holiday,
            }

            return RTN;
        }))

        setFullCalendarEventList(m_fullCalendarEventList);
    }, [
        scheduleDataList, 
        holidayListData
    ])

    return(
        <CardContainer class_name_list={[CSS.l_MonthlySchedule_main]}>
            <div className={CSS.l_MonthlySchedule_headerContainer}>
                <Text 
                    class_name_list={[CSS.l_MonthlySchedule_titleText]}
                    bold={true}
                >
                    촬영 일정
                </Text>

                <MonthlyScheduleChangeBtn 
                    state={btnState}
                    onClick={stateChangeClickHander}
                />
            </div>
            <div className={CSS.l_MonthlySchedule_contentContainer}>
                {
                    btnState === MONTHLY_SCHEDULE_BTN_CODE_OBJECT.calendar
                    &&  <MonthlyScheduleCalendar
                            fullCalendarEventList={fullCalendarEventList}
                            selectedDate={selectedDate} 
                            setSelectedDate={setSelectedDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            viewingDate={viewingDate}
                            setViewingDate={setViewingDate}
                        />
                }

                {
                    btnState === MONTHLY_SCHEDULE_BTN_CODE_OBJECT.list
                    &&  <MonthlyScheduleList 
                            schedule_data_list={
                                fullCalendarEventList
                                .filter((item) => item.type !== EVENT_CODE.holiday) // 2025-03-06 국가공휴일 출력은 적절하지 않다는 파스텔측의 피드백에 따라 제거
                                .map((item) => {
                                    return({
                                        date: item.start,
                                        start_time: item.time || "",
                                        shoot_title: item.title,
                                        type: item.type,
                                    })
                                })
                            }
                        />
                }
            </div>
        </CardContainer>
    )
}

export default MonthlySchedule;