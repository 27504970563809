import { schedulePhotoProductThemeInterface, themeItemOnClickHandlerType } from "../../static/interface/selectTheme.interface";
import SelectedThemeList from "../SelectedThemeList/SelectedThemeList";
import CSS from "./static/css/SelectedThemeListContainer.module.css";

const SelectedThemeListContainer = (
    {
        photoProductList,
        office_name,
        isReadOnly,
        mobileGoToSelectThemePage,
        subThemeHandler,
    }:{
        photoProductList: schedulePhotoProductThemeInterface[];
        office_name: string;
        isReadOnly?: boolean;
        mobileGoToSelectThemePage: (schedule_photo_product_id: number) => void;
        subThemeHandler: themeItemOnClickHandlerType;
    }
) => {
    return(
        <div className={CSS.l_SelectedThemeListContainer_main}>
            {
                photoProductList.map((item) => {
                    return(
                        <SelectedThemeList
                            key={item.schedule_photo_product_id}
                            photoProduct={item}
                            isReadOnly={isReadOnly}
                            mobileGoToSelectThemePage={mobileGoToSelectThemePage}
                            subThemeHandler={subThemeHandler}
                            office_name={office_name}
                        />
                    )
                })
            }
        </div>
    )
}

export default SelectedThemeListContainer;