import { useEffect, useState } from "react";
import CSS from "./static/css/DownloadIcon.module.css";
import download_icon from "./static/img/download_icon_26@3x.png";
import useImageZipDownloadREST from "../../hooks/useImageZipDownloadREST.hook";
import { isHiddenClassNameSetter, whichBrowserDoYouUse } from "../../../../../../Common/ts/commonTools";
import DownloadLoadingModalForKakao from "../../../DownloadLoadingModal/DownloadLoadingModalForKakao";
import DownloadLoadingModal from "../../../DownloadLoadingModal/DownloadLoadingModal";
import { isHiddenType } from "../../../../../../Common/interface/Common.interface";
import { BROWSER_CODE } from "../../../../../../Common/ts/const";

const DownloadIcon = (
    {
        download_type,
        photo_upload_id,
        file_name,
        isHidden,
    }:{
        download_type: number;
        photo_upload_id: number;
        file_name: string;
        isHidden?: isHiddenType;
    }
) => {
    const {
        isDownloading,
        downloadHandler,
        downloadingEndHandler,
        file_size,
        download_time,
    } = useImageZipDownloadREST({
        download_type,
        photo_upload_id,
        file_name,
    })

    const classNameSetter = () => {
        let RTN: string[] = [];

        RTN.push("g_click_event_item")
        if(isDownloading){
            RTN.push("not_allowed")
        }

        if(isHidden){
            RTN = isHiddenClassNameSetter({
                classNameList: RTN,
                isHidden,
                display: "flex"
            })
        }

        RTN.push(CSS.l_DownloadIcon_main);

        return RTN;
    }

    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    const l_browserCode = whichBrowserDoYouUse();

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "));
    }, [isDownloading, isHidden])

    return(
        <div 
            className={mainClassName}
            onClick={downloadHandler}
        >
            <img 
                className={CSS.l_DownloadIcon__icon_img}
                src={download_icon} 
            />

            {/* 다운로드 로딩 처리 */}
            {
                (l_browserCode === BROWSER_CODE.KakaoTalk || l_browserCode === BROWSER_CODE.LINE)
                ?   <DownloadLoadingModalForKakao
                        isModalOpen={isDownloading}
                        modalCloseHandler={downloadingEndHandler}
                        fileSize={file_size}
                        download_time={download_time}
                        auto_close={2}
                    />
                :   <DownloadLoadingModal
                        isModalOpen={isDownloading}
                        fileSize={file_size}
                        download_time={download_time}
                    />
            }            
        </div>
    )
}

export default DownloadIcon;