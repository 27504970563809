import CSS from "./static/css/ImageViewer.module.css";
import useImageGallery from "../ImageGallery/hooks/useImageGallery.hook";
import ViewerToolBar from "../ImageGallery/components/ViewerToolBar/ViewerToolBar";
import FunctionIcon from "../FunctionIcon/FunctionIcon";
import full_img_icon from "./static/img/full_img_icon@2x.png";
import { useEffect, useRef, useState } from "react";
import Img from "../Img/Img";

const ImageViewer = (
    {
        img_src,
        thumbnail_src,
        is_view_toolbar,
        object_fit,
        is_use_icon,
        class_name_list,
    }:{
        img_src: string;
        thumbnail_src: string;
        is_view_toolbar: boolean;
        object_fit?: "cover" | "fill" | "contain" | "scale-down" | "none";
        is_use_icon?: boolean;
        class_name_list?: string[];
    }
) => {
    const [totalClassName, setTotalClassName] = useState<string>("");

    const galleryRef = useRef<HTMLDivElement | null>(null);

    const {
        isViewerVisible,
        viewerInstanceRef
    } = useImageGallery({
        galleryRef,
        dependency_key: img_src,
        navbar: is_use_icon ? false : undefined,    //  아이콘을 썸네일로 대신 출력하는경우, 이미지 네비게이션바에 아이콘이 뜨는것이 오히려 이상하게 느껴짐 -> 아이콘을 사용하는 경우엔 이미지 네비게이션를 출력하지 않게 처리
    })

    useEffect(() => {
        const total_class_name_list: string[] = [];
        
        total_class_name_list.push("g_click_event_item");

        if(class_name_list){
            for(const class_name of class_name_list){
                total_class_name_list.push(class_name);
            }
        }

        total_class_name_list.push(CSS.l_image_viewer_main);

        if(is_use_icon){
            total_class_name_list.push(CSS.is_use_icon);
        }

        setTotalClassName(total_class_name_list.join(" "));
    }, [is_use_icon, class_name_list])

    return(
        <div  
            className={totalClassName}
            ref={galleryRef}
        >
            {/* 썸네일 이미지주소 대신 아이콘을 사용한다. */}
            <Img
                src={is_use_icon ? full_img_icon : thumbnail_src}
                object_fit={object_fit}
                class_name_list={[CSS.l_image_gallery__img_cover__img]}
                customdata={img_src}
            />

            {/* 썸네일대신 아이콘을 출력할 경우 기존에 보이던 아이콘은 제거해준다. */}
            {
                !is_use_icon
                &&  <FunctionIcon
                        class_name_list={[CSS.l_image_viewer__zoom_img]}
                        src={full_img_icon}
                    />
            }

            {/* 사용자 정의 툴바 */}
            {
                (isViewerVisible && is_view_toolbar)
                &&  <ViewerToolBar viewerInstanceRef={viewerInstanceRef} />
            }
        </div>
    )
}

export default ImageViewer;