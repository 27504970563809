import AccountPage from "../../Component/Elements/Page/AccountPage/AccountPage";
import CSS from "./static/css/MaintenancePage.module.css";

const MaintenancePage = () => {
    return(
        <AccountPage>
            <div className={CSS.l_MaintenancePage_main}>
                <h1 className={CSS.l_h1}>OOPS 서버 점검</h1>

                <p className={CSS.l_p}>현재 서버 점검 중입니다.</p>
                {/* <p className={CSS.l_p}>점검 일시: 2025년 2월 18일</p> */}
                {/* <p className={CSS.l_p}>예상 시간: 13:30 ~ 18:00</p> */}
                <p className={CSS.l_p}>더 나은 서비스를 제공하기 위해 노력하고 있습니다.</p>
            </div>
        </AccountPage>
    )
}

export default MaintenancePage;