import { useQuery } from "@tanstack/react-query";
import { callAxios, dev_console, isSuccess, printStateMsg, whoAmI } from "../../../Common/ts/commonTools";
import { defaultApiReaponseInterface } from "../../../Common/interface/Common.interface";
import { progressScheduleInterface, reservationListFilterCodeType } from "../static/interface/reservationHome.interface";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";
import { RTN_interface } from "../../../Common/interface/App.interface";

const MAIN_TYPE_VALUE_LIST = ["패키지 촬영", "단일 촬영"];
const STATUS_TYPE_VALUE_LIST = ["전체", "미완료", "완료"];

const EMPTY_LIST: progressScheduleInterface[] = [];

const TOAST_ID = "useProgressScheduleDataREST";

const useProgressScheduleDataREST = (
    {
        main_type,
        status_type,
    }:{
        main_type: 0 | 1; // 0: "패키지 촬영" | 1: "단일 촬영" 
        status_type: reservationListFilterCodeType;
    }
) => {    
    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer()){
            result = true;
        }

        return result;
    }

    const {
        data: progressScheduleDataList = EMPTY_LIST,
        error: progressScheduleDataListError,
        isLoading: progressScheduleDataListIsLoading,
        refetch: progressScheduleDataListRefetch,
    } = useQuery({
        queryKey: ["progressScheduleData", main_type, status_type],
        queryFn: getProgressSchedule,
        enabled: isAbleToRequest,
        select: (response) => {
            const RTN: RTN_interface<progressScheduleInterface[]> = {
                sign: false,
                data: EMPTY_LIST,
                msg: "",
            };
            
            SetData:{
                if(!isSuccess(response)){
                    RTN.msg = `${MAIN_TYPE_VALUE_LIST[main_type]}의 ${STATUS_TYPE_VALUE_LIST[status_type]} 촬영 일정 정보를 호출하는 과정에서 문제가 발생했습니다.`;
                    break SetData;
                }

                const data: defaultApiReaponseInterface<progressScheduleInterface[]> = response.data;
                const data_map_result = data.result;

                RTN.sign = true;
                RTN.data = data_map_result;
                RTN.msg = "success";
            }

            if(!RTN.sign){
                printStateMsg(response);
            }

            return RTN.data;
        },
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
    })

    // 스케줄 정보를 호출하는 함수
    async function getProgressSchedule(){
        /**
         * main_type
         * 0: 촬영전 스케쥴
         * 1: 촬영 후 수케쥴
         */
        const contract_url = `api/v3/customers/progress_schedule?main_type=${main_type}&status_type=${status_type}`;
        const response = await callAxios.api({
            url: contract_url,
            method: "get",
        })

        dev_console.log(contract_url);
        dev_console.log(response);

        return response;
    }
    
    useEffect(() => {
        if(progressScheduleDataListError){
            dev_console.error(progressScheduleDataListError);
            toast.error(`${MAIN_TYPE_VALUE_LIST[main_type]}의 ${STATUS_TYPE_VALUE_LIST[status_type]} 촬영 일정 정보를 호출하는 과정에서 문제가 발생했습니다.`);
        }
    }, [progressScheduleDataListError, main_type, status_type])

    return({
        progressScheduleDataList,
        progressScheduleDataListError,
        progressScheduleDataListIsLoading,
        progressScheduleDataListRefetch,
    })
}

export default useProgressScheduleDataREST;