import { NavigateFunction } from "react-router-dom";
import { stateClassNameType } from "../interface/reservationTool.interface";
import { buttonPresetType } from "../../../../Component/Elements/Button/Normal/interface/Button.interface";
import { progressScheduleInterface, reservationListFilterCodeType } from "../interface/reservationHome.interface";
import { PRODUCT_TYPE_CODE_OBJ } from "../../../ProductManufacturing/static/ts/const";
import { PHOTO_DETAILS_TYPE_CODE, PHOTO_TYPE_CODE } from "../../../PhotoList/static/ts/const";
import { getQueryParamsUrl } from "../../../../Common/ts/commonTools";
import { backPointUrlManagerClass } from "../../../../Common/ts/appTools";

const backPointUrlManager = new backPointUrlManagerClass();

export const getStatusContentsCode = (
    {
        status_contents,
        thema_list_name,
    }:{
        status_contents: number;
        thema_list_name: string[] | undefined;
    }
): number => {
    let status_contents_code: number = 0;

    if(status_contents === 0){
        if(thema_list_name){
            if(thema_list_name.length > 0 && thema_list_name[0].length > 0){
                status_contents_code = 2;
            }else{
                status_contents_code = 1;
            }
        }
    }else if(status_contents === 1){
        status_contents_code = 3;
    }

    return status_contents_code;
}

export const isProgressingScheduledItem = (item: progressScheduleInterface) => {
    let result: boolean = false

    if(item.status_contents === 0){
        result = true;
    }

    return result;
} 

// 버튼 문구, 기능 설정 함수
export const setProgressScheduleBtn = (
    {
        item,
        navigate,
        tab1,
    }:{
        item: progressScheduleInterface;
        navigate: NavigateFunction;
        tab1: reservationListFilterCodeType;
    }
) => {
    let btn_title = "";
    let onClick_btn = undefined;
    let state_class_name: stateClassNameType = "";
    let btn_preset: buttonPresetType = "normal";
    let is_use_btn: boolean = false;

    const l_back_point: string = backPointUrlManager.encoded({url: `/reservationHome?tab1=${tab1}`});

    const goToSelectThemePage = () => {
        const m_selectTheme_url = getQueryParamsUrl({
            main_url: "/selectTheme",
            queryParams: {
                schedule_id: `${item.schedule_id}`,
                back_point: l_back_point,
            }
        });

        navigate(m_selectTheme_url);
    }

    const goToPhotoDetailPage = () => {
        if(item.detail){                    
            const m_photoDetail_url = getQueryParamsUrl({
                main_url: "/photoDetail",
                queryParams: {
                    photo_upload_id: `${item.detail.photo_upload_id}`,
                    photo_type: `${PHOTO_TYPE_CODE.contract_shoot}`,  //  여기에 출력되는 데이터는 무조건 계약된 촬영데이터를 사용하므로 고정시킨다.
                    viewRange_type: `${PHOTO_DETAILS_TYPE_CODE.origin}`, 
                    back_point: l_back_point,
                }
            });

            navigate(m_photoDetail_url);
        }else{      
            navigate("/photoList");
        }
    }

    // 일정에 실제 촬영이 포함된 경우에만 테마선택이나 사진보기 버튼이 출력되도록 처리한다.
    if(item.product_type && item.product_type.some((product_type) => product_type === PRODUCT_TYPE_CODE_OBJ.shoot)){
        // thema_len으로 선택할수 있는 테마목록이 준비가 된 경우에만 버튼을 노출시킨다,
        if(item.btn_type === 0 && item.thema_len > 0){
            is_use_btn = true;

            btn_title = "테마 선택 필요";
            onClick_btn = goToSelectThemePage;
    
            state_class_name = "before_select_theme";
        }else if(item.btn_type === 1 && item.thema_len > 0){
            is_use_btn = true;

            btn_title = "선택한 테마 보기";
            onClick_btn = goToSelectThemePage;
    
            state_class_name = "before_photo";
            btn_preset = "normal_gray";
        }else if(item.btn_type === 2){
            is_use_btn = true;

            btn_title = "사진 보기";
            onClick_btn = goToPhotoDetailPage;
    
            state_class_name = "end_photo";
            btn_preset = "move_page";
        }
    }

    return {btn_title, onClick_btn, state_class_name, btn_preset, is_use_btn};
}

// 촬영일정에서 badge표시가 되야하는 항목의 조건
export const isNeedToThemeSelect = (item: progressScheduleInterface) => {
    return item.btn_type === 0;
}