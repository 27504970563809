import CSS from "./static/css/BabyInfoInputContainer.module.css";
import { BabyInfoInputBirthDay, BabyInfoInputBirthTime, BabyInfoInputName, BabyInfoInputTall, BabyInfoInputWeigth } from "../BabyInfoInput/BabyInfoInput";
import LabelAndContent from "../../../../Component/Elements/LabelAndContent/LabelAndContent";
import { addedInfoDataInterface } from "../../static/interface/ProductRequestEdit.interface";
import { ChangeEvent, Dispatch, SetStateAction, useCallback } from "react";

const BabyInfoInputContainer = (
    {
        addedInfo,
        setAddedInfo
    }:{
        addedInfo: addedInfoDataInterface;
        setAddedInfo: Dispatch<SetStateAction<addedInfoDataInterface>>;
    }
) => {
    
    const nameChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setAddedInfo((prev) => {
            return({
                ...prev,
                baby_name: event.target.value
            })
        })
    }, [])

    const birthChangeHandler = useCallback((date: Date | null) => {
        setAddedInfo((prev) => {
            return({
                ...prev,
                baby_birth: date
            })
        })
    }, [])

    const birthTimeChangeHandler = useCallback((date: Date | null) => {
        setAddedInfo((prev) => {
            return({
                ...prev,
                baby_birth_time: date
            })
        })
    }, [])

    const tallChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setAddedInfo((prev) => {
            return({
                ...prev,
                tall: event.target.value
            })
        })
    }, [])

    const weigthChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setAddedInfo((prev) => {
            return({
                ...prev,
                weight: event.target.value
            })
        })
    }, [])

    return(
        <LabelAndContent
            label={"추가 정보 입력"}
            flex_direction={"column"}
        >
            <div className={CSS.l_BabyInfoInputContainer_InputContainer}>
                <BabyInfoInputName 
                    value={addedInfo.baby_name}
                    onChange={nameChangeHandler}
                />
                
                <BabyInfoInputBirthDay 
                    value={addedInfo.baby_birth}
                    onChange={birthChangeHandler}
                />
                
                <BabyInfoInputBirthTime 
                    value={addedInfo.baby_birth_time}
                    onChange={birthTimeChangeHandler}
                />
                
                <BabyInfoInputTall 
                    value={addedInfo.tall}
                    onChange={tallChangeHandler}
                />
                
                <BabyInfoInputWeigth 
                    value={addedInfo.weight}
                    onChange={weigthChangeHandler}
                />
            </div>
        </LabelAndContent>
    )
}

export default BabyInfoInputContainer;