// PhotoList 출력용이 아닌, 별도의 이미지를 출력하는데 사용하는 범용성 컴포넌트

import 'photoswipe/dist/photoswipe.css'
import { Gallery } from 'react-photoswipe-gallery';

// 커스텀 css. 
import "./static/css/PhotoSwipe.css";
import { PHOTO_SWIPE_OPTIONS } from './static/ts/const';
import PhotoSwipeGalleryItem from './components/PhotoSwipeGalleryItem/PhotoSwipeGalleryItem';
import { FILE_CODE } from '../../../Common/ts/const';
import ReactPhotoSwipeSetter from './hooks/ReactPhotoSwipeSetter.hook';

const ReactPhotoSwipeSingleViewer = (
    {
        origin_url,
        thumbnail_url,
        children,
    }:{
        origin_url: string;
        thumbnail_url: string;
        children: React.ReactNode;
    }
) => {
    const {viewportSize} = ReactPhotoSwipeSetter();

    return(
        <Gallery 
            options={PHOTO_SWIPE_OPTIONS} 
            withCaption
        >
            <PhotoSwipeGalleryItem
                file_type={FILE_CODE.img}
                origin_url={origin_url}
                thumbnail_url={thumbnail_url}
                viewport_width={viewportSize.width}
                viewport_height={viewportSize.height}
                preset={"ReactPhotoSwipeSingleViewer"}
            >
                {children}
            </PhotoSwipeGalleryItem>           
        </Gallery>
    )
}

export default ReactPhotoSwipeSingleViewer;