import CSS from "./static/css/AddressInfo.module.css";
import { Address } from "react-daum-postcode";
import LabelAndContent from "../../../../Component/Elements/LabelAndContent/LabelAndContent";
import { addressDataInterface } from "../../../../Component/Elements/Postcode/interface/useDaumPostcode.interface";
import { recipientInfoInterface } from "../../static/interface/common.interface";
import Input from "../../../../Component/Elements/Input/Input";
import Postcode from "../../../../Component/Elements/Postcode/Postcode";
import { addHyphens } from "../../../../Common/ts/commonTools";
import { ReactNode } from "react";

const AddressInfo = (
    {
        headerNode,
        recipientInfo,
        recipientInfoChangeHandler,
        customAddressData,
        setCustomAddressData,
        setRowAddressData,
        readOnly,
    }:{
        headerNode?: ReactNode;
        recipientInfo: recipientInfoInterface;
        recipientInfoChangeHandler: (value: string, target: "name" | "phone") => void;
        customAddressData: addressDataInterface;
        setCustomAddressData: React.Dispatch<React.SetStateAction<addressDataInterface>>;
        setRowAddressData?: React.Dispatch<React.SetStateAction<Address | null>>;
        readOnly?: boolean;
    }
) => {
    return(
        <LabelAndContent
            label="배송 정보 입력"
            headerNode={headerNode}
            flex_direction={"column"}
            is_view_essential_icon={true}
            class_name_list={[CSS.l_StyleAdd_LabelAndContent]}
        >
            <div className={CSS.l_AddressInfo_main}>
                <div className={CSS.l_recipientInfo_container}>
                    <Input 
                        value={recipientInfo.recipient_name}
                        onChange={(event) => {
                            recipientInfoChangeHandler(event.target.value, "name");
                        }}
                        placeholder={"받는이"}
                        readOnly={readOnly}
                    />
                </div>

                <div className={CSS.l_recipientPhonenum}>
                    <Input
                        value={addHyphens(recipientInfo.recipient_phone)}
                        onChange={(event) => {
                            recipientInfoChangeHandler(event.target.value, "phone");
                        }}
                        placeholder={"연락처"}
                        readOnly={readOnly}
                        maxLength={13}
                    />
                </div>
                
                <div className={CSS.l_recipientAddress}>
                    <Postcode
                        customAddressData={customAddressData}
                        setCustomAddressData={setCustomAddressData}
                        setRowAddressData={setRowAddressData}
                        readOnly={readOnly}
                    />
                </div>
            </div>
        </LabelAndContent>
    )
}

export default AddressInfo;