import CSS from "./static/css/SelectThemeController.module.css";
import { schedulePhotoProductThemeInterface, themeItemOnClickHandlerType } from "../../static/interface/selectTheme.interface";
import ThemeSelectButtons from "../ThemeSelectButtons/ThemeSelectButtons";
import ThemeSelectInfoCard from "../ThemeSelectInfoCard/ThemeSelectInfoCard";

const SelectThemeController = (
    {
        photoProductList,
        office_name,
        schedule_id,
        contract_id,
        subThemeHandler,
        mobileGoToSelectThemePage,
        isReadOnly,
        contract_info,
        schedule_date_string,
        photo_time,
        photo_name,
    }:{
        photoProductList: schedulePhotoProductThemeInterface[];
        office_name: string;
        schedule_id: number;
        contract_id: string;
        subThemeHandler: themeItemOnClickHandlerType;
        mobileGoToSelectThemePage: (schedule_photo_product_id: number) => void;
        isReadOnly?: boolean;
        contract_info: string;
        schedule_date_string: string;
        photo_time: string;
        photo_name: string;
    }
) => {
    return(
        <div className={CSS.l_SelectThemeController_main}>
            <ThemeSelectInfoCard
                office_name={office_name}
                photoProductList={photoProductList}
                isReadOnly={isReadOnly}
                mobileGoToSelectThemePage={mobileGoToSelectThemePage}
                subThemeHandler={subThemeHandler}
                contract_info={contract_info}
                schedule_date_string={schedule_date_string}
                photo_time={photo_time}
                photo_name={photo_name}
            />

            <ThemeSelectButtons
                schedule_id={schedule_id}
                contract_id={contract_id}
                photoProductList={photoProductList}
                isReadOnly={isReadOnly}
            />
        </div> 
    )
}

export default SelectThemeController;