import CSS from "./static/css/TimeIcon.module.css";
import time_icon from "./static/img/time_icon_20@2x.png";

const TimeIcon = () => {
    return(
        <div className={CSS.l_TimeIcon_main}>
            <img 
                className={CSS.l_TimeIcon_icon_img}
                src={time_icon}
            />
        </div>
    )
}

export default TimeIcon;