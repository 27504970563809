import { useEffect, useRef, useState } from "react";
import Viewer from "viewerjs";

const useImageGallery = (
    {
        galleryRef,
        dependency_key,
        navbar = true,
        title = true,
    }:{
        galleryRef: React.MutableRefObject<HTMLDivElement | null>;
        dependency_key?: any;
        navbar?: boolean;
        title?: boolean;
    }
) => {
    const [isViewerVisible, setViewerVisible] = useState<boolean>(false);
    const viewerInstanceRef = useRef<Viewer | null>(null);

    useEffect(() => {

        if(!!galleryRef.current){
            viewerInstanceRef.current = new Viewer(galleryRef.current, {
                toolbar: false,
                navbar: navbar,
                title: title,
                shown: () => {
                    setViewerVisible(true); // Viewer 모달이 열릴 때 커스텀 툴바 활성화
                },
                hidden: () => {
                    setViewerVisible(false); // Viewer 모달이 닫히면 커스텀 툴바 숨김
                },
                url: "data-customdata",
                viewed: (item) => {
                    // dev_console.log(item.detail.originalImage.currentSrc);
                }
            });
        }

        return () => {
            if(!!viewerInstanceRef.current){
                viewerInstanceRef.current.destroy();
            }
        };
    }, [dependency_key])

    return({
        isViewerVisible,
        viewerInstanceRef
    })
}

export default useImageGallery;