import { ReactNode, useContext, useEffect } from "react";
import CSS from "./static/css/AccountPage.module.css";
import { commonContext } from "../../../../App";

const AccountPage = (
    {
        children
    }:{
        children?: ReactNode;
    }
) => {
    const l_commonContext = useContext(commonContext);

    useEffect(() => {
        l_commonContext.setIsNaviHidden(true);
        l_commonContext.setIsHeaderHidden(true);

        return () => {
            l_commonContext.setIsNaviHidden(false);
            l_commonContext.setIsHeaderHidden(false);
        }
    }, []);

    return(
        <div className={CSS.l_AccountPage_main}>
            {children}
        </div>
    )
}

export default AccountPage;