import { useEffect, useState } from "react";
import CSS from "./static/css/TabMenuItem.module.css";
import { tebMenuItemInterface } from "../static/interface/TabMenu.interface";
import Text from "../../Text/Text";

const TabMenuItem = <T,>(
    {
        tab_data,
        is_active,
        onClick,
    }:{
        tab_data: tebMenuItemInterface<T>;
        is_active?: boolean;
        onClick?: React.MouseEventHandler<HTMLDivElement>;
    }
) => {
    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    function classNameSetter(){
        const RTN: string[] = [];

        if(!!onClick){
            RTN.push("g_click_event_item");
        }

        RTN.push(CSS.l_TabMenuItem_main);

        if(is_active){
            RTN.push(CSS.active);
        }

        return RTN;
    }

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "));
    }, [onClick, is_active])

    return(
        <div 
            className={mainClassName}
            onClick={onClick}
        >
            <Text
                preset={"tab"}
                color={is_active ? "primary" : "gray3"}
                text_wrap={"nowrap"}
            >
                {tab_data.label}
            </Text>
            {/* <Text bold={active_sign}>
                {tab_item.badge}
            </Text> */}
        </div>
    )
}

export default TabMenuItem;