import { shootListItemInterface } from "../interface/ShootList.interface";
import { schedulePhotoProductThemeInterface } from "../../../static/interface/selectTheme.interface";

const useShootList = (
    {
        selectedThemeList
    }:{
        selectedThemeList: schedulePhotoProductThemeInterface[];
    }
) => {
    const shootListItemList: shootListItemInterface[] = [];

    for(const selected_item of selectedThemeList){
        shootListItemList.push({
            schedule_photo_product_id: selected_item.schedule_photo_product_id,
            shoot_name: selected_item.photo_name,
        })
    }

    return({
        shootListItemList
    })
}

export default useShootList;