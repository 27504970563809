import { MouseEventHandler, useMemo } from "react";
import CSS from "./static/css/ArrowIcon.module.css";
import left_arrow from "./static/img/arrow_left_alt@2x.png";
import right_arrow from "./static/img/arrow_right_alt@2x.png";

const ArrowIcon = (
    {
        diraction,
        onClick,
    }:{
        diraction: "left" | "right"
        onClick?: MouseEventHandler<HTMLDivElement>;
    }
) => {
    const mainClassName = useMemo(() => {
        const RTN: string[] = [];

        if(onClick){
            RTN.push("g_click_event_item");
        }

        RTN.push(CSS.l_ArrowIcon_main)

        return RTN.join(" ");
    }, [onClick])

    const l_src = useMemo(() => {
        let RTN:string = "";
        
        switch(diraction){
            case "left":
                RTN = left_arrow;
                break ;
            case "right":
                RTN = right_arrow;
                break ;
        }

        return RTN;
    }, [diraction])

    return(
        <div 
            className={mainClassName}
            onClick={onClick}
        >
            <img 
                className={CSS.l_img}
                src={l_src}
            />
        </div>
    )
}

export default ArrowIcon;