import CSS from "./static/css/loginInputContainer.module.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { adminTokenLogin, callAxios, customToast, dev_console, whatIsMode } from "../../../../Common/ts/commonTools";
import { setCookie } from "../../../../Common/ts/appTools";
import { useEffect, useRef, useState } from "react";
import CheckbaxContainer from "../../../../Component/Elements/CheckbaxContainer/CheckbaxContainer";
import HomeLinkLogo from "../../../../Component/Elements/HomeLinkLogo/HomeLinkLogo";
import CardContainer from "../../../../Component/Elements/CardContainer/CardContainer";
import LoopAnimationModal from "../../../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import Button from "../../../../Component/Elements/Button/Normal/Button";
import Text from "../../../../Component/Elements/Text/Text";
import Input from "../../../../Component/Elements/Input/Input";
import { useMediaQuery } from "react-responsive";

const LoginInputContainer = () => {
    // input에 입력된 id
    const [id, setId] = useState('');
    
    // input에 입력된 password
    const [password, setPassword] = useState('');

    // 아이디 기억하기 여부
    const [isRememberedId, setIsRememberedId] = useState(false);

    // 자동 로그인 여부
    const [isAutoLogin, setIsAutoLogin] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const id_input = useRef<HTMLInputElement>(null);
    const password_input = useRef<HTMLInputElement>(null);
    const button_ref = useRef<HTMLButtonElement>(null);

    const isMobile = useMediaQuery({maxWidth: 700});

    async function requestLogin({ id, password }: { id: string; password: string }) {
        const data = new FormData();
        data.append('username', id);
        data.append('password', password);

        const login_url = "api/v1/accounts/login/";
        const response = await callAxios.api({
            url: login_url,
            method: 'post',
            data: data,
            dataType: 'formdata',
        })

        dev_console.log(login_url);
        dev_console.log(response);

        return response;
    }

    useEffect(() => {

        // 아이디 저장상태 state에 업데이트
        const localStorage_is_remembered_id = localStorage.getItem('isRememberedId');
        if(localStorage_is_remembered_id === "true"){
            const localStorage_username = localStorage.getItem('username');
            if(localStorage_username){
                setIsRememberedId(true);
                setId(localStorage_username);
            }
        }else{
            setIsRememberedId(false);
        }

    }, []);

    useEffect(() => {
        if(!isRememberedId){
            setIsAutoLogin(false);
        }
    }, [isRememberedId])

    useEffect(() => {
        if(isAutoLogin){
            setIsRememberedId(true);
        }
    }, [isAutoLogin])

    const printError = (error: string) => {
        customToast.error({
            msg: error, 
            toastId: "login_fail"
        });
    }

    // 로그인 요청 함수
    const loginAction = () => {
        if(adminTokenLogin.isTokenLoginMode()){
            setCookie({name:'token', value: adminTokenLogin.token(), days: 0});
            setCookie({name:'user_type', value: "C", days: 0});
            window.location.href = '/';
        }else{
            if (id.length < 1) {
                id_input.current?.focus();
                printError("아이디를 입력해 주세요.")
            } else if (password.length < 1) {
                password_input.current?.focus();
                printError('비밀번호를 입력해 주세요.');
            } else {
                setIsLoading(true);
                requestLogin({id, password})
                .then((response) => {
                    if(response.status === 200){
                        if(response.data.user_type !== "C" && response.data.user_type !== "E"){
                            printError('접근 권한이 없습니다.');
                        }else{
                            let days = 0;   //  쿠키 유지 시간 / 단위: 일(day)

                            // 자동 로그인
                            if (isAutoLogin) {
                                days = 90;
                            }
        
                            // 로그인을요청에 따라 리턴되는 값은 token만 사용한다. 
                            // 이후 토큰값 외의 계정의 정보는 App.tsx내부에서 api/v3/accounts/web-token엔드포인트를 통해 가져온다.
                            setCookie({name:'token', value: response.data.token, days});

                            // 아이디 기억하기
                            if (isRememberedId) {
                                localStorage.setItem('isRememberedId', 'true');
                                localStorage.setItem('username', id);
                            }
        
                            // 로그인 결과를 적용하기위해 페이지 자체를 새로고침(이를통해 push메시지를 통해 접속하려는 원래의 페이지로 이동하는 동작도 처리된다.)
                            window.location.reload();
                        }
                    } else {
                        printError('아이디 혹은 비밀번호를 확인해 주세요.');
                    }
                })
                .catch((error) => {
                    if(axios.isAxiosError(error)){
                        dev_console.log(error);
                        if(error.response && error.response.status === 400){
                            // 서버에서 아이디와 비밀번호를 통해 계정 조회에 실패(잘못 입력한 계정 id 혹은 비밀번호)할 경우 400 status code로 응답한다.
                            printError("아이디 혹은 비밀번호를 확인해 주세요.");
                        }else if(error.response && error.response.data && error.response.data.msg === "로그인이 불가능 합니다."){
                            printError("아이디 혹은 비밀번호를 확인해 주세요.");
                        }else{
                            printError("로그인에 실패했습니다.");
                        }
                    }else{
                        // AxiosError가 아닌 일반적인 에러 처리
                        dev_console.log(error);
                        printError("예상치 못한 오류가 발생했습니다.");
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                })
            }
        }

    };

    const onKeyPressAction = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            loginAction();
        }
    };

    return (
        <div className={CSS.l_LoginInputContainer_main}>
            <CardContainer class_name_list={[CSS.l_LoginInputContainer_mainCardContainer]}>
                <div className={CSS.l_LoginInputContainer_logoContainer}>
                    <HomeLinkLogo />
                </div>
                <Text
                    preset={"AccoutPageTitle"}
                    class_name_list={[CSS.l_LoginInputContainer_loginText]}
                >
                    로그인
                </Text>

                <div className={CSS.l_LoginInputContainer_inputsContainer}>
                    <Input 
                        type={"text"}
                        value={id}
                        onChange={(e) => {
                            setId(e.target.value);
                        }}
                        onKeyDown={onKeyPressAction}
                        ref={id_input}
                        placeholder={"아이디를 입력하세요"}
                        autoComplete={"username"}
                    />

                    <Input 
                        type={"password"}
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        onKeyDown={onKeyPressAction}
                        ref={password_input}
                        placeholder={"비밀번호를 입력하세요"}
                        autoComplete={"current-password"}
                    />
                </div>

                <div className={CSS.l_LoginInputContainer_checkboxsContainer}>
                    <div className={CSS.l_LoginInputContainer_checkboxItemContainer}>
                        <CheckbaxContainer 
                            label={"아이디 기억하기"}
                            isChecked={isRememberedId}
                            onClickHander={() => {
                                setIsRememberedId(!isRememberedId);
                            }}
                        />
                    </div>

                    <div className={CSS.l_LoginInputContainer_checkboxItemContainer}>
                        {
                            isMobile
                            &&  <CheckbaxContainer 
                                    label={"자동 로그인"}
                                    isChecked={isAutoLogin}
                                    onClickHander={() => {
                                        setIsAutoLogin(!isAutoLogin);
                                    }}
                                />
                        }
                    </div>
                </div>
            </CardContainer>

            <Button
                class_name_list={[CSS.l_LoginInputContainer_loginBtn]}
                size={"size3"}
                onClick={loginAction}
                type='button'
                ref={button_ref}
                disabled={isLoading}
            >
                <div className={CSS.l_loginBtnText}>
                    로그인
                </div>
            </Button>

            <Link 
                to='/resetUserPassword'
                className={CSS.l_LoginInputContainer_Link}
            >
                <Text color={"secondary"}>
                    비밀번호 초기화
                </Text>
            </Link>

            <LoopAnimationModal 
                isModalOpen={isLoading}
            />
        </div>
    )
}

export default LoginInputContainer;