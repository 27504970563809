import CSS from "./static/css/ProductRequestEdit.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ProductDetail_SelectedOptionListEle_Interface } from "./static/interface/ProductDetail.interface";
import { callAxios, customParseInt, customToast, dateObjectManagerClass, dev_console, getQueryParamsUrl, imageFilesUpload, isAbleAccesser, isSuccess, printStateMsg } from "../../Common/ts/commonTools";
import useProductDetailSetting from "./hooks/useProductDetailSetting";
import useProductDetailREST from "./hooks/REST/useProductDetailREST";
import useDaumPostcode from "../../Component/Elements/Postcode/hooks/useDaumPostcode.hook";
import { chunkImageUploadDataInterface, defaultApiReaponseInterface, uploadRequestfileDataInterface } from "../../Common/interface/Common.interface";
import { toast } from "react-toastify";
import { addressDataInterface } from "../../Component/Elements/Postcode/interface/useDaumPostcode.interface";
import { addedInfoDataInterface, satisfiedPhotoNumsInterface, uploadImgFilesInterface } from "./static/interface/ProductRequestEdit.interface";
import ProductRequestEditWebViewing from "./components/ProductRequestEditPage/ProductRequestEditWebViewing/ProductRequestEditWebViewing";
import ProductRequestEditMobileViewing from "./components/ProductRequestEditPage/ProductRequestEditMobileViewing/ProductRequestEditMobileViewing";
import LoopAnimationModal from "../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import { userDataStorageManagerClass } from "./static/ts/common.tools";
import { albumDataInterface, recipientInfoInterface } from "./static/interface/common.interface";
import { PRODUCT_STATE_CODE_OBJ } from "./static/ts/const";
import useHistoryBackContralOverlayState from "../../Component/Elements/Overlay/hooks/useHistoryBackContralOverlayState.hook";
import DeliveryAddressListOverlay from "./components/DeliveryAddressListOverlay/DeliveryAddressListOverlay";

const userDataStorageManager = new userDataStorageManagerClass();

const { customFormatDate } = new dateObjectManagerClass();

const ProductRequestEdit = () => {
    const [isUploading, setIsUploading] = useState<boolean>(false);

    //  업로드된 이미지 데이터(key값은 folder의 id로 처리한다)
    const [imgFiles, setImgFiles] = useState<uploadImgFilesInterface>({});

    //  각 폴더별로 업로드된 사진의 개수가 만족하는지 여부
    const [satisfiedPhotoNums, setSatisfiedPhotoNums] = useState<satisfiedPhotoNumsInterface>({});

    //  새로 선택한 옵션들
    const [selectedOptionList, setSelectedOptionList] = useState<ProductDetail_SelectedOptionListEle_Interface[]>([]);

    // 추가입력정보(앨범에서 입력받는 아기정보)
    const [newAddedInfo, setNewAddedInfo] = useState<addedInfoDataInterface>({
        baby_name: "",
        baby_birth: null,
        baby_birth_time: null,
        tall: "",
        weight: "",
    })

    const leftContainerEleRefList = useRef<Array<HTMLDivElement | null>>([]);
    const centerContainerEleRefList = useRef<Array<HTMLDivElement | null>>([]);

    // 편집 요청 내용
    const [request, setRequest] = useState<string>("");
    
    const navigate = useNavigate();

    const [URLSearchParamsData, SetURLSearchParamsData] = useSearchParams();

    let l_is_done = URLSearchParamsData.get("is_done");

    let l_contract_product_id = customParseInt(URLSearchParamsData.get("contract_product_id"));
    // album_save_id는 고객이 사진을 업로드하면 생성된다.
    let l_album_save_id = customParseInt(URLSearchParamsData.get("album_save_id"));
    // photo_upload_id는 편집 사진이 업로드되면 생성된다.
    let l_photo_upload_id = customParseInt(URLSearchParamsData.get("photo_upload_id"));
    
    // 제품제작 신청이 완료된 직후에 다음 페이지에서 뒤로가기버튼을 통해 이 페이지에 접근한경우엔 productList로 내보낸다.
    // 뒤로가기 버튼을 통해 이미 완료된 해당페이지로 재진입하는것을 방지
    if(l_is_done === "Y"){
        window.location.href = "/productList";
    }

    // album_save_id와 photo_upload_id는 항상 있는 필수값이 아니므로 contract_product_id값이 넘어오는지만 확인한다.
    // l_contract_product_id가 없다면 처리가 불가능하므로 내보낸다.
    if(!l_contract_product_id){
        window.location.href = "/productList";
    }

    // 위의 null조건을 통과한 후에는 무조건 값이 있는 상태이다. -> 자료형을 교정해준다.
    l_contract_product_id = l_contract_product_id as number;

    const {
        isOpen,
        overlayHandler,
    } = useHistoryBackContralOverlayState(false);

    const {        
        productDetailData,
        productDetailError,
        productDetailIsLoading,
        productDetailRefetch
    } = useProductDetailREST({
        contract_product_id: l_contract_product_id,
        album_save_id: l_album_save_id,
        photo_upload_id: l_photo_upload_id
    })

    const {
        productDetailInfoData,
        productOptionDataList,
        folderList,
        initialSelectOptionDataList,
        initialSelectPhotoList,
        initialEditPhotoList,
        addedInfo,
        address,
        isAlbum,
    } = useProductDetailSetting({
        productDetailData,
    })

    const min_resolution = useMemo(() => {
        let RTN: number = 0;

        if(productDetailInfoData){
            RTN = customParseInt(productDetailInfoData.min_resolution) || 0;
        }

        return RTN;
    }, [productDetailInfoData]);

    const min_long_size = useMemo(() => {
        let RTN: number = 0;

        if(productDetailInfoData){
            RTN = customParseInt(productDetailInfoData.min_long_size) || 0;
        }

        return RTN;
    }, [productDetailInfoData]);

    const min_short_size = useMemo(() => {
        let RTN: number = 0;

        if(productDetailInfoData){
            RTN = customParseInt(productDetailInfoData.min_short_size) || 0;
        }

        return RTN;
    }, [productDetailInfoData]);

    // 주소
    const {
        customAddressData,
        setCustomAddressData,
        setRowAddressData,
    } = useDaumPostcode(null);

    // 받는 사람 정보
    const [recipientInfo, setRecipientInfo] = useState<recipientInfoInterface>({
        recipient_name: "",
        recipient_phone: "",
    })

    // 받는 사람 정보 업데이트 헨들러
    const recipientInfoChangeHandler = (value: string, target: "name" | "phone") => {
        if(target === "name"){
            setRecipientInfo((prev) => {
                return({
                    ...prev,
                    recipient_name: value
                })
            })
        }else if(target === "phone"){
            setRecipientInfo((prev) => {
                return({
                    ...prev,
                    recipient_phone: value
                })
            })
        }
    }

    const addOptionItemHandler = (option: ProductDetail_SelectedOptionListEle_Interface) => {
        // 현재는 선택할 수 있는 옵션이 반드시 1개이므로 선택한 옵션으로 그냥 갱신시킨다.
        setSelectedOptionList([option]);
    }

    const subOptionItemHandler = (option: ProductDetail_SelectedOptionListEle_Interface) => {
        if(!window.confirm(`선택하신 ${option.option_name} 옵션을 제거하시겠습니까?`)){
            return ;
        }

        setSelectedOptionList((prev) => prev.filter((item) => item.option_id !== option.option_id));
    }

    // 옵션 선택 클릭 동작은 최종적으로 토글방식을 사용하게 하자..
    const selectOptionItemClickHandler = (option: ProductDetail_SelectedOptionListEle_Interface) => {
        if(selectedOptionList.includes(option)){
            subOptionItemHandler(option);
        }else{
            addOptionItemHandler(option);
        }
    }

    // 스크롤 액션 함수
    const scrollToFirstFalseItem = (folder_id: number) => { 
        const m_ele_current = centerContainerEleRefList.current[folder_id];
        if (m_ele_current) {
            // 스크롤 이동
            m_ele_current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    }; 

    // 취소버튼은 뒤로가기...
    const cancelHandler = () => {
        let m_url: string = "/productList";
        
        // album_save_id가 존재한다는 이야기는, 기존에 업로드한 이미지가 존재한는 뜻이다 -> 재업로드를 위해 페이지를 이동했던 상태이므로 제품 상세페이지로 이동시킨다.
        if(l_album_save_id){
            const m_productDetail_url = getQueryParamsUrl({
                main_url: "/productDetail",
                queryParams: {
                    contract_product_id: `${l_contract_product_id || ""}`,
                    album_save_id: `${l_album_save_id || ""}`,
                    photo_upload_id: `${l_photo_upload_id || ""}`,
                }
            })

            m_url = m_productDetail_url;
        }

        navigate(m_url)
    }

    // 미완료된 항목이 있는지 검증하는 함수
    const checkFalseValue = () => {
        // 객체의 key-value 쌍을 배열로 변환
        const entries = Object.entries(satisfiedPhotoNums);
      
        // 가장 먼저 감지된 value가 false인 key 찾기
        const firstFalseKey = entries.find(([key, value]) => value === false);
      
        if (firstFalseKey) {
            const [key] = firstFalseKey;
            return parseInt(key); // 필요에 따라 key 값을 반환
        } else {
            return null; // false 값이 없으면 null 반환
        }
    };

    // 서버에 업로드 요청
    const requesetSelectHandler = async () => {
        const m_TOAST_ID = "ProductRequestEdit"

        let check_result = checkFalseValue();
        if(check_result !== null){
            dev_console.error("check_result");
            dev_console.error(check_result);

            if(check_result > 0){
                customToast.error({
                    msg: "필요한 수만큼 사진이 선택되지 않은 항목이 있습니다.",
                    toastId: m_TOAST_ID
                });
                scrollToFirstFalseItem(check_result);
            }else{
                customToast.error({
                    msg: "필요한 수만큼 사진이 선택되지 않았습니다.",
                    toastId: m_TOAST_ID
                });
            }

            return ;
        }

        if(productOptionDataList.length> 0 && selectedOptionList.length === 0){
            customToast.error({
                msg: "옵션을 선택해 주세요.(필수 택 1)",
                toastId: m_TOAST_ID
            });
            scrollToFirstFalseItem(-1);
            return ;
        }

        if(recipientInfo.recipient_name === "" || recipientInfo.recipient_phone === "" || !customAddressData.is_set){
            customToast.error({
                msg: "배송지 정보를 입력해 주세요.",
                toastId: m_TOAST_ID
            });
            return ;
        }

        const final_msg_list: string[] = ["상품 제작을 위한 사진을 모두 선택하셨습니까?", "제품 제작이 시작되면 입력하신 내용과 선택하신 사진 변경이 불가능합니다."];
        if(!window.confirm(final_msg_list.join(`\n`))){
            return ;
        }else if(!window.confirm(`사진 선택을 완료하시겠습니까?`)){
            return ;
        }

        // 로딩처리 시작
        setIsUploading(true);
        
        const file_data: uploadRequestfileDataInterface[] = [];
        
        let master_file_url = `media/office/${productDetailInfoData?.office_id || ""}/customers/${productDetailInfoData?.customer_id || ""}/selectPhoto/contract/${productDetailInfoData?.contract_id || ""}/products/${productDetailInfoData?.contract_product_id || ""}`;

        if(folderList.length > 0 && folderList[0].folder_id > 0){
            master_file_url += "/folder";
        }
            
        for(const folder of folderList){

            let upload_url = master_file_url;
            if(folder.folder_id > 0){
                upload_url += `/${folder.folder_id}`;
            }

            let pending_msg = "사진들을 업로드 중입니다...";
            let error_msg = "사진들의 업로드 과정에서 문제가 발생했습니다.";
            if(folder.folder_id > 0){
                pending_msg = `${folder.folder_name} ${pending_msg}`;
                error_msg = `${folder.folder_name} ${error_msg}`;
            }

            customToast.info({
                msg: pending_msg,
                autoClose: false,
                toastId: m_TOAST_ID
            })
                        
            try{
                //  이미지를 청크로 업로드하는 부분
                const result = await imageFilesUpload({
                    files: imgFiles[folder.folder_id],
                    file_dir: upload_url,
                })

                if(result.state === 200){
                    const result_data:chunkImageUploadDataInterface[] = result.data;
                    for(const data of result_data){
                        file_data.push({
                            file_url: upload_url,
                            file_name: data.file_name,
                            file_ext: data.file_extension,
                            file_size: data.file_size,
                        })
                    }
                }else{
                    // 에러로 인한 로딩 종료
                    setIsUploading(false);

                    customToast.error({
                        msg: error_msg,
                    })

                    break ;
                }

            }catch(error){
                // 에러로 인한 로딩 종료
                setIsUploading(false);

                customToast.error({
                    msg: error_msg,
                })

                break ;
            }
        }

        toast.dismiss(m_TOAST_ID);

        customToast.info({
            msg: "거의 완료됐습니다.",
            autoClose: false,
            toastId: m_TOAST_ID
        })

        let baby_birth:string = "";
        if(newAddedInfo.baby_birth){
            baby_birth = customFormatDate(newAddedInfo.baby_birth, "yyyy-MM-dd");
        }

        let birth_time: string = "";
        if(newAddedInfo.baby_birth_time){
            birth_time = customFormatDate(newAddedInfo.baby_birth_time, "HH:mm");
        }

        const m_baby_birth_date_time = new Date(`${baby_birth} ${birth_time}`);
        const m_babyinfo: albumDataInterface = {
            baby_name: newAddedInfo.baby_name,
            baby_birth: m_baby_birth_date_time,
            baby_birth_time: m_baby_birth_date_time,
            tall: newAddedInfo.tall,
            weight: newAddedInfo.weight,
        }
        
        const address: addressDataInterface = customAddressData;

        const config = {
            contract_product_id: productDetailInfoData?.contract_product_id,
            contract_id: `${productDetailInfoData?.contract_id}`,
            office_id: productDetailInfoData?.office_id,

            // album_save_id가 unll이 아니라는 이야기는 고객이 이미 최초 사진 업로드를 했다는 이야기다.
            album_save_id: `${l_album_save_id || ""}`,
            thema_id_list: selectedOptionList.map((item) => item.option_id).join(","),

            baby_name: newAddedInfo.baby_name,
            baby_birth,
            birth_time,
            height: newAddedInfo.tall,
            weight: newAddedInfo.weight,
            
            addr: address.mainAddress,
            addr_detail: address.detailAddress,
            building_no: address.buildingCode,
            zip_code: address.zonecode,
            common_memo: request,
            master_file_url: master_file_url,
            file_data: file_data,

            recipient_name: recipientInfo.recipient_name, // 받는이 이름
            recipient_phone: recipientInfo.recipient_phone.replaceAll("-", "") // 받는이 휴대폰(-제외)
        }

        dev_console.log(config);

        // 입력한 받는이 정보를 스토리지에 저장
        // 요청을 성공한 후에 저장해도 좋지만, 실패했을경우에는 더더욱 반복해서 입력해야하니까 미리 저장하는게 좋지 않을까? 해서 여기서 저장
        userDataStorageManager.babyInfo.set(m_babyinfo);
        userDataStorageManager.address.set(address);
        userDataStorageManager.recipientInfo.set(recipientInfo);

        // 모든 이미지의 업로드가 완료된 후, 업로드한 이미지들에대한 정보를 서버로 보낸다.
        const photo_select_upload_new_url = "api/v3/customers/photo-select-upload-new";
        callAxios.api({
            url: photo_select_upload_new_url,
            method: "post",
            dataType: "json",
            data: config,
        })
        .then(async (response) => {
            dev_console.log(photo_select_upload_new_url);
            dev_console.log(response);
            
            const response_data: defaultApiReaponseInterface<number> = response.data;
            
            if(isSuccess(response)){

                const m_URLSearchParamsData = new URLSearchParams(URLSearchParamsData);
                    
                const m_is_done = "Y";

                m_URLSearchParamsData.set("is_done", m_is_done);
                SetURLSearchParamsData(m_URLSearchParamsData);

                customToast.success({
                    msg: "요청이 완료되었습니다.",
                })

                // status_cd를 다음 상태인 PRODUCT_STATE_CODE_OBJ.application_in_progress로 변경하고 페이지를 이동한다. -> 고객이 업로드한 사진을 출력시킴
                // 업로드한 사진은 album_save_id값에 따라 조회되므로, 서버에서 전달받은 최신 값을 사용하여 album_save_id를 설정한다.
                const m_productDetail_url = getQueryParamsUrl({
                    main_url: "/productDetail",
                    queryParams: {
                        status_cd: `${PRODUCT_STATE_CODE_OBJ.application_in_progress || ""}`,
                        contract_product_id: `${l_contract_product_id || ""}`,
                        album_save_id: `${response_data.result || ""}`,
                        photo_upload_id: `${l_photo_upload_id || ""}`,
                        is_done: m_is_done,
                    }
                })

                navigate(m_productDetail_url);
        
            }else if(response_data.status.code === 201){
                customToast.error({msg: "사진 재선택이 불가능한 상품입니다.\n사유 : 이미 제품 제작이 시작된 상품입니다."});
            }else{
                printStateMsg(response);
                customToast.error({msg: "사진 업로드에 실패했습니다."})
            }
        })
        .catch((error)=>{
            dev_console.error(error);
            customToast.error({msg: "사진 업로드 과정에서 문제가 발생했습니다."})
        })
        .finally(()=>{
            // 로딩 종료
            setIsUploading(false);
            toast.dismiss(m_TOAST_ID);
        })
    };

    // 이전 주소에서 배송지를 변경하는 경우 사용할 핸들러 함수
    function deliveryAddressChangeHandler(
        {
            recipient_name,
            recipient_phone,
            zonecode,
            mainAddress,
            buildingCode,
            detailAddress
        }:{
            recipient_name: string;
            recipient_phone: string;
            zonecode: string;
            mainAddress: string;
            buildingCode: string;
            detailAddress: string;
        }
    ){
        if(!window.confirm("선택하신 배송지로 적용하시겠습니까?")){
            return ;
        }

        setRecipientInfo({
            recipient_name,
            recipient_phone,
        })

        setCustomAddressData({
            is_set: true,
            zonecode,
            mainAddress,            
            buildingCode,
            userSelectedType: "R",
            detailAddress,
            sigunguCode: "",
            bcode: "",
            bname: "",
            buildingName: "",
        })

        overlayHandler(false);
    }

    // 기존에 선택한 옵션 선택정보를 업데이트한다.
    useEffect(() => {
        setSelectedOptionList(initialSelectOptionDataList);
    }, [initialSelectOptionDataList])

    useEffect(() => {
        isAbleAccesser({
            allowedUserTypeList: ["C"]
        })
    }, [])

    // 기존에 설정한 추가정보를 업데이트한다.
    useEffect(() => {
        if(addedInfo){
            const m_birthDatetime = new Date(addedInfo.baby_birth_day);
            const m_birthTimeDatetime = new Date(`${addedInfo.baby_birth_day} ${addedInfo.baby_birth_day}`);

            setNewAddedInfo({
                baby_name: addedInfo.baby_name,
                baby_birth: isNaN(m_birthDatetime.getTime()) ? null : m_birthDatetime,
                baby_birth_time: isNaN(m_birthTimeDatetime.getTime()) ? null : m_birthTimeDatetime,
                tall: addedInfo.baby_height,
                weight: addedInfo.baby_weight,
            })
        }else{
            const m_babyInfo = userDataStorageManager.babyInfo.get();
            if(m_babyInfo){
                setNewAddedInfo(m_babyInfo);
            }
        }
    }, [addedInfo])

    // 기존에 설정한 주소 정보를 통해 가능한 최대로 주소 정보를 업데이트한다.(복구가 안되는것은 하드코딩처리)
    useEffect(() => {
        if(address){
            setCustomAddressData({
                is_set: true,
                zonecode: address.zip_code,
                mainAddress: address.addr,
                sigunguCode: "",
                bcode: "",
                bname: "",
                buildingName: "",
                buildingCode: address.building_no,
                userSelectedType: "J",
                detailAddress: address.addr_detail,
            })

            setRecipientInfo({
                recipient_name: address.recipient_name,
                recipient_phone: address.recipient_phone,
            })
        }else{
            // 마지막으로 사용한 주소정보와 아기정보를 불러와서 미리 세팅한다.
            const m_address = userDataStorageManager.address.get();
            if(m_address){
                setCustomAddressData(m_address);
            }

            // 마지막으로 사용한 받는이 정보를 불러와서 미리 세팅한다.
            const m_recipientInfo = userDataStorageManager.recipientInfo.get();
            if(m_recipientInfo){
                setRecipientInfo(m_recipientInfo);
            }
        }
    }, [address])

    return(
        <div className={CSS.l_ProductRequestEdit_main}>

            {/* 웹에서 보이는 화면 */}
            <ProductRequestEditWebViewing 
                folderList={folderList}
                leftContainerEleRefList={leftContainerEleRefList}
                centerContainerEleRefList={centerContainerEleRefList}
                productOptionDataList={productOptionDataList}
                imgFiles={imgFiles}
                setImgFiles={setImgFiles}
                satisfiedPhotoNums={satisfiedPhotoNums}
                setSatisfiedPhotoNums={setSatisfiedPhotoNums}
                selectedOptionList={selectedOptionList}
                productDetailInfoData={productDetailInfoData}
                newAddedInfo={newAddedInfo}
                setNewAddedInfo={setNewAddedInfo}
                request={request}
                setRequest={setRequest}
                customAddressData={customAddressData}
                setCustomAddressData={setCustomAddressData}
                setRowAddressData={setRowAddressData}
                cancelHandler={cancelHandler}
                requesetSelectHandler={requesetSelectHandler}
                selectOptionItemClickHandler={selectOptionItemClickHandler}
                subOptionItemHandler={subOptionItemHandler}
                scrollToFirstFalseItem={scrollToFirstFalseItem}
                isAlbum={isAlbum}
                recipientInfo={recipientInfo}
                recipientInfoChangeHandler={recipientInfoChangeHandler}
                min_resolution={min_resolution}
                min_long_size={min_long_size}
                min_short_size={min_short_size}
                deliveryAddressListOverlayOpenHandler={overlayHandler.bind(null, true)}
            />

            {/* 모바일에서 보이는 화면 */}
            <ProductRequestEditMobileViewing 
                folderList={folderList}
                productOptionDataList={productOptionDataList}
                imgFiles={imgFiles}
                setImgFiles={setImgFiles}
                satisfiedPhotoNums={satisfiedPhotoNums}
                setSatisfiedPhotoNums={setSatisfiedPhotoNums}
                selectedOptionList={selectedOptionList}
                newAddedInfo={newAddedInfo}
                setNewAddedInfo={setNewAddedInfo}
                request={request}
                setRequest={setRequest}
                customAddressData={customAddressData}
                setCustomAddressData={setCustomAddressData}
                setRowAddressData={setRowAddressData}
                cancelHandler={cancelHandler}
                requesetSelectHandler={requesetSelectHandler}
                selectOptionItemClickHandler={selectOptionItemClickHandler}
                isAlbum={isAlbum}
                recipientInfo={recipientInfo}
                recipientInfoChangeHandler={recipientInfoChangeHandler}
                min_resolution={min_resolution}
                min_long_size={min_long_size}
                min_short_size={min_short_size}
                deliveryAddressListOverlayOpenHandler={overlayHandler.bind(null, true)}
            />

            <LoopAnimationModal isModalOpen={isUploading}/>

            <DeliveryAddressListOverlay
                isOpen={isOpen}
                overlayCloseHandler={overlayHandler.bind(null, false)}
                onClickHandler={deliveryAddressChangeHandler}
            />
        </div>
    )
}

export default ProductRequestEdit;