import { useEffect, useState } from "react";
import { PhotoListItem_Interface } from "../components/PhotoList/static/interface/PhotoList.interface";
import { myPhotoREST_Interface } from "../static/interface/MyPhotoREST.interface";
import { productListEleInterface } from "../../ProductManufacturing/static/interface/ProductList.interface";
import { photoDetailsTypeCodeType, photoTypeCodeType } from "../static/interface/const.interface";
import { PHOTO_DETAILS_TYPE_CODE } from "../static/ts/const";

const usePhotoListSetter = (
    {
        myPhotoDataList,
        productListData,
        main_type,
        photo_details_type,
    }:{
        myPhotoDataList: myPhotoREST_Interface[];
        productListData: productListEleInterface[];
        main_type: photoTypeCodeType;
        photo_details_type: photoDetailsTypeCodeType;
    }
) => {
    const [photoListData, setPhotoListData] = useState<PhotoListItem_Interface[]>([]);

    useEffect(() => {
        if(photo_details_type === PHOTO_DETAILS_TYPE_CODE.edit_end){
            const m_photoListData: PhotoListItem_Interface[] = productListData.map((item) => {
                const RTN: PhotoListItem_Interface = {
                    img_url: item.image_url.middle_url,
                    title: item.product_name,
                    info: "",
                    upload_date: "",
                    photo_upload_id: item.photo_upload_id || 0,
                    main_type: main_type,
                    photo_details_type_code: photo_details_type,
                    photo_type: "",

                    product_type: item.product_type,
                    product_state: item.status_cd,
                    product_qty: item.product_qty,
                    options: item.option_name,
                    contract_product_id: item.contract_product_id,
                    album_save_id: item.album_save_id,

                    office_name: "",
                }

                return(RTN)
            });
            setPhotoListData(m_photoListData);
        }else{
            const m_photoListData: PhotoListItem_Interface[] = myPhotoDataList.map((item) => {
                const RTN: PhotoListItem_Interface = {
                    img_url: item.thumbnail.middle_url,
                    title: item.title,
                    info: item.schedule_date,
                    upload_date: item.upload_date,
                    photo_upload_id: item.photo_upload_id,
                    main_type: main_type,
                    photo_details_type_code: photo_details_type,
                    photo_type: item.photo_type,

                    product_type: null,
                    product_state: null,
                    product_qty: "",
                    options: "",
                    contract_product_id: 0,
                    album_save_id: null,

                    office_name: item.office_name,
                }

                return(RTN)
            });

            setPhotoListData(m_photoListData);
        }
    }, [main_type, photo_details_type, myPhotoDataList, productListData]);

    return {
        photoListData
    }
}

export default usePhotoListSetter;