import CSS from "./static/css/MoveArrowBtn.module.css"
import { MouseEventHandler, useMemo } from "react";
import ArrowIcon from "../../../Icon/ArrowIcon/ArrowIcon";

const MoveArrowBtn = (
    {
        diraction,
        onClick,
    }:{
        diraction: "left" | "right";
        onClick?: MouseEventHandler<HTMLDivElement>;
    }
) => {
    const mainClassName = useMemo(() => {
        const RTN: string[] = [];

        RTN.push(CSS.l_MoveArrowBtn_main);
        RTN.push(CSS.diraction);
        RTN.push(CSS[diraction]);

        return RTN.join(" ");
    }, [diraction])

    return(
        <div className={mainClassName}>
            <ArrowIcon
                diraction={diraction}
                onClick={(event) => {
                    event.stopPropagation();

                    if(onClick){
                        onClick(event)
                    }
                }}
            />
        </div>
    )
}

export default MoveArrowBtn;