import { useEffect, useState } from "react";
import { getFileType } from "../../../../Common/ts/commonTools";
import CSS from "../static/css/Media.module.css";
import { aspectRatioType } from "../interface/Media.interface";
import { FILE_CODE } from "../../../../Common/ts/const";
import { fileCodeType } from "../../../../Common/interface/Common.interface";

const useMedia = (
    {
        file_type,
        src,
        origin_src,
        class_name_list,
        aspect_ratio,
    }:{
        file_type?: fileCodeType;
        src?: string;
        origin_src?: string;
        class_name_list?: string[];
        aspect_ratio?: aspectRatioType;
    }
) => {
    let m_finalFileType_RTN: fileCodeType = FILE_CODE.img;

    if(file_type){
        m_finalFileType_RTN = file_type;
    }else{
        // 자료형이 정해지지 않은경우, 주소를 통해 자료형 추출을 시도한다.
        m_finalFileType_RTN = getFileType(src || "");

        if(m_finalFileType_RTN === FILE_CODE.none && origin_src){
            m_finalFileType_RTN = getFileType(origin_src || "");
        }        
    }

    const classNameSetter = () => {
        const RTN: string[] = [];

        if(class_name_list){
            for(const class_name of class_name_list){
                RTN.push(class_name);
            }
        }

        RTN.push(CSS.l_Media_main);

        if(aspect_ratio){
            RTN.push(CSS.aspect_ratio);
            RTN.push(CSS[aspect_ratio]);
        }

        return (RTN);
    }

    const [mainClassNameListRTN, setMainClassNameListRTN] = useState<string[]>(classNameSetter());

    useEffect(() => {
        setMainClassNameListRTN(classNameSetter());
    }, [class_name_list, aspect_ratio])

    return({
        finalFileType: m_finalFileType_RTN,
        mainClassNameList: mainClassNameListRTN
    })
}

export default useMedia;