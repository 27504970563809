import CSS from "./static/css/AddressInfoCard.module.css";
import { Address } from "react-daum-postcode";
import { addressDataInterface } from "../../../../Component/Elements/Postcode/interface/useDaumPostcode.interface";
import MainInfoCard from "../../../../Component/Elements/MainInfoCard/MainInfoCard";
import { recipientInfoInterface } from "../../static/interface/common.interface";
import AddressInfo from "../AddressInfo/AddressInfo";
import OvalIconButton from "../../../../Component/Elements/Button/OvalIconButton/OvalIconButton";

const AddressInfoCard = (
    {
        recipientInfo,
        recipientInfoChangeHandler,
        customAddressData,
        setCustomAddressData,
        setRowAddressData,
        readOnly,
        overlayHandler,
    }:{
        recipientInfo: recipientInfoInterface;
        recipientInfoChangeHandler: (value: string, target: "name" | "phone") => void;
        customAddressData: addressDataInterface;
        setCustomAddressData: React.Dispatch<React.SetStateAction<addressDataInterface>>;
        setRowAddressData?: React.Dispatch<React.SetStateAction<Address | null>>;
        readOnly?: boolean;
        overlayHandler: (sign: boolean) => void;
    }
) => {
    return(
        <div className={CSS.l_AddressInfoCard_main}>
            <MainInfoCard
                preset={"additionalInfoCard"}
            >
                <AddressInfo
                    recipientInfo={recipientInfo}
                    recipientInfoChangeHandler={recipientInfoChangeHandler}
                    customAddressData={customAddressData}
                    setCustomAddressData={setCustomAddressData}
                    setRowAddressData={setRowAddressData}
                    readOnly={readOnly}
                    headerNode={                                
                        <OvalIconButton 
                            onClick={overlayHandler.bind(null, true)}
                            preset={"history"}
                        />
                    }
                />
            </MainInfoCard>
        </div>
    )
}

export default AddressInfoCard;