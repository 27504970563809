import { useQuery } from "@tanstack/react-query";
import { callAxios, customToast, dev_console, isSuccess, whoAmI } from "../../../../Common/ts/commonTools";
import { productListEleInterface, productListFilterCodeType } from "../../static/interface/ProductList.interface";
import { defaultApiReaponseInterface } from "../../../../Common/interface/Common.interface";
import { GC_TIME, STALE_TIME } from "../../../../Common/ts/const";
import { useEffect } from "react";
import { RTN_interface } from "../../../../Common/interface/App.interface";

const EMPTY_LIST: productListEleInterface[] = [];

const TOAST_ID = "useProductListREST";

const useProductListREST = (
    {
        search_type
    }:{
        search_type: productListFilterCodeType;
    }
) => {
    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer()){
            result = true;
        }

        return result;
    }

    const {
        data: productListData = EMPTY_LIST,
        isLoading: productListIsLoading,
        error: productListError,
        refetch: productListRefetch,
    } = useQuery({
        queryKey: ["useProductListAPI", search_type],
        queryFn: callProductList,
        enabled: isAbleToRequest,
        select: (response) => {
            const RTN: RTN_interface<productListEleInterface[]> = {
                sign: false,
                data: EMPTY_LIST,
                msg: "",
            };

            SetData:{
                if(!isSuccess(response)){
                    RTN.msg = "제품 목록 데이터를 불러오는데 실패했습니다.";
                    break SetData;
                }

                const data:defaultApiReaponseInterface<productListEleInterface[]> = response.data;
                RTN.sign = true;
                RTN.data = data.result;
                RTN.msg = "success";
            }

            if(!RTN.sign){
                customToast.error({
                    msg: RTN.msg,
                    toastId: TOAST_ID
                })
            }

            return RTN.data;
        },
        staleTime: STALE_TIME,
        gcTime: GC_TIME,
    })

    async function callProductList() {
        const m_product_list_url = `api/v3/oops/product-list?search_type=${search_type}`
        const RTN = await callAxios.api({
            url: m_product_list_url,
            method: "get"
        })

        dev_console.log(m_product_list_url);
        dev_console.log(RTN);

        return RTN;
    }

    useEffect(() => {
        if(productListError){
            customToast.error({
                msg: "제품 목록 데이터 요청 과정에서 문제가 발생했습니다.",
                toastId: TOAST_ID
            })
        }
    }, [productListError])

    return({
        productListData,
        productListIsLoading,
        productListError,
        productListRefetch
    })
}

export default useProductListREST;