import { buttonColorType, buttonPresetType } from "../interface/Button.interface";
import { textColorType, textPresetType } from "../../../Text/interface/Text.interface";

interface textStyleInterface{
    textColor: textColorType; 
    textPreset: textPresetType;
}

const useButtonText = (
    {
        color,
        preset,
        disabled
    }:{
        color?: buttonColorType;
        preset?: buttonPresetType;
        disabled?: boolean;
    }
) => {
    const RTN: textStyleInterface = {
        textColor: "white",
        textPreset: "main_content",
    }

    if(preset){
        switch(preset){
            case "normal":
                RTN.textColor = "white";
                RTN.textPreset= "button";
                break;
            case "normal_gray":
                RTN.textColor = "dark";
                break;
            case "popup":
                RTN.textColor = "white";
                break;
            case "disabled":
                RTN.textColor = "gray3";
                break;
            case "filter":
                RTN.textColor = "primary";
                break;
            case "filter_item":
                RTN.textColor = "dark";
                break;
            case "filter_item_small":
                RTN.textColor = "dark";
                break;
            case "move_page":
                RTN.textColor = "primary";
                RTN.textPreset= "button";
                break;
            case "detail_modal":
                RTN.textColor = "primary";
                RTN.textPreset = "detail_modal";
                break;
            case "detail_view":
                RTN.textColor = "primary";
                RTN.textPreset = "detail_modal";
                break;
            case "trashcan_icon_btn":
                RTN.textColor = "red_gray";
                RTN.textPreset = "detail_modal";
                break;
            default :
        }
    }

    if(color){
        switch(color){
            case "primary":
                RTN.textColor = "white";
                break;
            case "gray":
                RTN.textColor = "dark";
                break;
            default :
        }
    }

    if(disabled){
        RTN.textColor = "gray3";
    }

    return(RTN)
}

export default useButtonText;