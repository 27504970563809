import LabelAndContent from "../../../../Component/Elements/LabelAndContent/LabelAndContent";
import LabelAndContentContainer from "../../../../Component/Elements/LabelAndContentContainer/LabelAndContentContainer";

const BabyInfoView = (
    {
        baby_name,
        baby_birth_day,
        baby_birth_time,
        baby_height,
        baby_weight,
    }:{
        baby_name: string;
        baby_birth_day: string;
        baby_birth_time: string;
        baby_height: string;
        baby_weight: string;
    }
) => {
    return(
        <LabelAndContentContainer>
            <LabelAndContent 
                label="아이 이름"
                content={[baby_name]}
            />
            <LabelAndContent 
                label="출생 정보"
                content={[`${baby_birth_day}`, `${baby_birth_time}`]}
            />
            <LabelAndContent 
                label="키,몸무게"
                content={[`${baby_height}cm`, `${baby_weight}kg`]}
            />
        </LabelAndContentContainer>
    )
}

export default BabyInfoView;