import { Fragment, ReactNode, useEffect, useState } from "react";
import Text from "../../../../Component/Elements/Text/Text";
import { schedulePhotoProductThemeInterface, themaInterface, themeItemOnClickHandlerType } from "../../static/interface/selectTheme.interface";
import CSS from "./static/css/SelectedThemeList.module.css"
import MediaItemCard from "../../../../Component/Elements/MediaItemCard/MediaItemCard";
import useSelectedThemeList from "./hooks/useSelectedThemeList.hook";
import Flex from "../../../../Component/Elements/Flex/Flex";
import DetailIconBtn from "../../../../Component/Elements/DetailIconBtn/DetailIconBtn";
import TrashIconBtn from "../../../../Component/Elements/TrashIconBtn.tsx/TrashIconBtn";
import { useMediaQuery } from "react-responsive";
import ThemaDetailPageOverlay from "../../../../PublicPage/ThemeBook/components/ThemaDetailPageOverlay/ThemaDetailPageModal";
import { findNextTheme } from "../../../../PublicPage/ThemeBook/static/ts/ThemeBook.tools";
import useHistoryBackContralOverlayState from "../../../../Component/Elements/Overlay/hooks/useHistoryBackContralOverlayState.hook";
const SelectedThemeList = (
    {
        photoProduct,
        isReadOnly,
        mobileGoToSelectThemePage,
        subThemeHandler,
        office_name,
    }:{
        photoProduct: schedulePhotoProductThemeInterface;
        isReadOnly?: boolean;
        mobileGoToSelectThemePage: (schedule_photo_product_id: number) => void;
        subThemeHandler: themeItemOnClickHandlerType;
        office_name: string;
    }
) => {

    const [detailTheme, setDetailTheme] = useState<themaInterface | null>(null);
    const [themeIndex, setThemeIndex] = useState<number>(0);

    const [selectedThemeNodeList, setSelectedThemeNodeList] = useState<ReactNode[]>([]);

    const isMobile = useMediaQuery({ maxWidth: 700 });

    const {
        isOpen,
        overlayHandler,
    } = useHistoryBackContralOverlayState(false);

    const {
        photo_id,
        photo_name,
        theme_num,
        allThemeList,
        selected_theme_list,
        schedule_photo_product_id,
    } = useSelectedThemeList({
        photoProduct,
    })

    const detailModalOpenHandler = (theme: themaInterface) => {
        IndexSetting: {
            if(selected_theme_list.length < 0){
                break IndexSetting;
            }

            if(selected_theme_list.length <= themeIndex){
                break IndexSetting;
            }

            const m_index = selected_theme_list.findIndex((item) => item.thema_id === theme.thema_id)

            if(m_index < 0){
                break IndexSetting;
            }

            setThemeIndex(m_index);
            overlayHandler(true);
        }
    }

    const detailModalCloseHandler = () => {
        overlayHandler(false);
    }

    const mobileOnClickHandler = (schedule_photo_product_id: number) => {
        if(isMobile){
            mobileGoToSelectThemePage(schedule_photo_product_id);
        }
    }

    const prevThemeSettingHandler = () => {
        const result = findNextTheme({
            theme_index: themeIndex, 
            theme_list: selected_theme_list,
            diraction: "prev",
        });

        if(result.sign){
            setThemeIndex(result.data)
        }
    }

    const nextThemeSettingHandler = () => {
        const result = findNextTheme({
            theme_index: themeIndex, 
            theme_list: selected_theme_list,
            diraction: "next",
        });

        if(result.sign){
            setThemeIndex(result.data)
        }
    }

    useEffect(() => {
        if(selected_theme_list.length >= 0 && selected_theme_list.length > themeIndex){
            setDetailTheme(selected_theme_list[themeIndex])
        }
    }, [themeIndex, selected_theme_list])

    useEffect(() => {
        const selected_theme_node_list: ReactNode[] = [];
        for(let index = 0 ; index < theme_num ; index++){
            if(index < selected_theme_list.length){

                const m_target_item = selected_theme_list[index];

                let is_have_content: boolean = false;
                if(m_target_item.content.length > 0){
                    is_have_content = true
                }

                const viewDetailFC = is_have_content ? detailModalOpenHandler.bind(null, m_target_item) : undefined;

                selected_theme_node_list.push(
                    <MediaItemCard 
                        src={selected_theme_list[index].thema_thumbnail.middle_url}
                        is_highlight={false}
                        isReadOnly={isReadOnly}
                        preset={"selected_theme_item"}
                        customHoverIcon={
                            isMobile
                            ?   undefined
                            :   <div className={CSS.l_SelectedThemeList_hoverContentContainer}>
                                    {
                                        !isReadOnly
                                        &&   <TrashIconBtn 
                                                deleteFC={() => {
                                                    subThemeHandler({
                                                        schedule_photo_product_id,
                                                        selected_item: selected_theme_list[index],
                                                    })
                                                }}
                                            />
                                    }

                                    {
                                        !!viewDetailFC
                                        &&  <DetailIconBtn viewDetailFC={viewDetailFC} />
                                    }
                                </div>
                        }

                        onClick={() => {
                            if(isMobile){
                                subThemeHandler({
                                    schedule_photo_product_id,
                                    selected_item: selected_theme_list[index],
                                })
                            }
                        }}
                    />
                )
            }else{
                if(!isReadOnly){
                    selected_theme_node_list.push(
                        <MediaItemCard
                            is_highlight={false}
                            isReadOnly={isReadOnly}
                            preset={"selected_theme_item"}
                            is_use_cross_icon={true}
                            onClick={() => {
                                if(isMobile){
                                    mobileOnClickHandler(schedule_photo_product_id)
                                }
                            }}
                        />
                    )
                }
            }
        }

        setSelectedThemeNodeList(selected_theme_node_list);
    }, [selected_theme_list, theme_num, isReadOnly])

    return(
        <div className={CSS.l_SelectedThemeList_main}>
            <Text>
                {photo_name} 
                {
                    (!isReadOnly)
                    &&  `(${selected_theme_list.length}/${theme_num})`
                }
            </Text>

            <Flex preset={"selected_theme_list"}>
                { 
                    selectedThemeNodeList.map((item, index) => {
                        return(
                            <Fragment key={index}>
                                {item}
                            </Fragment>
                        )
                    }) 
                }
            </Flex>

            <ThemaDetailPageOverlay
                isOverlayOpen={isOpen}
                overlayCloseHandler={detailModalCloseHandler}
                photo_name={detailTheme?.photo_name || ""}
                title={detailTheme?.thema_name || ""}
                office_name={office_name || ""}
                content={detailTheme?.content || ""}
                office_id={0}
                thema_id={detailTheme?.thema_id || 0}
                type={0}
                prevThemeHandler={prevThemeSettingHandler}
                nextThemeHandler={nextThemeSettingHandler}
            />
        </div>
    )
}

export default SelectedThemeList;