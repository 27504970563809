import { useMemo } from "react";
import Text from "../../../../../../Component/Elements/Text/Text";
import CSS from "./static/css/AlertItem.module.css";

const AlertItem = (
    {
        title,
        content,
        push_date,
        is_new,
        onClick,
    }:{
        title: string,
        content: string,
        push_date: string,
        is_new: boolean,
        onClick?: () => void,
    }
) => {
    const mainClassName = useMemo(() => {
        const RTN: string[] = [];

        if(onClick){
            RTN.push("g_click_event_item");
        }

        RTN.push(CSS.l_AlertItem_main);

        if(is_new){
            RTN.push(CSS.new);
        }

        return RTN.join(" ")
    }, [is_new, onClick])

    return (
        <div 
            className={mainClassName}
            onClick={onClick}
        >
            <div className={CSS.l_AlertItem_titleContainer}>
                <Text 
                    class_name_list={[CSS.l_AlertItem_text]}
                    size={"size1"}
                    bold={true}
                >
                    {title}
                </Text>
            </div>
            <div className={CSS.l_AlertItem_contentContainer}>
                <Text 
                    class_name_list={[CSS.l_AlertItem_text]}
                    size={"size1"}
                >
                    {content}
                </Text>
            </div>
            <div className={CSS.l_AlertItem_pushDateContainer}>
                <Text 
                    class_name_list={[CSS.l_AlertItem_text]}
                    size={"size0"}
                    color={"gray3"}
                >
                    {push_date}
                </Text>
            </div>
        </div>
    )
}

export default AlertItem;