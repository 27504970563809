import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { bytesToFormattedSize, customToast, dev_console, estimateDownloadTime, getAccountDataClass, getMobileOperatingSystem, kilobytesToMB, whichBrowserDoYouUse } from "../../../../../Common/ts/commonTools";
import streamSaver from "streamsaver";
import useFileSizeREST from "../../../hooks/useFileSizeREST.hook";
import { BROWSER_CODE } from "../../../../../Common/ts/const";
import { webViewBridgeClass } from "../../../../../Common/ts/appTools";

const webViewBridge = new webViewBridgeClass();
const getAccountData = new getAccountDataClass();

const useImageZipDownloadREST = (
    {
        download_type,
        photo_upload_id,
        file_name,
    }:{
        download_type: number;
        photo_upload_id: number;
        file_name: string;
    }
) => {
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    
    // 서버로부터 이미지를 직접 요청하는 함수
    const callImageData = async () => {
        const RTN:{
            sign: boolean,
            data: {
                response: Response | null, 
                content_length: string | null
            }
            msg: string;
        } = {
            sign: false,
            data: {
                response: null, 
                content_length: null,
            },
            msg: "",
        }

        try{
            const m_apiUrl = process.env.REACT_APP_API_URL;        
            const m_token = getAccountData.token();
            const m_endpointUrl = `api/v3/customers/photo-download?token=${m_token}&download_type=${download_type}&preview_id=${photo_upload_id}&file_name=${file_name}`;
            const m_finalUrl =  `${m_apiUrl}/${m_endpointUrl}`;

            dev_console.log("useImageZipDownloadREST callImageData m_finalUrl ===>", m_finalUrl);

            requsetImageData: {
                // flutter web_view환경에서 이미지 다운로드를 위해 보내는 요청
                const m_setDownloadUrl_response = await webViewBridge.setDownloadUrl(m_finalUrl);

                // flutter 환경일경우는 flutter에서 대신 처리하므로 동작을 종료시킨다.
                if(m_setDownloadUrl_response.sign){
                    RTN.sign = true;
                    RTN.msg = "webViewBridge setDownloadUrl is working";
                    break requsetImageData;
                }

                const m_fetch_data = () => {
                    return fetch(m_finalUrl, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${m_token}`,
                        }
                    })
                }
    
                const response = await toast.promise(
                    m_fetch_data, 
                    {
                        pending: "다운로드를 준비 중입니다. 잠시 기다려주세요.",
                        success: "다운로드 준비가 완료되었습니다.",
                        error: "다운로드를 준비하는 과정에서 문제가 발생했습니다."
                    }
                )

                dev_console.log("useImageZipDownloadREST callImageData reponse ===>", response);

                if(!response){
                    RTN.sign = false;
                    RTN.data = {response: null, content_length: null};
                    RTN.msg = "callImageData response is NULL";
                    break requsetImageData;
                }
    
                const content_length = response.headers.get('content-length');
    
                dev_console.log("useImageZipDownloadREST callImageData content_length ===>", content_length);

                RTN.sign = true;
                RTN.data = {response, content_length}
                RTN.msg = "success"; 
            }
        }catch(error){
            dev_console.error(error);
            customToast.error({msg: "서버로부터 사진 데이터를 호출하는데 실패했습니다."});

            RTN.sign = false;
            RTN.data = {response: null, content_length: null};
            RTN.msg = `${error}`;
        }

        dev_console.log("useImageZipDownloadREST callImageData RTN ===>", RTN);

        return RTN;
    } // callImageData

    const saveFileStream = (file_name: string, content_length: string | null) => {
        try{
            const fileStream = streamSaver.createWriteStream(`${file_name}.zip`, {
                size: content_length ? parseInt(content_length, 10) : undefined,
            });

            return fileStream;
        }catch(error){
            dev_console.error(error);
            customToast.error({msg: "압축파일을 생성하는 과정에서 문제가 발생했습니다."})

            return null;
        }
    }

    const doFileStream = async (response: Response, fileStream: WritableStream<Uint8Array>) => {
        try{
            if(!response.body || !window.WritableStream || !response.body.getReader) {
                throw new Error("iOS Safari 환경에서 Stream API가 지원되지 않을 수 있습니다.");
            }

            if(response.body){
                const readableStream = response.body;
        
                if(window.WritableStream && readableStream.pipeTo){
                    await readableStream.pipeTo(fileStream);
                }else{
                    const writer = fileStream.getWriter();
                    const reader = readableStream.getReader();

                    const pump = async () => {
                        try{
                            const { done, value } = await reader.read();
                            if(done){
                                writer.close();
                                return;
                            }
                            await writer.write(value!);
                            await pump();
                        }catch(error){
                            alert("파일 저장 중 문제가 발생했습니다.");
                            writer.abort(error);
                            throw error;
                        }
                    };
                    await pump();
                }
            }
        }catch(error){
            dev_console.error(error);

            customToast.error({
                msg: "압축파일을 저장하는 과정에서 문제가 발생했습니다."
            })
        }
    }

    // a테그 클릭 방식의 다운로드 핸들러
    const handleDownload_for_kakao = () => {
        if(!window.confirm("파일을 다운로드하시겠습니까?\n 다운로드가 완료될 때까지 기다려주세요.(최대 10분)")){
            return ;
        }

        const m_apiUrl = process.env.REACT_APP_API_URL;        
        const m_token = getAccountData.token();
        const m_endpointUrl = `api/v3/customers/photo-download?token=${m_token}&download_type=${download_type}&preview_id=${photo_upload_id}&file_name=${file_name}`;
        const m_finalUrl =  `${m_apiUrl}/${m_endpointUrl}`;

        window.location.href = m_finalUrl;
    }

    // 다운로드 버튼 핸들러
    const handleDownload = async () => {
        if(isDownloading){
            alert("다운로드가 진행 중입니다.");
            return ;
        }

        if(!window.confirm("사진들을 다운로드하시겠습니까?")){
            return ;
        }

        setIsDownloading(true);

        const m_callImageData_response = await callImageData();

        dev_console.log("useImageZipDownloadREST handleDownload m_callImageData_response ===>", m_callImageData_response);

        setIsDownloading(false);

        // 플루터 환경이 아닌데도 이미지 정보가 없다면...
        if(!webViewBridge.flutterInAppWebView && !(m_callImageData_response.data.response && m_callImageData_response.data.response.body)){
            customToast.error({
                msg: "사진 다운로드 기능을 지원하지 않는 브라우저입니다."
            })

            return ;
        }
    
        // 응답 헤더에 있는 컨텐츠 전체 길이값이 있으면 스트리밍을 처리하도록했는데...
        // 이 값은 브라우저에서 남은 다운로드 시간을 표시하는데 사용될 뿐, 사용하지 않더라도 다운로드 기능 자체 이용에는 문제가 없다.
        // 따라서 해당 조건문은 주석처리한다.
        // if(m_callImageData_response.data.content_length){
            const fileStream =  saveFileStream(file_name ,m_callImageData_response.data.content_length);
            if(!fileStream){
                return ;
            }

            if(m_callImageData_response.data.response){
                // 파일 스트리밍 처리 시작
                await doFileStream(m_callImageData_response.data.response, fileStream);
            }
        // }
    }

    const downloadingEndHandler = useCallback(() => {
        setIsDownloading(false);
    }, [])

    const {
        fileSizeData,
    } = useFileSizeREST({
        preview_id: photo_upload_id,
        type: download_type,
    });

    const file_size = bytesToFormattedSize(fileSizeData);
    const file_size_mb = kilobytesToMB(fileSizeData);
    const download_time = estimateDownloadTime(file_size_mb);

    const l_browserCode = whichBrowserDoYouUse();

    return({
        isDownloading,

        downloadHandler: 
            (
                // 현재 KakaoTalk, LINE인앱 브라우저를 사용할때는 외부 브라우저로 열리게 처리해두긴했다...
                // 한마디로 KakaoTalk와 LINE조건 확인은 일종의 예외처리용이다.
                // iOS환경에서 Safari 브라우저를 사용할 경우는 a테그 클릭을 통해 다운받게 처리한다.(파일 스트리밍형식으로 다운로드 진행시 정확한 원인은 알 수 없지만 다운로드가 진행되지 않는다...)
                l_browserCode === BROWSER_CODE.KakaoTalk
                || l_browserCode === BROWSER_CODE.LINE
                || (l_browserCode === BROWSER_CODE.Safari && getMobileOperatingSystem() === "iOS")  // iOS Safari환경에서는 파일스트림 형식으로 다운로드를 할 시 사파리가 새로고침을 진행해버리는 문제가 있어서 a태그 형식으로 다운로드를 진행핸다.
            )
            ?   handleDownload_for_kakao
            :   handleDownload,
        
        downloadingEndHandler,
        
        file_size,
        
        download_time,
    })
}

export default useImageZipDownloadREST;