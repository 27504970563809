import { progressScheduleInterface, progressScheduleOfficeDataInterface } from "../../../static/interface/reservationHome.interface";
import { dateObjectManagerClass } from "../../../../../Common/ts/commonTools";

const dateObjectManager = new dateObjectManagerClass();

const useReservationInfoCard = (
    {
        scheduleData
    }:{
        scheduleData: progressScheduleInterface;
    }
) => {

    let contract_info: string = "";
    let theme: string = "";
    let office_name: string = "";
    if(!!scheduleData.detail){
        if(scheduleData.detail.contract_id){
            contract_info = `[${scheduleData.detail.contract_id}]`
        }
        contract_info += `${scheduleData.package_name}`;

        theme = scheduleData.detail.thema_list_name.join(",") || "";
        
        office_name = scheduleData.detail.office_name || "";
    }

    const dateObject = new Date(scheduleData.schedule_date);

    let schedule_date_string:string = "";
    if(isNaN(dateObject.getTime())){
        schedule_date_string = "일정 없음";
    }else{
        const date_string = dateObjectManager.customFormatDate(dateObject, "yyyy년 MM월 dd일");
        const weekday_string = dateObjectManager.getWeekday(dateObject);
        schedule_date_string = `${date_string} (${weekday_string})`;
    }

    let m_timeLength: number = 0;
    
    if(scheduleData.detail){
        m_timeLength = dateObjectManager.getMinuteDifference(`${scheduleData.schedule_date} ${scheduleData.detail.photo_start}`, `${scheduleData.schedule_date} ${scheduleData.detail.photo_end}`)
    }

    const photo_time = `${scheduleData.detail?.photo_start || ""} ~ ${scheduleData.detail?.photo_end || ""} (${m_timeLength}분소요)`

    const memo = scheduleData.detail?.memo || "";

    let office_info: progressScheduleOfficeDataInterface = {
        schedule_id: 0,
        office_id: 0,
        office_name: "",
        office_addr: "",
        office_addr_detail: "",
    };

    if(scheduleData.office_data && scheduleData.office_data.length > 0){
        office_info = {
            ...scheduleData.office_data[0]
        }
    }

    const m_d_day = dateObjectManager.getDaysDifferenceFromToday(scheduleData.schedule_date);

    return({
        d_day: m_d_day,
        photo_name: scheduleData.photo_name,
        photo_type_nm: scheduleData.photo_type_nm || "",
        contract_info: scheduleData.package_name,
        schedule_date_string,
        photo_time,
        theme,
        office_info,
        memo,
    })
}

export default useReservationInfoCard;