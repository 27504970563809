import { useEffect } from "react";
import { photoDetailDataInterface } from "../static/interface/PhotoDetail.interface";
import { callAxios, customToast, dev_console, whoAmI } from "../../../Common/ts/commonTools";
import { useQuery } from "@tanstack/react-query";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";
import { RTN_interface } from "../../../Common/interface/App.interface";

const TOAST_ID = "usePhotoDetailDataREST";

const usePhotoDetailDataREST = (
    {
        photo_upload_id,
        main_type,
    }:{
        photo_upload_id: number,
        main_type: number;
    }
) => {
    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer()){
            if(!!photo_upload_id && !!main_type){
                    result = true;
            }
        }

        return result;
    }

    const {
        data: photoDetailData = null,
        error: photoDetailDataError,
        isLoading: photoDetailDataIsLoading,
        refetch: photoDetailDataRefetch,
    } = useQuery({
        queryKey: ["photoDetailData", photo_upload_id, main_type],
        queryFn: callPhotoDetailData,
        enabled: isAbleToRequest,
        select: (response) => {
            const RTN: RTN_interface<photoDetailDataInterface | null> = {
                sign: false,
                data: null,
                msg: "",
            };

            SetData:{

                if(!response){
                    RTN.msg = "촬영 사진의 데이터 요청 과정에서 문제가 발생했습니다.";
                    break SetData;
                }                
                
                if(response.status !== 200){
                    RTN.msg = "촬영 사진의 데이터 요청에 실패했습니다.";
                    break SetData;
                }

                RTN.sign = true;

                const response_data:photoDetailDataInterface[] = response.data.datas;
                if(response_data.length === 0){
                    break SetData;
                }

                RTN.sign = true;
                RTN.data = response_data[0];
            }

            if(!RTN.sign){
                customToast.error({
                    msg: "촬영 사진의 데이터 요청에 실패했습니다.",
                    toastId: TOAST_ID,
                });
            }

            return RTN.data;
        },
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
    })

    async function callPhotoDetailData(){
        const photo_detail_url = `api/v3/customers/photo_detail?photo_upload_id=${photo_upload_id}&main_type=${main_type}`;
        const response = await callAxios.api({
            url: photo_detail_url,
            method: "get",
        })

        dev_console.log(photo_detail_url);
        dev_console.log(response);

        return response;
    }

    useEffect(() => {
        if(!!photoDetailDataError){
            dev_console.error(photoDetailDataError);
            customToast.error({
                msg: "촬영 사진의 데이터 요청 중 문제가 발생했습니다.",
                toastId: TOAST_ID,
            });
        }
    }, [photoDetailDataError])

    return({
        photoDetailData,
        photoDetailDataIsLoading,
        photoDetailDataError,
        photoDetailDataRefetch,
    })
}

export default usePhotoDetailDataREST;