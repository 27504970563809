import CSS from "./static/css/header.module.css";
import NaviFunctionBtn from "../Navi/components/NaviFunctionBtn/NaviFunctionBtn";
import { useEffect, useState } from "react";
import MainTitle from "../../Component/Elements/MainTitle/MainTitle";
import UserFunctionBtn from "./components/UserFunctionBtn/UserFunctionBtn";
import { mobileBackgroundColorType } from "../../Common/interface/style.interface";
import useAlarmNewCountREST from "./hooks/useAlarmNewCountREST.hook";
import AlertIconBtn from "./components/AlertIconBtn/AlertIconBtn";

const Header = (
    {
        setIsOpenMenu,
        headerLeftNode,
        headerRightNode,
        mobileHeaderTitle,
        headerBackgroundColorPreset,
    }:{
        setIsOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
        headerLeftNode: React.ReactNode;
        headerRightNode: React.ReactNode;
        mobileHeaderTitle: string;
        headerBackgroundColorPreset: mobileBackgroundColorType;
    }
) => {
    const {
        alarmNewCountData,
        alarmNewCountIsLoading,
        alarmNewCountError,
        alarmNewCountRefetch,
    } = useAlarmNewCountREST();

    const classNameSetter = () => {
        const RTN: string[] = [CSS.l_header_main];

        if(headerBackgroundColorPreset){
            RTN.push(CSS.background_color_preset);
            RTN.push(CSS[headerBackgroundColorPreset]);
        }

        return RTN;
    }

    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "));
    }, [headerBackgroundColorPreset])

    return (
        <header className={mainClassName}>
            <div className={`${CSS.l_header_left}`}>
                {
                    headerLeftNode
                    ||  <NaviFunctionBtn
                            onClick={() => {
                                setIsOpenMenu((is_open_menu)=>!is_open_menu);
                            }}
                        />
                }
            </div>

            <MainTitle 
                title={mobileHeaderTitle}
                isHeaderTitle={true}
            />

            <div className={`${CSS.l_header_right}`}>
                {
                    headerRightNode
                    ||  <>
                            <AlertIconBtn
                                is_active={alarmNewCountData > 0}
                            />
                            <UserFunctionBtn />
                        </>
                }
            </div>
        </header>
    )
}

export default Header;