import CSS from "./static/css/Text.module.css";
import { useEffect, useState } from "react";
import { textColorType, textPresetType } from "./interface/Text.interface";
import { isHiddenType } from "../../../Common/interface/Common.interface";
import { isHiddenClassNameSetter } from "../../../Common/ts/commonTools";
import Copy from "../Icon/Copy/Copy";

const Text = (
    {
        children,
        size,
        style,
        color,
        class_name_list,
        onClick,
        bold,
        text_wrap,
        is_title,
        is_text_truncation_on,
        preset,
        justify_content,
        isHidden,
        is_view_essential_icon,
        copy_function_target_text,
        copy_message,
        text_decoration,
    }:{
        children?: React.ReactNode;
        size?: "size0" | "size1" | "size2" | "size3" | "size4" | "size5" | "size6" | "size7"; //  디자인 적용이 모두 완료됬을떈 사용하지 않을 기능. 제거해야한다.
        style?: "italic";
        color?: textColorType;
        class_name_list?: string[];
        onClick?: React.MouseEventHandler<HTMLDivElement>;
        bold?: boolean;
        text_wrap?: "nowrap" | "wrap" | "balance" | "pretty";
        is_title?: boolean;
        is_text_truncation_on?: boolean
        preset?: textPresetType;
        justify_content?: "start" | "center" | "end";
        isHidden?: isHiddenType;
        is_view_essential_icon?: boolean;
        copy_function_target_text?: string;
        copy_message?: string;
        text_decoration?: "underline";
    }
) => {
    const [totalClassName, setTotalClassName] = useState<string>(classNameSetter().join(" "));

    function classNameSetter(){
        let RTN: string[] = [];

        if(onClick){
            RTN.push("g_click_event_item");
        }

        if(isHidden){
            RTN = isHiddenClassNameSetter({
                classNameList: RTN,
                isHidden,
                display: "flex"
            })
        }
    
        if(class_name_list){
            for(const class_name of class_name_list){
                RTN.push(class_name);
            }
        }
    
        RTN.push(CSS.l_text);
    
        if(size){
            RTN.push(CSS[size]);
        }
    
        if(color){
            RTN.push(CSS.color);
            RTN.push(CSS[color]);
        }
    
        if(bold){
            RTN.push(CSS.bold);
        }
    
        if(style){
            RTN.push(CSS.style);
        }
    
        if(text_wrap){
            RTN.push(CSS.text_wrap);
            RTN.push(CSS[text_wrap]);
        }
    
        if(is_title){
            RTN.push(CSS.is_title);
        }
    
        if(is_text_truncation_on){
            RTN.push(CSS.text_truncation);
        }
    
        if(preset){
            RTN.push(CSS.preset);
            RTN.push(CSS[preset]);
        }

        if(justify_content){
            RTN.push(CSS.justify_content);
            RTN.push(CSS[justify_content]);
        }

        if(text_decoration){
            RTN.push(CSS.text_decoration);
            RTN.push(CSS[text_decoration]);
        }

        return RTN;
    }

    useEffect(() => {
        setTotalClassName(classNameSetter().join(" "));
    }, [isHidden, size, color, style, class_name_list, onClick, text_wrap, is_title, is_text_truncation_on, bold, preset, justify_content, text_decoration])

    return(
        <div className={CSS.l_Text_main}>
            <div 
                className={totalClassName}
                onClick={onClick}
            >
                {/* 빈 문자열이 들어온경우에도 영역을 확보하기 위해 띄어쓰기 문자를 넣어줌. */}
                {children || '\u00A0'}
            </div>

            {
                copy_function_target_text
                &&  <Copy 
                        copy_target_text={copy_function_target_text} 
                        copy_message={copy_message}
                    />
            }

            {
                is_view_essential_icon
                &&  <div className={`${CSS.totalClassName} ${CSS.l_Text__essential_icon}`}>
                        *
                    </div>
            }
        </div>
    )
}

export default Text;