import CSS from "./static/css/CalendarIcon.module.css";
import calendar_icon from "./static/img/calendar_month_icon_20@2x.png";

const CalendarIcon = () => {
    return(
        <div className={CSS.l_CalendarIcon_main}>
            <img 
                src={calendar_icon}
                className={CSS.l_CalendarIcon_icon_img}
            />
        </div>
    )
}

export default CalendarIcon;