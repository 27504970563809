import { ChangeEvent, forwardRef } from "react";
import Input from "../../../Component/Elements/Input/Input";
import { addHyphens } from "../../../Common/ts/commonTools";

interface PhoneNumberInputInterface{
    value: string;
    changeNumberHandler?: (number: string) => void;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    disabled?: boolean;
    readOnly?: boolean;
}

const PhoneNumberInput = forwardRef<HTMLInputElement, PhoneNumberInputInterface>(({
    value,
    onBlur,
    changeNumberHandler,
    disabled,
    readOnly,
}, ref) => {
    const handlePhoneInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const formattedPhone = addHyphens(event.target.value);
        if(changeNumberHandler){
            changeNumberHandler(formattedPhone);
        }
    }

    return (
        <Input
            type={"text"}
            value={addHyphens(value)}
            onChange={changeNumberHandler ? handlePhoneInputOnChange : undefined}
            onBlur={onBlur}
            maxLength={14}
            ref={ref}
            disabled={disabled}
            readOnly={readOnly}
            placeholder={!readOnly && !disabled ? "스마트폰 번호 입력" : undefined}
        />
    )
})

export default PhoneNumberInput;