import { forwardRef, useMemo } from "react";
import noimg from "./static/img/noimage2.svg";
import CSS from "./static/css/img.module.css";

interface imgInterface{
    src?: string;
    id?: string;
    onClick?: React.MouseEventHandler<HTMLImageElement>
    loading?: "eager" | "lazy";
    title?: string,
    object_fit?: "cover" | "fill" | "contain" | "scale-down" | "none";
    height_standard?: boolean;
    class_name_list?: string[];
    customdata?: string;
    hidden?: boolean;
}

const Img = forwardRef<HTMLImageElement, imgInterface> ((
    {
        src,
        id,
        onClick,
        loading,
        title,
        object_fit,
        height_standard,
        class_name_list,
        customdata,
        hidden,
    }, ref
) => {

    const mainClassName = useMemo(() => {
        const m_class_name_list: string[] = [];

        if(class_name_list){
            for(const class_name of class_name_list){
                m_class_name_list.push(class_name)
            }
        }
        
        m_class_name_list.push(CSS.l_img_main)

        if(object_fit){
            m_class_name_list.push(CSS.object_fit);

            if(object_fit === "cover"){
                m_class_name_list.push(CSS.cover);
            }else if(object_fit === "fill"){
                m_class_name_list.push(CSS.fill);
            }else if(object_fit === "contain"){
                m_class_name_list.push(CSS.contain);
            }else if(object_fit === "scale-down"){
                m_class_name_list.push(CSS.scale_down);
            }else{
                m_class_name_list.push(CSS.none);
            }
        }

        if(height_standard){
            m_class_name_list.push(CSS.height_standard);
        }

        return m_class_name_list.join(" ");
    }, [object_fit, height_standard, class_name_list])

    return(
        <img
            className={mainClassName}
            ref={ref}
            id={id}
            src={src || noimg}
            // onLoad={(e) => {
            //     setIsLoaded(true);
            //     if(onLoad){
            //         onLoad(e);
            //     }
            // }}
            // onError={(e) => {
            //     setIsError(true);
            // }}
            onClick={onClick}
            loading={loading}
            title={title}
            draggable="false"
            data-customdata={customdata}
            onContextMenu={(e) => {
                e.preventDefault(); // 기본 메뉴 방지
            }}
            hidden={hidden}
        />
    )
})

export default Img;