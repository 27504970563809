import { MouseEventHandler } from "react";
import { PRODUCT_STATE_CODE_OBJ } from "./const";
import { productListFilterCodeType, productStateCodeType } from "../interface/ProductList.interface";
import { NavigateFunction } from "react-router-dom";
import { backPointUrlManagerClass, localStorageManagerClass } from "../../../../Common/ts/appTools";
import { addressDataInterface } from "../../../../Component/Elements/Postcode/interface/useDaumPostcode.interface";
import { albumDataInterface, recipientInfoInterface } from "../interface/common.interface";
import { getQueryParamsUrl } from "../../../../Common/ts/commonTools";

const backPointUrlManager = new backPointUrlManagerClass();

// 제품목록에서 제품을 클릭했을때 페이지를 이동하는 핸들러 함수를 생성하는 함수
export function getGotoPageClickEvent(
    {
        status_cd,
        contract_product_id,
        album_save_id,
        photo_upload_id,
        navigate,
        tab1,
    }:{
        status_cd: productStateCodeType;
        contract_product_id: number;
        album_save_id: number | null;
        photo_upload_id: number | null;
        navigate: NavigateFunction
        tab1: productListFilterCodeType;
    }
){
    let RTN: MouseEventHandler<HTMLDivElement | HTMLButtonElement> = () => {}
    
    const l_back_point: string = backPointUrlManager.encoded({url: `/productList?tab1=${tab1}`});

    const l_productDetail_url = getQueryParamsUrl({
        main_url: "/productDetail",
        queryParams: {
            contract_product_id: `${contract_product_id || ""}`,
            album_save_id: `${album_save_id || ""}`,
            photo_upload_id: `${photo_upload_id || ""}`,
            back_point: l_back_point,
        }
    })

    const l_productRequestEdit_url = getQueryParamsUrl({
        main_url: "/productRequestEdit",
        queryParams: {
            contract_product_id: `${contract_product_id || ""}`,
            album_save_id: album_save_id !== null ? `${album_save_id}` : "",
            photo_upload_id: photo_upload_id !== null ? `${photo_upload_id}` : "",
            back_point: l_back_point,
        }
    })

    switch(status_cd){
        case PRODUCT_STATE_CODE_OBJ.application_not_possible:
            break;
        case PRODUCT_STATE_CODE_OBJ.application_possible:
            RTN = () => {
                navigate(l_productRequestEdit_url)
            }
            break;
        case PRODUCT_STATE_CODE_OBJ.application_in_progress:
            RTN = () => {
                navigate(l_productDetail_url)
            }
            break;
        case PRODUCT_STATE_CODE_OBJ.editing:
            RTN = () => {
                navigate(l_productDetail_url)
            }
            break;
        case PRODUCT_STATE_CODE_OBJ.confirm_request:
            RTN = () => {
                navigate(l_productDetail_url)
            }
            break;
        case PRODUCT_STATE_CODE_OBJ.edit_end:
            RTN = () => {
                navigate(l_productDetail_url)
            }
            break;
        case PRODUCT_STATE_CODE_OBJ.confirm_rejected:
            RTN = () => {
                navigate(l_productDetail_url)
            }
            break;
    }

    return RTN;
}

// 저장소에 제품제작신청관련 입력한 정보 저장 및 불러오기를 관리하는 클래스
// 서버에서 주소를 관리하기 시작하면 사용할 일이 없을것이다.
export class userDataStorageManagerClass{
    localStorageManager: localStorageManagerClass;

    // babyInfo와 address를 속성으로 추가
    babyInfo: {
        get: () => albumDataInterface | null;
        set: (data: albumDataInterface) => any;
        remove: () => boolean;
    };

    address: {
        get: () => addressDataInterface | null;
        set: (data: addressDataInterface) => any;
        remove: () => boolean;
    };

    recipientInfo: {
        get: () => recipientInfoInterface | null;
        set: (data: recipientInfoInterface) => any;
        remove: () => boolean;
    };

    constructor() {
        this.localStorageManager = new localStorageManagerClass(this);
        
        // babyInfo 객체 정의
        this.babyInfo = {
            get: () => {
                const RTN = this.localStorageManager.get<albumDataInterface>("babyInfo");

                // JSON.parse로 복구하더라도 Date객체는 문자열인상태로 남아있게된다. 따라서 이를 복구하는 로직이 별도로 필요하다.
                if(RTN && RTN.baby_birth){
                    let baby_birth: Date = new Date(RTN.baby_birth);
                    
                    if(isNaN(baby_birth.getTime())){
                        RTN.baby_birth = null;
                    }else{
                        RTN.baby_birth = baby_birth;
                    }
                }

                if(RTN && RTN.baby_birth_time){
                    let m_baby_birth_time: Date = new Date(RTN.baby_birth_time);

                    if(isNaN(m_baby_birth_time.getTime())){
                        RTN.baby_birth_time = null;
                    }else{
                        RTN.baby_birth_time = m_baby_birth_time;
                    }
                }

                return RTN;
            },
            set: (data: albumDataInterface) => {
                return this.localStorageManager.set<albumDataInterface>({
                    key: "babyInfo",
                    data,
                });
            },
            remove: () => {
                return this.localStorageManager.remove("babyInfo");
            },
        };

        // address 객체 정의
        this.address = {
            get: () => {
                return this.localStorageManager.get<addressDataInterface>("address");
            },
            set: (data: addressDataInterface) => {
                return this.localStorageManager.set<addressDataInterface>({
                    key: "address",
                    data,
                });
            },
            remove: () => {
                return this.localStorageManager.remove("address");
            },
        };

        // 받는이 정보
        this.recipientInfo = {
            get: () => {
                return this.localStorageManager.get<recipientInfoInterface>("recipientInfo");
            },
            set: (data: recipientInfoInterface) => {
                return this.localStorageManager.set<recipientInfoInterface>({
                    key: "recipientInfo",
                    data: {
                        recipient_name: data.recipient_name,
                        recipient_phone: data.recipient_phone.replaceAll("-", ""),
                    },
                });
            },
            remove: () => {
                return this.localStorageManager.remove("recipientInfo");
            },
        }
    }
}