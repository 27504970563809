import { useEffect, useState } from "react";
import CSS from "./static/css/flex.module.css";

const Flex = (
    {
        class_name_list,
        children,
        preset
    }:{
        class_name_list?: string[];
        children?: React.ReactNode;
        preset?: "ImageGallery" | "selected_theme_list" | "all_theme_item_list" | "OptionList" | "photo_or_product_list"
    }
) => {

    const classNameSetter = () => {
        const m_total_class_list: string[] = [];

        if(class_name_list){
            for(const class_name of class_name_list){
                m_total_class_list.push(class_name);
            }
        }

        m_total_class_list.push(CSS.l_Flex_main);

        if(preset){
            m_total_class_list.push(CSS.preset);
            m_total_class_list.push(CSS[preset]);
        }

        return m_total_class_list;
    }

    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "));
    }, [class_name_list, preset])

    return (
        <div className={mainClassName}>
            {children}
        </div>
    )
}

export default Flex;