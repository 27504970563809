import { useEffect, useState } from "react";
import CSS from "./static/css/modal.module.css";

const Modal = (
    {
        isModalOpen,
        modalCloseHandler,
        classNameList,
        children,
    }:{
        isModalOpen: boolean;
        modalCloseHandler?: () => void;
        classNameList?: string[];
        children?: React.ReactNode;
    }
) => {

    const classNameSetter = () => {
        const RTN: string[] = [];

        if(!!classNameList){
            for(const class_name of classNameList){
                RTN.push(class_name);
            }
        }

        RTN.push(CSS.l_modal);

        return RTN;
    }

    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "))
    }, [classNameList])

    const backBtnHandler = () => {
        if(modalCloseHandler){
            modalCloseHandler();
        }
    };

    // 모달이 열려있을 때 뒤로가기버튼을 통해 모달이 닫힐 수 있도록 처리
    useEffect(() => {

        // 로딩창 출력의 목적으로 모달이 출력된 경우에도 해당 기능은 예외없이 동작하므로, 뒤로가기버튼 자체가 막힐것이다.(로딩창에선 modalCloseHandler 자체가 없기때문에)
        // 로딩창은 벗어나지 않는것이 좋다고판단되므로 유지한다.
        if(isModalOpen){
            window.history.pushState(null, "", window.location.href);
            window.addEventListener("popstate", backBtnHandler);
        }else{
            window.removeEventListener('popstate', backBtnHandler);
        }

        return(() => {
            window.removeEventListener('popstate', backBtnHandler);
        })
        
    }, [isModalOpen])

    if(isModalOpen){
        return (
            <div 
                className={mainClassName}
                onClick={backBtnHandler}
            >
                <div 
                    className={CSS.l_modal__children_container}
                    onClick={(event) => {
                        // 모달 본문 내용을 클릭했을때 backgroundClickEventHandler가 동작하는것을 막기 위해 이벤트의 전파를 차단함
                        event.stopPropagation();
                    }}
                >
                    {children}
                </div>
            </div>
        )
    }else{
        return null;
    }
}

export default Modal