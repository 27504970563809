import { useEffect, useState } from "react";
import { dateObjectManagerClass } from "../../../Common/ts/commonTools";
import { joinedEventType } from "../static/interface/PhotoList.interface";

const useEventApplication = (
    {
        upload_date,
        joined_event,
        isEventApplication,
    }:{
        upload_date: string;
        joined_event: joinedEventType,
        isEventApplication: boolean;
    }
) => {

    const [state, setState] = useState<{state:number, msg: string}>({
        state: 0,
        msg: "",
    });

    const dateObjectManager = new dateObjectManagerClass();

    useEffect(() => {

        const state_result: {state:number, msg: string} = {
            state: 0,
            msg: "",
        };

        if(isEventApplication){
            const today_date = new Date();
            const upload_datetime = new Date(upload_date);
    
            if(!isNaN(upload_datetime.getTime())){
                upload_datetime.setDate(upload_datetime.getDate() + 7);
                const init_today_date = dateObjectManager.removeTime(today_date);
                const init_upload_datetime = dateObjectManager.removeTime(upload_datetime);
    
                if(init_today_date && init_upload_datetime){
                    if(init_today_date <= init_upload_datetime){
                        if(joined_event === "N"){
                            state_result.state = 1;
                        }else{
                            state_result.state = -1;
                        }
                    }else{
                        state_result.state = -2;
                    }
                }else{
                    state_result.state = -3;
                }
            }else{
                state_result.state = -4;
            }
        }
        
        switch(state_result.state){
            case 1:
                state_result.msg = "이벤트 참여 버튼 출력 허용";
                break;
            case -1:
                state_result.msg = "이벤트에 참여 신청함";
                break;
            case -2:
                state_result.msg = "버튼 출력 가능 기간이 지남";
                break;
            case -3:
                state_result.msg = "날짜 비교를 위한 date객체 생성에 실패함";
                break;
            case -4:
                state_result.msg = "업데이트 날짜의 date객체 생성에 실패함";
                break;
            default :
                ;
        }

        setState(state_result);

    }, [upload_date, joined_event])

    return({
        state,
    })
}

export default useEventApplication;