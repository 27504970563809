import CSS from "./static/css/ContractAmountDetailPart.module.css"
import { useMediaQuery } from "react-responsive";
import Text from "../../../../../Component/Elements/Text/Text";
import { useMemo } from "react";
import CurrencyUnitText from "../CurrencyUnitText/CurrencyUnitText";

const ContractAmountDetailPart = (
    {
        className,
        label,
        content,
        justify_content,
        is_header,
    }:{
        className?: string;
        label: string;
        content: string;
        justify_content?: "flex_start" | "flex_end" | "center" | "space_between"
        is_header?: boolean;
    }
) => {
    const isMobile = useMediaQuery({ maxWidth: 700 });

    const mainClassName = useMemo(() => {
        const RTN: string[] = [];

        if(className){
            RTN.push(className);
        }

        RTN.push(CSS.l_ContractAmountDetailPart_main);

        if(justify_content){
            RTN.push(CSS.justify_content);
            RTN.push(CSS[justify_content]);
        }

        return RTN.join(" ");
    }, [className, justify_content])

    return(
        <div className={mainClassName}>
            {
                isMobile
                &&  <Text size={"size1"}>
                        {label}
                    </Text>
            }

            <CurrencyUnitText 
                pay={content}
                isMobile={isMobile}
                is_header={is_header}
            / >
        </div>
    )
}

export default ContractAmountDetailPart;