import { MouseEventHandler } from "react";
import Text from "../../../../../Component/Elements/Text/Text";
import CSS from "./static/css/MonthlyScheduleChangeBtn.module.css";
import { monthlyScheduleBtnCodeType } from "../../MonthlySchedule/static/interface/common.interface";
import useMonthlyScheduleChangeBtnSetter from "./hooks/useMonthlyScheduleChangeBtnSetter.hook";

const MonthlyScheduleChangeBtn = (
    {
        state,
        onClick
    }:{
        state: monthlyScheduleBtnCodeType;
        onClick: MouseEventHandler<HTMLDivElement>;
    }
) => {
    const {
        text,
        img_src,
    } = useMonthlyScheduleChangeBtnSetter({
        state
    })

    return(
        <div
            className={`g_click_event_item ${CSS.l_MonthlyScheduleChangeBtn_main}`}
            onClick={onClick}
        >
            <Text 
                class_name_list={[CSS.l_MonthlyScheduleChangeBtn_btnText]}
                color={"primary"}
            >
                {text}
            </Text>
            <div className={CSS.l_MonthlyScheduleChangeBtn_iconContainer}>
                <img 
                    className={CSS.l_MonthlyScheduleChangeBtn_iconImg}
                    src={img_src}     
                />
            </div>
        </div>
    )
}

export default MonthlyScheduleChangeBtn;