import { useMemo, useRef } from "react"
import CSS from "./static/css/BottomSheetControlBar.module.css"
import { isHiddenType } from "../../../../../Common/interface/Common.interface";
import { isHiddenClassNameSetter } from "../../../../../Common/ts/commonTools";

const BottomSheetControlBar = (
    {
        onClick,
        isHidden,
    }:{
        onClick?: React.MouseEventHandler<HTMLDivElement>;
        isHidden?: isHiddenType;
    }
) => {
    const touchStartY = useRef<number | null>(null);
    const SWIPE_THRESHOLD = 20; // 아래로 스와이프인지 판단하는 임계값 (px)

    const mainClassName = useMemo(() => {
        let RTN: string[] = [];      

        if(isHidden){
            RTN = isHiddenClassNameSetter({
                classNameList: RTN,
                isHidden,
                display: "flex"
            })
        }

        RTN.push("g_click_event_item");

        RTN.push(CSS.l_BottomSheetControlBar_main);

        return RTN.join(" ");
    }, [isHidden])


    // 터치 시작 시 Y좌표 저장
    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        touchStartY.current = e.touches[0].clientY;
    };

    // 터치 종료 시 Y좌표 차이를 계산하여 아래로 스와이프면 onClick 실행
    const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
        if(touchStartY.current === null){
            return;
        } 

        const deltaY = event.changedTouches[0].clientY - touchStartY.current;
        
        if(deltaY > SWIPE_THRESHOLD){
            if(onClick){
                // 터치 이벤트를 마우스 이벤트로 캐스팅하여 onClick 실행
                onClick(event as unknown as React.MouseEvent<HTMLDivElement>);
            }
        }
        touchStartY.current = null;
    };

    return(
        <div 
            className={mainClassName}
            onClick={onClick}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
        >
            <div className={CSS.l_BottomSheetControlBar__slide_bar} />
        </div>
    )
}

export default BottomSheetControlBar;