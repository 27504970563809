import { useMemo } from "react";
import CSS from "./static/css/AlertIcon.module.css";
import colorbell_icon_on from "./static/img/colorbell_icon_on_24@2x.png";
import colorbell_icon_off from "./static/img/colorbell_icon_off_24@2x.png";

const AlertIcon = (
    {
        onClick,
        is_active
    }:{
        onClick?: () => void;
        is_active: boolean;
    }
) => {
    const mainClassName = useMemo(() => {
        const RTN: string[] = [CSS.l_AlertIcon_main];

        if(onClick){
            RTN.push("g_click_event_item");
        }

        RTN.push(CSS.l_AlertIcon_main)

        return RTN.join(" ");
    }, [onClick])

    return(
        <div className={mainClassName}>
            <img 
                className={CSS.l_AlertIcon_img}
                src={is_active ? colorbell_icon_on : colorbell_icon_off} 
            />
        </div>
    )
}

export default AlertIcon;