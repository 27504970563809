import CSS from "./static/css/EditRequestView.module.css";
import LabelAndContent from "../../../../Component/Elements/LabelAndContent/LabelAndContent";
import LabelAndContentContainer from "../../../../Component/Elements/LabelAndContentContainer/LabelAndContentContainer";
import Textarea from "../../../../Component/Elements/Textarea/Textarea";

const EditRequestView = (
    {
        request
    }:{
        request: string;
    }
) => {
    return(
        <LabelAndContentContainer>
            <LabelAndContent
                label={"편집 요청 사항"}
                flex_direction={"column"}
            >
                <div className={CSS.l_EditRequestView_TextareaContainer}>
                    <Textarea 
                        value={request}
                        readOnly={true}
                    />
                </div>
            </LabelAndContent>
        </LabelAndContentContainer>
    )
}

export default EditRequestView;