import { ToastContainer, ToastPosition } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

const Toast = (
    {
        position
    }:{
        position?: ToastPosition
    }
) => {
    return(
        <ToastContainer 
            position={position}
            hideProgressBar={true}
        />
    )
}

export default Toast