import { MouseEventHandler } from "react";
import HistoryBackIcon from "../Icon/HistoryBackIcon/HistoryBackIcon";
import Text from "../Text/Text";
import CSS from "./static/css/HistoryBackBtn.module.css";
import { dev_console, isHiddenClassNameSetter } from "../../../Common/ts/commonTools";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getBackPath } from "../../../PublicPage/Header/static/ts/Header.tools";
import { backPointUrlManagerClass } from "../../../Common/ts/appTools";

const backPointUrlManager = new backPointUrlManagerClass();

const HistoryBackBtn = (
    {
        onClick
    }:{
        onClick?: MouseEventHandler<HTMLDivElement>;
    }
) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [URLSearchParams, SetURLSearchParams] = useSearchParams();

    const l_mobile_class_name_list = isHiddenClassNameSetter({
        classNameList: [],
        isHidden: "mobile"
    })

    const comeBackToParentsHandler = () => {
        let m_back_point_url: string | number = "/";

        BackPointSet:{
            const m_back_point = backPointUrlManager.getBackPointUrlAndDecode(URLSearchParams);
            // 특별히 이동해야할 뒤로가기 페이지가 설정된경우.
            if(m_back_point){
                m_back_point_url = m_back_point;
                break BackPointSet;
            }

            // 뒤로가기 위치가 정해진 특정 페이지인경우
            const m_reservate_back_point = getBackPath(location.pathname);
            if(m_reservate_back_point){
                m_back_point_url = m_reservate_back_point.parents;
                break BackPointSet;
            }

            // 일반적인 뒤로가기
            if(window.history.length > 0){
                m_back_point_url = -1;
                break BackPointSet;
            }

            // 뒤로갈 페이지가 없는경우(모바일환경에서 push메시지를 통해 들어온경우가 해당됨);
            m_back_point_url = "/";
        }

        if(typeof m_back_point_url === "number"){
            navigate(m_back_point_url);
        }else{
            navigate(m_back_point_url, {replace: true});
        }
    }

    return(
        <div 
            className={`g_click_event_item ${CSS.l_HistoryBackBtn_main}`}
            onClick={onClick || comeBackToParentsHandler}
        >
            <HistoryBackIcon />
            <Text
                class_name_list={l_mobile_class_name_list}
                preset={"history_back_button"}
            >
                목록으로 돌아가기
            </Text>
        </div>
    )
}

export default HistoryBackBtn;