import { viewerImageInterface } from '../../../ImageGallery/interface/ImageGallery.interface';
import { getFileType } from '../../../../../Common/ts/commonTools';
import Flex from '../../../Flex/Flex';
import { FILE_CODE } from '../../../../../Common/ts/const';
import { fileCodeType } from '../../../../../Common/interface/Common.interface';
import PhotoSwipeGalleryItem from "../PhotoSwipeGalleryItem/PhotoSwipeGalleryItem";
import MediaItemCard from '../../../MediaItemCard/MediaItemCard';

const ReactPhotoSwipeGalleryContents = (
    {
        img_list,
        isComfrimMode,
        viewport_width,
        viewport_height,
    }:{
        img_list: viewerImageInterface[];
        isComfrimMode?: boolean;
        viewport_width: number;
        viewport_height: number;
    }
) => {
    return(
        <Flex preset={"ImageGallery"}>
            {
                img_list.map((img_data, index) => {
                    
                    let file_type: fileCodeType = FILE_CODE.none;

                    if(img_data.file_type === FILE_CODE.img){
                        file_type = FILE_CODE.img;
                    }else if(img_data.file_type === FILE_CODE.video){
                        file_type = FILE_CODE.video;
                    }else if(img_data.file_type === FILE_CODE.zip){
                        file_type = FILE_CODE.zip;
                    }else{
                        file_type = getFileType(img_data.origin_url);
                    }

                    return(
                        <PhotoSwipeGalleryItem
                            key={index}
                            file_type={file_type}
                            origin_url={img_data.origin_url}
                            thumbnail_url={img_data.thumbnail_url}
                            viewport_width={viewport_width}
                            viewport_height={viewport_height}
                        >
                            <MediaItemCard 
                                src={img_data.thumbnail_url}
                                is_highlight={false}
                                fileName={img_data.file_name}
                                isComfrimMode={isComfrimMode}
                            />
                        </PhotoSwipeGalleryItem>
                    )
                })
            }
        </Flex>
    )
}

export default ReactPhotoSwipeGalleryContents;