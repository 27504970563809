import { useMemo } from "react";
import CSS from "./static/css/DescriptionIcon.module.css";
import description_icon from "./static/img/description_icon_30@3x.png";

const DescriptionIcon = (
    {
        className,
        size,
    }:{
        className?: string;
        size?: "small" | "medium";
    }
) => {
    const mainClassName = useMemo(function classNameSetter(){
        const RTN: string[] = [];

        if(className){
            RTN.push(className);
        }

        RTN.push(CSS.l_DescriptionIcon_main);

        if(size){
            RTN.push(CSS.size);
            RTN.push(CSS[size]);
        }

        return RTN.join(" ");
    }, [className, size])
    
    return(
        <div className={mainClassName}>
            <img 
                className={CSS.l_Img}
                src={description_icon}
            />
        </div>
    )
}

export default DescriptionIcon;