import { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { customParseInt } from "../../Common/ts/commonTools";
import { schedulePhotoProductThemeInterface, themaInterface, themeItemOnClickHandlerType, viewingMobilePageCodeType } from "./static/interface/selectTheme.interface";
import ThemeListContainer from "./components/ThemeListContainer/ThemeListContainer";
import useThemeDataREST from "./hooks/useThemeDataREST.hook";
import ShootList from "./components/ShootList/ShootList";
import ThemeList from "./components/ThemeList/ThemeList";
import ThreeSplitPages from "../../Component/Elements/Page/ThreeSplitPages/ThreeSplitPages";
import MainPage from "../../Component/Elements/Page/MainPage/MainPage";
import { VIEWING_MOBILE_PAGE_CODE } from "./static/ts/const";
import LoopAnimationModal from "../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import SplitPagesContainer from "../../Component/Elements/Page/ThreeSplitPages/components/SplitPagesContainer/SplitPagesContainer";
import { commonContext } from "../../App";
import SelectThemeController from "./components/SelectThemeController/SelectThemeController";
import HistoryBackBtn from "../../Component/Elements/HistoryBackBtn/HistoryBackBtn";

const SelectTheme = () => {
    const [readOnly, setReadOnly] = useState<boolean>(false);

    // 촬영 목록의 각 항목에 할당되는 ref들의 배열
    const l_shootListRef = useRef<Array<HTMLDivElement | null>>([]);

    // 선택할 수 있는 테마들 목록의 각 항목에 할당되는 ref들의 배열
    const l_AllThemeListRef = useRef<Array<HTMLDivElement | null>>([]);
    
    // 선택할 수 있는 테마들 목록을 출력하는 영역에 할당되는 ref
    const l_AllTheme_div_ref = useRef<HTMLDivElement | null>(null);
    
    const [topEleIndex, setTopEleIndex] = useState<number>(-1);

    // 모바일 환경에서의 페이지 설정
    const [viewingMobilePage, setViewingMobilePage] = useState<viewingMobilePageCodeType>(VIEWING_MOBILE_PAGE_CODE.info);
    
    // 모바일 환경에서의 테마 선택 페이지 출력에 필요한 data
    const [selectedSchedulePhotoProductId, setSelectedSchedulePhotoProductId] = useState<number>(0);

    // 테마 선택 동작을 위해 별도로 분리시킨 데이터. -> 선택가능한 테마 목록을 출력시키거나, 선택한 테마 데이터를 관리하는것 모두 여기서 처리한다.
    // 만약 선택한 테마의 초기화가 필요하다면 아래 useThemeDataREST훅에서 받아온 themeData를 통해 초기화를 진행한다.
    const [photoProductList, setPhotoProductList] = useState<schedulePhotoProductThemeInterface[]>([]);
    
    const [URLSearchParams, SetURLSearchParams] = useSearchParams();

    let l_schedule_id = customParseInt(URLSearchParams.get("schedule_id"));

    // 촬영 정보가 없는 경우 이전페이지로 되돌아가게한다.
    if(!l_schedule_id){
        if(window.history.length > 1){
            window.history.back();
        }else{
            window.location.href = "/";
        }
    }

    // 데이터 검증이 끝난경우 타입을 고정시켜줌
    l_schedule_id  = l_schedule_id as number;

    const l_commonContext = useContext(commonContext);

    const {
        themeData,
        themeDataIsLoading,
    } = useThemeDataREST({
        schedule_id: l_schedule_id,
    });

    // 선택한 테마에 추가
    const addThemeHandler: themeItemOnClickHandlerType = ({
        selected_item,
        schedule_photo_product_id,
    }) => {
        // 테마를 추가하려는 촬영 상품을 기존에 선택된 목록에서 특정한다.
        const m_photo_product = photoProductList.find((item) => {
            return item.schedule_photo_product_id === schedule_photo_product_id;
        })

        // 테마를 추가하려는 촬영 상품이 없다면 동작시키지 않는다.
        if(!m_photo_product){
            return ;
        }

        // 복사본을 생성한다.
        const m_new_selected_theme_list: themaInterface[] = [...m_photo_product.selected_theme_list]

        // 기존의 촬영 상품의 선택된 테마 목록에서 테마의 id만을 추출하여 배열로 만든다.
        const m_selected_theme_id_list = m_new_selected_theme_list.map((item) => item.thema_id);
        // 새로 추가하려는 테마상품의 테마 id값이 기존에 존재했는지, 존재 했다면 어느 순서로 있는지 순서의 가장 마지막 위치를 확인한다.
        const m_last_item_index = m_selected_theme_id_list.lastIndexOf(selected_item.thema_id);

        // 추가하려는 테마가 기존에 없던테마면 맨 뒤에 삽입하고, 존재한다면 존재하는 테마들의 순서를 유지한채 추가한다.
        if(m_last_item_index === -1){
            m_new_selected_theme_list.push(selected_item);
        }else{
            if(window.confirm("이미 선택한 테마입니다. 같은 테마를 추가로 선택하시겠습니까?")){
                m_new_selected_theme_list.splice(m_last_item_index, 0, selected_item);
            }
        }

        setPhotoProductList((prev) => {
            return(
                prev.map((prev_item) => {
                    if(prev_item.schedule_photo_product_id === schedule_photo_product_id){
                        return {
                            ...prev_item,
                            selected_theme_list: m_new_selected_theme_list,
                        }
                    }else{
                        return prev_item;
                    }
                })
            )
        })
    }

    // 선택한 테마에서 제거
    const subThemeHandler: themeItemOnClickHandlerType = ({
        selected_item,
        schedule_photo_product_id,
    }) => {

        // 테마를 추가하려는 촬영 상품을 기존에 선택된 목록에서 특정한다.
        const m_photo_product = photoProductList.find((item) => {
            return item.schedule_photo_product_id === schedule_photo_product_id;
        })

        // 테마를 추가하려는 촬영 상품이 없다면 동작시키지 않는다.
        if(!m_photo_product){
            return ;
        }

        if(!window.confirm(`${selected_item.thema_name} 테마를 제거하시겠습니까?`)){
            return ;
        }

        const m_new_selected_theme_list = [...m_photo_product.selected_theme_list];

        const m_selected_theme_id_list = m_photo_product.selected_theme_list.map((item) => item.thema_id);
        const m_last_item_index = m_selected_theme_id_list.lastIndexOf(selected_item.thema_id);

        if(m_last_item_index > -1){
            m_new_selected_theme_list.splice(m_last_item_index, 1);
        }

        setPhotoProductList((prev) => 
            prev.map((prev_item) => {
                if(prev_item.schedule_photo_product_id === m_photo_product.schedule_photo_product_id){
                    return {
                        ...prev_item,
                        selected_theme_list: m_new_selected_theme_list,
                    }
                }else{
                    return prev_item;
                }
            })
        )
    }

    // 모바일 환경에서 테마선택 페이지로 전환시키는 함수
    const mobileGoToSelectThemePage = (schedule_photo_product_id: number) => {
        setViewingMobilePage(VIEWING_MOBILE_PAGE_CODE.selectTheme);

        setSelectedSchedulePhotoProductId(schedule_photo_product_id);
    }

    const mobileGoToThemeInfoPage = () => {
        setViewingMobilePage(VIEWING_MOBILE_PAGE_CODE.info);
    }

    // 항목을 클릭했을 때 해당항목 항목이 있는 위치로 스크롤을 이동시키는 함수
    const itemClickHandler = (index: number) => {
        const m_AllThemeListRef_current = l_AllThemeListRef.current;
        if(m_AllThemeListRef_current){
            const item = m_AllThemeListRef_current[index] as HTMLElement;
            if(item){
                item.scrollIntoView({
                    behavior: "smooth", // 부드러운 스크롤
                    block: "start",  
                })
            }
        }
    }

    // 모바일 환경에서 헤더에 출력되는 뒤로가기 버튼을 별도로 컨트롤
    useEffect(() => {
        // 헤더의 오른쪽 컴포넌트가 출력되는것을 막는다.
        // 왜 여기서 숨기는 동작을 처리했는가 하면...
        // viewingMobilePage가 변화는 과정에서 기존의 MainPage가 소멸되고 새로운 MainPage가 생성되는데,
        // 이 때 헤더 설정이 초기화되므로 다시 설정해준다...
        l_commonContext.setHeaderRightNode(<></>);

        if(viewingMobilePage === VIEWING_MOBILE_PAGE_CODE.info){
            l_commonContext.setHeaderLeftNode(null);
        }else if(viewingMobilePage === VIEWING_MOBILE_PAGE_CODE.selectTheme){
            l_commonContext.setHeaderLeftNode(
                <HistoryBackBtn
                    onClick={() => {
                        setViewingMobilePage(VIEWING_MOBILE_PAGE_CODE.info);
                    }}
                />
            );
        }
    }, [viewingMobilePage])

    useEffect(() => {
        // 헤더의 오른쪽 컴포넌트가 출력되는것을 막는다.
        // 왜 여기서 숨기는 동작을 처리했는가 하면...
        // themeData가 업데이트될 때 페이지가 리렌더링되면서 오른쪽 헤더가 다시 나와버린다...
        // 사실상 헤더컴포넌트의 심각한 설계 오류로 인해 이렇게 만들었다
        l_commonContext.setHeaderRightNode(<></>);

        if(themeData){
            let is_read_only: boolean = false;
            for(const schedule_photo_product of themeData.schedule_photo_product_list){
                if(schedule_photo_product.selected_theme_list.length > 0){
                    is_read_only = true;
                    break;
                }
            }
            setReadOnly(is_read_only)
            setPhotoProductList(themeData.schedule_photo_product_list);
        }
        else{
            setPhotoProductList([]);
        }
    }, [themeData])

    if(themeDataIsLoading){
        return(
            <LoopAnimationModal 
                isModalOpen={themeDataIsLoading}
            />
        )
    }else if(viewingMobilePage === VIEWING_MOBILE_PAGE_CODE.info){
        return (
            <ThreeSplitPages 
                title={""}
                background_color={"select_page"}
                is_navi_hidden={true}

                leftContainerNode={
                    <SplitPagesContainer>
                        <ShootList 
                            selectedThemeList={photoProductList} 
                            ref_list={l_shootListRef}
                            itemClickHandler={itemClickHandler}
                            topEleIndex={topEleIndex}
                        />
                    </SplitPagesContainer>
                }
                isLeftHidden={"mobile"}

                centerContainerNode={
                    <SplitPagesContainer>
                        <ThemeListContainer 
                            isReadOnly={readOnly}
                            photoProductList={photoProductList}
                            office_name={themeData?.office_name || ""}
                            addThemeHandler={addThemeHandler}
                            subThemeHandler={subThemeHandler}
                            ref_list={l_AllThemeListRef}
                            setTopEleIndex={setTopEleIndex}
                            parentsRef={l_AllTheme_div_ref}
                        />
                    </SplitPagesContainer>
                }
                isCenterHidden={"mobile"}
                centerRef={l_AllTheme_div_ref}

                rightContainerNode={
                    <SplitPagesContainer>
                        <SelectThemeController
                            photoProductList={photoProductList}
                            schedule_id={l_schedule_id}
                            contract_id={themeData?.contract_id || ""}
                            mobileGoToSelectThemePage={mobileGoToSelectThemePage}
                            subThemeHandler={subThemeHandler}
                            isReadOnly={readOnly}
                            contract_info={`${themeData?.contract_id} ${themeData?.package_name}`}
                            schedule_date_string={themeData?.schedule_date || ""}
                            photo_time={`${themeData?.photo_start} ~ ${themeData?.photo_end}`}
                            photo_name={themeData?.schedule_title || ""}
                            office_name={themeData?.office_name || ""}
                        />
                    </SplitPagesContainer>
                }
            />
        )
    }else if(viewingMobilePage === VIEWING_MOBILE_PAGE_CODE.selectTheme){
        return (
            <MainPage
                title={""}
                is_navi_hidden={true}
            >
                <ThemeList
                    isReadOnly={readOnly}
                    schedule_photo_product={photoProductList.find((item) => {
                        return item.schedule_photo_product_id === selectedSchedulePhotoProductId;
                    }) || null}
                    office_name={themeData?.office_name || ""}
                    addThemeHandler={addThemeHandler}
                    subThemeHandler={subThemeHandler}
                    mobileGoToThemeInfoPage={mobileGoToThemeInfoPage}
                />
            </MainPage>
        )
    }else{
        //  이 경우가 발생하는 일은 없을것이니, 원한다면 그냥 제거해버려도 될듯?
        return null;
    }
}

export default SelectTheme
