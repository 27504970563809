import LoopAnimation from "../LoopAnimation/LoopAnimation";
import Modal from "../Modal/Modal";
import CSS from "./static/css/loopAnimationModal.module.css";

const LoopAnimationModal = (
    {
        isModalOpen,
        children,
    }:{
        isModalOpen: boolean;
        children?: React.ReactNode;
    }
) => {
    return (
        <Modal isModalOpen={isModalOpen}>
            <div className={CSS.l_loop_animation_modal_main}>
                <LoopAnimation />
                <div className={CSS.l_loop_animation_modal__text_container}>
                    {children}
                </div>
            </div>
        </Modal>
    )
}

export default LoopAnimationModal;