import { MouseEventHandler, ReactNode, useEffect, useState } from "react";
import CSS from "./static/css/WrokRequiredItemContainer.module.css";

const WrokRequiredItemContainer = (
    {
        preset,
        class_name_list,
        children,
        onClick,
    }:{
        preset: "item" | "main_item";
        class_name_list?: string[];
        children?: ReactNode;
        onClick?: MouseEventHandler<HTMLDivElement>;
    }
) => {
    const [mainClassName, setMainClassName] = useState<string>(classNameSetter().join(" "));

    function classNameSetter(){
        const RTN: string[] = [];
        
        if(onClick){
            RTN.push("g_click_event_item");
        }

        if(class_name_list){
            RTN.push(...class_name_list);
        }

        RTN.push(CSS.l_WrokRequiredItemContainer_main);

        RTN.push(CSS.preset);
        RTN.push(CSS[preset]);

        return RTN;
    }

    useEffect(() => {
        setMainClassName(classNameSetter().join(" "))
    }, [class_name_list, preset, onClick])
    
    return(
        <div 
            className={mainClassName}
            onClick={onClick}
        >
            {children}
        </div>
    )
}

export default WrokRequiredItemContainer;