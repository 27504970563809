import DaumPostcodeEmbed, { Address } from 'react-daum-postcode';
import { useState } from 'react';
import CSS from "./static/css/postcode.module.css";
import { addressDataInterface } from './interface/useDaumPostcode.interface';
import Input from '../Input/Input';
import SearchIcon from '../Icon/SearchIcon/SearchIcon';
import Overlay from '../Overlay/Overlay';

const Postcode = (
    {
        customAddressData,
        setCustomAddressData,
        setRowAddressData,
        readOnly,
    }:{
        customAddressData: addressDataInterface
        setCustomAddressData: React.Dispatch<React.SetStateAction<addressDataInterface>>;
        setRowAddressData?: React.Dispatch<React.SetStateAction<Address | null>>;
        readOnly?: boolean;
    }
) => {

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleOpenModal = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();

        if(readOnly){
            return ;
        }

        setIsModalOpen(true);
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
    }

    const handleComplete = (data: Address) => {
        if(setRowAddressData){
            setRowAddressData(data);
        }
        
        setIsModalOpen(false);
    }

    const handleDetailAddressOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomAddressData((prv) => {
            return({
                ...prv,
                detailAddress: event.target.value
            })
        });
    }

    return (
        <div className={CSS.l_Postcode_main}>
            <div className={CSS.l_Postcode_inputContainer}>
                <div 
                    className={`${CSS.l_Postcode_inputEleContainer} ${CSS.PostNumer}`}
                    onClick={handleOpenModal}
                >
                    <Input
                        value={customAddressData.zonecode}
                        readOnly={true}
                        disabled={true}
                        placeholder="우편번호 검색"
                    >
                        <SearchIcon />
                    </Input>
                </div>

                <div 
                    className={`${CSS.l_Postcode_inputEleContainer} ${CSS.Address}`}
                    onClick={handleOpenModal}
                >
                    <Input 
                        value={customAddressData.mainAddress}
                        readOnly={true}
                        disabled={true}
                        placeholder="주소 입력"
                    />
                </div>

                <div className={`${CSS.l_Postcode_inputEleContainer} ${CSS.DetailAddress}`}>
                    <Input 
                        value={customAddressData.detailAddress}
                        onChange={handleDetailAddressOnChange}
                        placeholder="상세 주소 입력"
                        readOnly={readOnly}
                    />
                </div>
            </div>

            <Overlay
                isOverlayOpen={isModalOpen}
                overlayCloseHandler={handleCloseModal}
            >
                {
                    // DaumPostcodeEmbed컴포넌트는 onComplete가 동작한 직후 컴포넌트 자체가 소멸해 버리는것 같다
                    // 그래서 다시 사용하기위해 화면에 다시 띄우려고해도 단순 상태변환만으로는 화면에 다시는 나오지 못한다.
                    // 다시 보이게 하기위해 랜더링 자체를 다시 할 필요가 있다.
                    // 아직 다른 해결방안은 찾지 못함
                    isModalOpen
                    &&  <div className={CSS.l_Postcode_DaumPostcodeEmbedOverlay}>
                            <DaumPostcodeEmbed 
                                onComplete={handleComplete}
                            />
                        </div>
                }
            </Overlay>
        </div>
    )
}

export default Postcode;